(function (factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof globalThis['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'kotlin-browser'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'kotlin-browser'.");
    }
    globalThis['kotlin-browser'] = factory(typeof globalThis['kotlin-browser'] === 'undefined' ? {} : globalThis['kotlin-browser'], globalThis['kotlin-kotlin-stdlib']);
  }
}(function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var VOID = kotlin_kotlin.$_$.g;
  var protoOf = kotlin_kotlin.$_$.ab;
  var initMetadataForObject = kotlin_kotlin.$_$.fa;
  //endregion
  //region block: pre-declaration
  initMetadataForObject(HTML, 'HTML');
  //endregion
  function copy(_this__u8e3s4, bubbles, cancelable, composed, pseudoElement, animationName, elapsedTime) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    pseudoElement = pseudoElement === VOID ? VOID : pseudoElement;
    animationName = animationName === VOID ? VOID : animationName;
    elapsedTime = elapsedTime === VOID ? VOID : elapsedTime;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, pseudoElement: pseudoElement, animationName: animationName, elapsedTime: elapsedTime});
  }
  function invoke(_this__u8e3s4, bubbles, cancelable, composed, pseudoElement, animationName, elapsedTime) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    pseudoElement = pseudoElement === VOID ? VOID : pseudoElement;
    animationName = animationName === VOID ? VOID : animationName;
    elapsedTime = elapsedTime === VOID ? VOID : elapsedTime;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, pseudoElement: pseudoElement, animationName: animationName, elapsedTime: elapsedTime};
  }
  function copy_0(_this__u8e3s4, bubbles, cancelable, composed, timelineTime, currentTime) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    timelineTime = timelineTime === VOID ? VOID : timelineTime;
    currentTime = currentTime === VOID ? VOID : currentTime;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, timelineTime: timelineTime, currentTime: currentTime});
  }
  function invoke_0(_this__u8e3s4, bubbles, cancelable, composed, timelineTime, currentTime) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    timelineTime = timelineTime === VOID ? VOID : timelineTime;
    currentTime = currentTime === VOID ? VOID : currentTime;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, timelineTime: timelineTime, currentTime: currentTime};
  }
  function copy_1(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations, progress, endTime, activeDuration, currentIteration, startTime, localTime) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    progress = progress === VOID ? VOID : progress;
    endTime = endTime === VOID ? VOID : endTime;
    activeDuration = activeDuration === VOID ? VOID : activeDuration;
    currentIteration = currentIteration === VOID ? VOID : currentIteration;
    startTime = startTime === VOID ? VOID : startTime;
    localTime = localTime === VOID ? VOID : localTime;
    return Object.assign({}, _this__u8e3s4, {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations, progress: progress, endTime: endTime, activeDuration: activeDuration, currentIteration: currentIteration, startTime: startTime, localTime: localTime});
  }
  function invoke_1(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations, progress, endTime, activeDuration, currentIteration, startTime, localTime) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    progress = progress === VOID ? VOID : progress;
    endTime = endTime === VOID ? VOID : endTime;
    activeDuration = activeDuration === VOID ? VOID : activeDuration;
    currentIteration = currentIteration === VOID ? VOID : currentIteration;
    startTime = startTime === VOID ? VOID : startTime;
    localTime = localTime === VOID ? VOID : localTime;
    return {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations, progress: progress, endTime: endTime, activeDuration: activeDuration, currentIteration: currentIteration, startTime: startTime, localTime: localTime};
  }
  function copy_2(_this__u8e3s4, originTime) {
    originTime = originTime === VOID ? VOID : originTime;
    return Object.assign({}, _this__u8e3s4, {originTime: originTime});
  }
  function invoke_2(_this__u8e3s4, originTime) {
    originTime = originTime === VOID ? VOID : originTime;
    return {originTime: originTime};
  }
  function copy_3(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    return Object.assign({}, _this__u8e3s4, {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations});
  }
  function invoke_3(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    return {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations};
  }
  function copy_4(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations, composite, pseudoElement, iterationComposite, id, timeline) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    composite = composite === VOID ? VOID : composite;
    pseudoElement = pseudoElement === VOID ? VOID : pseudoElement;
    iterationComposite = iterationComposite === VOID ? VOID : iterationComposite;
    id = id === VOID ? VOID : id;
    timeline = timeline === VOID ? VOID : timeline;
    return Object.assign({}, _this__u8e3s4, {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations, composite: composite, pseudoElement: pseudoElement, iterationComposite: iterationComposite, id: id, timeline: timeline});
  }
  function invoke_4(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations, composite, pseudoElement, iterationComposite, id, timeline) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    composite = composite === VOID ? VOID : composite;
    pseudoElement = pseudoElement === VOID ? VOID : pseudoElement;
    iterationComposite = iterationComposite === VOID ? VOID : iterationComposite;
    id = id === VOID ? VOID : id;
    timeline = timeline === VOID ? VOID : timeline;
    return {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations, composite: composite, pseudoElement: pseudoElement, iterationComposite: iterationComposite, id: id, timeline: timeline};
  }
  function copy_5(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations, composite, pseudoElement, iterationComposite) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    composite = composite === VOID ? VOID : composite;
    pseudoElement = pseudoElement === VOID ? VOID : pseudoElement;
    iterationComposite = iterationComposite === VOID ? VOID : iterationComposite;
    return Object.assign({}, _this__u8e3s4, {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations, composite: composite, pseudoElement: pseudoElement, iterationComposite: iterationComposite});
  }
  function invoke_5(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations, composite, pseudoElement, iterationComposite) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    composite = composite === VOID ? VOID : composite;
    pseudoElement = pseudoElement === VOID ? VOID : pseudoElement;
    iterationComposite = iterationComposite === VOID ? VOID : iterationComposite;
    return {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations, composite: composite, pseudoElement: pseudoElement, iterationComposite: iterationComposite};
  }
  function copy_6(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    return Object.assign({}, _this__u8e3s4, {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations});
  }
  function invoke_6(_this__u8e3s4, direction, endDelay, fill, easing, iterationStart, duration, delay, playbackRate, iterations) {
    direction = direction === VOID ? VOID : direction;
    endDelay = endDelay === VOID ? VOID : endDelay;
    fill = fill === VOID ? VOID : fill;
    easing = easing === VOID ? VOID : easing;
    iterationStart = iterationStart === VOID ? VOID : iterationStart;
    duration = duration === VOID ? VOID : duration;
    delay = delay === VOID ? VOID : delay;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    iterations = iterations === VOID ? VOID : iterations;
    return {direction: direction, endDelay: endDelay, fill: fill, easing: easing, iterationStart: iterationStart, duration: duration, delay: delay, playbackRate: playbackRate, iterations: iterations};
  }
  function copy_7(_this__u8e3s4, appid, minPinLength, hmacCreateSecret, prf, credProps) {
    appid = appid === VOID ? VOID : appid;
    minPinLength = minPinLength === VOID ? VOID : minPinLength;
    hmacCreateSecret = hmacCreateSecret === VOID ? VOID : hmacCreateSecret;
    prf = prf === VOID ? VOID : prf;
    credProps = credProps === VOID ? VOID : credProps;
    return Object.assign({}, _this__u8e3s4, {appid: appid, minPinLength: minPinLength, hmacCreateSecret: hmacCreateSecret, prf: prf, credProps: credProps});
  }
  function invoke_7(_this__u8e3s4, appid, minPinLength, hmacCreateSecret, prf, credProps) {
    appid = appid === VOID ? VOID : appid;
    minPinLength = minPinLength === VOID ? VOID : minPinLength;
    hmacCreateSecret = hmacCreateSecret === VOID ? VOID : hmacCreateSecret;
    prf = prf === VOID ? VOID : prf;
    credProps = credProps === VOID ? VOID : credProps;
    return {appid: appid, minPinLength: minPinLength, hmacCreateSecret: hmacCreateSecret, prf: prf, credProps: credProps};
  }
  function copy_8(_this__u8e3s4, evalByCredential, eval_1uzks) {
    evalByCredential = evalByCredential === VOID ? VOID : evalByCredential;
    eval_1uzks = eval_1uzks === VOID ? VOID : eval_1uzks;
    return Object.assign({}, _this__u8e3s4, {evalByCredential: evalByCredential, 'eval': eval_1uzks});
  }
  function invoke_8(_this__u8e3s4, evalByCredential, eval_1uzks) {
    evalByCredential = evalByCredential === VOID ? VOID : evalByCredential;
    eval_1uzks = eval_1uzks === VOID ? VOID : eval_1uzks;
    return {evalByCredential: evalByCredential, 'eval': eval_1uzks};
  }
  function copy_9(_this__u8e3s4, second, first) {
    second = second === VOID ? VOID : second;
    first = first === VOID ? VOID : first;
    return Object.assign({}, _this__u8e3s4, {second: second, first: first});
  }
  function invoke_9(_this__u8e3s4, second, first) {
    second = second === VOID ? VOID : second;
    return {second: second, first: first};
  }
  function copy_10(_this__u8e3s4, authenticatorAttachment, requireResidentKey, residentKey, userVerification) {
    authenticatorAttachment = authenticatorAttachment === VOID ? VOID : authenticatorAttachment;
    requireResidentKey = requireResidentKey === VOID ? VOID : requireResidentKey;
    residentKey = residentKey === VOID ? VOID : residentKey;
    userVerification = userVerification === VOID ? VOID : userVerification;
    return Object.assign({}, _this__u8e3s4, {authenticatorAttachment: authenticatorAttachment, requireResidentKey: requireResidentKey, residentKey: residentKey, userVerification: userVerification});
  }
  function invoke_10(_this__u8e3s4, authenticatorAttachment, requireResidentKey, residentKey, userVerification) {
    authenticatorAttachment = authenticatorAttachment === VOID ? VOID : authenticatorAttachment;
    requireResidentKey = requireResidentKey === VOID ? VOID : requireResidentKey;
    residentKey = residentKey === VOID ? VOID : residentKey;
    userVerification = userVerification === VOID ? VOID : userVerification;
    return {authenticatorAttachment: authenticatorAttachment, requireResidentKey: requireResidentKey, residentKey: residentKey, userVerification: userVerification};
  }
  function copy_11(_this__u8e3s4, authenticatorSelection, rp, user, pubKeyCredParams, timeout, challenge, excludeCredentials, attestation, extensions) {
    authenticatorSelection = authenticatorSelection === VOID ? VOID : authenticatorSelection;
    rp = rp === VOID ? VOID : rp;
    user = user === VOID ? VOID : user;
    pubKeyCredParams = pubKeyCredParams === VOID ? VOID : pubKeyCredParams;
    timeout = timeout === VOID ? VOID : timeout;
    challenge = challenge === VOID ? VOID : challenge;
    excludeCredentials = excludeCredentials === VOID ? VOID : excludeCredentials;
    attestation = attestation === VOID ? VOID : attestation;
    extensions = extensions === VOID ? VOID : extensions;
    return Object.assign({}, _this__u8e3s4, {authenticatorSelection: authenticatorSelection, rp: rp, user: user, pubKeyCredParams: pubKeyCredParams, timeout: timeout, challenge: challenge, excludeCredentials: excludeCredentials, attestation: attestation, extensions: extensions});
  }
  function invoke_11(_this__u8e3s4, authenticatorSelection, rp, user, pubKeyCredParams, timeout, challenge, excludeCredentials, attestation, extensions) {
    authenticatorSelection = authenticatorSelection === VOID ? VOID : authenticatorSelection;
    timeout = timeout === VOID ? VOID : timeout;
    excludeCredentials = excludeCredentials === VOID ? VOID : excludeCredentials;
    attestation = attestation === VOID ? VOID : attestation;
    extensions = extensions === VOID ? VOID : extensions;
    return {authenticatorSelection: authenticatorSelection, rp: rp, user: user, pubKeyCredParams: pubKeyCredParams, timeout: timeout, challenge: challenge, excludeCredentials: excludeCredentials, attestation: attestation, extensions: extensions};
  }
  function copy_12(_this__u8e3s4, id, transports, type) {
    id = id === VOID ? VOID : id;
    transports = transports === VOID ? VOID : transports;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {id: id, transports: transports, type: type});
  }
  function invoke_12(_this__u8e3s4, id, transports, type) {
    transports = transports === VOID ? VOID : transports;
    return {id: id, transports: transports, type: type};
  }
  function copy_13(_this__u8e3s4, name) {
    name = name === VOID ? VOID : name;
    return Object.assign({}, _this__u8e3s4, {name: name});
  }
  function invoke_13(_this__u8e3s4, name) {
    return {name: name};
  }
  function copy_14(_this__u8e3s4, alg, type) {
    alg = alg === VOID ? VOID : alg;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {alg: alg, type: type});
  }
  function invoke_14(_this__u8e3s4, alg, type) {
    return {alg: alg, type: type};
  }
  function copy_15(_this__u8e3s4, timeout, challenge, extensions, userVerification, allowCredentials, rpId) {
    timeout = timeout === VOID ? VOID : timeout;
    challenge = challenge === VOID ? VOID : challenge;
    extensions = extensions === VOID ? VOID : extensions;
    userVerification = userVerification === VOID ? VOID : userVerification;
    allowCredentials = allowCredentials === VOID ? VOID : allowCredentials;
    rpId = rpId === VOID ? VOID : rpId;
    return Object.assign({}, _this__u8e3s4, {timeout: timeout, challenge: challenge, extensions: extensions, userVerification: userVerification, allowCredentials: allowCredentials, rpId: rpId});
  }
  function invoke_15(_this__u8e3s4, timeout, challenge, extensions, userVerification, allowCredentials, rpId) {
    timeout = timeout === VOID ? VOID : timeout;
    extensions = extensions === VOID ? VOID : extensions;
    userVerification = userVerification === VOID ? VOID : userVerification;
    allowCredentials = allowCredentials === VOID ? VOID : allowCredentials;
    rpId = rpId === VOID ? VOID : rpId;
    return {timeout: timeout, challenge: challenge, extensions: extensions, userVerification: userVerification, allowCredentials: allowCredentials, rpId: rpId};
  }
  function copy_16(_this__u8e3s4, name, id) {
    name = name === VOID ? VOID : name;
    id = id === VOID ? VOID : id;
    return Object.assign({}, _this__u8e3s4, {name: name, id: id});
  }
  function invoke_16(_this__u8e3s4, name, id) {
    id = id === VOID ? VOID : id;
    return {name: name, id: id};
  }
  function copy_17(_this__u8e3s4, name, id, displayName) {
    name = name === VOID ? VOID : name;
    id = id === VOID ? VOID : id;
    displayName = displayName === VOID ? VOID : displayName;
    return Object.assign({}, _this__u8e3s4, {name: name, id: id, displayName: displayName});
  }
  function invoke_17(_this__u8e3s4, name, id, displayName) {
    return {name: name, id: id, displayName: displayName};
  }
  function copy_18(_this__u8e3s4, quality, type) {
    quality = quality === VOID ? VOID : quality;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {quality: quality, type: type});
  }
  function invoke_18(_this__u8e3s4, quality, type) {
    quality = quality === VOID ? VOID : quality;
    type = type === VOID ? VOID : type;
    return {quality: quality, type: type};
  }
  function copy_19(_this__u8e3s4, bubbles, cancelable, composed, clipboardData) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    clipboardData = clipboardData === VOID ? VOID : clipboardData;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, clipboardData: clipboardData});
  }
  function invoke_19(_this__u8e3s4, bubbles, cancelable, composed, clipboardData) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    clipboardData = clipboardData === VOID ? VOID : clipboardData;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, clipboardData: clipboardData};
  }
  function copy_20(_this__u8e3s4, presentationStyle) {
    presentationStyle = presentationStyle === VOID ? VOID : presentationStyle;
    return Object.assign({}, _this__u8e3s4, {presentationStyle: presentationStyle});
  }
  function invoke_20(_this__u8e3s4, presentationStyle) {
    presentationStyle = presentationStyle === VOID ? VOID : presentationStyle;
    return {presentationStyle: presentationStyle};
  }
  function copy_21(_this__u8e3s4, flatten) {
    flatten = flatten === VOID ? VOID : flatten;
    return Object.assign({}, _this__u8e3s4, {flatten: flatten});
  }
  function invoke_21(_this__u8e3s4, flatten) {
    flatten = flatten === VOID ? VOID : flatten;
    return {flatten: flatten};
  }
  function copy_22(_this__u8e3s4, delegatesFocus, serializable, slotAssignment, mode) {
    delegatesFocus = delegatesFocus === VOID ? VOID : delegatesFocus;
    serializable = serializable === VOID ? VOID : serializable;
    slotAssignment = slotAssignment === VOID ? VOID : slotAssignment;
    mode = mode === VOID ? VOID : mode;
    return Object.assign({}, _this__u8e3s4, {delegatesFocus: delegatesFocus, serializable: serializable, slotAssignment: slotAssignment, mode: mode});
  }
  function invoke_22(_this__u8e3s4, delegatesFocus, serializable, slotAssignment, mode) {
    delegatesFocus = delegatesFocus === VOID ? VOID : delegatesFocus;
    serializable = serializable === VOID ? VOID : serializable;
    slotAssignment = slotAssignment === VOID ? VOID : slotAssignment;
    return {delegatesFocus: delegatesFocus, serializable: serializable, slotAssignment: slotAssignment, mode: mode};
  }
  function copy_23(_this__u8e3s4, signal, signal_0, publicKey) {
    signal = signal === VOID ? VOID : signal;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    publicKey = publicKey === VOID ? VOID : publicKey;
    return Object.assign({}, _this__u8e3s4, {signal: signal_0, signal: signal_0, publicKey: publicKey});
  }
  function invoke_23(_this__u8e3s4, signal, signal_0, publicKey) {
    signal = signal === VOID ? VOID : signal;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    publicKey = publicKey === VOID ? VOID : publicKey;
    return {signal: signal_0, signal: signal_0, publicKey: publicKey};
  }
  function copy_24(_this__u8e3s4, signal, signal_0, publicKey, mediation) {
    signal = signal === VOID ? VOID : signal;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    publicKey = publicKey === VOID ? VOID : publicKey;
    mediation = mediation === VOID ? VOID : mediation;
    return Object.assign({}, _this__u8e3s4, {signal: signal_0, signal: signal_0, publicKey: publicKey, mediation: mediation});
  }
  function invoke_24(_this__u8e3s4, signal, signal_0, publicKey, mediation) {
    signal = signal === VOID ? VOID : signal;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    publicKey = publicKey === VOID ? VOID : publicKey;
    mediation = mediation === VOID ? VOID : mediation;
    return {signal: signal_0, signal: signal_0, publicKey: publicKey, mediation: mediation};
  }
  function copy_25(_this__u8e3s4, bubbles, cancelable, composed, referrer, effectiveDirective, blockedURI, sourceFile, sample, violatedDirective, statusCode, lineNumber, disposition, originalPolicy, columnNumber, documentURI) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    referrer = referrer === VOID ? VOID : referrer;
    effectiveDirective = effectiveDirective === VOID ? VOID : effectiveDirective;
    blockedURI = blockedURI === VOID ? VOID : blockedURI;
    sourceFile = sourceFile === VOID ? VOID : sourceFile;
    sample = sample === VOID ? VOID : sample;
    violatedDirective = violatedDirective === VOID ? VOID : violatedDirective;
    statusCode = statusCode === VOID ? VOID : statusCode;
    lineNumber = lineNumber === VOID ? VOID : lineNumber;
    disposition = disposition === VOID ? VOID : disposition;
    originalPolicy = originalPolicy === VOID ? VOID : originalPolicy;
    columnNumber = columnNumber === VOID ? VOID : columnNumber;
    documentURI = documentURI === VOID ? VOID : documentURI;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, referrer: referrer, effectiveDirective: effectiveDirective, blockedURI: blockedURI, sourceFile: sourceFile, sample: sample, violatedDirective: violatedDirective, statusCode: statusCode, lineNumber: lineNumber, disposition: disposition, originalPolicy: originalPolicy, columnNumber: columnNumber, documentURI: documentURI});
  }
  function invoke_25(_this__u8e3s4, bubbles, cancelable, composed, referrer, effectiveDirective, blockedURI, sourceFile, sample, violatedDirective, statusCode, lineNumber, disposition, originalPolicy, columnNumber, documentURI) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    referrer = referrer === VOID ? VOID : referrer;
    effectiveDirective = effectiveDirective === VOID ? VOID : effectiveDirective;
    blockedURI = blockedURI === VOID ? VOID : blockedURI;
    sourceFile = sourceFile === VOID ? VOID : sourceFile;
    sample = sample === VOID ? VOID : sample;
    violatedDirective = violatedDirective === VOID ? VOID : violatedDirective;
    statusCode = statusCode === VOID ? VOID : statusCode;
    lineNumber = lineNumber === VOID ? VOID : lineNumber;
    disposition = disposition === VOID ? VOID : disposition;
    originalPolicy = originalPolicy === VOID ? VOID : originalPolicy;
    columnNumber = columnNumber === VOID ? VOID : columnNumber;
    documentURI = documentURI === VOID ? VOID : documentURI;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, referrer: referrer, effectiveDirective: effectiveDirective, blockedURI: blockedURI, sourceFile: sourceFile, sample: sample, violatedDirective: violatedDirective, statusCode: statusCode, lineNumber: lineNumber, disposition: disposition, originalPolicy: originalPolicy, columnNumber: columnNumber, documentURI: documentURI};
  }
  function copy_26(_this__u8e3s4, media, disabled, baseURL) {
    media = media === VOID ? VOID : media;
    disabled = disabled === VOID ? VOID : disabled;
    baseURL = baseURL === VOID ? VOID : baseURL;
    return Object.assign({}, _this__u8e3s4, {media: media, disabled: disabled, baseURL: baseURL});
  }
  function invoke_26(_this__u8e3s4, media, disabled, baseURL) {
    media = media === VOID ? VOID : media;
    disabled = disabled === VOID ? VOID : disabled;
    baseURL = baseURL === VOID ? VOID : baseURL;
    return {media: media, disabled: disabled, baseURL: baseURL};
  }
  function copy_27(_this__u8e3s4, bubbles, cancelable, composed, propertyName, pseudoElement, elapsedTime) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    propertyName = propertyName === VOID ? VOID : propertyName;
    pseudoElement = pseudoElement === VOID ? VOID : pseudoElement;
    elapsedTime = elapsedTime === VOID ? VOID : elapsedTime;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, propertyName: propertyName, pseudoElement: pseudoElement, elapsedTime: elapsedTime});
  }
  function invoke_27(_this__u8e3s4, bubbles, cancelable, composed, propertyName, pseudoElement, elapsedTime) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    propertyName = propertyName === VOID ? VOID : propertyName;
    pseudoElement = pseudoElement === VOID ? VOID : pseudoElement;
    elapsedTime = elapsedTime === VOID ? VOID : elapsedTime;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, propertyName: propertyName, pseudoElement: pseudoElement, elapsedTime: elapsedTime};
  }
  function copy_28(_this__u8e3s4, z, y, x) {
    z = z === VOID ? VOID : z;
    y = y === VOID ? VOID : y;
    x = x === VOID ? VOID : x;
    return Object.assign({}, _this__u8e3s4, {z: z, y: y, x: x});
  }
  function invoke_28(_this__u8e3s4, z, y, x) {
    z = z === VOID ? VOID : z;
    y = y === VOID ? VOID : y;
    x = x === VOID ? VOID : x;
    return {z: z, y: y, x: x};
  }
  function copy_29(_this__u8e3s4, bubbles, cancelable, composed, accelerationIncludingGravity, interval, acceleration, rotationRate) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    accelerationIncludingGravity = accelerationIncludingGravity === VOID ? VOID : accelerationIncludingGravity;
    interval = interval === VOID ? VOID : interval;
    acceleration = acceleration === VOID ? VOID : acceleration;
    rotationRate = rotationRate === VOID ? VOID : rotationRate;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, accelerationIncludingGravity: accelerationIncludingGravity, interval: interval, acceleration: acceleration, rotationRate: rotationRate});
  }
  function invoke_29(_this__u8e3s4, bubbles, cancelable, composed, accelerationIncludingGravity, interval, acceleration, rotationRate) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    accelerationIncludingGravity = accelerationIncludingGravity === VOID ? VOID : accelerationIncludingGravity;
    interval = interval === VOID ? VOID : interval;
    acceleration = acceleration === VOID ? VOID : acceleration;
    rotationRate = rotationRate === VOID ? VOID : rotationRate;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, accelerationIncludingGravity: accelerationIncludingGravity, interval: interval, acceleration: acceleration, rotationRate: rotationRate};
  }
  function copy_30(_this__u8e3s4, beta, gamma, alpha) {
    beta = beta === VOID ? VOID : beta;
    gamma = gamma === VOID ? VOID : gamma;
    alpha = alpha === VOID ? VOID : alpha;
    return Object.assign({}, _this__u8e3s4, {beta: beta, gamma: gamma, alpha: alpha});
  }
  function invoke_30(_this__u8e3s4, beta, gamma, alpha) {
    beta = beta === VOID ? VOID : beta;
    gamma = gamma === VOID ? VOID : gamma;
    alpha = alpha === VOID ? VOID : alpha;
    return {beta: beta, gamma: gamma, alpha: alpha};
  }
  function copy_31(_this__u8e3s4, bubbles, cancelable, composed, beta, gamma, absolute, alpha) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    beta = beta === VOID ? VOID : beta;
    gamma = gamma === VOID ? VOID : gamma;
    absolute = absolute === VOID ? VOID : absolute;
    alpha = alpha === VOID ? VOID : alpha;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, beta: beta, gamma: gamma, absolute: absolute, alpha: alpha});
  }
  function invoke_31(_this__u8e3s4, bubbles, cancelable, composed, beta, gamma, absolute, alpha) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    beta = beta === VOID ? VOID : beta;
    gamma = gamma === VOID ? VOID : gamma;
    absolute = absolute === VOID ? VOID : absolute;
    alpha = alpha === VOID ? VOID : alpha;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, beta: beta, gamma: gamma, absolute: absolute, alpha: alpha};
  }
  function copy_32(_this__u8e3s4, shadowRoots) {
    shadowRoots = shadowRoots === VOID ? VOID : shadowRoots;
    return Object.assign({}, _this__u8e3s4, {shadowRoots: shadowRoots});
  }
  function invoke_32(_this__u8e3s4, shadowRoots) {
    shadowRoots = shadowRoots === VOID ? VOID : shadowRoots;
    return {shadowRoots: shadowRoots};
  }
  function copy_33(_this__u8e3s4, contentVisibilityAuto, opacityProperty, visibilityProperty, checkVisibilityCSS, checkOpacity) {
    contentVisibilityAuto = contentVisibilityAuto === VOID ? VOID : contentVisibilityAuto;
    opacityProperty = opacityProperty === VOID ? VOID : opacityProperty;
    visibilityProperty = visibilityProperty === VOID ? VOID : visibilityProperty;
    checkVisibilityCSS = checkVisibilityCSS === VOID ? VOID : checkVisibilityCSS;
    checkOpacity = checkOpacity === VOID ? VOID : checkOpacity;
    return Object.assign({}, _this__u8e3s4, {contentVisibilityAuto: contentVisibilityAuto, opacityProperty: opacityProperty, visibilityProperty: visibilityProperty, checkVisibilityCSS: checkVisibilityCSS, checkOpacity: checkOpacity});
  }
  function invoke_33(_this__u8e3s4, contentVisibilityAuto, opacityProperty, visibilityProperty, checkVisibilityCSS, checkOpacity) {
    contentVisibilityAuto = contentVisibilityAuto === VOID ? VOID : contentVisibilityAuto;
    opacityProperty = opacityProperty === VOID ? VOID : opacityProperty;
    visibilityProperty = visibilityProperty === VOID ? VOID : visibilityProperty;
    checkVisibilityCSS = checkVisibilityCSS === VOID ? VOID : checkVisibilityCSS;
    checkOpacity = checkOpacity === VOID ? VOID : checkOpacity;
    return {contentVisibilityAuto: contentVisibilityAuto, opacityProperty: opacityProperty, visibilityProperty: visibilityProperty, checkVisibilityCSS: checkVisibilityCSS, checkOpacity: checkOpacity};
  }
  function copy_34(_this__u8e3s4, is) {
    is = is === VOID ? VOID : is;
    return Object.assign({}, _this__u8e3s4, {is: is});
  }
  function invoke_34(_this__u8e3s4, is) {
    is = is === VOID ? VOID : is;
    return {is: is};
  }
  function copy_35(_this__u8e3s4, preventScroll) {
    preventScroll = preventScroll === VOID ? VOID : preventScroll;
    return Object.assign({}, _this__u8e3s4, {preventScroll: preventScroll});
  }
  function invoke_35(_this__u8e3s4, preventScroll) {
    preventScroll = preventScroll === VOID ? VOID : preventScroll;
    return {preventScroll: preventScroll};
  }
  function copy_36(_this__u8e3s4, subtree) {
    subtree = subtree === VOID ? VOID : subtree;
    return Object.assign({}, _this__u8e3s4, {subtree: subtree});
  }
  function invoke_36(_this__u8e3s4, subtree) {
    subtree = subtree === VOID ? VOID : subtree;
    return {subtree: subtree};
  }
  function copy_37(_this__u8e3s4, shadowRoots, serializableShadowRoots) {
    shadowRoots = shadowRoots === VOID ? VOID : shadowRoots;
    serializableShadowRoots = serializableShadowRoots === VOID ? VOID : serializableShadowRoots;
    return Object.assign({}, _this__u8e3s4, {shadowRoots: shadowRoots, serializableShadowRoots: serializableShadowRoots});
  }
  function invoke_37(_this__u8e3s4, shadowRoots, serializableShadowRoots) {
    shadowRoots = shadowRoots === VOID ? VOID : shadowRoots;
    serializableShadowRoots = serializableShadowRoots === VOID ? VOID : serializableShadowRoots;
    return {shadowRoots: shadowRoots, serializableShadowRoots: serializableShadowRoots};
  }
  function copy_38(_this__u8e3s4, composed) {
    composed = composed === VOID ? VOID : composed;
    return Object.assign({}, _this__u8e3s4, {composed: composed});
  }
  function invoke_38(_this__u8e3s4, composed) {
    composed = composed === VOID ? VOID : composed;
    return {composed: composed};
  }
  function copy_39(_this__u8e3s4, unadjustedMovement) {
    unadjustedMovement = unadjustedMovement === VOID ? VOID : unadjustedMovement;
    return Object.assign({}, _this__u8e3s4, {unadjustedMovement: unadjustedMovement});
  }
  function invoke_39(_this__u8e3s4, unadjustedMovement) {
    unadjustedMovement = unadjustedMovement === VOID ? VOID : unadjustedMovement;
    return {unadjustedMovement: unadjustedMovement};
  }
  function copy_40(_this__u8e3s4, bubbles, cancelable, composed, lineno, colno, filename, message, error) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    lineno = lineno === VOID ? VOID : lineno;
    colno = colno === VOID ? VOID : colno;
    filename = filename === VOID ? VOID : filename;
    message = message === VOID ? VOID : message;
    error = error === VOID ? VOID : error;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, lineno: lineno, colno: colno, filename: filename, message: message, error: error});
  }
  function invoke_40(_this__u8e3s4, bubbles, cancelable, composed, lineno, colno, filename, message, error) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    lineno = lineno === VOID ? VOID : lineno;
    colno = colno === VOID ? VOID : colno;
    filename = filename === VOID ? VOID : filename;
    message = message === VOID ? VOID : message;
    error = error === VOID ? VOID : error;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, lineno: lineno, colno: colno, filename: filename, message: message, error: error};
  }
  function copy_41(_this__u8e3s4, lineGapOverride, descentOverride, display, featureSettings, style, unicodeRange, ascentOverride, stretch, weight) {
    lineGapOverride = lineGapOverride === VOID ? VOID : lineGapOverride;
    descentOverride = descentOverride === VOID ? VOID : descentOverride;
    display = display === VOID ? VOID : display;
    featureSettings = featureSettings === VOID ? VOID : featureSettings;
    style = style === VOID ? VOID : style;
    unicodeRange = unicodeRange === VOID ? VOID : unicodeRange;
    ascentOverride = ascentOverride === VOID ? VOID : ascentOverride;
    stretch = stretch === VOID ? VOID : stretch;
    weight = weight === VOID ? VOID : weight;
    return Object.assign({}, _this__u8e3s4, {lineGapOverride: lineGapOverride, descentOverride: descentOverride, display: display, featureSettings: featureSettings, style: style, unicodeRange: unicodeRange, ascentOverride: ascentOverride, stretch: stretch, weight: weight});
  }
  function invoke_41(_this__u8e3s4, lineGapOverride, descentOverride, display, featureSettings, style, unicodeRange, ascentOverride, stretch, weight) {
    lineGapOverride = lineGapOverride === VOID ? VOID : lineGapOverride;
    descentOverride = descentOverride === VOID ? VOID : descentOverride;
    display = display === VOID ? VOID : display;
    featureSettings = featureSettings === VOID ? VOID : featureSettings;
    style = style === VOID ? VOID : style;
    unicodeRange = unicodeRange === VOID ? VOID : unicodeRange;
    ascentOverride = ascentOverride === VOID ? VOID : ascentOverride;
    stretch = stretch === VOID ? VOID : stretch;
    weight = weight === VOID ? VOID : weight;
    return {lineGapOverride: lineGapOverride, descentOverride: descentOverride, display: display, featureSettings: featureSettings, style: style, unicodeRange: unicodeRange, ascentOverride: ascentOverride, stretch: stretch, weight: weight};
  }
  function copy_42(_this__u8e3s4, bubbles, cancelable, composed, fontfaces) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    fontfaces = fontfaces === VOID ? VOID : fontfaces;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, fontfaces: fontfaces});
  }
  function invoke_42(_this__u8e3s4, bubbles, cancelable, composed, fontfaces) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    fontfaces = fontfaces === VOID ? VOID : fontfaces;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, fontfaces: fontfaces};
  }
  function copy_43(_this__u8e3s4, bubbles, cancelable, composed, formData) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    formData = formData === VOID ? VOID : formData;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, formData: formData});
  }
  function invoke_43(_this__u8e3s4, bubbles, cancelable, composed, formData) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, formData: formData};
  }
  function copy_44(_this__u8e3s4, bubbles, cancelable, composed, submitter) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    submitter = submitter === VOID ? VOID : submitter;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, submitter: submitter});
  }
  function invoke_44(_this__u8e3s4, bubbles, cancelable, composed, submitter) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    submitter = submitter === VOID ? VOID : submitter;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, submitter: submitter};
  }
  function copy_45(_this__u8e3s4, keepExistingData) {
    keepExistingData = keepExistingData === VOID ? VOID : keepExistingData;
    return Object.assign({}, _this__u8e3s4, {keepExistingData: keepExistingData});
  }
  function invoke_45(_this__u8e3s4, keepExistingData) {
    keepExistingData = keepExistingData === VOID ? VOID : keepExistingData;
    return {keepExistingData: keepExistingData};
  }
  function copy_46(_this__u8e3s4, exclusive, create) {
    exclusive = exclusive === VOID ? VOID : exclusive;
    create = create === VOID ? VOID : create;
    return Object.assign({}, _this__u8e3s4, {exclusive: exclusive, create: create});
  }
  function invoke_46(_this__u8e3s4, exclusive, create) {
    exclusive = exclusive === VOID ? VOID : exclusive;
    create = create === VOID ? VOID : create;
    return {exclusive: exclusive, create: create};
  }
  function copy_47(_this__u8e3s4, create) {
    create = create === VOID ? VOID : create;
    return Object.assign({}, _this__u8e3s4, {create: create});
  }
  function invoke_47(_this__u8e3s4, create) {
    create = create === VOID ? VOID : create;
    return {create: create};
  }
  function copy_48(_this__u8e3s4, create) {
    create = create === VOID ? VOID : create;
    return Object.assign({}, _this__u8e3s4, {create: create});
  }
  function invoke_48(_this__u8e3s4, create) {
    create = create === VOID ? VOID : create;
    return {create: create};
  }
  function copy_49(_this__u8e3s4, at) {
    at = at === VOID ? VOID : at;
    return Object.assign({}, _this__u8e3s4, {at: at});
  }
  function invoke_49(_this__u8e3s4, at) {
    at = at === VOID ? VOID : at;
    return {at: at};
  }
  function copy_50(_this__u8e3s4, recursive) {
    recursive = recursive === VOID ? VOID : recursive;
    return Object.assign({}, _this__u8e3s4, {recursive: recursive});
  }
  function invoke_50(_this__u8e3s4, recursive) {
    recursive = recursive === VOID ? VOID : recursive;
    return {recursive: recursive};
  }
  function copy_51(_this__u8e3s4, navigationUI) {
    navigationUI = navigationUI === VOID ? VOID : navigationUI;
    return Object.assign({}, _this__u8e3s4, {navigationUI: navigationUI});
  }
  function invoke_51(_this__u8e3s4, navigationUI) {
    navigationUI = navigationUI === VOID ? VOID : navigationUI;
    return {navigationUI: navigationUI};
  }
  function copy_52(_this__u8e3s4, strongMagnitude, rightTrigger, duration, startDelay, weakMagnitude, leftTrigger) {
    strongMagnitude = strongMagnitude === VOID ? VOID : strongMagnitude;
    rightTrigger = rightTrigger === VOID ? VOID : rightTrigger;
    duration = duration === VOID ? VOID : duration;
    startDelay = startDelay === VOID ? VOID : startDelay;
    weakMagnitude = weakMagnitude === VOID ? VOID : weakMagnitude;
    leftTrigger = leftTrigger === VOID ? VOID : leftTrigger;
    return Object.assign({}, _this__u8e3s4, {strongMagnitude: strongMagnitude, rightTrigger: rightTrigger, duration: duration, startDelay: startDelay, weakMagnitude: weakMagnitude, leftTrigger: leftTrigger});
  }
  function invoke_52(_this__u8e3s4, strongMagnitude, rightTrigger, duration, startDelay, weakMagnitude, leftTrigger) {
    strongMagnitude = strongMagnitude === VOID ? VOID : strongMagnitude;
    rightTrigger = rightTrigger === VOID ? VOID : rightTrigger;
    duration = duration === VOID ? VOID : duration;
    startDelay = startDelay === VOID ? VOID : startDelay;
    weakMagnitude = weakMagnitude === VOID ? VOID : weakMagnitude;
    leftTrigger = leftTrigger === VOID ? VOID : leftTrigger;
    return {strongMagnitude: strongMagnitude, rightTrigger: rightTrigger, duration: duration, startDelay: startDelay, weakMagnitude: weakMagnitude, leftTrigger: leftTrigger};
  }
  function copy_53(_this__u8e3s4, bubbles, cancelable, composed, gamepad) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    gamepad = gamepad === VOID ? VOID : gamepad;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, gamepad: gamepad});
  }
  function invoke_53(_this__u8e3s4, bubbles, cancelable, composed, gamepad) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, gamepad: gamepad};
  }
  function copy_54(_this__u8e3s4, maximumAge, enableHighAccuracy, timeout) {
    maximumAge = maximumAge === VOID ? VOID : maximumAge;
    enableHighAccuracy = enableHighAccuracy === VOID ? VOID : enableHighAccuracy;
    timeout = timeout === VOID ? VOID : timeout;
    return Object.assign({}, _this__u8e3s4, {maximumAge: maximumAge, enableHighAccuracy: enableHighAccuracy, timeout: timeout});
  }
  function invoke_54(_this__u8e3s4, maximumAge, enableHighAccuracy, timeout) {
    maximumAge = maximumAge === VOID ? VOID : maximumAge;
    enableHighAccuracy = enableHighAccuracy === VOID ? VOID : enableHighAccuracy;
    timeout = timeout === VOID ? VOID : timeout;
    return {maximumAge: maximumAge, enableHighAccuracy: enableHighAccuracy, timeout: timeout};
  }
  function copy_55(_this__u8e3s4, m12, m11, m22, m41, m21, m42, f, e, d, c, b, a) {
    m12 = m12 === VOID ? VOID : m12;
    m11 = m11 === VOID ? VOID : m11;
    m22 = m22 === VOID ? VOID : m22;
    m41 = m41 === VOID ? VOID : m41;
    m21 = m21 === VOID ? VOID : m21;
    m42 = m42 === VOID ? VOID : m42;
    f = f === VOID ? VOID : f;
    e = e === VOID ? VOID : e;
    d = d === VOID ? VOID : d;
    c = c === VOID ? VOID : c;
    b = b === VOID ? VOID : b;
    a = a === VOID ? VOID : a;
    return Object.assign({}, _this__u8e3s4, {m12: m12, m11: m11, m22: m22, m41: m41, m21: m21, m42: m42, f: f, e: e, d: d, c: c, b: b, a: a});
  }
  function invoke_55(_this__u8e3s4, m12, m11, m22, m41, m21, m42, f, e, d, c, b, a) {
    m12 = m12 === VOID ? VOID : m12;
    m11 = m11 === VOID ? VOID : m11;
    m22 = m22 === VOID ? VOID : m22;
    m41 = m41 === VOID ? VOID : m41;
    m21 = m21 === VOID ? VOID : m21;
    m42 = m42 === VOID ? VOID : m42;
    f = f === VOID ? VOID : f;
    e = e === VOID ? VOID : e;
    d = d === VOID ? VOID : d;
    c = c === VOID ? VOID : c;
    b = b === VOID ? VOID : b;
    a = a === VOID ? VOID : a;
    return {m12: m12, m11: m11, m22: m22, m41: m41, m21: m21, m42: m42, f: f, e: e, d: d, c: c, b: b, a: a};
  }
  function copy_56(_this__u8e3s4, m12, m11, m22, m41, m21, m42, f, e, d, c, b, a, m23, m34, m33, m44, m14, m13, m24, m32, m43, m31, is2D) {
    m12 = m12 === VOID ? VOID : m12;
    m11 = m11 === VOID ? VOID : m11;
    m22 = m22 === VOID ? VOID : m22;
    m41 = m41 === VOID ? VOID : m41;
    m21 = m21 === VOID ? VOID : m21;
    m42 = m42 === VOID ? VOID : m42;
    f = f === VOID ? VOID : f;
    e = e === VOID ? VOID : e;
    d = d === VOID ? VOID : d;
    c = c === VOID ? VOID : c;
    b = b === VOID ? VOID : b;
    a = a === VOID ? VOID : a;
    m23 = m23 === VOID ? VOID : m23;
    m34 = m34 === VOID ? VOID : m34;
    m33 = m33 === VOID ? VOID : m33;
    m44 = m44 === VOID ? VOID : m44;
    m14 = m14 === VOID ? VOID : m14;
    m13 = m13 === VOID ? VOID : m13;
    m24 = m24 === VOID ? VOID : m24;
    m32 = m32 === VOID ? VOID : m32;
    m43 = m43 === VOID ? VOID : m43;
    m31 = m31 === VOID ? VOID : m31;
    is2D = is2D === VOID ? VOID : is2D;
    return Object.assign({}, _this__u8e3s4, {m12: m12, m11: m11, m22: m22, m41: m41, m21: m21, m42: m42, f: f, e: e, d: d, c: c, b: b, a: a, m23: m23, m34: m34, m33: m33, m44: m44, m14: m14, m13: m13, m24: m24, m32: m32, m43: m43, m31: m31, is2D: is2D});
  }
  function invoke_56(_this__u8e3s4, m12, m11, m22, m41, m21, m42, f, e, d, c, b, a, m23, m34, m33, m44, m14, m13, m24, m32, m43, m31, is2D) {
    m12 = m12 === VOID ? VOID : m12;
    m11 = m11 === VOID ? VOID : m11;
    m22 = m22 === VOID ? VOID : m22;
    m41 = m41 === VOID ? VOID : m41;
    m21 = m21 === VOID ? VOID : m21;
    m42 = m42 === VOID ? VOID : m42;
    f = f === VOID ? VOID : f;
    e = e === VOID ? VOID : e;
    d = d === VOID ? VOID : d;
    c = c === VOID ? VOID : c;
    b = b === VOID ? VOID : b;
    a = a === VOID ? VOID : a;
    m23 = m23 === VOID ? VOID : m23;
    m34 = m34 === VOID ? VOID : m34;
    m33 = m33 === VOID ? VOID : m33;
    m44 = m44 === VOID ? VOID : m44;
    m14 = m14 === VOID ? VOID : m14;
    m13 = m13 === VOID ? VOID : m13;
    m24 = m24 === VOID ? VOID : m24;
    m32 = m32 === VOID ? VOID : m32;
    m43 = m43 === VOID ? VOID : m43;
    m31 = m31 === VOID ? VOID : m31;
    is2D = is2D === VOID ? VOID : is2D;
    return {m12: m12, m11: m11, m22: m22, m41: m41, m21: m21, m42: m42, f: f, e: e, d: d, c: c, b: b, a: a, m23: m23, m34: m34, m33: m33, m44: m44, m14: m14, m13: m13, m24: m24, m32: m32, m43: m43, m31: m31, is2D: is2D};
  }
  function copy_57(_this__u8e3s4, z, y, x, w) {
    z = z === VOID ? VOID : z;
    y = y === VOID ? VOID : y;
    x = x === VOID ? VOID : x;
    w = w === VOID ? VOID : w;
    return Object.assign({}, _this__u8e3s4, {z: z, y: y, x: x, w: w});
  }
  function invoke_57(_this__u8e3s4, z, y, x, w) {
    z = z === VOID ? VOID : z;
    y = y === VOID ? VOID : y;
    x = x === VOID ? VOID : x;
    w = w === VOID ? VOID : w;
    return {z: z, y: y, x: x, w: w};
  }
  function copy_58(_this__u8e3s4, height, y, x, width) {
    height = height === VOID ? VOID : height;
    y = y === VOID ? VOID : y;
    x = x === VOID ? VOID : x;
    width = width === VOID ? VOID : width;
    return Object.assign({}, _this__u8e3s4, {height: height, y: y, x: x, width: width});
  }
  function invoke_58(_this__u8e3s4, height, y, x, width) {
    height = height === VOID ? VOID : height;
    y = y === VOID ? VOID : y;
    x = x === VOID ? VOID : x;
    width = width === VOID ? VOID : width;
    return {height: height, y: y, x: x, width: width};
  }
  function copy_59(_this__u8e3s4, bubbles, cancelable, composed, oldURL, newURL) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    oldURL = oldURL === VOID ? VOID : oldURL;
    newURL = newURL === VOID ? VOID : newURL;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, oldURL: oldURL, newURL: newURL});
  }
  function invoke_59(_this__u8e3s4, bubbles, cancelable, composed, oldURL, newURL) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    oldURL = oldURL === VOID ? VOID : oldURL;
    newURL = newURL === VOID ? VOID : newURL;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, oldURL: oldURL, newURL: newURL};
  }
  function copy_60(_this__u8e3s4, bubbles, cancelable, composed, persisted) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    persisted = persisted === VOID ? VOID : persisted;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, persisted: persisted});
  }
  function invoke_60(_this__u8e3s4, bubbles, cancelable, composed, persisted) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    persisted = persisted === VOID ? VOID : persisted;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, persisted: persisted};
  }
  function copy_61(_this__u8e3s4, bubbles, cancelable, composed, state) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    state = state === VOID ? VOID : state;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, state: state});
  }
  function invoke_61(_this__u8e3s4, bubbles, cancelable, composed, state) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    state = state === VOID ? VOID : state;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, state: state};
  }
  function HTML() {
  }
  var HTML_instance;
  function HTML_getInstance() {
    return HTML_instance;
  }
  function copy_62(_this__u8e3s4, bubbles, cancelable, composed, initDataType, initData) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    initDataType = initDataType === VOID ? VOID : initDataType;
    initData = initData === VOID ? VOID : initData;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, initDataType: initDataType, initData: initData});
  }
  function invoke_62(_this__u8e3s4, bubbles, cancelable, composed, initDataType, initData) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    initDataType = initDataType === VOID ? VOID : initDataType;
    initData = initData === VOID ? VOID : initData;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, initDataType: initDataType, initData: initData};
  }
  function copy_63(_this__u8e3s4, height, mediaTime, presentedFrames, receiveTime, presentationTime, captureTime, rtpTimestamp, expectedDisplayTime, processingDuration, width) {
    height = height === VOID ? VOID : height;
    mediaTime = mediaTime === VOID ? VOID : mediaTime;
    presentedFrames = presentedFrames === VOID ? VOID : presentedFrames;
    receiveTime = receiveTime === VOID ? VOID : receiveTime;
    presentationTime = presentationTime === VOID ? VOID : presentationTime;
    captureTime = captureTime === VOID ? VOID : captureTime;
    rtpTimestamp = rtpTimestamp === VOID ? VOID : rtpTimestamp;
    expectedDisplayTime = expectedDisplayTime === VOID ? VOID : expectedDisplayTime;
    processingDuration = processingDuration === VOID ? VOID : processingDuration;
    width = width === VOID ? VOID : width;
    return Object.assign({}, _this__u8e3s4, {height: height, mediaTime: mediaTime, presentedFrames: presentedFrames, receiveTime: receiveTime, presentationTime: presentationTime, captureTime: captureTime, rtpTimestamp: rtpTimestamp, expectedDisplayTime: expectedDisplayTime, processingDuration: processingDuration, width: width});
  }
  function invoke_63(_this__u8e3s4, height, mediaTime, presentedFrames, receiveTime, presentationTime, captureTime, rtpTimestamp, expectedDisplayTime, processingDuration, width) {
    receiveTime = receiveTime === VOID ? VOID : receiveTime;
    captureTime = captureTime === VOID ? VOID : captureTime;
    rtpTimestamp = rtpTimestamp === VOID ? VOID : rtpTimestamp;
    processingDuration = processingDuration === VOID ? VOID : processingDuration;
    return {height: height, mediaTime: mediaTime, presentedFrames: presentedFrames, receiveTime: receiveTime, presentationTime: presentationTime, captureTime: captureTime, rtpTimestamp: rtpTimestamp, expectedDisplayTime: expectedDisplayTime, processingDuration: processingDuration, width: width};
  }
  function copy_64(_this__u8e3s4, clientId, mode, name) {
    clientId = clientId === VOID ? VOID : clientId;
    mode = mode === VOID ? VOID : mode;
    name = name === VOID ? VOID : name;
    return Object.assign({}, _this__u8e3s4, {clientId: clientId, mode: mode, name: name});
  }
  function invoke_64(_this__u8e3s4, clientId, mode, name) {
    clientId = clientId === VOID ? VOID : clientId;
    mode = mode === VOID ? VOID : mode;
    name = name === VOID ? VOID : name;
    return {clientId: clientId, mode: mode, name: name};
  }
  function copy_65(_this__u8e3s4, held, pending) {
    held = held === VOID ? VOID : held;
    pending = pending === VOID ? VOID : pending;
    return Object.assign({}, _this__u8e3s4, {held: held, pending: pending});
  }
  function invoke_65(_this__u8e3s4, held, pending) {
    held = held === VOID ? VOID : held;
    pending = pending === VOID ? VOID : pending;
    return {held: held, pending: pending};
  }
  function copy_66(_this__u8e3s4, signal, signal_0, ifAvailable, mode, steal) {
    signal = signal === VOID ? VOID : signal;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    ifAvailable = ifAvailable === VOID ? VOID : ifAvailable;
    mode = mode === VOID ? VOID : mode;
    steal = steal === VOID ? VOID : steal;
    return Object.assign({}, _this__u8e3s4, {signal: signal_0, signal: signal_0, ifAvailable: ifAvailable, mode: mode, steal: steal});
  }
  function invoke_66(_this__u8e3s4, signal, signal_0, ifAvailable, mode, steal) {
    signal = signal === VOID ? VOID : signal;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    ifAvailable = ifAvailable === VOID ? VOID : ifAvailable;
    mode = mode === VOID ? VOID : mode;
    steal = steal === VOID ? VOID : steal;
    return {signal: signal_0, signal: signal_0, ifAvailable: ifAvailable, mode: mode, steal: steal};
  }
  function copy_67(_this__u8e3s4, channels, contentType, samplerate, bitrate, spatialRendering) {
    channels = channels === VOID ? VOID : channels;
    contentType = contentType === VOID ? VOID : contentType;
    samplerate = samplerate === VOID ? VOID : samplerate;
    bitrate = bitrate === VOID ? VOID : bitrate;
    spatialRendering = spatialRendering === VOID ? VOID : spatialRendering;
    return Object.assign({}, _this__u8e3s4, {channels: channels, contentType: contentType, samplerate: samplerate, bitrate: bitrate, spatialRendering: spatialRendering});
  }
  function invoke_67(_this__u8e3s4, channels, contentType, samplerate, bitrate, spatialRendering) {
    channels = channels === VOID ? VOID : channels;
    samplerate = samplerate === VOID ? VOID : samplerate;
    bitrate = bitrate === VOID ? VOID : bitrate;
    spatialRendering = spatialRendering === VOID ? VOID : spatialRendering;
    return {channels: channels, contentType: contentType, samplerate: samplerate, bitrate: bitrate, spatialRendering: spatialRendering};
  }
  function copy_68(_this__u8e3s4, supported, powerEfficient, smooth, configuration) {
    supported = supported === VOID ? VOID : supported;
    powerEfficient = powerEfficient === VOID ? VOID : powerEfficient;
    smooth = smooth === VOID ? VOID : smooth;
    configuration = configuration === VOID ? VOID : configuration;
    return Object.assign({}, _this__u8e3s4, {supported: supported, powerEfficient: powerEfficient, smooth: smooth, configuration: configuration});
  }
  function invoke_68(_this__u8e3s4, supported, powerEfficient, smooth, configuration) {
    configuration = configuration === VOID ? VOID : configuration;
    return {supported: supported, powerEfficient: powerEfficient, smooth: smooth, configuration: configuration};
  }
  function copy_69(_this__u8e3s4, supported, powerEfficient, smooth, configuration) {
    supported = supported === VOID ? VOID : supported;
    powerEfficient = powerEfficient === VOID ? VOID : powerEfficient;
    smooth = smooth === VOID ? VOID : smooth;
    configuration = configuration === VOID ? VOID : configuration;
    return Object.assign({}, _this__u8e3s4, {supported: supported, powerEfficient: powerEfficient, smooth: smooth, configuration: configuration});
  }
  function invoke_69(_this__u8e3s4, supported, powerEfficient, smooth, configuration) {
    configuration = configuration === VOID ? VOID : configuration;
    return {supported: supported, powerEfficient: powerEfficient, smooth: smooth, configuration: configuration};
  }
  function copy_70(_this__u8e3s4, supported, powerEfficient, smooth) {
    supported = supported === VOID ? VOID : supported;
    powerEfficient = powerEfficient === VOID ? VOID : powerEfficient;
    smooth = smooth === VOID ? VOID : smooth;
    return Object.assign({}, _this__u8e3s4, {supported: supported, powerEfficient: powerEfficient, smooth: smooth});
  }
  function invoke_70(_this__u8e3s4, supported, powerEfficient, smooth) {
    return {supported: supported, powerEfficient: powerEfficient, smooth: smooth};
  }
  function copy_71(_this__u8e3s4, video, audio) {
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    return Object.assign({}, _this__u8e3s4, {video: video, audio: audio});
  }
  function invoke_71(_this__u8e3s4, video, audio) {
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    return {video: video, audio: audio};
  }
  function copy_72(_this__u8e3s4, video, audio, type) {
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {video: video, audio: audio, type: type});
  }
  function invoke_72(_this__u8e3s4, video, audio, type) {
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    return {video: video, audio: audio, type: type};
  }
  function copy_73(_this__u8e3s4, video, audio, type) {
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {video: video, audio: audio, type: type});
  }
  function invoke_73(_this__u8e3s4, video, audio, type) {
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    return {video: video, audio: audio, type: type};
  }
  function copy_74(_this__u8e3s4, framerate, height, contentType, colorGamut, bitrate, scalabilityMode, transferFunction, width, hasAlphaChannel, hdrMetadataType) {
    framerate = framerate === VOID ? VOID : framerate;
    height = height === VOID ? VOID : height;
    contentType = contentType === VOID ? VOID : contentType;
    colorGamut = colorGamut === VOID ? VOID : colorGamut;
    bitrate = bitrate === VOID ? VOID : bitrate;
    scalabilityMode = scalabilityMode === VOID ? VOID : scalabilityMode;
    transferFunction = transferFunction === VOID ? VOID : transferFunction;
    width = width === VOID ? VOID : width;
    hasAlphaChannel = hasAlphaChannel === VOID ? VOID : hasAlphaChannel;
    hdrMetadataType = hdrMetadataType === VOID ? VOID : hdrMetadataType;
    return Object.assign({}, _this__u8e3s4, {framerate: framerate, height: height, contentType: contentType, colorGamut: colorGamut, bitrate: bitrate, scalabilityMode: scalabilityMode, transferFunction: transferFunction, width: width, hasAlphaChannel: hasAlphaChannel, hdrMetadataType: hdrMetadataType});
  }
  function invoke_74(_this__u8e3s4, framerate, height, contentType, colorGamut, bitrate, scalabilityMode, transferFunction, width, hasAlphaChannel, hdrMetadataType) {
    colorGamut = colorGamut === VOID ? VOID : colorGamut;
    scalabilityMode = scalabilityMode === VOID ? VOID : scalabilityMode;
    transferFunction = transferFunction === VOID ? VOID : transferFunction;
    hasAlphaChannel = hasAlphaChannel === VOID ? VOID : hasAlphaChannel;
    hdrMetadataType = hdrMetadataType === VOID ? VOID : hdrMetadataType;
    return {framerate: framerate, height: height, contentType: contentType, colorGamut: colorGamut, bitrate: bitrate, scalabilityMode: scalabilityMode, transferFunction: transferFunction, width: width, hasAlphaChannel: hasAlphaChannel, hdrMetadataType: hdrMetadataType};
  }
  function copy_75(_this__u8e3s4, video, audio) {
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    return Object.assign({}, _this__u8e3s4, {video: video, audio: audio});
  }
  function invoke_75(_this__u8e3s4, video, audio) {
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    return {video: video, audio: audio};
  }
  function copy_76(_this__u8e3s4, bubbles, cancelable, composed, messageType, message) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    messageType = messageType === VOID ? VOID : messageType;
    message = message === VOID ? VOID : message;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, messageType: messageType, message: message});
  }
  function invoke_76(_this__u8e3s4, bubbles, cancelable, composed, messageType, message) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, messageType: messageType, message: message};
  }
  function copy_77(_this__u8e3s4, videoCapabilities, distinctiveIdentifier, initDataTypes, audioCapabilities, label, persistentState, sessionTypes) {
    videoCapabilities = videoCapabilities === VOID ? VOID : videoCapabilities;
    distinctiveIdentifier = distinctiveIdentifier === VOID ? VOID : distinctiveIdentifier;
    initDataTypes = initDataTypes === VOID ? VOID : initDataTypes;
    audioCapabilities = audioCapabilities === VOID ? VOID : audioCapabilities;
    label = label === VOID ? VOID : label;
    persistentState = persistentState === VOID ? VOID : persistentState;
    sessionTypes = sessionTypes === VOID ? VOID : sessionTypes;
    return Object.assign({}, _this__u8e3s4, {videoCapabilities: videoCapabilities, distinctiveIdentifier: distinctiveIdentifier, initDataTypes: initDataTypes, audioCapabilities: audioCapabilities, label: label, persistentState: persistentState, sessionTypes: sessionTypes});
  }
  function invoke_77(_this__u8e3s4, videoCapabilities, distinctiveIdentifier, initDataTypes, audioCapabilities, label, persistentState, sessionTypes) {
    videoCapabilities = videoCapabilities === VOID ? VOID : videoCapabilities;
    distinctiveIdentifier = distinctiveIdentifier === VOID ? VOID : distinctiveIdentifier;
    initDataTypes = initDataTypes === VOID ? VOID : initDataTypes;
    audioCapabilities = audioCapabilities === VOID ? VOID : audioCapabilities;
    label = label === VOID ? VOID : label;
    persistentState = persistentState === VOID ? VOID : persistentState;
    sessionTypes = sessionTypes === VOID ? VOID : sessionTypes;
    return {videoCapabilities: videoCapabilities, distinctiveIdentifier: distinctiveIdentifier, initDataTypes: initDataTypes, audioCapabilities: audioCapabilities, label: label, persistentState: persistentState, sessionTypes: sessionTypes};
  }
  function copy_78(_this__u8e3s4, robustness, contentType, encryptionScheme) {
    robustness = robustness === VOID ? VOID : robustness;
    contentType = contentType === VOID ? VOID : contentType;
    encryptionScheme = encryptionScheme === VOID ? VOID : encryptionScheme;
    return Object.assign({}, _this__u8e3s4, {robustness: robustness, contentType: contentType, encryptionScheme: encryptionScheme});
  }
  function invoke_78(_this__u8e3s4, robustness, contentType, encryptionScheme) {
    robustness = robustness === VOID ? VOID : robustness;
    contentType = contentType === VOID ? VOID : contentType;
    encryptionScheme = encryptionScheme === VOID ? VOID : encryptionScheme;
    return {robustness: robustness, contentType: contentType, encryptionScheme: encryptionScheme};
  }
  function copy_79(_this__u8e3s4, minHdcpVersion) {
    minHdcpVersion = minHdcpVersion === VOID ? VOID : minHdcpVersion;
    return Object.assign({}, _this__u8e3s4, {minHdcpVersion: minHdcpVersion});
  }
  function invoke_79(_this__u8e3s4, minHdcpVersion) {
    minHdcpVersion = minHdcpVersion === VOID ? VOID : minHdcpVersion;
    return {minHdcpVersion: minHdcpVersion};
  }
  function copy_80(_this__u8e3s4, sizes, src, type) {
    sizes = sizes === VOID ? VOID : sizes;
    src = src === VOID ? VOID : src;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {sizes: sizes, src: src, type: type});
  }
  function invoke_80(_this__u8e3s4, sizes, src, type) {
    sizes = sizes === VOID ? VOID : sizes;
    type = type === VOID ? VOID : type;
    return {sizes: sizes, src: src, type: type};
  }
  function copy_81(_this__u8e3s4, artist, album, artwork, title) {
    artist = artist === VOID ? VOID : artist;
    album = album === VOID ? VOID : album;
    artwork = artwork === VOID ? VOID : artwork;
    title = title === VOID ? VOID : title;
    return Object.assign({}, _this__u8e3s4, {artist: artist, album: album, artwork: artwork, title: title});
  }
  function invoke_81(_this__u8e3s4, artist, album, artwork, title) {
    artist = artist === VOID ? VOID : artist;
    album = album === VOID ? VOID : album;
    artwork = artwork === VOID ? VOID : artwork;
    title = title === VOID ? VOID : title;
    return {artist: artist, album: album, artwork: artwork, title: title};
  }
  function copy_82(_this__u8e3s4, position, duration, playbackRate) {
    position = position === VOID ? VOID : position;
    duration = duration === VOID ? VOID : duration;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    return Object.assign({}, _this__u8e3s4, {position: position, duration: duration, playbackRate: playbackRate});
  }
  function invoke_82(_this__u8e3s4, position, duration, playbackRate) {
    position = position === VOID ? VOID : position;
    duration = duration === VOID ? VOID : duration;
    playbackRate = playbackRate === VOID ? VOID : playbackRate;
    return {position: position, duration: duration, playbackRate: playbackRate};
  }
  function copy_83(_this__u8e3s4, seekOffset, action, fastSeek, seekTime) {
    seekOffset = seekOffset === VOID ? VOID : seekOffset;
    action = action === VOID ? VOID : action;
    fastSeek = fastSeek === VOID ? VOID : fastSeek;
    seekTime = seekTime === VOID ? VOID : seekTime;
    return Object.assign({}, _this__u8e3s4, {seekOffset: seekOffset, action: action, fastSeek: fastSeek, seekTime: seekTime});
  }
  function invoke_83(_this__u8e3s4, seekOffset, action, fastSeek, seekTime) {
    seekOffset = seekOffset === VOID ? VOID : seekOffset;
    fastSeek = fastSeek === VOID ? VOID : fastSeek;
    seekTime = seekTime === VOID ? VOID : seekTime;
    return {seekOffset: seekOffset, action: action, fastSeek: fastSeek, seekTime: seekTime};
  }
  function copy_84(_this__u8e3s4, min, max) {
    min = min === VOID ? VOID : min;
    max = max === VOID ? VOID : max;
    return Object.assign({}, _this__u8e3s4, {min: min, max: max});
  }
  function invoke_84(_this__u8e3s4, min, max) {
    min = min === VOID ? VOID : min;
    max = max === VOID ? VOID : max;
    return {min: min, max: max};
  }
  function copy_85(_this__u8e3s4, preferCurrentTab, video, audio, peerIdentity) {
    preferCurrentTab = preferCurrentTab === VOID ? VOID : preferCurrentTab;
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    peerIdentity = peerIdentity === VOID ? VOID : peerIdentity;
    return Object.assign({}, _this__u8e3s4, {preferCurrentTab: preferCurrentTab, video: video, audio: audio, peerIdentity: peerIdentity});
  }
  function invoke_85(_this__u8e3s4, preferCurrentTab, video, audio, peerIdentity) {
    preferCurrentTab = preferCurrentTab === VOID ? VOID : preferCurrentTab;
    video = video === VOID ? VOID : video;
    audio = audio === VOID ? VOID : audio;
    peerIdentity = peerIdentity === VOID ? VOID : peerIdentity;
    return {preferCurrentTab: preferCurrentTab, video: video, audio: audio, peerIdentity: peerIdentity};
  }
  function copy_86(_this__u8e3s4, bubbles, cancelable, composed, track) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    track = track === VOID ? VOID : track;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, track: track});
  }
  function invoke_86(_this__u8e3s4, bubbles, cancelable, composed, track) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, track: track};
  }
  function copy_87(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    return Object.assign({}, _this__u8e3s4, {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width});
  }
  function invoke_87(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    return {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width};
  }
  function copy_88(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    return Object.assign({}, _this__u8e3s4, {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width});
  }
  function invoke_88(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    return {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width};
  }
  function copy_89(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width, advanced) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    advanced = advanced === VOID ? VOID : advanced;
    return Object.assign({}, _this__u8e3s4, {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width, advanced: advanced});
  }
  function invoke_89(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width, advanced) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    advanced = advanced === VOID ? VOID : advanced;
    return {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width, advanced: advanced};
  }
  function copy_90(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    return Object.assign({}, _this__u8e3s4, {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width});
  }
  function invoke_90(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    return {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width};
  }
  function copy_91(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    return Object.assign({}, _this__u8e3s4, {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width});
  }
  function invoke_91(_this__u8e3s4, channelCount, height, sampleSize, aspectRatio, displaySurface, echoCancellation, backgroundBlur, facingMode, groupId, autoGainControl, deviceId, frameRate, sampleRate, noiseSuppression, width) {
    channelCount = channelCount === VOID ? VOID : channelCount;
    height = height === VOID ? VOID : height;
    sampleSize = sampleSize === VOID ? VOID : sampleSize;
    aspectRatio = aspectRatio === VOID ? VOID : aspectRatio;
    displaySurface = displaySurface === VOID ? VOID : displaySurface;
    echoCancellation = echoCancellation === VOID ? VOID : echoCancellation;
    backgroundBlur = backgroundBlur === VOID ? VOID : backgroundBlur;
    facingMode = facingMode === VOID ? VOID : facingMode;
    groupId = groupId === VOID ? VOID : groupId;
    autoGainControl = autoGainControl === VOID ? VOID : autoGainControl;
    deviceId = deviceId === VOID ? VOID : deviceId;
    frameRate = frameRate === VOID ? VOID : frameRate;
    sampleRate = sampleRate === VOID ? VOID : sampleRate;
    noiseSuppression = noiseSuppression === VOID ? VOID : noiseSuppression;
    width = width === VOID ? VOID : width;
    return {channelCount: channelCount, height: height, sampleSize: sampleSize, aspectRatio: aspectRatio, displaySurface: displaySurface, echoCancellation: echoCancellation, backgroundBlur: backgroundBlur, facingMode: facingMode, groupId: groupId, autoGainControl: autoGainControl, deviceId: deviceId, frameRate: frameRate, sampleRate: sampleRate, noiseSuppression: noiseSuppression, width: width};
  }
  function copy_92(_this__u8e3s4, min, max) {
    min = min === VOID ? VOID : min;
    max = max === VOID ? VOID : max;
    return Object.assign({}, _this__u8e3s4, {min: min, max: max});
  }
  function invoke_92(_this__u8e3s4, min, max) {
    min = min === VOID ? VOID : min;
    max = max === VOID ? VOID : max;
    return {min: min, max: max};
  }
  function copy_93(_this__u8e3s4, bubbles, cancelable, composed, port) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    port = port === VOID ? VOID : port;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, port: port});
  }
  function invoke_93(_this__u8e3s4, bubbles, cancelable, composed, port) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    port = port === VOID ? VOID : port;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, port: port};
  }
  function copy_94(_this__u8e3s4, bubbles, cancelable, composed, data) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    data = data === VOID ? VOID : data;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, data: data});
  }
  function invoke_94(_this__u8e3s4, bubbles, cancelable, composed, data) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    data = data === VOID ? VOID : data;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, data: data};
  }
  function copy_95(_this__u8e3s4, software, sysex) {
    software = software === VOID ? VOID : software;
    sysex = sysex === VOID ? VOID : sysex;
    return Object.assign({}, _this__u8e3s4, {software: software, sysex: sysex});
  }
  function invoke_95(_this__u8e3s4, software, sysex) {
    software = software === VOID ? VOID : software;
    sysex = sysex === VOID ? VOID : sysex;
    return {software: software, sysex: sysex};
  }
  function copy_96(_this__u8e3s4, tag, icon, silent, requireInteraction, body, data, dir, badge, lang) {
    tag = tag === VOID ? VOID : tag;
    icon = icon === VOID ? VOID : icon;
    silent = silent === VOID ? VOID : silent;
    requireInteraction = requireInteraction === VOID ? VOID : requireInteraction;
    body = body === VOID ? VOID : body;
    data = data === VOID ? VOID : data;
    dir = dir === VOID ? VOID : dir;
    badge = badge === VOID ? VOID : badge;
    lang = lang === VOID ? VOID : lang;
    return Object.assign({}, _this__u8e3s4, {tag: tag, icon: icon, silent: silent, requireInteraction: requireInteraction, body: body, data: data, dir: dir, badge: badge, lang: lang});
  }
  function invoke_96(_this__u8e3s4, tag, icon, silent, requireInteraction, body, data, dir, badge, lang) {
    tag = tag === VOID ? VOID : tag;
    icon = icon === VOID ? VOID : icon;
    silent = silent === VOID ? VOID : silent;
    requireInteraction = requireInteraction === VOID ? VOID : requireInteraction;
    body = body === VOID ? VOID : body;
    data = data === VOID ? VOID : data;
    dir = dir === VOID ? VOID : dir;
    badge = badge === VOID ? VOID : badge;
    lang = lang === VOID ? VOID : lang;
    return {tag: tag, icon: icon, silent: silent, requireInteraction: requireInteraction, body: body, data: data, dir: dir, badge: badge, lang: lang};
  }
  function copy_97(_this__u8e3s4, name) {
    name = name === VOID ? VOID : name;
    return Object.assign({}, _this__u8e3s4, {name: name});
  }
  function invoke_97(_this__u8e3s4, name) {
    return {name: name};
  }
  function copy_98(_this__u8e3s4, bubbles, cancelable, composed, pictureInPictureWindow) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    pictureInPictureWindow = pictureInPictureWindow === VOID ? VOID : pictureInPictureWindow;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, pictureInPictureWindow: pictureInPictureWindow});
  }
  function invoke_98(_this__u8e3s4, bubbles, cancelable, composed, pictureInPictureWindow) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, pictureInPictureWindow: pictureInPictureWindow};
  }
  function copy_99(_this__u8e3s4, bubbles, cancelable, composed, newState, oldState) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    newState = newState === VOID ? VOID : newState;
    oldState = oldState === VOID ? VOID : oldState;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, newState: newState, oldState: oldState});
  }
  function invoke_99(_this__u8e3s4, bubbles, cancelable, composed, newState, oldState) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    newState = newState === VOID ? VOID : newState;
    oldState = oldState === VOID ? VOID : oldState;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, newState: newState, oldState: oldState};
  }
  function copy_100(_this__u8e3s4, endpoint, expirationTime, keys) {
    endpoint = endpoint === VOID ? VOID : endpoint;
    expirationTime = expirationTime === VOID ? VOID : expirationTime;
    keys = keys === VOID ? VOID : keys;
    return Object.assign({}, _this__u8e3s4, {endpoint: endpoint, expirationTime: expirationTime, keys: keys});
  }
  function invoke_100(_this__u8e3s4, endpoint, expirationTime, keys) {
    endpoint = endpoint === VOID ? VOID : endpoint;
    expirationTime = expirationTime === VOID ? VOID : expirationTime;
    keys = keys === VOID ? VOID : keys;
    return {endpoint: endpoint, expirationTime: expirationTime, keys: keys};
  }
  function copy_101(_this__u8e3s4, userVisibleOnly, applicationServerKey) {
    userVisibleOnly = userVisibleOnly === VOID ? VOID : userVisibleOnly;
    applicationServerKey = applicationServerKey === VOID ? VOID : applicationServerKey;
    return Object.assign({}, _this__u8e3s4, {userVisibleOnly: userVisibleOnly, applicationServerKey: applicationServerKey});
  }
  function invoke_101(_this__u8e3s4, userVisibleOnly, applicationServerKey) {
    userVisibleOnly = userVisibleOnly === VOID ? VOID : userVisibleOnly;
    applicationServerKey = applicationServerKey === VOID ? VOID : applicationServerKey;
    return {userVisibleOnly: userVisibleOnly, applicationServerKey: applicationServerKey};
  }
  function copy_102(_this__u8e3s4, endOffset, startOffset, endContainer, startContainer) {
    endOffset = endOffset === VOID ? VOID : endOffset;
    startOffset = startOffset === VOID ? VOID : startOffset;
    endContainer = endContainer === VOID ? VOID : endContainer;
    startContainer = startContainer === VOID ? VOID : startContainer;
    return Object.assign({}, _this__u8e3s4, {endOffset: endOffset, startOffset: startOffset, endContainer: endContainer, startContainer: startContainer});
  }
  function invoke_102(_this__u8e3s4, endOffset, startOffset, endContainer, startContainer) {
    return {endOffset: endOffset, startOffset: startOffset, endContainer: endContainer, startContainer: startContainer};
  }
  function copy_103(_this__u8e3s4, behavior, inline, block) {
    behavior = behavior === VOID ? VOID : behavior;
    inline = inline === VOID ? VOID : inline;
    block = block === VOID ? VOID : block;
    return Object.assign({}, _this__u8e3s4, {behavior: behavior, inline: inline, block: block});
  }
  function invoke_103(_this__u8e3s4, behavior, inline, block) {
    behavior = behavior === VOID ? VOID : behavior;
    inline = inline === VOID ? VOID : inline;
    block = block === VOID ? VOID : block;
    return {behavior: behavior, inline: inline, block: block};
  }
  function copy_104(_this__u8e3s4, behavior) {
    behavior = behavior === VOID ? VOID : behavior;
    return Object.assign({}, _this__u8e3s4, {behavior: behavior});
  }
  function invoke_104(_this__u8e3s4, behavior) {
    behavior = behavior === VOID ? VOID : behavior;
    return {behavior: behavior};
  }
  function copy_105(_this__u8e3s4, behavior, left, top) {
    behavior = behavior === VOID ? VOID : behavior;
    left = left === VOID ? VOID : left;
    top = top === VOID ? VOID : top;
    return Object.assign({}, _this__u8e3s4, {behavior: behavior, left: left, top: top});
  }
  function invoke_105(_this__u8e3s4, behavior, left, top) {
    behavior = behavior === VOID ? VOID : behavior;
    left = left === VOID ? VOID : left;
    top = top === VOID ? VOID : top;
    return {behavior: behavior, left: left, top: top};
  }
  function copy_106(_this__u8e3s4, tag) {
    tag = tag === VOID ? VOID : tag;
    return Object.assign({}, _this__u8e3s4, {tag: tag});
  }
  function invoke_106(_this__u8e3s4, tag) {
    tag = tag === VOID ? VOID : tag;
    return {tag: tag};
  }
  function copy_107(_this__u8e3s4, headerValue, enabled) {
    headerValue = headerValue === VOID ? VOID : headerValue;
    enabled = enabled === VOID ? VOID : enabled;
    return Object.assign({}, _this__u8e3s4, {headerValue: headerValue, enabled: enabled});
  }
  function invoke_107(_this__u8e3s4, headerValue, enabled) {
    headerValue = headerValue === VOID ? VOID : headerValue;
    enabled = enabled === VOID ? VOID : enabled;
    return {headerValue: headerValue, enabled: enabled};
  }
  function copy_108(_this__u8e3s4, updateViaCache, scope, type) {
    updateViaCache = updateViaCache === VOID ? VOID : updateViaCache;
    scope = scope === VOID ? VOID : scope;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {updateViaCache: updateViaCache, scope: scope, type: type});
  }
  function invoke_108(_this__u8e3s4, updateViaCache, scope, type) {
    updateViaCache = updateViaCache === VOID ? VOID : updateViaCache;
    scope = scope === VOID ? VOID : scope;
    type = type === VOID ? VOID : type;
    return {updateViaCache: updateViaCache, scope: scope, type: type};
  }
  function copy_109(_this__u8e3s4, files, text, url, title) {
    files = files === VOID ? VOID : files;
    text = text === VOID ? VOID : text;
    url = url === VOID ? VOID : url;
    title = title === VOID ? VOID : title;
    return Object.assign({}, _this__u8e3s4, {files: files, text: text, url: url, title: title});
  }
  function invoke_109(_this__u8e3s4, files, text, url, title) {
    files = files === VOID ? VOID : files;
    text = text === VOID ? VOID : text;
    url = url === VOID ? VOID : url;
    title = title === VOID ? VOID : title;
    return {files: files, text: text, url: url, title: title};
  }
  function copy_110(_this__u8e3s4, quota, usage) {
    quota = quota === VOID ? VOID : quota;
    usage = usage === VOID ? VOID : usage;
    return Object.assign({}, _this__u8e3s4, {quota: quota, usage: usage});
  }
  function invoke_110(_this__u8e3s4, quota, usage) {
    quota = quota === VOID ? VOID : quota;
    usage = usage === VOID ? VOID : usage;
    return {quota: quota, usage: usage};
  }
  function copy_111(_this__u8e3s4, bubbles, cancelable, composed, newValue, oldValue, key, url, storageArea) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    newValue = newValue === VOID ? VOID : newValue;
    oldValue = oldValue === VOID ? VOID : oldValue;
    key = key === VOID ? VOID : key;
    url = url === VOID ? VOID : url;
    storageArea = storageArea === VOID ? VOID : storageArea;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, newValue: newValue, oldValue: oldValue, key: key, url: url, storageArea: storageArea});
  }
  function invoke_111(_this__u8e3s4, bubbles, cancelable, composed, newValue, oldValue, key, url, storageArea) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    newValue = newValue === VOID ? VOID : newValue;
    oldValue = oldValue === VOID ? VOID : oldValue;
    key = key === VOID ? VOID : key;
    url = url === VOID ? VOID : url;
    storageArea = storageArea === VOID ? VOID : storageArea;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, newValue: newValue, oldValue: oldValue, key: key, url: url, storageArea: storageArea};
  }
  function copy_112(_this__u8e3s4, clipped, stroke, fill, markers) {
    clipped = clipped === VOID ? VOID : clipped;
    stroke = stroke === VOID ? VOID : stroke;
    fill = fill === VOID ? VOID : fill;
    markers = markers === VOID ? VOID : markers;
    return Object.assign({}, _this__u8e3s4, {clipped: clipped, stroke: stroke, fill: fill, markers: markers});
  }
  function invoke_112(_this__u8e3s4, clipped, stroke, fill, markers) {
    clipped = clipped === VOID ? VOID : clipped;
    stroke = stroke === VOID ? VOID : stroke;
    fill = fill === VOID ? VOID : fill;
    markers = markers === VOID ? VOID : markers;
    return {clipped: clipped, stroke: stroke, fill: fill, markers: markers};
  }
  function copy_113(_this__u8e3s4, bubbles, cancelable, composed, detail, view, data) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    data = data === VOID ? VOID : data;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, data: data});
  }
  function invoke_113(_this__u8e3s4, bubbles, cancelable, composed, detail, view, data) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    data = data === VOID ? VOID : data;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, data: data};
  }
  function copy_114(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, movementY, movementX, screenX, screenY, clientX, clientY, relatedTarget, buttons, button, dataTransfer) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    movementY = movementY === VOID ? VOID : movementY;
    movementX = movementX === VOID ? VOID : movementX;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    buttons = buttons === VOID ? VOID : buttons;
    button = button === VOID ? VOID : button;
    dataTransfer = dataTransfer === VOID ? VOID : dataTransfer;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, movementY: movementY, movementX: movementX, screenX: screenX, screenY: screenY, clientX: clientX, clientY: clientY, relatedTarget: relatedTarget, buttons: buttons, button: button, dataTransfer: dataTransfer});
  }
  function invoke_114(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, movementY, movementX, screenX, screenY, clientX, clientY, relatedTarget, buttons, button, dataTransfer) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    movementY = movementY === VOID ? VOID : movementY;
    movementX = movementX === VOID ? VOID : movementX;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    buttons = buttons === VOID ? VOID : buttons;
    button = button === VOID ? VOID : button;
    dataTransfer = dataTransfer === VOID ? VOID : dataTransfer;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, movementY: movementY, movementX: movementX, screenX: screenX, screenY: screenY, clientX: clientX, clientY: clientY, relatedTarget: relatedTarget, buttons: buttons, button: button, dataTransfer: dataTransfer};
  }
  function copy_115(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn});
  }
  function invoke_115(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn};
  }
  function copy_116(_this__u8e3s4, bubbles, cancelable, composed, detail, view, relatedTarget) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, relatedTarget: relatedTarget});
  }
  function invoke_116(_this__u8e3s4, bubbles, cancelable, composed, detail, view, relatedTarget) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, relatedTarget: relatedTarget};
  }
  function copy_117(_this__u8e3s4, bubbles, cancelable, composed, detail, view, inputType, targetRanges, dataTransfer, data, isComposing) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    inputType = inputType === VOID ? VOID : inputType;
    targetRanges = targetRanges === VOID ? VOID : targetRanges;
    dataTransfer = dataTransfer === VOID ? VOID : dataTransfer;
    data = data === VOID ? VOID : data;
    isComposing = isComposing === VOID ? VOID : isComposing;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, inputType: inputType, targetRanges: targetRanges, dataTransfer: dataTransfer, data: data, isComposing: isComposing});
  }
  function invoke_117(_this__u8e3s4, bubbles, cancelable, composed, detail, view, inputType, targetRanges, dataTransfer, data, isComposing) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    inputType = inputType === VOID ? VOID : inputType;
    targetRanges = targetRanges === VOID ? VOID : targetRanges;
    dataTransfer = dataTransfer === VOID ? VOID : dataTransfer;
    data = data === VOID ? VOID : data;
    isComposing = isComposing === VOID ? VOID : isComposing;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, inputType: inputType, targetRanges: targetRanges, dataTransfer: dataTransfer, data: data, isComposing: isComposing};
  }
  function copy_118(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, key, code, isComposing, repeat, location) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    key = key === VOID ? VOID : key;
    code = code === VOID ? VOID : code;
    isComposing = isComposing === VOID ? VOID : isComposing;
    repeat = repeat === VOID ? VOID : repeat;
    location = location === VOID ? VOID : location;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, key: key, code: code, isComposing: isComposing, repeat: repeat, location: location});
  }
  function invoke_118(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, key, code, isComposing, repeat, location) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    key = key === VOID ? VOID : key;
    code = code === VOID ? VOID : code;
    isComposing = isComposing === VOID ? VOID : isComposing;
    repeat = repeat === VOID ? VOID : repeat;
    location = location === VOID ? VOID : location;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, key: key, code: code, isComposing: isComposing, repeat: repeat, location: location};
  }
  function copy_119(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, movementY, movementX, screenX, screenY, clientX, clientY, relatedTarget, buttons, button) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    movementY = movementY === VOID ? VOID : movementY;
    movementX = movementX === VOID ? VOID : movementX;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    buttons = buttons === VOID ? VOID : buttons;
    button = button === VOID ? VOID : button;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, movementY: movementY, movementX: movementX, screenX: screenX, screenY: screenY, clientX: clientX, clientY: clientY, relatedTarget: relatedTarget, buttons: buttons, button: button});
  }
  function invoke_119(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, movementY, movementX, screenX, screenY, clientX, clientY, relatedTarget, buttons, button) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    movementY = movementY === VOID ? VOID : movementY;
    movementX = movementX === VOID ? VOID : movementX;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    buttons = buttons === VOID ? VOID : buttons;
    button = button === VOID ? VOID : button;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, movementY: movementY, movementX: movementX, screenX: screenX, screenY: screenY, clientX: clientX, clientY: clientY, relatedTarget: relatedTarget, buttons: buttons, button: button};
  }
  function copy_120(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, movementY, movementX, screenX, screenY, clientX, clientY, relatedTarget, buttons, button, height, tangentialPressure, predictedEvents, pointerId, tiltY, tiltX, twist, pointerType, pressure, altitudeAngle, isPrimary, azimuthAngle, coalescedEvents, width) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    movementY = movementY === VOID ? VOID : movementY;
    movementX = movementX === VOID ? VOID : movementX;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    buttons = buttons === VOID ? VOID : buttons;
    button = button === VOID ? VOID : button;
    height = height === VOID ? VOID : height;
    tangentialPressure = tangentialPressure === VOID ? VOID : tangentialPressure;
    predictedEvents = predictedEvents === VOID ? VOID : predictedEvents;
    pointerId = pointerId === VOID ? VOID : pointerId;
    tiltY = tiltY === VOID ? VOID : tiltY;
    tiltX = tiltX === VOID ? VOID : tiltX;
    twist = twist === VOID ? VOID : twist;
    pointerType = pointerType === VOID ? VOID : pointerType;
    pressure = pressure === VOID ? VOID : pressure;
    altitudeAngle = altitudeAngle === VOID ? VOID : altitudeAngle;
    isPrimary = isPrimary === VOID ? VOID : isPrimary;
    azimuthAngle = azimuthAngle === VOID ? VOID : azimuthAngle;
    coalescedEvents = coalescedEvents === VOID ? VOID : coalescedEvents;
    width = width === VOID ? VOID : width;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, movementY: movementY, movementX: movementX, screenX: screenX, screenY: screenY, clientX: clientX, clientY: clientY, relatedTarget: relatedTarget, buttons: buttons, button: button, height: height, tangentialPressure: tangentialPressure, predictedEvents: predictedEvents, pointerId: pointerId, tiltY: tiltY, tiltX: tiltX, twist: twist, pointerType: pointerType, pressure: pressure, altitudeAngle: altitudeAngle, isPrimary: isPrimary, azimuthAngle: azimuthAngle, coalescedEvents: coalescedEvents, width: width});
  }
  function invoke_120(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, movementY, movementX, screenX, screenY, clientX, clientY, relatedTarget, buttons, button, height, tangentialPressure, predictedEvents, pointerId, tiltY, tiltX, twist, pointerType, pressure, altitudeAngle, isPrimary, azimuthAngle, coalescedEvents, width) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    movementY = movementY === VOID ? VOID : movementY;
    movementX = movementX === VOID ? VOID : movementX;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    buttons = buttons === VOID ? VOID : buttons;
    button = button === VOID ? VOID : button;
    height = height === VOID ? VOID : height;
    tangentialPressure = tangentialPressure === VOID ? VOID : tangentialPressure;
    predictedEvents = predictedEvents === VOID ? VOID : predictedEvents;
    pointerId = pointerId === VOID ? VOID : pointerId;
    tiltY = tiltY === VOID ? VOID : tiltY;
    tiltX = tiltX === VOID ? VOID : tiltX;
    twist = twist === VOID ? VOID : twist;
    pointerType = pointerType === VOID ? VOID : pointerType;
    pressure = pressure === VOID ? VOID : pressure;
    altitudeAngle = altitudeAngle === VOID ? VOID : altitudeAngle;
    isPrimary = isPrimary === VOID ? VOID : isPrimary;
    azimuthAngle = azimuthAngle === VOID ? VOID : azimuthAngle;
    coalescedEvents = coalescedEvents === VOID ? VOID : coalescedEvents;
    width = width === VOID ? VOID : width;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, movementY: movementY, movementX: movementX, screenX: screenX, screenY: screenY, clientX: clientX, clientY: clientY, relatedTarget: relatedTarget, buttons: buttons, button: button, height: height, tangentialPressure: tangentialPressure, predictedEvents: predictedEvents, pointerId: pointerId, tiltY: tiltY, tiltX: tiltX, twist: twist, pointerType: pointerType, pressure: pressure, altitudeAngle: altitudeAngle, isPrimary: isPrimary, azimuthAngle: azimuthAngle, coalescedEvents: coalescedEvents, width: width};
  }
  function copy_121(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, targetTouches, touches, changedTouches) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    targetTouches = targetTouches === VOID ? VOID : targetTouches;
    touches = touches === VOID ? VOID : touches;
    changedTouches = changedTouches === VOID ? VOID : changedTouches;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, targetTouches: targetTouches, touches: touches, changedTouches: changedTouches});
  }
  function invoke_121(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, targetTouches, touches, changedTouches) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    targetTouches = targetTouches === VOID ? VOID : targetTouches;
    touches = touches === VOID ? VOID : touches;
    changedTouches = changedTouches === VOID ? VOID : changedTouches;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, targetTouches: targetTouches, touches: touches, changedTouches: changedTouches};
  }
  function copy_122(_this__u8e3s4, target, screenX, screenY, rotationAngle, force, pageY, altitudeAngle, pageX, touchType, clientX, clientY, azimuthAngle, identifier, radiusY, radiusX) {
    target = target === VOID ? VOID : target;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    rotationAngle = rotationAngle === VOID ? VOID : rotationAngle;
    force = force === VOID ? VOID : force;
    pageY = pageY === VOID ? VOID : pageY;
    altitudeAngle = altitudeAngle === VOID ? VOID : altitudeAngle;
    pageX = pageX === VOID ? VOID : pageX;
    touchType = touchType === VOID ? VOID : touchType;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    azimuthAngle = azimuthAngle === VOID ? VOID : azimuthAngle;
    identifier = identifier === VOID ? VOID : identifier;
    radiusY = radiusY === VOID ? VOID : radiusY;
    radiusX = radiusX === VOID ? VOID : radiusX;
    return Object.assign({}, _this__u8e3s4, {target: target, screenX: screenX, screenY: screenY, rotationAngle: rotationAngle, force: force, pageY: pageY, altitudeAngle: altitudeAngle, pageX: pageX, touchType: touchType, clientX: clientX, clientY: clientY, azimuthAngle: azimuthAngle, identifier: identifier, radiusY: radiusY, radiusX: radiusX});
  }
  function invoke_122(_this__u8e3s4, target, screenX, screenY, rotationAngle, force, pageY, altitudeAngle, pageX, touchType, clientX, clientY, azimuthAngle, identifier, radiusY, radiusX) {
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    rotationAngle = rotationAngle === VOID ? VOID : rotationAngle;
    force = force === VOID ? VOID : force;
    pageY = pageY === VOID ? VOID : pageY;
    altitudeAngle = altitudeAngle === VOID ? VOID : altitudeAngle;
    pageX = pageX === VOID ? VOID : pageX;
    touchType = touchType === VOID ? VOID : touchType;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    azimuthAngle = azimuthAngle === VOID ? VOID : azimuthAngle;
    radiusY = radiusY === VOID ? VOID : radiusY;
    radiusX = radiusX === VOID ? VOID : radiusX;
    return {target: target, screenX: screenX, screenY: screenY, rotationAngle: rotationAngle, force: force, pageY: pageY, altitudeAngle: altitudeAngle, pageX: pageX, touchType: touchType, clientX: clientX, clientY: clientY, azimuthAngle: azimuthAngle, identifier: identifier, radiusY: radiusY, radiusX: radiusX};
  }
  function copy_123(_this__u8e3s4, bubbles, cancelable, composed, detail, view) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view});
  }
  function invoke_123(_this__u8e3s4, bubbles, cancelable, composed, detail, view) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view};
  }
  function copy_124(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, movementY, movementX, screenX, screenY, clientX, clientY, relatedTarget, buttons, button, deltaX, deltaMode, deltaZ, deltaY) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    movementY = movementY === VOID ? VOID : movementY;
    movementX = movementX === VOID ? VOID : movementX;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    buttons = buttons === VOID ? VOID : buttons;
    button = button === VOID ? VOID : button;
    deltaX = deltaX === VOID ? VOID : deltaX;
    deltaMode = deltaMode === VOID ? VOID : deltaMode;
    deltaZ = deltaZ === VOID ? VOID : deltaZ;
    deltaY = deltaY === VOID ? VOID : deltaY;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, movementY: movementY, movementX: movementX, screenX: screenX, screenY: screenY, clientX: clientX, clientY: clientY, relatedTarget: relatedTarget, buttons: buttons, button: button, deltaX: deltaX, deltaMode: deltaMode, deltaZ: deltaZ, deltaY: deltaY});
  }
  function invoke_124(_this__u8e3s4, bubbles, cancelable, composed, detail, view, altKey, metaKey, modifierFnLock, modifierSymbol, modifierSymbolLock, modifierCapsLock, ctrlKey, modifierAltGraph, modifierScrollLock, modifierSuper, modifierHyper, modifierNumLock, shiftKey, modifierFn, movementY, movementX, screenX, screenY, clientX, clientY, relatedTarget, buttons, button, deltaX, deltaMode, deltaZ, deltaY) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    detail = detail === VOID ? VOID : detail;
    view = view === VOID ? VOID : view;
    altKey = altKey === VOID ? VOID : altKey;
    metaKey = metaKey === VOID ? VOID : metaKey;
    modifierFnLock = modifierFnLock === VOID ? VOID : modifierFnLock;
    modifierSymbol = modifierSymbol === VOID ? VOID : modifierSymbol;
    modifierSymbolLock = modifierSymbolLock === VOID ? VOID : modifierSymbolLock;
    modifierCapsLock = modifierCapsLock === VOID ? VOID : modifierCapsLock;
    ctrlKey = ctrlKey === VOID ? VOID : ctrlKey;
    modifierAltGraph = modifierAltGraph === VOID ? VOID : modifierAltGraph;
    modifierScrollLock = modifierScrollLock === VOID ? VOID : modifierScrollLock;
    modifierSuper = modifierSuper === VOID ? VOID : modifierSuper;
    modifierHyper = modifierHyper === VOID ? VOID : modifierHyper;
    modifierNumLock = modifierNumLock === VOID ? VOID : modifierNumLock;
    shiftKey = shiftKey === VOID ? VOID : shiftKey;
    modifierFn = modifierFn === VOID ? VOID : modifierFn;
    movementY = movementY === VOID ? VOID : movementY;
    movementX = movementX === VOID ? VOID : movementX;
    screenX = screenX === VOID ? VOID : screenX;
    screenY = screenY === VOID ? VOID : screenY;
    clientX = clientX === VOID ? VOID : clientX;
    clientY = clientY === VOID ? VOID : clientY;
    relatedTarget = relatedTarget === VOID ? VOID : relatedTarget;
    buttons = buttons === VOID ? VOID : buttons;
    button = button === VOID ? VOID : button;
    deltaX = deltaX === VOID ? VOID : deltaX;
    deltaMode = deltaMode === VOID ? VOID : deltaMode;
    deltaZ = deltaZ === VOID ? VOID : deltaZ;
    deltaY = deltaY === VOID ? VOID : deltaY;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, detail: detail, view: view, altKey: altKey, metaKey: metaKey, modifierFnLock: modifierFnLock, modifierSymbol: modifierSymbol, modifierSymbolLock: modifierSymbolLock, modifierCapsLock: modifierCapsLock, ctrlKey: ctrlKey, modifierAltGraph: modifierAltGraph, modifierScrollLock: modifierScrollLock, modifierSuper: modifierSuper, modifierHyper: modifierHyper, modifierNumLock: modifierNumLock, shiftKey: shiftKey, modifierFn: modifierFn, movementY: movementY, movementX: movementX, screenX: screenX, screenY: screenY, clientX: clientX, clientY: clientY, relatedTarget: relatedTarget, buttons: buttons, button: button, deltaX: deltaX, deltaMode: deltaMode, deltaZ: deltaZ, deltaY: deltaY};
  }
  function copy_125(_this__u8e3s4, tooShort, typeMismatch, badInput, rangeOverflow, customError, stepMismatch, valueMissing, tooLong, rangeUnderflow, patternMismatch) {
    tooShort = tooShort === VOID ? VOID : tooShort;
    typeMismatch = typeMismatch === VOID ? VOID : typeMismatch;
    badInput = badInput === VOID ? VOID : badInput;
    rangeOverflow = rangeOverflow === VOID ? VOID : rangeOverflow;
    customError = customError === VOID ? VOID : customError;
    stepMismatch = stepMismatch === VOID ? VOID : stepMismatch;
    valueMissing = valueMissing === VOID ? VOID : valueMissing;
    tooLong = tooLong === VOID ? VOID : tooLong;
    rangeUnderflow = rangeUnderflow === VOID ? VOID : rangeUnderflow;
    patternMismatch = patternMismatch === VOID ? VOID : patternMismatch;
    return Object.assign({}, _this__u8e3s4, {tooShort: tooShort, typeMismatch: typeMismatch, badInput: badInput, rangeOverflow: rangeOverflow, customError: customError, stepMismatch: stepMismatch, valueMissing: valueMissing, tooLong: tooLong, rangeUnderflow: rangeUnderflow, patternMismatch: patternMismatch});
  }
  function invoke_125(_this__u8e3s4, tooShort, typeMismatch, badInput, rangeOverflow, customError, stepMismatch, valueMissing, tooLong, rangeUnderflow, patternMismatch) {
    tooShort = tooShort === VOID ? VOID : tooShort;
    typeMismatch = typeMismatch === VOID ? VOID : typeMismatch;
    badInput = badInput === VOID ? VOID : badInput;
    rangeOverflow = rangeOverflow === VOID ? VOID : rangeOverflow;
    customError = customError === VOID ? VOID : customError;
    stepMismatch = stepMismatch === VOID ? VOID : stepMismatch;
    valueMissing = valueMissing === VOID ? VOID : valueMissing;
    tooLong = tooLong === VOID ? VOID : tooLong;
    rangeUnderflow = rangeUnderflow === VOID ? VOID : rangeUnderflow;
    patternMismatch = patternMismatch === VOID ? VOID : patternMismatch;
    return {tooShort: tooShort, typeMismatch: typeMismatch, badInput: badInput, rangeOverflow: rangeOverflow, customError: customError, stepMismatch: stepMismatch, valueMissing: valueMissing, tooLong: tooLong, rangeUnderflow: rangeUnderflow, patternMismatch: patternMismatch};
  }
  function copy_126(_this__u8e3s4, bubbles, cancelable, composed, viewTransition) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    viewTransition = viewTransition === VOID ? VOID : viewTransition;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, viewTransition: viewTransition});
  }
  function invoke_126(_this__u8e3s4, bubbles, cancelable, composed, viewTransition) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    viewTransition = viewTransition === VOID ? VOID : viewTransition;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, viewTransition: viewTransition};
  }
  function copy_127(_this__u8e3s4, bubbles, cancelable, composed, viewTransition, activation) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    viewTransition = viewTransition === VOID ? VOID : viewTransition;
    activation = activation === VOID ? VOID : activation;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, viewTransition: viewTransition, activation: activation});
  }
  function invoke_127(_this__u8e3s4, bubbles, cancelable, composed, viewTransition, activation) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    viewTransition = viewTransition === VOID ? VOID : viewTransition;
    activation = activation === VOID ? VOID : activation;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, viewTransition: viewTransition, activation: activation};
  }
  function copy_128(_this__u8e3s4, bubbles, cancelable, composed, track) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    track = track === VOID ? VOID : track;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, track: track});
  }
  function invoke_128(_this__u8e3s4, bubbles, cancelable, composed, track) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    track = track === VOID ? VOID : track;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, track: track};
  }
  function copy_129(_this__u8e3s4, transfer, targetOrigin) {
    transfer = transfer === VOID ? VOID : transfer;
    targetOrigin = targetOrigin === VOID ? VOID : targetOrigin;
    return Object.assign({}, _this__u8e3s4, {transfer: transfer, targetOrigin: targetOrigin});
  }
  function invoke_129(_this__u8e3s4, transfer, targetOrigin) {
    transfer = transfer === VOID ? VOID : transfer;
    targetOrigin = targetOrigin === VOID ? VOID : targetOrigin;
    return {transfer: transfer, targetOrigin: targetOrigin};
  }
  //region block: init
  HTML_instance = new HTML();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = HTML_instance;
  //endregion
  return _;
}));

