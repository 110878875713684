(function (factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof globalThis['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'kotlin-react-dom'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'kotlin-react-dom'.");
    }
    globalThis['kotlin-react-dom'] = factory(typeof globalThis['kotlin-react-dom'] === 'undefined' ? {} : globalThis['kotlin-react-dom'], globalThis['kotlin-kotlin-stdlib']);
  }
}(function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var protoOf = kotlin_kotlin.$_$.ab;
  var initMetadataForObject = kotlin_kotlin.$_$.fa;
  var VOID = kotlin_kotlin.$_$.g;
  //endregion
  //region block: pre-declaration
  initMetadataForObject(ReactHTML, 'ReactHTML');
  //endregion
  function ReactHTML() {
  }
  var ReactHTML_instance;
  function ReactHTML_getInstance() {
    return ReactHTML_instance;
  }
  function copy(_this__u8e3s4, componentStack, errorBoundary) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    errorBoundary = errorBoundary === VOID ? VOID : errorBoundary;
    return Object.assign({}, _this__u8e3s4, {componentStack: componentStack, errorBoundary: errorBoundary});
  }
  function invoke(_this__u8e3s4, componentStack, errorBoundary) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    errorBoundary = errorBoundary === VOID ? VOID : errorBoundary;
    return {componentStack: componentStack, errorBoundary: errorBoundary};
  }
  function copy_0(_this__u8e3s4, componentStack) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    return Object.assign({}, _this__u8e3s4, {componentStack: componentStack});
  }
  function invoke_0(_this__u8e3s4, componentStack) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    return {componentStack: componentStack};
  }
  function copy_1(_this__u8e3s4, componentStack) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    return Object.assign({}, _this__u8e3s4, {componentStack: componentStack});
  }
  function invoke_1(_this__u8e3s4, componentStack) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    return {componentStack: componentStack};
  }
  function copy_2(_this__u8e3s4, onRecoverableError, identifierPrefix, onCaughtError, onUncaughtError) {
    onRecoverableError = onRecoverableError === VOID ? VOID : onRecoverableError;
    identifierPrefix = identifierPrefix === VOID ? VOID : identifierPrefix;
    onCaughtError = onCaughtError === VOID ? VOID : onCaughtError;
    onUncaughtError = onUncaughtError === VOID ? VOID : onUncaughtError;
    return Object.assign({}, _this__u8e3s4, {onRecoverableError: onRecoverableError, identifierPrefix: identifierPrefix, onCaughtError: onCaughtError, onUncaughtError: onUncaughtError});
  }
  function invoke_2(_this__u8e3s4, onRecoverableError, identifierPrefix, onCaughtError, onUncaughtError) {
    onRecoverableError = onRecoverableError === VOID ? VOID : onRecoverableError;
    identifierPrefix = identifierPrefix === VOID ? VOID : identifierPrefix;
    onCaughtError = onCaughtError === VOID ? VOID : onCaughtError;
    onUncaughtError = onUncaughtError === VOID ? VOID : onUncaughtError;
    return {onRecoverableError: onRecoverableError, identifierPrefix: identifierPrefix, onCaughtError: onCaughtError, onUncaughtError: onUncaughtError};
  }
  function copy_3(_this__u8e3s4, componentStack) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    return Object.assign({}, _this__u8e3s4, {componentStack: componentStack});
  }
  function invoke_3(_this__u8e3s4, componentStack) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    return {componentStack: componentStack};
  }
  //region block: init
  ReactHTML_instance = new ReactHTML();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = ReactHTML_instance;
  //endregion
  return _;
}));

