(function (factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlinx-serialization-kotlinx-serialization-core.js', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlinx-serialization-kotlinx-serialization-core.js'), require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof globalThis['kotlinx-serialization-kotlinx-serialization-core'] === 'undefined') {
      throw new Error("Error loading module 'kotlinx-serialization-kotlinx-serialization-json'. Its dependency 'kotlinx-serialization-kotlinx-serialization-core' was not found. Please, check whether 'kotlinx-serialization-kotlinx-serialization-core' is loaded prior to 'kotlinx-serialization-kotlinx-serialization-json'.");
    }
    if (typeof globalThis['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'kotlinx-serialization-kotlinx-serialization-json'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'kotlinx-serialization-kotlinx-serialization-json'.");
    }
    globalThis['kotlinx-serialization-kotlinx-serialization-json'] = factory(typeof globalThis['kotlinx-serialization-kotlinx-serialization-json'] === 'undefined' ? {} : globalThis['kotlinx-serialization-kotlinx-serialization-json'], globalThis['kotlinx-serialization-kotlinx-serialization-core'], globalThis['kotlin-kotlin-stdlib']);
  }
}(function (_, kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var EmptySerializersModule = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.e2;
  var protoOf = kotlin_kotlin.$_$.ab;
  var initMetadataForObject = kotlin_kotlin.$_$.fa;
  var VOID = kotlin_kotlin.$_$.g;
  var Unit_instance = kotlin_kotlin.$_$.v4;
  var initMetadataForClass = kotlin_kotlin.$_$.aa;
  var toString = kotlin_kotlin.$_$.eb;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.r1;
  var charSequenceLength = kotlin_kotlin.$_$.o9;
  var charSequenceGet = kotlin_kotlin.$_$.n9;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.k2;
  var equals = kotlin_kotlin.$_$.s9;
  var toString_0 = kotlin_kotlin.$_$.af;
  var Decoder = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.o1;
  var CompositeDecoder = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.m1;
  var initMetadataForInterface = kotlin_kotlin.$_$.da;
  var initMetadataForCompanion = kotlin_kotlin.$_$.ba;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.j1;
  var THROW_CCE = kotlin_kotlin.$_$.yd;
  var hashCode = kotlin_kotlin.$_$.z9;
  var joinToString = kotlin_kotlin.$_$.u6;
  var KtMap = kotlin_kotlin.$_$.f5;
  var getKClassFromExpression = kotlin_kotlin.$_$.e;
  var LazyThreadSafetyMode_PUBLICATION_getInstance = kotlin_kotlin.$_$.n;
  var lazy = kotlin_kotlin.$_$.ve;
  var SerializerFactory = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.a2;
  var getBooleanHashCode = kotlin_kotlin.$_$.u9;
  var getStringHashCode = kotlin_kotlin.$_$.y9;
  var KtList = kotlin_kotlin.$_$.d5;
  var NumberFormatException_init_$Create$ = kotlin_kotlin.$_$.x1;
  var numberRangeToNumber = kotlin_kotlin.$_$.ua;
  var ClosedRange = kotlin_kotlin.$_$.gb;
  var isInterface = kotlin_kotlin.$_$.oa;
  var contains = kotlin_kotlin.$_$.jb;
  var toDouble = kotlin_kotlin.$_$.uc;
  var StringCompanionObject_instance = kotlin_kotlin.$_$.l4;
  var serializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.v;
  var InlinePrimitiveDescriptor = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.v1;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.z;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.q;
  var SEALED_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.e;
  var buildSerialDescriptor = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.i1;
  var KSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.i2;
  var MapSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.t;
  var SerialDescriptor = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.g1;
  var STRING_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.f;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.u1;
  var ListSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.s;
  var ENUM_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.h;
  var PrimitiveSerialDescriptor = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.c1;
  var toLongOrNull = kotlin_kotlin.$_$.yc;
  var toULongOrNull = kotlin_kotlin.$_$.dd;
  var ULong = kotlin_kotlin.$_$.he;
  var Companion_getInstance = kotlin_kotlin.$_$.t4;
  var serializer_0 = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.z;
  var _ULong___get_data__impl__fggpzb = kotlin_kotlin.$_$.l3;
  var toDoubleOrNull = kotlin_kotlin.$_$.tc;
  var toBooleanStrictOrNull = kotlin_kotlin.$_$.qc;
  var lazy_0 = kotlin_kotlin.$_$.we;
  var get_isNullable = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.f1;
  var get_isInline = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.e1;
  var get_annotations = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.d1;
  var KProperty1 = kotlin_kotlin.$_$.ob;
  var getPropertyCallableRef = kotlin_kotlin.$_$.x9;
  var Encoder = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.p1;
  var CompositeEncoder = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.n1;
  var toLong = kotlin_kotlin.$_$.cb;
  var _UInt___init__impl__l7qpdl = kotlin_kotlin.$_$.b3;
  var UInt__toString_impl_dbgl21 = kotlin_kotlin.$_$.d3;
  var _ULong___init__impl__c78o9k = kotlin_kotlin.$_$.k3;
  var ULong__toString_impl_f9au7k = kotlin_kotlin.$_$.m3;
  var _UByte___init__impl__g9hnc4 = kotlin_kotlin.$_$.s2;
  var UByte__toString_impl_v72jg = kotlin_kotlin.$_$.u2;
  var _UShort___init__impl__jigrne = kotlin_kotlin.$_$.t3;
  var UShort__toString_impl_edaoee = kotlin_kotlin.$_$.v3;
  var ElementMarker = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.s1;
  var captureStack = kotlin_kotlin.$_$.k9;
  var SerializationException = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.l2;
  var SerializationException_init_$Init$ = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.b;
  var charSequenceSubSequence = kotlin_kotlin.$_$.p9;
  var coerceAtLeast = kotlin_kotlin.$_$.hb;
  var coerceAtMost = kotlin_kotlin.$_$.ib;
  var SerializationException_init_$Create$ = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.c;
  var CLASS_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.i;
  var singleOrNull = kotlin_kotlin.$_$.u7;
  var emptyMap = kotlin_kotlin.$_$.k6;
  var getValue = kotlin_kotlin.$_$.p6;
  var fillArrayVal = kotlin_kotlin.$_$.t9;
  var copyOf = kotlin_kotlin.$_$.f6;
  var copyOf_0 = kotlin_kotlin.$_$.g6;
  var LIST_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.j;
  var DeepRecursiveFunction = kotlin_kotlin.$_$.od;
  var invoke = kotlin_kotlin.$_$.re;
  var CoroutineImpl = kotlin_kotlin.$_$.a9;
  var DeepRecursiveScope = kotlin_kotlin.$_$.pd;
  var Unit = kotlin_kotlin.$_$.ke;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.l8;
  var initMetadataForLambda = kotlin_kotlin.$_$.ea;
  var initMetadataForCoroutine = kotlin_kotlin.$_$.ca;
  var AbstractPolymorphicSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.q1;
  var getKClass = kotlin_kotlin.$_$.f;
  var findPolymorphicSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.m2;
  var ensureNotNull = kotlin_kotlin.$_$.qe;
  var DeserializationStrategy = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.h2;
  var SealedClassSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.k2;
  var jsonCachedSerialNames = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.c2;
  var ENUM = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.h1;
  var PrimitiveKind = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.b1;
  var PolymorphicKind = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.a1;
  var CONTEXTUAL_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.g;
  var MAP_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.k;
  var contextual = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.f2;
  var SerializersModuleCollector = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.g2;
  var AbstractDecoder = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.k1;
  var substringBefore = kotlin_kotlin.$_$.nc;
  var removeSuffix = kotlin_kotlin.$_$.fc;
  var substringAfter = kotlin_kotlin.$_$.mc;
  var contains_0 = kotlin_kotlin.$_$.vb;
  var plus = kotlin_kotlin.$_$.ye;
  var MissingFieldException = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.j2;
  var IllegalArgumentException = kotlin_kotlin.$_$.td;
  var isFinite = kotlin_kotlin.$_$.te;
  var isFinite_0 = kotlin_kotlin.$_$.se;
  var toUInt = kotlin_kotlin.$_$.cd;
  var _UInt___get_data__impl__f0vqqw = kotlin_kotlin.$_$.c3;
  var toULong = kotlin_kotlin.$_$.ed;
  var toUByte = kotlin_kotlin.$_$.bd;
  var _UByte___get_data__impl__jof9qr = kotlin_kotlin.$_$.t2;
  var toUShort = kotlin_kotlin.$_$.fd;
  var _UShort___get_data__impl__g0245 = kotlin_kotlin.$_$.u3;
  var objectCreate = kotlin_kotlin.$_$.za;
  var AbstractEncoder = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.l1;
  var findPolymorphicSerializer_0 = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.n2;
  var toString_1 = kotlin_kotlin.$_$.n2;
  var Companion_getInstance_0 = kotlin_kotlin.$_$.s4;
  var serializer_1 = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.x;
  var Companion_getInstance_1 = kotlin_kotlin.$_$.r4;
  var serializer_2 = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.y;
  var Companion_getInstance_2 = kotlin_kotlin.$_$.u4;
  var serializer_3 = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.w;
  var setOf = kotlin_kotlin.$_$.t7;
  var Char__toInt_impl_vasixd = kotlin_kotlin.$_$.m2;
  var numberToChar = kotlin_kotlin.$_$.va;
  var equals_0 = kotlin_kotlin.$_$.yb;
  var toByte = kotlin_kotlin.$_$.bb;
  var NamedValueDecoder = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.y1;
  var toShort = kotlin_kotlin.$_$.db;
  var single = kotlin_kotlin.$_$.ic;
  var Char = kotlin_kotlin.$_$.ld;
  var emptySet = kotlin_kotlin.$_$.l6;
  var plus_0 = kotlin_kotlin.$_$.l7;
  var toInt = kotlin_kotlin.$_$.wc;
  var toList = kotlin_kotlin.$_$.z7;
  var enumEntries = kotlin_kotlin.$_$.f9;
  var Enum = kotlin_kotlin.$_$.qd;
  var getContextualDescriptor = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.j1;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.xe;
  var last = kotlin_kotlin.$_$.y6;
  var removeLast = kotlin_kotlin.$_$.r7;
  var lastIndexOf = kotlin_kotlin.$_$.cc;
  var Long = kotlin_kotlin.$_$.ud;
  var Char__minus_impl_a2frrh = kotlin_kotlin.$_$.l2;
  var numberToLong = kotlin_kotlin.$_$.ya;
  var charArray = kotlin_kotlin.$_$.m9;
  var indexOf = kotlin_kotlin.$_$.zb;
  var isArray = kotlin_kotlin.$_$.ga;
  var isNumber = kotlin_kotlin.$_$.qa;
  var toBoolean = kotlin_kotlin.$_$.rc;
  var toByteOrNull = kotlin_kotlin.$_$.sc;
  var toShortOrNull = kotlin_kotlin.$_$.zc;
  var toIntOrNull = kotlin_kotlin.$_$.vc;
  var StringBuilder_init_$Create$_0 = kotlin_kotlin.$_$.i1;
  var HashMap_init_$Create$ = kotlin_kotlin.$_$.s;
  //endregion
  //region block: pre-declaration
  initMetadataForClass(Json, 'Json');
  initMetadataForObject(Default, 'Default', VOID, Json);
  initMetadataForClass(JsonBuilder, 'JsonBuilder');
  initMetadataForClass(JsonImpl, 'JsonImpl', VOID, Json);
  initMetadataForClass(JsonClassDiscriminator, 'JsonClassDiscriminator');
  initMetadataForClass(JsonNames, 'JsonNames');
  initMetadataForClass(JsonConfiguration, 'JsonConfiguration');
  initMetadataForInterface(JsonDecoder, 'JsonDecoder', VOID, VOID, [Decoder, CompositeDecoder]);
  initMetadataForCompanion(Companion);
  initMetadataForClass(JsonElement, 'JsonElement', VOID, VOID, VOID, VOID, VOID, {0: JsonElementSerializer_getInstance});
  initMetadataForCompanion(Companion_0);
  initMetadataForClass(JsonObject, 'JsonObject', VOID, JsonElement, [JsonElement, KtMap], VOID, VOID, {0: JsonObjectSerializer_getInstance});
  initMetadataForCompanion(Companion_1);
  initMetadataForClass(JsonPrimitive, 'JsonPrimitive', VOID, JsonElement, VOID, VOID, VOID, {0: JsonPrimitiveSerializer_getInstance});
  initMetadataForObject(JsonNull, 'JsonNull', VOID, JsonPrimitive, [JsonPrimitive, SerializerFactory], VOID, VOID, {0: JsonNull_getInstance});
  initMetadataForClass(JsonLiteral, 'JsonLiteral', VOID, JsonPrimitive);
  initMetadataForCompanion(Companion_2);
  initMetadataForClass(JsonArray, 'JsonArray', VOID, JsonElement, [JsonElement, KtList], VOID, VOID, {0: JsonArraySerializer_getInstance});
  initMetadataForClass(JsonObjectBuilder, 'JsonObjectBuilder');
  initMetadataForClass(JsonArrayBuilder, 'JsonArrayBuilder');
  initMetadataForObject(JsonElementSerializer, 'JsonElementSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(JsonObjectDescriptor, 'JsonObjectDescriptor', VOID, VOID, [SerialDescriptor]);
  initMetadataForObject(JsonObjectSerializer, 'JsonObjectSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(JsonPrimitiveSerializer, 'JsonPrimitiveSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(JsonArrayDescriptor, 'JsonArrayDescriptor', VOID, VOID, [SerialDescriptor]);
  initMetadataForObject(JsonArraySerializer, 'JsonArraySerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(JsonNullSerializer, 'JsonNullSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(JsonLiteralSerializer, 'JsonLiteralSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(defer$1, VOID, VOID, VOID, [SerialDescriptor]);
  initMetadataForInterface(JsonEncoder, 'JsonEncoder', VOID, VOID, [Encoder, CompositeEncoder]);
  initMetadataForClass(Composer, 'Composer');
  initMetadataForClass(ComposerForUnsignedNumbers, 'ComposerForUnsignedNumbers', VOID, Composer);
  initMetadataForClass(ComposerForUnquotedLiterals, 'ComposerForUnquotedLiterals', VOID, Composer);
  initMetadataForClass(ComposerWithPrettyPrint, 'ComposerWithPrettyPrint', VOID, Composer);
  initMetadataForClass(JsonElementMarker, 'JsonElementMarker');
  initMetadataForClass(JsonException, 'JsonException', VOID, SerializationException);
  initMetadataForClass(JsonEncodingException, 'JsonEncodingException', VOID, JsonException);
  initMetadataForClass(JsonDecodingException, 'JsonDecodingException', VOID, JsonException);
  initMetadataForObject(Tombstone, 'Tombstone');
  initMetadataForClass(JsonPath, 'JsonPath', JsonPath);
  initMetadataForLambda(JsonTreeReader$readDeepRecursive$slambda, CoroutineImpl, VOID, [2]);
  initMetadataForCoroutine($readObjectCOROUTINE$0, CoroutineImpl);
  initMetadataForClass(JsonTreeReader, 'JsonTreeReader', VOID, VOID, VOID, [0]);
  initMetadataForClass(PolymorphismValidator, 'PolymorphismValidator', VOID, VOID, [SerializersModuleCollector]);
  initMetadataForClass(Key, 'Key', Key);
  initMetadataForClass(DescriptorSchemaCache, 'DescriptorSchemaCache', DescriptorSchemaCache);
  initMetadataForClass(DiscriminatorHolder, 'DiscriminatorHolder');
  initMetadataForClass(StreamingJsonDecoder, 'StreamingJsonDecoder', VOID, AbstractDecoder, [JsonDecoder, AbstractDecoder]);
  initMetadataForClass(JsonDecoderForUnsignedTypes, 'JsonDecoderForUnsignedTypes', VOID, AbstractDecoder);
  initMetadataForClass(StreamingJsonEncoder, 'StreamingJsonEncoder', VOID, AbstractEncoder, [JsonEncoder, AbstractEncoder]);
  initMetadataForClass(AbstractJsonTreeDecoder, 'AbstractJsonTreeDecoder', VOID, NamedValueDecoder, [NamedValueDecoder, JsonDecoder]);
  initMetadataForClass(JsonTreeDecoder, 'JsonTreeDecoder', VOID, AbstractJsonTreeDecoder);
  initMetadataForClass(JsonTreeListDecoder, 'JsonTreeListDecoder', VOID, AbstractJsonTreeDecoder);
  initMetadataForClass(JsonPrimitiveDecoder, 'JsonPrimitiveDecoder', VOID, AbstractJsonTreeDecoder);
  initMetadataForClass(JsonTreeMapDecoder, 'JsonTreeMapDecoder', VOID, JsonTreeDecoder);
  initMetadataForClass(WriteMode, 'WriteMode', VOID, Enum);
  initMetadataForClass(AbstractJsonLexer, 'AbstractJsonLexer');
  initMetadataForObject(CharMappings, 'CharMappings');
  initMetadataForClass(StringJsonLexer, 'StringJsonLexer', VOID, AbstractJsonLexer);
  initMetadataForClass(DynamicInput, 'DynamicInput', VOID, NamedValueDecoder, [NamedValueDecoder, JsonDecoder]);
  initMetadataForClass(PrimitiveDynamicInput, 'PrimitiveDynamicInput', VOID, DynamicInput);
  initMetadataForClass(DynamicListInput, 'DynamicListInput', VOID, DynamicInput);
  initMetadataForClass(DynamicMapInput, 'DynamicMapInput', VOID, DynamicInput);
  initMetadataForClass(JsonToStringWriter, 'JsonToStringWriter', JsonToStringWriter);
  //endregion
  function Default() {
    Default_instance = this;
    Json.call(this, new JsonConfiguration(), EmptySerializersModule());
  }
  var Default_instance;
  function Default_getInstance() {
    if (Default_instance == null)
      new Default();
    return Default_instance;
  }
  function Json(configuration, serializersModule) {
    Default_getInstance();
    this.s16_1 = configuration;
    this.t16_1 = serializersModule;
    this.u16_1 = new DescriptorSchemaCache();
  }
  protoOf(Json).mq = function () {
    return this.t16_1;
  };
  protoOf(Json).v16 = function (serializer, value) {
    var result = new JsonToStringWriter();
    try {
      encodeByWriter(this, result, serializer, value);
      return result.toString();
    }finally {
      result.z16();
    }
  };
  protoOf(Json).w16 = function (deserializer, string) {
    var lexer = new StringJsonLexer(string);
    var input = new StreamingJsonDecoder(this, WriteMode_OBJ_getInstance(), lexer, deserializer.pm(), null);
    var result = input.wp(deserializer);
    lexer.m17();
    return result;
  };
  protoOf(Json).x16 = function (string) {
    return this.w16(JsonElementSerializer_getInstance(), string);
  };
  function Json_0(from, builderAction) {
    from = from === VOID ? Default_getInstance() : from;
    var builder = new JsonBuilder(from);
    builderAction(builder);
    var conf = builder.d18();
    return new JsonImpl(conf, builder.c18_1);
  }
  function JsonBuilder(json) {
    this.n17_1 = json.s16_1.e18_1;
    this.o17_1 = json.s16_1.j18_1;
    this.p17_1 = json.s16_1.f18_1;
    this.q17_1 = json.s16_1.g18_1;
    this.r17_1 = json.s16_1.h18_1;
    this.s17_1 = json.s16_1.i18_1;
    this.t17_1 = json.s16_1.k18_1;
    this.u17_1 = json.s16_1.l18_1;
    this.v17_1 = json.s16_1.m18_1;
    this.w17_1 = json.s16_1.n18_1;
    this.x17_1 = json.s16_1.o18_1;
    this.y17_1 = json.s16_1.p18_1;
    this.z17_1 = json.s16_1.q18_1;
    this.a18_1 = json.s16_1.r18_1;
    this.b18_1 = json.s16_1.s18_1;
    this.c18_1 = json.mq();
  }
  protoOf(JsonBuilder).d18 = function () {
    if (this.v17_1) {
      // Inline function 'kotlin.require' call
      if (!(this.w17_1 === 'type')) {
        // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
        var message = 'Class discriminator should not be specified when array polymorphism is specified';
        throw IllegalArgumentException_init_$Create$(toString(message));
      }
    }
    if (!this.s17_1) {
      // Inline function 'kotlin.require' call
      if (!(this.t17_1 === '    ')) {
        // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
        var message_0 = 'Indent should not be specified when default printing mode is used';
        throw IllegalArgumentException_init_$Create$(toString(message_0));
      }
    } else if (!(this.t17_1 === '    ')) {
      var tmp2 = this.t17_1;
      var tmp$ret$3;
      $l$block: {
        // Inline function 'kotlin.text.all' call
        var inductionVariable = 0;
        while (inductionVariable < charSequenceLength(tmp2)) {
          var element = charSequenceGet(tmp2, inductionVariable);
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
          if (!(element === _Char___init__impl__6a9atx(32) || element === _Char___init__impl__6a9atx(9) || element === _Char___init__impl__6a9atx(13) || element === _Char___init__impl__6a9atx(10))) {
            tmp$ret$3 = false;
            break $l$block;
          }
        }
        tmp$ret$3 = true;
      }
      var allWhitespaces = tmp$ret$3;
      // Inline function 'kotlin.require' call
      if (!allWhitespaces) {
        // Inline function 'kotlinx.serialization.json.JsonBuilder.build.<anonymous>' call
        var message_1 = 'Only whitespace, tab, newline and carriage return are allowed as pretty print symbols. Had ' + this.t17_1;
        throw IllegalArgumentException_init_$Create$(toString(message_1));
      }
    }
    return new JsonConfiguration(this.n17_1, this.p17_1, this.q17_1, this.r17_1, this.s17_1, this.o17_1, this.t17_1, this.u17_1, this.v17_1, this.w17_1, this.x17_1, this.y17_1, this.z17_1, this.a18_1, this.b18_1);
  };
  function validateConfiguration($this) {
    if (equals($this.mq(), EmptySerializersModule()))
      return Unit_instance;
    var collector = new PolymorphismValidator($this.s16_1.m18_1, $this.s16_1.n18_1);
    $this.mq().b16(collector);
  }
  function JsonImpl(configuration, module_0) {
    Json.call(this, configuration, module_0);
    validateConfiguration(this);
  }
  function JsonClassDiscriminator() {
  }
  function JsonNames() {
  }
  function JsonConfiguration(encodeDefaults, ignoreUnknownKeys, isLenient, allowStructuredMapKeys, prettyPrint, explicitNulls, prettyPrintIndent, coerceInputValues, useArrayPolymorphism, classDiscriminator, allowSpecialFloatingPointValues, useAlternativeNames, namingStrategy, decodeEnumsCaseInsensitive, allowTrailingComma) {
    encodeDefaults = encodeDefaults === VOID ? false : encodeDefaults;
    ignoreUnknownKeys = ignoreUnknownKeys === VOID ? false : ignoreUnknownKeys;
    isLenient = isLenient === VOID ? false : isLenient;
    allowStructuredMapKeys = allowStructuredMapKeys === VOID ? false : allowStructuredMapKeys;
    prettyPrint = prettyPrint === VOID ? false : prettyPrint;
    explicitNulls = explicitNulls === VOID ? true : explicitNulls;
    prettyPrintIndent = prettyPrintIndent === VOID ? '    ' : prettyPrintIndent;
    coerceInputValues = coerceInputValues === VOID ? false : coerceInputValues;
    useArrayPolymorphism = useArrayPolymorphism === VOID ? false : useArrayPolymorphism;
    classDiscriminator = classDiscriminator === VOID ? 'type' : classDiscriminator;
    allowSpecialFloatingPointValues = allowSpecialFloatingPointValues === VOID ? false : allowSpecialFloatingPointValues;
    useAlternativeNames = useAlternativeNames === VOID ? true : useAlternativeNames;
    namingStrategy = namingStrategy === VOID ? null : namingStrategy;
    decodeEnumsCaseInsensitive = decodeEnumsCaseInsensitive === VOID ? false : decodeEnumsCaseInsensitive;
    allowTrailingComma = allowTrailingComma === VOID ? false : allowTrailingComma;
    this.e18_1 = encodeDefaults;
    this.f18_1 = ignoreUnknownKeys;
    this.g18_1 = isLenient;
    this.h18_1 = allowStructuredMapKeys;
    this.i18_1 = prettyPrint;
    this.j18_1 = explicitNulls;
    this.k18_1 = prettyPrintIndent;
    this.l18_1 = coerceInputValues;
    this.m18_1 = useArrayPolymorphism;
    this.n18_1 = classDiscriminator;
    this.o18_1 = allowSpecialFloatingPointValues;
    this.p18_1 = useAlternativeNames;
    this.q18_1 = namingStrategy;
    this.r18_1 = decodeEnumsCaseInsensitive;
    this.s18_1 = allowTrailingComma;
  }
  protoOf(JsonConfiguration).toString = function () {
    return 'JsonConfiguration(encodeDefaults=' + this.e18_1 + ', ignoreUnknownKeys=' + this.f18_1 + ', isLenient=' + this.g18_1 + ', ' + ('allowStructuredMapKeys=' + this.h18_1 + ', prettyPrint=' + this.i18_1 + ', explicitNulls=' + this.j18_1 + ', ') + ("prettyPrintIndent='" + this.k18_1 + "', coerceInputValues=" + this.l18_1 + ', useArrayPolymorphism=' + this.m18_1 + ', ') + ("classDiscriminator='" + this.n18_1 + "', allowSpecialFloatingPointValues=" + this.o18_1 + ', useAlternativeNames=' + this.p18_1 + ', ') + ('namingStrategy=' + toString_0(this.q18_1) + ', decodeEnumsCaseInsensitive=' + this.r18_1 + ', allowTrailingComma=' + this.s18_1 + ')');
  };
  function JsonDecoder() {
  }
  function get_jsonUnquotedLiteralDescriptor() {
    _init_properties_JsonElement_kt__7cbdc2();
    return jsonUnquotedLiteralDescriptor;
  }
  var jsonUnquotedLiteralDescriptor;
  function Companion() {
  }
  protoOf(Companion).v18 = function () {
    return JsonElementSerializer_getInstance();
  };
  var Companion_instance;
  function Companion_getInstance_3() {
    return Companion_instance;
  }
  function JsonElement() {
  }
  function Companion_0() {
  }
  var Companion_instance_0;
  function Companion_getInstance_4() {
    return Companion_instance_0;
  }
  function JsonObject$toString$lambda(_name_for_destructuring_parameter_0__wldtmu) {
    // Inline function 'kotlin.collections.component1' call
    var k = _name_for_destructuring_parameter_0__wldtmu.z1();
    // Inline function 'kotlin.collections.component2' call
    var v = _name_for_destructuring_parameter_0__wldtmu.a2();
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlinx.serialization.json.JsonObject.toString.<anonymous>.<anonymous>' call
    printQuoted(this_0, k);
    this_0.v7(_Char___init__impl__6a9atx(58));
    this_0.t7(v);
    return this_0.toString();
  }
  function JsonObject(content) {
    JsonElement.call(this);
    this.w18_1 = content;
  }
  protoOf(JsonObject).g2 = function () {
    return this.w18_1.g2();
  };
  protoOf(JsonObject).e2 = function () {
    return this.w18_1.e2();
  };
  protoOf(JsonObject).n = function () {
    return this.w18_1.n();
  };
  protoOf(JsonObject).f2 = function () {
    return this.w18_1.f2();
  };
  protoOf(JsonObject).x18 = function (key) {
    return this.w18_1.b2(key);
  };
  protoOf(JsonObject).b2 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    return this.x18((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(JsonObject).dd = function (key) {
    return this.w18_1.d2(key);
  };
  protoOf(JsonObject).d2 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.dd((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(JsonObject).p = function () {
    return this.w18_1.p();
  };
  protoOf(JsonObject).equals = function (other) {
    return equals(this.w18_1, other);
  };
  protoOf(JsonObject).hashCode = function () {
    return hashCode(this.w18_1);
  };
  protoOf(JsonObject).toString = function () {
    var tmp = this.w18_1.g2();
    return joinToString(tmp, ',', '{', '}', VOID, VOID, JsonObject$toString$lambda);
  };
  function Companion_1() {
  }
  var Companion_instance_1;
  function Companion_getInstance_5() {
    return Companion_instance_1;
  }
  function JsonPrimitive() {
    JsonElement.call(this);
  }
  protoOf(JsonPrimitive).toString = function () {
    return this.y18();
  };
  function get_jsonPrimitive(_this__u8e3s4) {
    _init_properties_JsonElement_kt__7cbdc2();
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof JsonPrimitive ? _this__u8e3s4 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      error(_this__u8e3s4, 'JsonPrimitive');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function error(_this__u8e3s4, element) {
    _init_properties_JsonElement_kt__7cbdc2();
    throw IllegalArgumentException_init_$Create$('Element ' + toString(getKClassFromExpression(_this__u8e3s4)) + ' is not a ' + element);
  }
  function _get_$cachedSerializer__te6jhj($this) {
    return $this.a19_1.a2();
  }
  function JsonNull$_anonymous__enib48() {
    return JsonNullSerializer_getInstance();
  }
  function JsonNull() {
    JsonNull_instance = this;
    JsonPrimitive.call(this);
    this.z18_1 = 'null';
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.a19_1 = lazy(tmp_0, JsonNull$_anonymous__enib48);
  }
  protoOf(JsonNull).y18 = function () {
    return this.z18_1;
  };
  protoOf(JsonNull).v18 = function () {
    return _get_$cachedSerializer__te6jhj(this);
  };
  protoOf(JsonNull).jy = function (typeParamsSerializers) {
    return this.v18();
  };
  var JsonNull_instance;
  function JsonNull_getInstance() {
    if (JsonNull_instance == null)
      new JsonNull();
    return JsonNull_instance;
  }
  function JsonPrimitive_0(value) {
    _init_properties_JsonElement_kt__7cbdc2();
    if (value == null)
      return JsonNull_getInstance();
    return new JsonLiteral(value, false);
  }
  function JsonPrimitive_1(value) {
    _init_properties_JsonElement_kt__7cbdc2();
    if (value == null)
      return JsonNull_getInstance();
    return new JsonLiteral(value, false);
  }
  function JsonPrimitive_2(value) {
    _init_properties_JsonElement_kt__7cbdc2();
    if (value == null)
      return JsonNull_getInstance();
    return new JsonLiteral(value, true);
  }
  function JsonLiteral(body, isString, coerceToInlineType) {
    coerceToInlineType = coerceToInlineType === VOID ? null : coerceToInlineType;
    JsonPrimitive.call(this);
    this.b19_1 = isString;
    this.c19_1 = coerceToInlineType;
    this.d19_1 = toString(body);
    if (!(this.c19_1 == null)) {
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.require' call
      if (!this.c19_1.do()) {
        // Inline function 'kotlin.require.<anonymous>' call
        var message = 'Failed requirement.';
        throw IllegalArgumentException_init_$Create$(toString(message));
      }
    }
  }
  protoOf(JsonLiteral).y18 = function () {
    return this.d19_1;
  };
  protoOf(JsonLiteral).toString = function () {
    var tmp;
    if (this.b19_1) {
      // Inline function 'kotlin.text.buildString' call
      // Inline function 'kotlin.apply' call
      var this_0 = StringBuilder_init_$Create$();
      // Inline function 'kotlinx.serialization.json.JsonLiteral.toString.<anonymous>' call
      printQuoted(this_0, this.d19_1);
      tmp = this_0.toString();
    } else {
      tmp = this.d19_1;
    }
    return tmp;
  };
  protoOf(JsonLiteral).equals = function (other) {
    if (this === other)
      return true;
    if (other == null || !getKClassFromExpression(this).equals(getKClassFromExpression(other)))
      return false;
    if (!(other instanceof JsonLiteral))
      THROW_CCE();
    if (!(this.b19_1 === other.b19_1))
      return false;
    if (!(this.d19_1 === other.d19_1))
      return false;
    return true;
  };
  protoOf(JsonLiteral).hashCode = function () {
    var result = getBooleanHashCode(this.b19_1);
    result = imul(31, result) + getStringHashCode(this.d19_1) | 0;
    return result;
  };
  function Companion_2() {
  }
  var Companion_instance_2;
  function Companion_getInstance_6() {
    return Companion_instance_2;
  }
  function JsonArray(content) {
    JsonElement.call(this);
    this.e19_1 = content;
  }
  protoOf(JsonArray).n = function () {
    return this.e19_1.n();
  };
  protoOf(JsonArray).f19 = function (element) {
    return this.e19_1.r(element);
  };
  protoOf(JsonArray).r = function (element) {
    if (!(element instanceof JsonElement))
      return false;
    return this.f19(element instanceof JsonElement ? element : THROW_CCE());
  };
  protoOf(JsonArray).q = function (index) {
    return this.e19_1.q(index);
  };
  protoOf(JsonArray).g19 = function (element) {
    return this.e19_1.t(element);
  };
  protoOf(JsonArray).t = function (element) {
    if (!(element instanceof JsonElement))
      return -1;
    return this.g19(element instanceof JsonElement ? element : THROW_CCE());
  };
  protoOf(JsonArray).p = function () {
    return this.e19_1.p();
  };
  protoOf(JsonArray).k = function () {
    return this.e19_1.k();
  };
  protoOf(JsonArray).s = function (index) {
    return this.e19_1.s(index);
  };
  protoOf(JsonArray).equals = function (other) {
    return equals(this.e19_1, other);
  };
  protoOf(JsonArray).hashCode = function () {
    return hashCode(this.e19_1);
  };
  protoOf(JsonArray).toString = function () {
    return joinToString(this.e19_1, ',', '[', ']');
  };
  function get_booleanOrNull(_this__u8e3s4) {
    _init_properties_JsonElement_kt__7cbdc2();
    return toBooleanStrictOrNull_0(_this__u8e3s4.y18());
  }
  function get_int(_this__u8e3s4) {
    _init_properties_JsonElement_kt__7cbdc2();
    // Inline function 'kotlinx.serialization.json.mapExceptions' call
    var tmp;
    try {
      // Inline function 'kotlinx.serialization.json.<get-int>.<anonymous>' call
      tmp = (new StringJsonLexer(_this__u8e3s4.y18())).h19();
    } catch ($p) {
      var tmp_0;
      if ($p instanceof JsonDecodingException) {
        var e = $p;
        throw NumberFormatException_init_$Create$(e.message);
      } else {
        throw $p;
      }
    }
    var result = tmp;
    // Inline function 'kotlin.ranges.contains' call
    var this_0 = numberRangeToNumber(-2147483648, 2147483647);
    if (!contains(isInterface(this_0, ClosedRange) ? this_0 : THROW_CCE(), result))
      throw NumberFormatException_init_$Create$(_this__u8e3s4.y18() + ' is not an Int');
    return result.i1();
  }
  function get_long(_this__u8e3s4) {
    _init_properties_JsonElement_kt__7cbdc2();
    // Inline function 'kotlinx.serialization.json.mapExceptions' call
    var tmp;
    try {
      // Inline function 'kotlinx.serialization.json.<get-long>.<anonymous>' call
      tmp = (new StringJsonLexer(_this__u8e3s4.y18())).h19();
    } catch ($p) {
      var tmp_0;
      if ($p instanceof JsonDecodingException) {
        var e = $p;
        throw NumberFormatException_init_$Create$(e.message);
      } else {
        throw $p;
      }
    }
    return tmp;
  }
  function get_float(_this__u8e3s4) {
    _init_properties_JsonElement_kt__7cbdc2();
    // Inline function 'kotlin.text.toFloat' call
    var this_0 = _this__u8e3s4.y18();
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return toDouble(this_0);
  }
  function get_double(_this__u8e3s4) {
    _init_properties_JsonElement_kt__7cbdc2();
    return toDouble(_this__u8e3s4.y18());
  }
  function get_contentOrNull(_this__u8e3s4) {
    _init_properties_JsonElement_kt__7cbdc2();
    var tmp;
    if (_this__u8e3s4 instanceof JsonNull) {
      tmp = null;
    } else {
      tmp = _this__u8e3s4.y18();
    }
    return tmp;
  }
  function get_jsonObject(_this__u8e3s4) {
    _init_properties_JsonElement_kt__7cbdc2();
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof JsonObject ? _this__u8e3s4 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      error(_this__u8e3s4, 'JsonObject');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  var properties_initialized_JsonElement_kt_abxy8s;
  function _init_properties_JsonElement_kt__7cbdc2() {
    if (!properties_initialized_JsonElement_kt_abxy8s) {
      properties_initialized_JsonElement_kt_abxy8s = true;
      jsonUnquotedLiteralDescriptor = InlinePrimitiveDescriptor('kotlinx.serialization.json.JsonUnquotedLiteral', serializer(StringCompanionObject_instance));
    }
  }
  function JsonObjectBuilder() {
    var tmp = this;
    // Inline function 'kotlin.collections.linkedMapOf' call
    tmp.i19_1 = LinkedHashMap_init_$Create$();
  }
  protoOf(JsonObjectBuilder).j19 = function (key, element) {
    return this.i19_1.j2(key, element);
  };
  protoOf(JsonObjectBuilder).d18 = function () {
    return new JsonObject(this.i19_1);
  };
  function JsonArrayBuilder() {
    var tmp = this;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp.k19_1 = ArrayList_init_$Create$();
  }
  protoOf(JsonArrayBuilder).l19 = function (element) {
    // Inline function 'kotlin.collections.plusAssign' call
    this.k19_1.e(element);
    return true;
  };
  protoOf(JsonArrayBuilder).d18 = function () {
    return new JsonArray(this.k19_1);
  };
  function JsonElementSerializer$descriptor$lambda($this$buildSerialDescriptor) {
    $this$buildSerialDescriptor.an('JsonPrimitive', defer(JsonElementSerializer$descriptor$lambda$lambda));
    $this$buildSerialDescriptor.an('JsonNull', defer(JsonElementSerializer$descriptor$lambda$lambda_0));
    $this$buildSerialDescriptor.an('JsonLiteral', defer(JsonElementSerializer$descriptor$lambda$lambda_1));
    $this$buildSerialDescriptor.an('JsonObject', defer(JsonElementSerializer$descriptor$lambda$lambda_2));
    $this$buildSerialDescriptor.an('JsonArray', defer(JsonElementSerializer$descriptor$lambda$lambda_3));
    return Unit_instance;
  }
  function JsonElementSerializer$descriptor$lambda$lambda() {
    return JsonPrimitiveSerializer_getInstance().m19_1;
  }
  function JsonElementSerializer$descriptor$lambda$lambda_0() {
    return JsonNullSerializer_getInstance().n19_1;
  }
  function JsonElementSerializer$descriptor$lambda$lambda_1() {
    return JsonLiteralSerializer_getInstance().o19_1;
  }
  function JsonElementSerializer$descriptor$lambda$lambda_2() {
    return JsonObjectSerializer_getInstance().p19_1;
  }
  function JsonElementSerializer$descriptor$lambda$lambda_3() {
    return JsonArraySerializer_getInstance().q19_1;
  }
  function JsonElementSerializer() {
    JsonElementSerializer_instance = this;
    var tmp = this;
    var tmp_0 = SEALED_getInstance();
    tmp.r19_1 = buildSerialDescriptor('kotlinx.serialization.json.JsonElement', tmp_0, [], JsonElementSerializer$descriptor$lambda);
  }
  protoOf(JsonElementSerializer).pm = function () {
    return this.r19_1;
  };
  protoOf(JsonElementSerializer).s19 = function (encoder, value) {
    verify(encoder);
    if (value instanceof JsonPrimitive) {
      encoder.pr(JsonPrimitiveSerializer_getInstance(), value);
    } else {
      if (value instanceof JsonObject) {
        encoder.pr(JsonObjectSerializer_getInstance(), value);
      } else {
        if (value instanceof JsonArray) {
          encoder.pr(JsonArraySerializer_getInstance(), value);
        }
      }
    }
  };
  protoOf(JsonElementSerializer).qm = function (encoder, value) {
    return this.s19(encoder, value instanceof JsonElement ? value : THROW_CCE());
  };
  protoOf(JsonElementSerializer).rm = function (decoder) {
    var input = asJsonDecoder(decoder);
    return input.u18();
  };
  var JsonElementSerializer_instance;
  function JsonElementSerializer_getInstance() {
    if (JsonElementSerializer_instance == null)
      new JsonElementSerializer();
    return JsonElementSerializer_instance;
  }
  function JsonObjectDescriptor() {
    JsonObjectDescriptor_instance = this;
    this.t19_1 = MapSerializer(serializer(StringCompanionObject_instance), JsonElementSerializer_getInstance()).pm();
    this.u19_1 = 'kotlinx.serialization.json.JsonObject';
  }
  protoOf(JsonObjectDescriptor).bo = function () {
    return this.t19_1.bo();
  };
  protoOf(JsonObjectDescriptor).co = function () {
    return this.t19_1.co();
  };
  protoOf(JsonObjectDescriptor).do = function () {
    return this.t19_1.do();
  };
  protoOf(JsonObjectDescriptor).xn = function () {
    return this.t19_1.xn();
  };
  protoOf(JsonObjectDescriptor).eo = function () {
    return this.t19_1.eo();
  };
  protoOf(JsonObjectDescriptor).fo = function (index) {
    return this.t19_1.fo(index);
  };
  protoOf(JsonObjectDescriptor).go = function (index) {
    return this.t19_1.go(index);
  };
  protoOf(JsonObjectDescriptor).ho = function (name) {
    return this.t19_1.ho(name);
  };
  protoOf(JsonObjectDescriptor).io = function (index) {
    return this.t19_1.io(index);
  };
  protoOf(JsonObjectDescriptor).jo = function (index) {
    return this.t19_1.jo(index);
  };
  protoOf(JsonObjectDescriptor).qn = function () {
    return this.u19_1;
  };
  var JsonObjectDescriptor_instance;
  function JsonObjectDescriptor_getInstance() {
    if (JsonObjectDescriptor_instance == null)
      new JsonObjectDescriptor();
    return JsonObjectDescriptor_instance;
  }
  function JsonObjectSerializer() {
    JsonObjectSerializer_instance = this;
    this.p19_1 = JsonObjectDescriptor_getInstance();
  }
  protoOf(JsonObjectSerializer).pm = function () {
    return this.p19_1;
  };
  protoOf(JsonObjectSerializer).v19 = function (encoder, value) {
    verify(encoder);
    MapSerializer(serializer(StringCompanionObject_instance), JsonElementSerializer_getInstance()).qm(encoder, value);
  };
  protoOf(JsonObjectSerializer).qm = function (encoder, value) {
    return this.v19(encoder, value instanceof JsonObject ? value : THROW_CCE());
  };
  protoOf(JsonObjectSerializer).rm = function (decoder) {
    verify_0(decoder);
    return new JsonObject(MapSerializer(serializer(StringCompanionObject_instance), JsonElementSerializer_getInstance()).rm(decoder));
  };
  var JsonObjectSerializer_instance;
  function JsonObjectSerializer_getInstance() {
    if (JsonObjectSerializer_instance == null)
      new JsonObjectSerializer();
    return JsonObjectSerializer_instance;
  }
  function JsonPrimitiveSerializer() {
    JsonPrimitiveSerializer_instance = this;
    this.m19_1 = buildSerialDescriptor('kotlinx.serialization.json.JsonPrimitive', STRING_getInstance(), []);
  }
  protoOf(JsonPrimitiveSerializer).pm = function () {
    return this.m19_1;
  };
  protoOf(JsonPrimitiveSerializer).w19 = function (encoder, value) {
    verify(encoder);
    var tmp;
    if (value instanceof JsonNull) {
      encoder.pr(JsonNullSerializer_getInstance(), JsonNull_getInstance());
      tmp = Unit_instance;
    } else {
      var tmp_0 = JsonLiteralSerializer_getInstance();
      encoder.pr(tmp_0, value instanceof JsonLiteral ? value : THROW_CCE());
      tmp = Unit_instance;
    }
    return tmp;
  };
  protoOf(JsonPrimitiveSerializer).qm = function (encoder, value) {
    return this.w19(encoder, value instanceof JsonPrimitive ? value : THROW_CCE());
  };
  protoOf(JsonPrimitiveSerializer).rm = function (decoder) {
    var result = asJsonDecoder(decoder).u18();
    if (!(result instanceof JsonPrimitive))
      throw JsonDecodingException_0(-1, 'Unexpected JSON element, expected JsonPrimitive, had ' + toString(getKClassFromExpression(result)), toString(result));
    return result;
  };
  var JsonPrimitiveSerializer_instance;
  function JsonPrimitiveSerializer_getInstance() {
    if (JsonPrimitiveSerializer_instance == null)
      new JsonPrimitiveSerializer();
    return JsonPrimitiveSerializer_instance;
  }
  function asJsonEncoder(_this__u8e3s4) {
    var tmp0_elvis_lhs = isInterface(_this__u8e3s4, JsonEncoder) ? _this__u8e3s4 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$('This serializer can be used only with Json format.' + ('Expected Encoder to be JsonEncoder, got ' + toString(getKClassFromExpression(_this__u8e3s4))));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function asJsonDecoder(_this__u8e3s4) {
    var tmp0_elvis_lhs = isInterface(_this__u8e3s4, JsonDecoder) ? _this__u8e3s4 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$('This serializer can be used only with Json format.' + ('Expected Decoder to be JsonDecoder, got ' + toString(getKClassFromExpression(_this__u8e3s4))));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function JsonArrayDescriptor() {
    JsonArrayDescriptor_instance = this;
    this.x19_1 = ListSerializer(JsonElementSerializer_getInstance()).pm();
    this.y19_1 = 'kotlinx.serialization.json.JsonArray';
  }
  protoOf(JsonArrayDescriptor).bo = function () {
    return this.x19_1.bo();
  };
  protoOf(JsonArrayDescriptor).co = function () {
    return this.x19_1.co();
  };
  protoOf(JsonArrayDescriptor).do = function () {
    return this.x19_1.do();
  };
  protoOf(JsonArrayDescriptor).xn = function () {
    return this.x19_1.xn();
  };
  protoOf(JsonArrayDescriptor).eo = function () {
    return this.x19_1.eo();
  };
  protoOf(JsonArrayDescriptor).fo = function (index) {
    return this.x19_1.fo(index);
  };
  protoOf(JsonArrayDescriptor).go = function (index) {
    return this.x19_1.go(index);
  };
  protoOf(JsonArrayDescriptor).ho = function (name) {
    return this.x19_1.ho(name);
  };
  protoOf(JsonArrayDescriptor).io = function (index) {
    return this.x19_1.io(index);
  };
  protoOf(JsonArrayDescriptor).jo = function (index) {
    return this.x19_1.jo(index);
  };
  protoOf(JsonArrayDescriptor).qn = function () {
    return this.y19_1;
  };
  var JsonArrayDescriptor_instance;
  function JsonArrayDescriptor_getInstance() {
    if (JsonArrayDescriptor_instance == null)
      new JsonArrayDescriptor();
    return JsonArrayDescriptor_instance;
  }
  function JsonArraySerializer() {
    JsonArraySerializer_instance = this;
    this.q19_1 = JsonArrayDescriptor_getInstance();
  }
  protoOf(JsonArraySerializer).pm = function () {
    return this.q19_1;
  };
  protoOf(JsonArraySerializer).z19 = function (encoder, value) {
    verify(encoder);
    ListSerializer(JsonElementSerializer_getInstance()).qm(encoder, value);
  };
  protoOf(JsonArraySerializer).qm = function (encoder, value) {
    return this.z19(encoder, value instanceof JsonArray ? value : THROW_CCE());
  };
  protoOf(JsonArraySerializer).rm = function (decoder) {
    verify_0(decoder);
    return new JsonArray(ListSerializer(JsonElementSerializer_getInstance()).rm(decoder));
  };
  var JsonArraySerializer_instance;
  function JsonArraySerializer_getInstance() {
    if (JsonArraySerializer_instance == null)
      new JsonArraySerializer();
    return JsonArraySerializer_instance;
  }
  function defer(deferred) {
    return new defer$1(deferred);
  }
  function JsonNullSerializer() {
    JsonNullSerializer_instance = this;
    this.n19_1 = buildSerialDescriptor('kotlinx.serialization.json.JsonNull', ENUM_getInstance(), []);
  }
  protoOf(JsonNullSerializer).pm = function () {
    return this.n19_1;
  };
  protoOf(JsonNullSerializer).a1a = function (encoder, value) {
    verify(encoder);
    encoder.sq();
  };
  protoOf(JsonNullSerializer).qm = function (encoder, value) {
    return this.a1a(encoder, value instanceof JsonNull ? value : THROW_CCE());
  };
  protoOf(JsonNullSerializer).rm = function (decoder) {
    verify_0(decoder);
    if (decoder.ip()) {
      throw new JsonDecodingException("Expected 'null' literal");
    }
    decoder.jp();
    return JsonNull_getInstance();
  };
  var JsonNullSerializer_instance;
  function JsonNullSerializer_getInstance() {
    if (JsonNullSerializer_instance == null)
      new JsonNullSerializer();
    return JsonNullSerializer_instance;
  }
  function JsonLiteralSerializer() {
    JsonLiteralSerializer_instance = this;
    this.o19_1 = PrimitiveSerialDescriptor('kotlinx.serialization.json.JsonLiteral', STRING_getInstance());
  }
  protoOf(JsonLiteralSerializer).pm = function () {
    return this.o19_1;
  };
  protoOf(JsonLiteralSerializer).b1a = function (encoder, value) {
    verify(encoder);
    if (value.b19_1) {
      return encoder.br(value.d19_1);
    }
    if (!(value.c19_1 == null)) {
      return encoder.dr(value.c19_1).br(value.d19_1);
    }
    var tmp0_safe_receiver = toLongOrNull(value.d19_1);
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      return encoder.xq(tmp0_safe_receiver);
    }
    var tmp1_safe_receiver = toULongOrNull(value.d19_1);
    var tmp = tmp1_safe_receiver;
    if ((tmp == null ? null : new ULong(tmp)) == null)
      null;
    else {
      var tmp_0 = tmp1_safe_receiver;
      // Inline function 'kotlin.let' call
      var it = (tmp_0 == null ? null : new ULong(tmp_0)).xl_1;
      var tmp_1 = encoder.dr(serializer_0(Companion_getInstance()).pm());
      // Inline function 'kotlin.ULong.toLong' call
      var tmp$ret$1 = _ULong___get_data__impl__fggpzb(it);
      tmp_1.xq(tmp$ret$1);
      return Unit_instance;
    }
    var tmp2_safe_receiver = toDoubleOrNull(value.d19_1);
    if (tmp2_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      return encoder.zq(tmp2_safe_receiver);
    }
    var tmp3_safe_receiver = toBooleanStrictOrNull(value.d19_1);
    if (tmp3_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      return encoder.tq(tmp3_safe_receiver);
    }
    encoder.br(value.d19_1);
  };
  protoOf(JsonLiteralSerializer).qm = function (encoder, value) {
    return this.b1a(encoder, value instanceof JsonLiteral ? value : THROW_CCE());
  };
  protoOf(JsonLiteralSerializer).rm = function (decoder) {
    var result = asJsonDecoder(decoder).u18();
    if (!(result instanceof JsonLiteral))
      throw JsonDecodingException_0(-1, 'Unexpected JSON element, expected JsonLiteral, had ' + toString(getKClassFromExpression(result)), toString(result));
    return result;
  };
  var JsonLiteralSerializer_instance;
  function JsonLiteralSerializer_getInstance() {
    if (JsonLiteralSerializer_instance == null)
      new JsonLiteralSerializer();
    return JsonLiteralSerializer_instance;
  }
  function verify(encoder) {
    asJsonEncoder(encoder);
  }
  function verify_0(decoder) {
    asJsonDecoder(decoder);
  }
  function _get_original__l7ku1m($this) {
    var tmp0 = $this.c1a_1;
    // Inline function 'kotlin.getValue' call
    original$factory();
    return tmp0.a2();
  }
  function defer$1($deferred) {
    this.c1a_1 = lazy_0($deferred);
  }
  protoOf(defer$1).qn = function () {
    return _get_original__l7ku1m(this).qn();
  };
  protoOf(defer$1).eo = function () {
    return _get_original__l7ku1m(this).eo();
  };
  protoOf(defer$1).co = function () {
    return _get_original__l7ku1m(this).co();
  };
  protoOf(defer$1).io = function (index) {
    return _get_original__l7ku1m(this).io(index);
  };
  protoOf(defer$1).ho = function (name) {
    return _get_original__l7ku1m(this).ho(name);
  };
  protoOf(defer$1).fo = function (index) {
    return _get_original__l7ku1m(this).fo(index);
  };
  protoOf(defer$1).go = function (index) {
    return _get_original__l7ku1m(this).go(index);
  };
  protoOf(defer$1).jo = function (index) {
    return _get_original__l7ku1m(this).jo(index);
  };
  function original$factory() {
    return getPropertyCallableRef('original', 1, KProperty1, function (receiver) {
      return _get_original__l7ku1m(receiver);
    }, null);
  }
  function JsonEncoder() {
  }
  function Composer(writer) {
    this.d1a_1 = writer;
    this.e1a_1 = true;
  }
  protoOf(Composer).f1a = function () {
    this.e1a_1 = true;
  };
  protoOf(Composer).g1a = function () {
    return Unit_instance;
  };
  protoOf(Composer).h1a = function () {
    this.e1a_1 = false;
  };
  protoOf(Composer).i1a = function () {
    return Unit_instance;
  };
  protoOf(Composer).j1a = function (v) {
    return this.d1a_1.k1a(v);
  };
  protoOf(Composer).l1a = function (v) {
    return this.d1a_1.m1a(v);
  };
  protoOf(Composer).n1a = function (v) {
    return this.d1a_1.m1a(v.toString());
  };
  protoOf(Composer).o1a = function (v) {
    return this.d1a_1.m1a(v.toString());
  };
  protoOf(Composer).p1a = function (v) {
    return this.d1a_1.q1a(toLong(v));
  };
  protoOf(Composer).r1a = function (v) {
    return this.d1a_1.q1a(toLong(v));
  };
  protoOf(Composer).s1a = function (v) {
    return this.d1a_1.q1a(toLong(v));
  };
  protoOf(Composer).t1a = function (v) {
    return this.d1a_1.q1a(v);
  };
  protoOf(Composer).u1a = function (v) {
    return this.d1a_1.m1a(v.toString());
  };
  protoOf(Composer).v1a = function (value) {
    return this.d1a_1.w1a(value);
  };
  function Composer_0(sb, json) {
    return json.s16_1.i18_1 ? new ComposerWithPrettyPrint(sb, json) : new Composer(sb);
  }
  function ComposerForUnsignedNumbers(writer, forceQuoting) {
    Composer.call(this, writer);
    this.z1a_1 = forceQuoting;
  }
  protoOf(ComposerForUnsignedNumbers).s1a = function (v) {
    if (this.z1a_1) {
      // Inline function 'kotlin.toUInt' call
      var tmp$ret$0 = _UInt___init__impl__l7qpdl(v);
      this.v1a(UInt__toString_impl_dbgl21(tmp$ret$0));
    } else {
      // Inline function 'kotlin.toUInt' call
      var tmp$ret$1 = _UInt___init__impl__l7qpdl(v);
      this.l1a(UInt__toString_impl_dbgl21(tmp$ret$1));
    }
  };
  protoOf(ComposerForUnsignedNumbers).t1a = function (v) {
    if (this.z1a_1) {
      // Inline function 'kotlin.toULong' call
      var tmp$ret$0 = _ULong___init__impl__c78o9k(v);
      this.v1a(ULong__toString_impl_f9au7k(tmp$ret$0));
    } else {
      // Inline function 'kotlin.toULong' call
      var tmp$ret$1 = _ULong___init__impl__c78o9k(v);
      this.l1a(ULong__toString_impl_f9au7k(tmp$ret$1));
    }
  };
  protoOf(ComposerForUnsignedNumbers).p1a = function (v) {
    if (this.z1a_1) {
      // Inline function 'kotlin.toUByte' call
      var tmp$ret$0 = _UByte___init__impl__g9hnc4(v);
      this.v1a(UByte__toString_impl_v72jg(tmp$ret$0));
    } else {
      // Inline function 'kotlin.toUByte' call
      var tmp$ret$1 = _UByte___init__impl__g9hnc4(v);
      this.l1a(UByte__toString_impl_v72jg(tmp$ret$1));
    }
  };
  protoOf(ComposerForUnsignedNumbers).r1a = function (v) {
    if (this.z1a_1) {
      // Inline function 'kotlin.toUShort' call
      var tmp$ret$0 = _UShort___init__impl__jigrne(v);
      this.v1a(UShort__toString_impl_edaoee(tmp$ret$0));
    } else {
      // Inline function 'kotlin.toUShort' call
      var tmp$ret$1 = _UShort___init__impl__jigrne(v);
      this.l1a(UShort__toString_impl_edaoee(tmp$ret$1));
    }
  };
  function ComposerForUnquotedLiterals(writer, forceQuoting) {
    Composer.call(this, writer);
    this.c1b_1 = forceQuoting;
  }
  protoOf(ComposerForUnquotedLiterals).v1a = function (value) {
    if (this.c1b_1) {
      protoOf(Composer).v1a.call(this, value);
    } else {
      protoOf(Composer).l1a.call(this, value);
    }
  };
  function ComposerWithPrettyPrint(writer, json) {
    Composer.call(this, writer);
    this.f1b_1 = json;
    this.g1b_1 = 0;
  }
  protoOf(ComposerWithPrettyPrint).f1a = function () {
    this.e1a_1 = true;
    this.g1b_1 = this.g1b_1 + 1 | 0;
  };
  protoOf(ComposerWithPrettyPrint).g1a = function () {
    this.g1b_1 = this.g1b_1 - 1 | 0;
  };
  protoOf(ComposerWithPrettyPrint).h1a = function () {
    this.e1a_1 = false;
    this.l1a('\n');
    // Inline function 'kotlin.repeat' call
    var times = this.g1b_1;
    var inductionVariable = 0;
    if (inductionVariable < times)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.serialization.json.internal.ComposerWithPrettyPrint.nextItem.<anonymous>' call
        this.l1a(this.f1b_1.s16_1.k18_1);
      }
       while (inductionVariable < times);
  };
  protoOf(ComposerWithPrettyPrint).i1a = function () {
    this.j1a(_Char___init__impl__6a9atx(32));
  };
  function readIfAbsent($this, descriptor, index) {
    $this.i1b_1 = (!descriptor.jo(index) && descriptor.go(index).xn());
    return $this.i1b_1;
  }
  function JsonElementMarker$readIfAbsent$ref($boundThis) {
    var l = function (p0, p1) {
      return readIfAbsent($boundThis, p0, p1);
    };
    l.callableName = 'readIfAbsent';
    return l;
  }
  function JsonElementMarker(descriptor) {
    var tmp = this;
    tmp.h1b_1 = new ElementMarker(descriptor, JsonElementMarker$readIfAbsent$ref(this));
    this.i1b_1 = false;
  }
  protoOf(JsonElementMarker).j1b = function (index) {
    this.h1b_1.bw(index);
  };
  protoOf(JsonElementMarker).k1b = function () {
    return this.h1b_1.cw();
  };
  function invalidTrailingComma(_this__u8e3s4, entity) {
    entity = entity === VOID ? 'object' : entity;
    _this__u8e3s4.l1b('Trailing comma before the end of JSON ' + entity, _this__u8e3s4.i17_1 - 1 | 0, "Trailing commas are non-complaint JSON and not allowed by default. Use 'allowTrailingCommas = true' in 'Json {}' builder to support them.");
  }
  function throwInvalidFloatingPointDecoded(_this__u8e3s4, result) {
    _this__u8e3s4.m1b('Unexpected special floating-point value ' + toString(result) + '. By default, ' + 'non-finite floating point values are prohibited because they do not conform JSON specification', VOID, "It is possible to deserialize them using 'JsonBuilder.allowSpecialFloatingPointValues = true'");
  }
  function JsonEncodingException(message) {
    JsonException.call(this, message);
    captureStack(this, JsonEncodingException);
  }
  function InvalidKeyKindException(keyDescriptor) {
    return new JsonEncodingException("Value of type '" + keyDescriptor.qn() + "' can't be used in JSON as a key in the map. " + ("It should have either primitive or enum kind, but its kind is '" + keyDescriptor.eo().toString() + "'.\n") + "Use 'allowStructuredMapKeys = true' in 'Json {}' builder to convert such maps to [key1, value1, key2, value2,...] arrays.");
  }
  function JsonDecodingException(message) {
    JsonException.call(this, message);
    captureStack(this, JsonDecodingException);
  }
  function JsonDecodingException_0(offset, message, input) {
    return JsonDecodingException_1(offset, message + '\nJSON input: ' + toString(minify(input, offset)));
  }
  function InvalidFloatingPointDecoded(value, key, output) {
    return JsonDecodingException_1(-1, unexpectedFpErrorMessage(value, key, output));
  }
  function JsonDecodingException_1(offset, message) {
    return new JsonDecodingException(offset >= 0 ? 'Unexpected JSON token at offset ' + offset + ': ' + message : message);
  }
  function UnknownKeyException(key, input) {
    return JsonDecodingException_1(-1, "Encountered an unknown key '" + key + "'.\n" + "Use 'ignoreUnknownKeys = true' in 'Json {}' builder to ignore unknown keys.\n" + ('Current input: ' + toString(minify(input))));
  }
  function InvalidFloatingPointEncoded(value, output) {
    return new JsonEncodingException('Unexpected special floating-point value ' + toString(value) + '. By default, ' + 'non-finite floating point values are prohibited because they do not conform JSON specification. ' + "It is possible to deserialize them using 'JsonBuilder.allowSpecialFloatingPointValues = true'\n" + ('Current output: ' + toString(minify(output))));
  }
  function JsonException(message) {
    SerializationException_init_$Init$(message, this);
    captureStack(this, JsonException);
  }
  function unexpectedFpErrorMessage(value, key, output) {
    return 'Unexpected special floating-point value ' + toString(value) + ' with key ' + key + '. By default, ' + 'non-finite floating point values are prohibited because they do not conform JSON specification. ' + "It is possible to deserialize them using 'JsonBuilder.allowSpecialFloatingPointValues = true'\n" + ('Current output: ' + toString(minify(output)));
  }
  function minify(_this__u8e3s4, offset) {
    offset = offset === VOID ? -1 : offset;
    if (charSequenceLength(_this__u8e3s4) < 200)
      return _this__u8e3s4;
    if (offset === -1) {
      var start = charSequenceLength(_this__u8e3s4) - 60 | 0;
      if (start <= 0)
        return _this__u8e3s4;
      // Inline function 'kotlin.text.substring' call
      var endIndex = charSequenceLength(_this__u8e3s4);
      return '.....' + toString(charSequenceSubSequence(_this__u8e3s4, start, endIndex));
    }
    var start_0 = offset - 30 | 0;
    var end = offset + 30 | 0;
    var prefix = start_0 <= 0 ? '' : '.....';
    var suffix = end >= charSequenceLength(_this__u8e3s4) ? '' : '.....';
    var tmp4 = coerceAtLeast(start_0, 0);
    // Inline function 'kotlin.text.substring' call
    var endIndex_0 = coerceAtMost(end, charSequenceLength(_this__u8e3s4));
    return prefix + toString(charSequenceSubSequence(_this__u8e3s4, tmp4, endIndex_0)) + suffix;
  }
  function get_JsonDeserializationNamesKey() {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    return JsonDeserializationNamesKey;
  }
  var JsonDeserializationNamesKey;
  function get_JsonSerializationNamesKey() {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    return JsonSerializationNamesKey;
  }
  var JsonSerializationNamesKey;
  function getJsonNameIndex(_this__u8e3s4, json, name) {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    if (decodeCaseInsensitive(json, _this__u8e3s4)) {
      // Inline function 'kotlin.text.lowercase' call
      // Inline function 'kotlin.js.asDynamic' call
      var tmp$ret$1 = name.toLowerCase();
      return getJsonNameIndexSlowPath(_this__u8e3s4, json, tmp$ret$1);
    }
    var strategy = namingStrategy(_this__u8e3s4, json);
    if (!(strategy == null))
      return getJsonNameIndexSlowPath(_this__u8e3s4, json, name);
    var index = _this__u8e3s4.ho(name);
    if (!(index === -3))
      return index;
    if (!json.s16_1.p18_1)
      return index;
    return getJsonNameIndexSlowPath(_this__u8e3s4, json, name);
  }
  function getJsonNameIndexOrThrow(_this__u8e3s4, json, name, suffix) {
    suffix = suffix === VOID ? '' : suffix;
    _init_properties_JsonNamesMap_kt__cbbp0k();
    var index = getJsonNameIndex(_this__u8e3s4, json, name);
    if (index === -3)
      throw SerializationException_init_$Create$(_this__u8e3s4.qn() + " does not contain element with name '" + name + "'" + suffix);
    return index;
  }
  function getJsonElementName(_this__u8e3s4, json, index) {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    var strategy = namingStrategy(_this__u8e3s4, json);
    return strategy == null ? _this__u8e3s4.io(index) : serializationNamesIndices(_this__u8e3s4, json, strategy)[index];
  }
  function namingStrategy(_this__u8e3s4, json) {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    return equals(_this__u8e3s4.eo(), CLASS_getInstance()) ? json.s16_1.q18_1 : null;
  }
  function deserializationNamesMap(_this__u8e3s4, descriptor) {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    var tmp = get_schemaCache(_this__u8e3s4);
    var tmp_0 = get_JsonDeserializationNamesKey();
    return tmp.o1b(descriptor, tmp_0, deserializationNamesMap$lambda(descriptor, _this__u8e3s4));
  }
  function decodeCaseInsensitive(_this__u8e3s4, descriptor) {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    return _this__u8e3s4.s16_1.r18_1 && equals(descriptor.eo(), ENUM_getInstance());
  }
  function getJsonNameIndexSlowPath(_this__u8e3s4, json, name) {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    var tmp0_elvis_lhs = deserializationNamesMap(json, _this__u8e3s4).d2(name);
    return tmp0_elvis_lhs == null ? -3 : tmp0_elvis_lhs;
  }
  function serializationNamesIndices(_this__u8e3s4, json, strategy) {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    var tmp = get_schemaCache(json);
    var tmp_0 = get_JsonSerializationNamesKey();
    return tmp.o1b(_this__u8e3s4, tmp_0, serializationNamesIndices$lambda(_this__u8e3s4, strategy));
  }
  function buildDeserializationNamesMap(_this__u8e3s4, json) {
    _init_properties_JsonNamesMap_kt__cbbp0k();
    // Inline function 'kotlin.collections.mutableMapOf' call
    var builder = LinkedHashMap_init_$Create$();
    var useLowercaseEnums = decodeCaseInsensitive(json, _this__u8e3s4);
    var strategyForClasses = namingStrategy(_this__u8e3s4, json);
    var inductionVariable = 0;
    var last = _this__u8e3s4.co();
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.collections.filterIsInstance' call
        var tmp0 = _this__u8e3s4.fo(i);
        // Inline function 'kotlin.collections.filterIsInstanceTo' call
        var destination = ArrayList_init_$Create$();
        var _iterator__ex2g4s = tmp0.k();
        while (_iterator__ex2g4s.l()) {
          var element = _iterator__ex2g4s.m();
          if (element instanceof JsonNames) {
            destination.e(element);
          }
        }
        var tmp1_safe_receiver = singleOrNull(destination);
        var tmp2_safe_receiver = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.p1b_1;
        if (tmp2_safe_receiver == null)
          null;
        else {
          // Inline function 'kotlin.collections.forEach' call
          var inductionVariable_0 = 0;
          var last_0 = tmp2_safe_receiver.length;
          while (inductionVariable_0 < last_0) {
            var element_0 = tmp2_safe_receiver[inductionVariable_0];
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            // Inline function 'kotlinx.serialization.json.internal.buildDeserializationNamesMap.<anonymous>' call
            var tmp;
            if (useLowercaseEnums) {
              // Inline function 'kotlin.text.lowercase' call
              // Inline function 'kotlin.js.asDynamic' call
              tmp = element_0.toLowerCase();
            } else {
              tmp = element_0;
            }
            buildDeserializationNamesMap$putOrThrow(builder, _this__u8e3s4, tmp, i);
          }
        }
        var tmp_0;
        if (useLowercaseEnums) {
          // Inline function 'kotlin.text.lowercase' call
          // Inline function 'kotlin.js.asDynamic' call
          tmp_0 = _this__u8e3s4.io(i).toLowerCase();
        } else if (!(strategyForClasses == null)) {
          tmp_0 = strategyForClasses.q1b(_this__u8e3s4, i, _this__u8e3s4.io(i));
        } else {
          tmp_0 = null;
        }
        var nameToPut = tmp_0;
        if (nameToPut == null)
          null;
        else {
          // Inline function 'kotlin.let' call
          // Inline function 'kotlinx.serialization.json.internal.buildDeserializationNamesMap.<anonymous>' call
          buildDeserializationNamesMap$putOrThrow(builder, _this__u8e3s4, nameToPut, i);
        }
      }
       while (inductionVariable < last);
    // Inline function 'kotlin.collections.ifEmpty' call
    var tmp_1;
    if (builder.p()) {
      // Inline function 'kotlinx.serialization.json.internal.buildDeserializationNamesMap.<anonymous>' call
      tmp_1 = emptyMap();
    } else {
      tmp_1 = builder;
    }
    return tmp_1;
  }
  function buildDeserializationNamesMap$putOrThrow(_this__u8e3s4, $this_buildDeserializationNamesMap, name, index) {
    var entity = equals($this_buildDeserializationNamesMap.eo(), ENUM_getInstance()) ? 'enum value' : 'property';
    // Inline function 'kotlin.collections.contains' call
    // Inline function 'kotlin.collections.containsKey' call
    if ((isInterface(_this__u8e3s4, KtMap) ? _this__u8e3s4 : THROW_CCE()).b2(name)) {
      throw new JsonException("The suggested name '" + name + "' for " + entity + ' ' + $this_buildDeserializationNamesMap.io(index) + ' is already one of the names for ' + entity + ' ' + ($this_buildDeserializationNamesMap.io(getValue(_this__u8e3s4, name)) + ' in ' + toString($this_buildDeserializationNamesMap)));
    }
    // Inline function 'kotlin.collections.set' call
    _this__u8e3s4.j2(name, index);
  }
  function deserializationNamesMap$lambda($descriptor, $this_deserializationNamesMap) {
    return function () {
      return buildDeserializationNamesMap($descriptor, $this_deserializationNamesMap);
    };
  }
  function serializationNamesIndices$lambda($this_serializationNamesIndices, $strategy) {
    return function () {
      var tmp = 0;
      var tmp_0 = $this_serializationNamesIndices.co();
      // Inline function 'kotlin.arrayOfNulls' call
      var tmp_1 = fillArrayVal(Array(tmp_0), null);
      while (tmp < tmp_0) {
        var tmp_2 = tmp;
        var baseName = $this_serializationNamesIndices.io(tmp_2);
        tmp_1[tmp_2] = $strategy.q1b($this_serializationNamesIndices, tmp_2, baseName);
        tmp = tmp + 1 | 0;
      }
      return tmp_1;
    };
  }
  var properties_initialized_JsonNamesMap_kt_ljpf42;
  function _init_properties_JsonNamesMap_kt__cbbp0k() {
    if (!properties_initialized_JsonNamesMap_kt_ljpf42) {
      properties_initialized_JsonNamesMap_kt_ljpf42 = true;
      JsonDeserializationNamesKey = new Key();
      JsonSerializationNamesKey = new Key();
    }
  }
  function Tombstone() {
  }
  var Tombstone_instance;
  function Tombstone_getInstance() {
    return Tombstone_instance;
  }
  function resize($this) {
    var newSize = imul($this.t1b_1, 2);
    $this.r1b_1 = copyOf($this.r1b_1, newSize);
    $this.s1b_1 = copyOf_0($this.s1b_1, newSize);
  }
  function JsonPath() {
    var tmp = this;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp.r1b_1 = fillArrayVal(Array(8), null);
    var tmp_0 = this;
    var tmp_1 = 0;
    var tmp_2 = new Int32Array(8);
    while (tmp_1 < 8) {
      tmp_2[tmp_1] = -1;
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp_0.s1b_1 = tmp_2;
    this.t1b_1 = -1;
  }
  protoOf(JsonPath).u1b = function (sd) {
    this.t1b_1 = this.t1b_1 + 1 | 0;
    var depth = this.t1b_1;
    if (depth === this.r1b_1.length) {
      resize(this);
    }
    this.r1b_1[depth] = sd;
  };
  protoOf(JsonPath).v1b = function (index) {
    this.s1b_1[this.t1b_1] = index;
  };
  protoOf(JsonPath).w1b = function (key) {
    var tmp;
    if (!(this.s1b_1[this.t1b_1] === -2)) {
      this.t1b_1 = this.t1b_1 + 1 | 0;
      tmp = this.t1b_1 === this.r1b_1.length;
    } else {
      tmp = false;
    }
    if (tmp) {
      resize(this);
    }
    this.r1b_1[this.t1b_1] = key;
    this.s1b_1[this.t1b_1] = -2;
  };
  protoOf(JsonPath).x1b = function () {
    if (this.s1b_1[this.t1b_1] === -2) {
      this.r1b_1[this.t1b_1] = Tombstone_instance;
    }
  };
  protoOf(JsonPath).y1b = function () {
    var depth = this.t1b_1;
    if (this.s1b_1[depth] === -2) {
      this.s1b_1[depth] = -1;
      this.t1b_1 = this.t1b_1 - 1 | 0;
    }
    if (!(this.t1b_1 === -1)) {
      this.t1b_1 = this.t1b_1 - 1 | 0;
    }
  };
  protoOf(JsonPath).z1b = function () {
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.apply' call
    var this_0 = StringBuilder_init_$Create$();
    // Inline function 'kotlinx.serialization.json.internal.JsonPath.getPath.<anonymous>' call
    this_0.u7('$');
    // Inline function 'kotlin.repeat' call
    var times = this.t1b_1 + 1 | 0;
    var inductionVariable = 0;
    if (inductionVariable < times)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.serialization.json.internal.JsonPath.getPath.<anonymous>.<anonymous>' call
        var element = this.r1b_1[index];
        if (!(element == null) ? isInterface(element, SerialDescriptor) : false) {
          if (equals(element.eo(), LIST_getInstance())) {
            if (!(this.s1b_1[index] === -1)) {
              this_0.u7('[');
              this_0.xb(this.s1b_1[index]);
              this_0.u7(']');
            }
          } else {
            var idx = this.s1b_1[index];
            if (idx >= 0) {
              this_0.u7('.');
              this_0.u7(element.io(idx));
            }
          }
        } else {
          if (!(element === Tombstone_instance)) {
            this_0.u7('[');
            this_0.u7("'");
            this_0.t7(element);
            this_0.u7("'");
            this_0.u7(']');
          }
        }
      }
       while (inductionVariable < times);
    return this_0.toString();
  };
  protoOf(JsonPath).toString = function () {
    return this.z1b();
  };
  function encodeByWriter(json, writer, serializer, value) {
    var tmp = WriteMode_OBJ_getInstance();
    // Inline function 'kotlin.arrayOfNulls' call
    var size = get_entries().n();
    var tmp$ret$0 = fillArrayVal(Array(size), null);
    var encoder = StreamingJsonEncoder_init_$Create$(writer, json, tmp, tmp$ret$0);
    encoder.pr(serializer, value);
  }
  function readObject($this) {
    // Inline function 'kotlinx.serialization.json.internal.JsonTreeReader.readObjectImpl' call
    var lastToken = $this.i1c_1.m1c(6);
    if ($this.i1c_1.n1c() === 4) {
      $this.i1c_1.m1b('Unexpected leading comma');
    }
    // Inline function 'kotlin.collections.linkedMapOf' call
    var result = LinkedHashMap_init_$Create$();
    $l$loop: while ($this.i1c_1.o1c()) {
      var key = $this.j1c_1 ? $this.i1c_1.q1c() : $this.i1c_1.p1c();
      $this.i1c_1.m1c(5);
      // Inline function 'kotlinx.serialization.json.internal.JsonTreeReader.readObject.<anonymous>' call
      var element = $this.r1c();
      // Inline function 'kotlin.collections.set' call
      result.j2(key, element);
      lastToken = $this.i1c_1.s1c();
      var tmp0_subject = lastToken;
      if (tmp0_subject !== 4)
        if (tmp0_subject === 7)
          break $l$loop;
        else {
          $this.i1c_1.m1b('Expected end of the object or comma');
        }
    }
    if (lastToken === 6) {
      $this.i1c_1.m1c(7);
    } else if (lastToken === 4) {
      if (!$this.k1c_1) {
        invalidTrailingComma($this.i1c_1);
      }
      $this.i1c_1.m1c(7);
    }
    return new JsonObject(result);
  }
  function readObject_0(_this__u8e3s4, $this, $completion) {
    var tmp = new $readObjectCOROUTINE$0($this, _this__u8e3s4, $completion);
    tmp.i8_1 = Unit_instance;
    tmp.j8_1 = null;
    return tmp.o8();
  }
  function readArray($this) {
    var lastToken = $this.i1c_1.s1c();
    if ($this.i1c_1.n1c() === 4) {
      $this.i1c_1.m1b('Unexpected leading comma');
    }
    // Inline function 'kotlin.collections.arrayListOf' call
    var result = ArrayList_init_$Create$();
    while ($this.i1c_1.o1c()) {
      var element = $this.r1c();
      result.e(element);
      lastToken = $this.i1c_1.s1c();
      if (!(lastToken === 4)) {
        var tmp0 = $this.i1c_1;
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.require' call
        var condition = lastToken === 9;
        var position = tmp0.i17_1;
        if (!condition) {
          // Inline function 'kotlinx.serialization.json.internal.JsonTreeReader.readArray.<anonymous>' call
          var tmp$ret$1 = 'Expected end of the array or comma';
          tmp0.m1b(tmp$ret$1, position);
        }
      }
    }
    if (lastToken === 8) {
      $this.i1c_1.m1c(9);
    } else if (lastToken === 4) {
      if (!$this.k1c_1) {
        invalidTrailingComma($this.i1c_1, 'array');
      }
      $this.i1c_1.m1c(9);
    }
    return new JsonArray(result);
  }
  function readValue($this, isString) {
    var tmp;
    if ($this.j1c_1 || !isString) {
      tmp = $this.i1c_1.q1c();
    } else {
      tmp = $this.i1c_1.p1c();
    }
    var string = tmp;
    if (!isString && string === 'null')
      return JsonNull_getInstance();
    return new JsonLiteral(string, isString);
  }
  function readDeepRecursive($this) {
    return invoke(new DeepRecursiveFunction(JsonTreeReader$readDeepRecursive$slambda_0($this, null)), Unit_instance);
  }
  function JsonTreeReader$readDeepRecursive$slambda(this$0, resultContinuation) {
    this.q1d_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(JsonTreeReader$readDeepRecursive$slambda).v1d = function ($this$$receiver, it, $completion) {
    var tmp = this.w1d($this$$receiver, it, $completion);
    tmp.i8_1 = Unit_instance;
    tmp.j8_1 = null;
    return tmp.o8();
  };
  protoOf(JsonTreeReader$readDeepRecursive$slambda).c9 = function (p1, p2, $completion) {
    var tmp = p1 instanceof DeepRecursiveScope ? p1 : THROW_CCE();
    return this.v1d(tmp, p2 instanceof Unit ? p2 : THROW_CCE(), $completion);
  };
  protoOf(JsonTreeReader$readDeepRecursive$slambda).o8 = function () {
    var suspendResult = this.i8_1;
    $sm: do
      try {
        var tmp = this.g8_1;
        switch (tmp) {
          case 0:
            this.h8_1 = 3;
            this.t1d_1 = this.q1d_1.i1c_1.n1c();
            if (this.t1d_1 === 1) {
              this.u1d_1 = readValue(this.q1d_1, true);
              this.g8_1 = 2;
              continue $sm;
            } else {
              if (this.t1d_1 === 0) {
                this.u1d_1 = readValue(this.q1d_1, false);
                this.g8_1 = 2;
                continue $sm;
              } else {
                if (this.t1d_1 === 6) {
                  this.g8_1 = 1;
                  suspendResult = readObject_0(this.r1d_1, this.q1d_1, this);
                  if (suspendResult === get_COROUTINE_SUSPENDED()) {
                    return suspendResult;
                  }
                  continue $sm;
                } else {
                  if (this.t1d_1 === 8) {
                    this.u1d_1 = readArray(this.q1d_1);
                    this.g8_1 = 2;
                    continue $sm;
                  } else {
                    var tmp_0 = this;
                    this.q1d_1.i1c_1.m1b("Can't begin reading element, unexpected token");
                  }
                }
              }
            }

            break;
          case 1:
            this.u1d_1 = suspendResult;
            this.g8_1 = 2;
            continue $sm;
          case 2:
            return this.u1d_1;
          case 3:
            throw this.j8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.h8_1 === 3) {
          throw e;
        } else {
          this.g8_1 = this.h8_1;
          this.j8_1 = e;
        }
      }
     while (true);
  };
  protoOf(JsonTreeReader$readDeepRecursive$slambda).w1d = function ($this$$receiver, it, completion) {
    var i = new JsonTreeReader$readDeepRecursive$slambda(this.q1d_1, completion);
    i.r1d_1 = $this$$receiver;
    i.s1d_1 = it;
    return i;
  };
  function JsonTreeReader$readDeepRecursive$slambda_0(this$0, resultContinuation) {
    var i = new JsonTreeReader$readDeepRecursive$slambda(this$0, resultContinuation);
    var l = function ($this$$receiver, it, $completion) {
      return i.v1d($this$$receiver, it, $completion);
    };
    l.$arity = 2;
    return l;
  }
  function $readObjectCOROUTINE$0(_this__u8e3s4, _this__u8e3s4_0, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.b1d_1 = _this__u8e3s4;
    this.c1d_1 = _this__u8e3s4_0;
  }
  protoOf($readObjectCOROUTINE$0).o8 = function () {
    var suspendResult = this.i8_1;
    $sm: do
      try {
        var tmp = this.g8_1;
        switch (tmp) {
          case 0:
            this.h8_1 = 5;
            var tmp_0 = this;
            tmp_0.d1d_1 = this.b1d_1;
            this.e1d_1 = this.d1d_1;
            this.f1d_1 = this.e1d_1.i1c_1.m1c(6);
            if (this.e1d_1.i1c_1.n1c() === 4) {
              this.e1d_1.i1c_1.m1b('Unexpected leading comma');
            }

            var tmp_1 = this;
            tmp_1.g1d_1 = LinkedHashMap_init_$Create$();
            this.g8_1 = 1;
            continue $sm;
          case 1:
            if (!this.e1d_1.i1c_1.o1c()) {
              this.g8_1 = 4;
              continue $sm;
            }

            this.h1d_1 = this.e1d_1.j1c_1 ? this.e1d_1.i1c_1.q1c() : this.e1d_1.i1c_1.p1c();
            this.e1d_1.i1c_1.m1c(5);
            this.g8_1 = 2;
            suspendResult = this.c1d_1.hk(Unit_instance, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var element = suspendResult;
            var tmp0 = this.g1d_1;
            var key = this.h1d_1;
            tmp0.j2(key, element);
            this.f1d_1 = this.e1d_1.i1c_1.s1c();
            var tmp0_subject = this.f1d_1;
            if (tmp0_subject === 4) {
              this.g8_1 = 3;
              continue $sm;
            } else {
              if (tmp0_subject === 7) {
                this.g8_1 = 4;
                continue $sm;
              } else {
                this.e1d_1.i1c_1.m1b('Expected end of the object or comma');
              }
            }

            break;
          case 3:
            this.g8_1 = 1;
            continue $sm;
          case 4:
            if (this.f1d_1 === 6) {
              this.e1d_1.i1c_1.m1c(7);
            } else if (this.f1d_1 === 4) {
              if (!this.e1d_1.k1c_1) {
                invalidTrailingComma(this.e1d_1.i1c_1);
              }
              this.e1d_1.i1c_1.m1c(7);
            }

            return new JsonObject(this.g1d_1);
          case 5:
            throw this.j8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.h8_1 === 5) {
          throw e;
        } else {
          this.g8_1 = this.h8_1;
          this.j8_1 = e;
        }
      }
     while (true);
  };
  function JsonTreeReader(configuration, lexer) {
    this.i1c_1 = lexer;
    this.j1c_1 = configuration.g18_1;
    this.k1c_1 = configuration.s18_1;
    this.l1c_1 = 0;
  }
  protoOf(JsonTreeReader).r1c = function () {
    var token = this.i1c_1.n1c();
    var tmp;
    if (token === 1) {
      tmp = readValue(this, true);
    } else if (token === 0) {
      tmp = readValue(this, false);
    } else if (token === 6) {
      var tmp_0;
      this.l1c_1 = this.l1c_1 + 1 | 0;
      if (this.l1c_1 === 200) {
        tmp_0 = readDeepRecursive(this);
      } else {
        tmp_0 = readObject(this);
      }
      var result = tmp_0;
      this.l1c_1 = this.l1c_1 - 1 | 0;
      tmp = result;
    } else if (token === 8) {
      tmp = readArray(this);
    } else {
      this.i1c_1.m1b('Cannot read Json element because of unexpected ' + tokenDescription(token));
    }
    return tmp;
  };
  function classDiscriminator(_this__u8e3s4, json) {
    var tmp0_iterator = _this__u8e3s4.bo().k();
    while (tmp0_iterator.l()) {
      var annotation = tmp0_iterator.m();
      if (annotation instanceof JsonClassDiscriminator)
        return annotation.x1d_1;
    }
    return json.s16_1.n18_1;
  }
  function decodeSerializableValuePolymorphic(_this__u8e3s4, deserializer) {
    var tmp;
    if (!(deserializer instanceof AbstractPolymorphicSerializer)) {
      tmp = true;
    } else {
      tmp = _this__u8e3s4.t18().s16_1.m18_1;
    }
    if (tmp) {
      return deserializer.rm(_this__u8e3s4);
    }
    var discriminator = classDiscriminator(deserializer.pm(), _this__u8e3s4.t18());
    var tmp0 = _this__u8e3s4.u18();
    // Inline function 'kotlinx.serialization.json.internal.cast' call
    var descriptor = deserializer.pm();
    if (!(tmp0 instanceof JsonObject)) {
      throw JsonDecodingException_1(-1, 'Expected ' + toString(getKClass(JsonObject)) + ' as the serialized body of ' + descriptor.qn() + ', but had ' + toString(getKClassFromExpression(tmp0)));
    }
    var jsonTree = tmp0;
    var tmp0_safe_receiver = jsonTree.dd(discriminator);
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_jsonPrimitive(tmp0_safe_receiver);
    var type = tmp1_safe_receiver == null ? null : get_contentOrNull(tmp1_safe_receiver);
    var tmp_0;
    try {
      tmp_0 = findPolymorphicSerializer(deserializer, _this__u8e3s4, type);
    } catch ($p) {
      var tmp_1;
      if ($p instanceof SerializationException) {
        var it = $p;
        throw JsonDecodingException_0(-1, ensureNotNull(it.message), jsonTree.toString());
      } else {
        throw $p;
      }
    }
    var tmp_2 = tmp_0;
    var actualSerializer = isInterface(tmp_2, DeserializationStrategy) ? tmp_2 : THROW_CCE();
    return readPolymorphicJson(_this__u8e3s4.t18(), discriminator, jsonTree, actualSerializer);
  }
  function validateIfSealed(serializer, actualSerializer, classDiscriminator) {
    if (!(serializer instanceof SealedClassSerializer))
      return Unit_instance;
    if (jsonCachedSerialNames(actualSerializer.pm()).r(classDiscriminator)) {
      var baseName = serializer.pm().qn();
      var actualName = actualSerializer.pm().qn();
      // Inline function 'kotlin.error' call
      var message = "Sealed class '" + actualName + "' cannot be serialized as base class '" + baseName + "' because" + (" it has property name that conflicts with JSON class discriminator '" + classDiscriminator + "'. ") + 'You can either change class discriminator in JsonConfiguration, ' + 'rename property with @SerialName annotation or fall back to array polymorphism';
      throw IllegalStateException_init_$Create$(toString(message));
    }
  }
  function checkKind(kind) {
    if (kind instanceof ENUM) {
      // Inline function 'kotlin.error' call
      var message = "Enums cannot be serialized polymorphically with 'type' parameter. You can use 'JsonBuilder.useArrayPolymorphism' instead";
      throw IllegalStateException_init_$Create$(toString(message));
    }
    if (kind instanceof PrimitiveKind) {
      // Inline function 'kotlin.error' call
      var message_0 = "Primitives cannot be serialized polymorphically with 'type' parameter. You can use 'JsonBuilder.useArrayPolymorphism' instead";
      throw IllegalStateException_init_$Create$(toString(message_0));
    }
    if (kind instanceof PolymorphicKind) {
      // Inline function 'kotlin.error' call
      var message_1 = 'Actual serializer for polymorphic cannot be polymorphic itself';
      throw IllegalStateException_init_$Create$(toString(message_1));
    }
  }
  function access$validateIfSealed$tPolymorphicKt(serializer, actualSerializer, classDiscriminator) {
    return validateIfSealed(serializer, actualSerializer, classDiscriminator);
  }
  function checkKind_0($this, descriptor, actualClass) {
    var kind = descriptor.eo();
    var tmp;
    if (kind instanceof PolymorphicKind) {
      tmp = true;
    } else {
      tmp = equals(kind, CONTEXTUAL_getInstance());
    }
    if (tmp) {
      throw IllegalArgumentException_init_$Create$('Serializer for ' + actualClass.ka() + " can't be registered as a subclass for polymorphic serialization " + ('because its kind ' + kind.toString() + ' is not concrete. To work with multiple hierarchies, register it as a base class.'));
    }
    if ($this.y1d_1)
      return Unit_instance;
    var tmp_0;
    var tmp_1;
    if (equals(kind, LIST_getInstance()) || equals(kind, MAP_getInstance())) {
      tmp_1 = true;
    } else {
      tmp_1 = kind instanceof PrimitiveKind;
    }
    if (tmp_1) {
      tmp_0 = true;
    } else {
      tmp_0 = kind instanceof ENUM;
    }
    if (tmp_0) {
      throw IllegalArgumentException_init_$Create$('Serializer for ' + actualClass.ka() + ' of kind ' + kind.toString() + ' cannot be serialized polymorphically with class discriminator.');
    }
  }
  function checkDiscriminatorCollisions($this, descriptor, actualClass) {
    var inductionVariable = 0;
    var last = descriptor.co();
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var name = descriptor.io(i);
        if (name === $this.z1d_1) {
          throw IllegalArgumentException_init_$Create$('Polymorphic serializer for ' + toString(actualClass) + " has property '" + name + "' that conflicts " + 'with JSON class discriminator. You can either change class discriminator in JsonConfiguration, ' + 'rename property with @SerialName annotation ' + 'or fall back to array polymorphism');
        }
      }
       while (inductionVariable < last);
  }
  function PolymorphismValidator(useArrayPolymorphism, discriminator) {
    this.y1d_1 = useArrayPolymorphism;
    this.z1d_1 = discriminator;
  }
  protoOf(PolymorphismValidator).j16 = function (kClass, provider) {
  };
  protoOf(PolymorphismValidator).m16 = function (baseClass, actualClass, actualSerializer) {
    var descriptor = actualSerializer.pm();
    checkKind_0(this, descriptor, actualClass);
    if (!this.y1d_1) {
      checkDiscriminatorCollisions(this, descriptor, actualClass);
    }
  };
  protoOf(PolymorphismValidator).n16 = function (baseClass, defaultSerializerProvider) {
  };
  protoOf(PolymorphismValidator).o16 = function (baseClass, defaultDeserializerProvider) {
  };
  function Key() {
  }
  function DescriptorSchemaCache() {
    this.n1b_1 = createMapForCache(16);
  }
  protoOf(DescriptorSchemaCache).a1e = function (descriptor, key, value) {
    // Inline function 'kotlin.collections.getOrPut' call
    var this_0 = this.n1b_1;
    var value_0 = this_0.d2(descriptor);
    var tmp;
    if (value_0 == null) {
      // Inline function 'kotlinx.serialization.json.internal.DescriptorSchemaCache.set.<anonymous>' call
      var answer = createMapForCache(2);
      this_0.j2(descriptor, answer);
      tmp = answer;
    } else {
      tmp = value_0;
    }
    var tmp2 = tmp;
    var tmp3 = key instanceof Key ? key : THROW_CCE();
    // Inline function 'kotlin.collections.set' call
    var value_1 = !(value == null) ? value : THROW_CCE();
    tmp2.j2(tmp3, value_1);
  };
  protoOf(DescriptorSchemaCache).o1b = function (descriptor, key, defaultValue) {
    var tmp0_safe_receiver = this.b1e(descriptor, key);
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      return tmp0_safe_receiver;
    }
    var value = defaultValue();
    this.a1e(descriptor, key, value);
    return value;
  };
  protoOf(DescriptorSchemaCache).b1e = function (descriptor, key) {
    var tmp0_safe_receiver = this.n1b_1.d2(descriptor);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = tmp0_safe_receiver.d2(key instanceof Key ? key : THROW_CCE());
    }
    var tmp_0 = tmp;
    return !(tmp_0 == null) ? tmp_0 : null;
  };
  function DiscriminatorHolder(discriminatorToSkip) {
    this.c1e_1 = discriminatorToSkip;
  }
  function trySkip(_this__u8e3s4, $this, unknownKey) {
    if (_this__u8e3s4 == null)
      return false;
    if (_this__u8e3s4.c1e_1 === unknownKey) {
      _this__u8e3s4.c1e_1 = null;
      return true;
    }
    return false;
  }
  function skipLeftoverElements($this, descriptor) {
    while (!($this.oq(descriptor) === -1)) {
    }
  }
  function checkLeadingComma($this) {
    if ($this.c17_1.n1c() === 4) {
      $this.c17_1.m1b('Unexpected leading comma');
    }
  }
  function decodeMapIndex($this) {
    var hasComma = false;
    var decodingKey = !(($this.e17_1 % 2 | 0) === 0);
    if (decodingKey) {
      if (!($this.e17_1 === -1)) {
        hasComma = $this.c17_1.e1e();
      }
    } else {
      $this.c17_1.d1e(_Char___init__impl__6a9atx(58));
    }
    var tmp;
    if ($this.c17_1.o1c()) {
      if (decodingKey) {
        if ($this.e17_1 === -1) {
          var tmp0 = $this.c17_1;
          // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.require' call
          var condition = !hasComma;
          var position = tmp0.i17_1;
          if (!condition) {
            // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeMapIndex.<anonymous>' call
            var tmp$ret$0 = 'Unexpected leading comma';
            tmp0.m1b(tmp$ret$0, position);
          }
        } else {
          var tmp3 = $this.c17_1;
          // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.require' call
          var condition_0 = hasComma;
          var position_0 = tmp3.i17_1;
          if (!condition_0) {
            // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeMapIndex.<anonymous>' call
            var tmp$ret$1 = 'Expected comma after the key-value pair';
            tmp3.m1b(tmp$ret$1, position_0);
          }
        }
      }
      $this.e17_1 = $this.e17_1 + 1 | 0;
      tmp = $this.e17_1;
    } else {
      if (hasComma && !$this.a17_1.s16_1.s18_1) {
        invalidTrailingComma($this.c17_1);
      }
      tmp = -1;
    }
    return tmp;
  }
  function coerceInputValue($this, descriptor, index) {
    var tmp0 = $this.a17_1;
    var tmp1 = descriptor.go(index);
    var tmp$ret$1;
    $l$block_2: {
      // Inline function 'kotlinx.serialization.json.internal.tryCoerceValue' call
      var tmp;
      if (!tmp1.xn()) {
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.coerceInputValue.<anonymous>' call
        tmp = $this.c17_1.f1e(true);
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$1 = true;
        break $l$block_2;
      }
      if (equals(tmp1.eo(), ENUM_getInstance())) {
        var tmp_0;
        if (tmp1.xn()) {
          // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.coerceInputValue.<anonymous>' call
          tmp_0 = $this.c17_1.f1e(false);
        } else {
          tmp_0 = false;
        }
        if (tmp_0) {
          tmp$ret$1 = false;
          break $l$block_2;
        }
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.coerceInputValue.<anonymous>' call
        var tmp0_elvis_lhs = $this.c17_1.g1e($this.g17_1.g18_1);
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          tmp$ret$1 = false;
          break $l$block_2;
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        var enumValue = tmp_1;
        var enumIndex = getJsonNameIndex(tmp1, tmp0, enumValue);
        if (enumIndex === -3) {
          // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.coerceInputValue.<anonymous>' call
          $this.c17_1.p1c();
          tmp$ret$1 = true;
          break $l$block_2;
        }
      }
      tmp$ret$1 = false;
    }
    return tmp$ret$1;
  }
  function decodeObjectIndex($this, descriptor) {
    var hasComma = $this.c17_1.e1e();
    while ($this.c17_1.o1c()) {
      hasComma = false;
      var key = decodeStringKey($this);
      $this.c17_1.d1e(_Char___init__impl__6a9atx(58));
      var index = getJsonNameIndex(descriptor, $this.a17_1, key);
      var tmp;
      if (!(index === -3)) {
        var tmp_0;
        if ($this.g17_1.l18_1 && coerceInputValue($this, descriptor, index)) {
          hasComma = $this.c17_1.e1e();
          tmp_0 = false;
        } else {
          var tmp0_safe_receiver = $this.h17_1;
          if (tmp0_safe_receiver == null)
            null;
          else {
            tmp0_safe_receiver.j1b(index);
          }
          return index;
        }
        tmp = tmp_0;
      } else {
        tmp = true;
      }
      var isUnknown = tmp;
      if (isUnknown) {
        hasComma = handleUnknown($this, key);
      }
    }
    if (hasComma && !$this.a17_1.s16_1.s18_1) {
      invalidTrailingComma($this.c17_1);
    }
    var tmp1_safe_receiver = $this.h17_1;
    var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.k1b();
    return tmp2_elvis_lhs == null ? -1 : tmp2_elvis_lhs;
  }
  function handleUnknown($this, key) {
    if ($this.g17_1.f18_1 || trySkip($this.f17_1, $this, key)) {
      $this.c17_1.i1e($this.g17_1.g18_1);
    } else {
      $this.c17_1.h1e(key);
    }
    return $this.c17_1.e1e();
  }
  function decodeListIndex($this) {
    var hasComma = $this.c17_1.e1e();
    var tmp;
    if ($this.c17_1.o1c()) {
      if (!($this.e17_1 === -1) && !hasComma) {
        $this.c17_1.m1b('Expected end of the array or comma');
      }
      $this.e17_1 = $this.e17_1 + 1 | 0;
      tmp = $this.e17_1;
    } else {
      if (hasComma && !$this.a17_1.s16_1.s18_1) {
        invalidTrailingComma($this.c17_1, 'array');
      }
      tmp = -1;
    }
    return tmp;
  }
  function decodeStringKey($this) {
    var tmp;
    if ($this.g17_1.g18_1) {
      tmp = $this.c17_1.k1e();
    } else {
      tmp = $this.c17_1.j1e();
    }
    return tmp;
  }
  function StreamingJsonDecoder(json, mode, lexer, descriptor, discriminatorHolder) {
    AbstractDecoder.call(this);
    this.a17_1 = json;
    this.b17_1 = mode;
    this.c17_1 = lexer;
    this.d17_1 = this.a17_1.mq();
    this.e17_1 = -1;
    this.f17_1 = discriminatorHolder;
    this.g17_1 = this.a17_1.s16_1;
    this.h17_1 = this.g17_1.j18_1 ? null : new JsonElementMarker(descriptor);
  }
  protoOf(StreamingJsonDecoder).t18 = function () {
    return this.a17_1;
  };
  protoOf(StreamingJsonDecoder).mq = function () {
    return this.d17_1;
  };
  protoOf(StreamingJsonDecoder).u18 = function () {
    return (new JsonTreeReader(this.a17_1.s16_1, this.c17_1)).r1c();
  };
  protoOf(StreamingJsonDecoder).wp = function (deserializer) {
    try {
      var tmp;
      if (!(deserializer instanceof AbstractPolymorphicSerializer)) {
        tmp = true;
      } else {
        tmp = this.a17_1.s16_1.m18_1;
      }
      if (tmp) {
        return deserializer.rm(this);
      }
      var discriminator = classDiscriminator(deserializer.pm(), this.a17_1);
      var tmp0_elvis_lhs = this.c17_1.l1e(discriminator, this.g17_1.g18_1);
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        return decodeSerializableValuePolymorphic(this, isInterface(deserializer, DeserializationStrategy) ? deserializer : THROW_CCE());
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      var type = tmp_0;
      var tmp_1;
      try {
        tmp_1 = findPolymorphicSerializer(deserializer, this, type);
      } catch ($p) {
        var tmp_2;
        if ($p instanceof SerializationException) {
          var it = $p;
          var message = removeSuffix(substringBefore(ensureNotNull(it.message), _Char___init__impl__6a9atx(10)), '.');
          var hint = substringAfter(ensureNotNull(it.message), _Char___init__impl__6a9atx(10), '');
          this.c17_1.m1b(message, VOID, hint);
        } else {
          throw $p;
        }
        tmp_1 = tmp_2;
      }
      var tmp_3 = tmp_1;
      var actualSerializer = isInterface(tmp_3, DeserializationStrategy) ? tmp_3 : THROW_CCE();
      this.f17_1 = new DiscriminatorHolder(discriminator);
      return actualSerializer.rm(this);
    } catch ($p) {
      if ($p instanceof MissingFieldException) {
        var e = $p;
        if (contains_0(ensureNotNull(e.message), 'at path'))
          throw e;
        throw new MissingFieldException(e.sn_1, plus(e.message, ' at path: ') + this.c17_1.j17_1.z1b(), e);
      } else {
        throw $p;
      }
    }
  };
  protoOf(StreamingJsonDecoder).xp = function (descriptor) {
    var newMode = switchMode(this.a17_1, descriptor);
    this.c17_1.j17_1.u1b(descriptor);
    this.c17_1.d1e(newMode.o1e_1);
    checkLeadingComma(this);
    var tmp;
    switch (newMode.m2_1) {
      case 1:
      case 2:
      case 3:
        tmp = new StreamingJsonDecoder(this.a17_1, newMode, this.c17_1, descriptor, this.f17_1);
        break;
      default:
        var tmp_0;
        if (this.b17_1.equals(newMode) && this.a17_1.s16_1.j18_1) {
          tmp_0 = this;
        } else {
          tmp_0 = new StreamingJsonDecoder(this.a17_1, newMode, this.c17_1, descriptor, this.f17_1);
        }

        tmp = tmp_0;
        break;
    }
    return tmp;
  };
  protoOf(StreamingJsonDecoder).yp = function (descriptor) {
    if (this.a17_1.s16_1.f18_1 && descriptor.co() === 0) {
      skipLeftoverElements(this, descriptor);
    }
    if (this.c17_1.e1e() && !this.a17_1.s16_1.s18_1) {
      invalidTrailingComma(this.c17_1, '');
    }
    this.c17_1.d1e(this.b17_1.p1e_1);
    this.c17_1.j17_1.y1b();
  };
  protoOf(StreamingJsonDecoder).ip = function () {
    var tmp;
    var tmp0_safe_receiver = this.h17_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.i1b_1;
    if (!(tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs)) {
      tmp = !this.c17_1.q1e();
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(StreamingJsonDecoder).jp = function () {
    return null;
  };
  protoOf(StreamingJsonDecoder).jq = function (descriptor, index, deserializer, previousValue) {
    var isMapKey = this.b17_1.equals(WriteMode_MAP_getInstance()) && (index & 1) === 0;
    if (isMapKey) {
      this.c17_1.j17_1.x1b();
    }
    var value = protoOf(AbstractDecoder).jq.call(this, descriptor, index, deserializer, previousValue);
    if (isMapKey) {
      this.c17_1.j17_1.w1b(value);
    }
    return value;
  };
  protoOf(StreamingJsonDecoder).oq = function (descriptor) {
    var index;
    switch (this.b17_1.m2_1) {
      case 0:
        index = decodeObjectIndex(this, descriptor);
        break;
      case 2:
        index = decodeMapIndex(this);
        break;
      default:
        index = decodeListIndex(this);
        break;
    }
    if (!this.b17_1.equals(WriteMode_MAP_getInstance())) {
      this.c17_1.j17_1.v1b(index);
    }
    return index;
  };
  protoOf(StreamingJsonDecoder).kp = function () {
    return this.c17_1.r1e();
  };
  protoOf(StreamingJsonDecoder).lp = function () {
    var value = this.c17_1.h19();
    if (!value.equals(toLong(value.f3()))) {
      this.c17_1.m1b("Failed to parse byte for input '" + value.toString() + "'");
    }
    return value.f3();
  };
  protoOf(StreamingJsonDecoder).mp = function () {
    var value = this.c17_1.h19();
    if (!value.equals(toLong(value.g3()))) {
      this.c17_1.m1b("Failed to parse short for input '" + value.toString() + "'");
    }
    return value.g3();
  };
  protoOf(StreamingJsonDecoder).np = function () {
    var value = this.c17_1.h19();
    if (!value.equals(toLong(value.i1()))) {
      this.c17_1.m1b("Failed to parse int for input '" + value.toString() + "'");
    }
    return value.i1();
  };
  protoOf(StreamingJsonDecoder).op = function () {
    return this.c17_1.h19();
  };
  protoOf(StreamingJsonDecoder).pp = function () {
    var tmp0 = this.c17_1;
    var tmp$ret$4;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var input = tmp0.q1c();
      try {
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeFloat.<anonymous>' call
        // Inline function 'kotlin.text.toFloat' call
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$4 = toDouble(input);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          tmp0.m1b("Failed to parse type '" + 'float' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    var result = tmp$ret$4;
    var specialFp = this.a17_1.s16_1.o18_1;
    if (specialFp || isFinite(result))
      return result;
    throwInvalidFloatingPointDecoded(this.c17_1, result);
  };
  protoOf(StreamingJsonDecoder).qp = function () {
    var tmp0 = this.c17_1;
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var input = tmp0.q1c();
      try {
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonDecoder.decodeDouble.<anonymous>' call
        tmp$ret$1 = toDouble(input);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          tmp0.m1b("Failed to parse type '" + 'double' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    var result = tmp$ret$1;
    var specialFp = this.a17_1.s16_1.o18_1;
    if (specialFp || isFinite_0(result))
      return result;
    throwInvalidFloatingPointDecoded(this.c17_1, result);
  };
  protoOf(StreamingJsonDecoder).rp = function () {
    var string = this.c17_1.q1c();
    if (!(string.length === 1)) {
      this.c17_1.m1b("Expected single char, but got '" + string + "'");
    }
    return charSequenceGet(string, 0);
  };
  protoOf(StreamingJsonDecoder).sp = function () {
    var tmp;
    if (this.g17_1.g18_1) {
      tmp = this.c17_1.k1e();
    } else {
      tmp = this.c17_1.p1c();
    }
    return tmp;
  };
  protoOf(StreamingJsonDecoder).up = function (descriptor) {
    return get_isUnsignedNumber(descriptor) ? new JsonDecoderForUnsignedTypes(this.c17_1, this.a17_1) : protoOf(AbstractDecoder).up.call(this, descriptor);
  };
  protoOf(StreamingJsonDecoder).tp = function (enumDescriptor) {
    return getJsonNameIndexOrThrow(enumDescriptor, this.a17_1, this.sp(), ' at path ' + this.c17_1.j17_1.z1b());
  };
  function JsonDecoderForUnsignedTypes(lexer, json) {
    AbstractDecoder.call(this);
    this.s1e_1 = lexer;
    this.t1e_1 = json.mq();
  }
  protoOf(JsonDecoderForUnsignedTypes).mq = function () {
    return this.t1e_1;
  };
  protoOf(JsonDecoderForUnsignedTypes).oq = function (descriptor) {
    var message = 'unsupported';
    throw IllegalStateException_init_$Create$(toString(message));
  };
  protoOf(JsonDecoderForUnsignedTypes).np = function () {
    var tmp0 = this.s1e_1;
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var input = tmp0.q1c();
      try {
        // Inline function 'kotlinx.serialization.json.internal.JsonDecoderForUnsignedTypes.decodeInt.<anonymous>' call
        // Inline function 'kotlin.UInt.toInt' call
        var this_0 = toUInt(input);
        tmp$ret$2 = _UInt___get_data__impl__f0vqqw(this_0);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          tmp0.m1b("Failed to parse type '" + 'UInt' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$2;
  };
  protoOf(JsonDecoderForUnsignedTypes).op = function () {
    var tmp0 = this.s1e_1;
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var input = tmp0.q1c();
      try {
        // Inline function 'kotlinx.serialization.json.internal.JsonDecoderForUnsignedTypes.decodeLong.<anonymous>' call
        // Inline function 'kotlin.ULong.toLong' call
        var this_0 = toULong(input);
        tmp$ret$2 = _ULong___get_data__impl__fggpzb(this_0);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          tmp0.m1b("Failed to parse type '" + 'ULong' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$2;
  };
  protoOf(JsonDecoderForUnsignedTypes).lp = function () {
    var tmp0 = this.s1e_1;
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var input = tmp0.q1c();
      try {
        // Inline function 'kotlinx.serialization.json.internal.JsonDecoderForUnsignedTypes.decodeByte.<anonymous>' call
        // Inline function 'kotlin.UByte.toByte' call
        var this_0 = toUByte(input);
        tmp$ret$2 = _UByte___get_data__impl__jof9qr(this_0);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          tmp0.m1b("Failed to parse type '" + 'UByte' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$2;
  };
  protoOf(JsonDecoderForUnsignedTypes).mp = function () {
    var tmp0 = this.s1e_1;
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.parseString' call
      var input = tmp0.q1c();
      try {
        // Inline function 'kotlinx.serialization.json.internal.JsonDecoderForUnsignedTypes.decodeShort.<anonymous>' call
        // Inline function 'kotlin.UShort.toShort' call
        var this_0 = toUShort(input);
        tmp$ret$2 = _UShort___get_data__impl__g0245(this_0);
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          tmp0.m1b("Failed to parse type '" + 'UShort' + "' for input '" + input + "'");
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$2;
  };
  function get_unsignedNumberDescriptors() {
    _init_properties_StreamingJsonEncoder_kt__pn1bsi();
    return unsignedNumberDescriptors;
  }
  var unsignedNumberDescriptors;
  function StreamingJsonEncoder_init_$Init$(output, json, mode, modeReuseCache, $this) {
    StreamingJsonEncoder.call($this, Composer_0(output, json), json, mode, modeReuseCache);
    return $this;
  }
  function StreamingJsonEncoder_init_$Create$(output, json, mode, modeReuseCache) {
    return StreamingJsonEncoder_init_$Init$(output, json, mode, modeReuseCache, objectCreate(protoOf(StreamingJsonEncoder)));
  }
  function encodeTypeInfo($this, descriptor) {
    $this.a1c_1.h1a();
    $this.br(ensureNotNull($this.h1c_1));
    $this.a1c_1.j1a(_Char___init__impl__6a9atx(58));
    $this.a1c_1.i1a();
    $this.br(descriptor.qn());
  }
  function StreamingJsonEncoder(composer, json, mode, modeReuseCache) {
    AbstractEncoder.call(this);
    this.a1c_1 = composer;
    this.b1c_1 = json;
    this.c1c_1 = mode;
    this.d1c_1 = modeReuseCache;
    this.e1c_1 = this.b1c_1.mq();
    this.f1c_1 = this.b1c_1.s16_1;
    this.g1c_1 = false;
    this.h1c_1 = null;
    var i = this.c1c_1.m2_1;
    if (!(this.d1c_1 == null)) {
      if (!(this.d1c_1[i] === null) || !(this.d1c_1[i] === this)) {
        this.d1c_1[i] = this;
      }
    }
  }
  protoOf(StreamingJsonEncoder).t18 = function () {
    return this.b1c_1;
  };
  protoOf(StreamingJsonEncoder).mq = function () {
    return this.e1c_1;
  };
  protoOf(StreamingJsonEncoder).ur = function (descriptor, index) {
    return this.f1c_1.e18_1;
  };
  protoOf(StreamingJsonEncoder).pr = function (serializer, value) {
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.encodePolymorphically' call
      var tmp;
      if (!(serializer instanceof AbstractPolymorphicSerializer)) {
        tmp = true;
      } else {
        tmp = this.t18().s16_1.m18_1;
      }
      if (tmp) {
        serializer.qm(this, value);
        break $l$block;
      }
      var casted = serializer instanceof AbstractPolymorphicSerializer ? serializer : THROW_CCE();
      var baseClassDiscriminator = classDiscriminator(serializer.pm(), this.t18());
      var actualSerializer = findPolymorphicSerializer_0(casted, this, !(value == null) ? value : THROW_CCE());
      access$validateIfSealed$tPolymorphicKt(casted, actualSerializer, baseClassDiscriminator);
      checkKind(actualSerializer.pm().eo());
      // Inline function 'kotlinx.serialization.json.internal.StreamingJsonEncoder.encodeSerializableValue.<anonymous>' call
      this.h1c_1 = baseClassDiscriminator;
      actualSerializer.qm(this, value);
    }
  };
  protoOf(StreamingJsonEncoder).xp = function (descriptor) {
    var newMode = switchMode(this.b1c_1, descriptor);
    if (!(newMode.o1e_1 === _Char___init__impl__6a9atx(0))) {
      this.a1c_1.j1a(newMode.o1e_1);
      this.a1c_1.f1a();
    }
    if (!(this.h1c_1 == null)) {
      encodeTypeInfo(this, descriptor);
      this.h1c_1 = null;
    }
    if (this.c1c_1.equals(newMode)) {
      return this;
    }
    var tmp0_safe_receiver = this.d1c_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver[newMode.m2_1];
    return tmp1_elvis_lhs == null ? new StreamingJsonEncoder(this.a1c_1, this.b1c_1, newMode, this.d1c_1) : tmp1_elvis_lhs;
  };
  protoOf(StreamingJsonEncoder).yp = function (descriptor) {
    if (!(this.c1c_1.p1e_1 === _Char___init__impl__6a9atx(0))) {
      this.a1c_1.g1a();
      this.a1c_1.h1a();
      this.a1c_1.j1a(this.c1c_1.p1e_1);
    }
  };
  protoOf(StreamingJsonEncoder).qq = function (descriptor, index) {
    switch (this.c1c_1.m2_1) {
      case 1:
        if (!this.a1c_1.e1a_1) {
          this.a1c_1.j1a(_Char___init__impl__6a9atx(44));
        }

        this.a1c_1.h1a();
        break;
      case 2:
        if (!this.a1c_1.e1a_1) {
          var tmp = this;
          var tmp_0;
          if ((index % 2 | 0) === 0) {
            this.a1c_1.j1a(_Char___init__impl__6a9atx(44));
            this.a1c_1.h1a();
            tmp_0 = true;
          } else {
            this.a1c_1.j1a(_Char___init__impl__6a9atx(58));
            this.a1c_1.i1a();
            tmp_0 = false;
          }
          tmp.g1c_1 = tmp_0;
        } else {
          this.g1c_1 = true;
          this.a1c_1.h1a();
        }

        break;
      case 3:
        if (index === 0)
          this.g1c_1 = true;
        if (index === 1) {
          this.a1c_1.j1a(_Char___init__impl__6a9atx(44));
          this.a1c_1.i1a();
          this.g1c_1 = false;
        }

        break;
      default:
        if (!this.a1c_1.e1a_1) {
          this.a1c_1.j1a(_Char___init__impl__6a9atx(44));
        }

        this.a1c_1.h1a();
        this.br(getJsonElementName(descriptor, this.b1c_1, index));
        this.a1c_1.j1a(_Char___init__impl__6a9atx(58));
        this.a1c_1.i1a();
        break;
    }
    return true;
  };
  protoOf(StreamingJsonEncoder).qr = function (descriptor, index, serializer, value) {
    if (!(value == null) || this.f1c_1.j18_1) {
      protoOf(AbstractEncoder).qr.call(this, descriptor, index, serializer, value);
    }
  };
  protoOf(StreamingJsonEncoder).dr = function (descriptor) {
    var tmp;
    if (get_isUnsignedNumber(descriptor)) {
      // Inline function 'kotlinx.serialization.json.internal.StreamingJsonEncoder.composerAs' call
      var tmp_0;
      var tmp_1 = this.a1c_1;
      if (tmp_1 instanceof ComposerForUnsignedNumbers) {
        tmp_0 = this.a1c_1;
      } else {
        var tmp1 = this.a1c_1.d1a_1;
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonEncoder.encodeInline.stub_for_inlining' call
        var p1 = this.g1c_1;
        tmp_0 = new ComposerForUnsignedNumbers(tmp1, p1);
      }
      var tmp$ret$1 = tmp_0;
      tmp = new StreamingJsonEncoder(tmp$ret$1, this.b1c_1, this.c1c_1, null);
    } else if (get_isUnquotedLiteral(descriptor)) {
      // Inline function 'kotlinx.serialization.json.internal.StreamingJsonEncoder.composerAs' call
      var tmp_2;
      var tmp_3 = this.a1c_1;
      if (tmp_3 instanceof ComposerForUnquotedLiterals) {
        tmp_2 = this.a1c_1;
      } else {
        var tmp4 = this.a1c_1.d1a_1;
        // Inline function 'kotlinx.serialization.json.internal.StreamingJsonEncoder.encodeInline.stub_for_inlining' call
        var p1_0 = this.g1c_1;
        tmp_2 = new ComposerForUnquotedLiterals(tmp4, p1_0);
      }
      var tmp$ret$3 = tmp_2;
      tmp = new StreamingJsonEncoder(tmp$ret$3, this.b1c_1, this.c1c_1, null);
    } else {
      tmp = protoOf(AbstractEncoder).dr.call(this, descriptor);
    }
    return tmp;
  };
  protoOf(StreamingJsonEncoder).sq = function () {
    this.a1c_1.l1a('null');
  };
  protoOf(StreamingJsonEncoder).tq = function (value) {
    if (this.g1c_1) {
      this.br(value.toString());
    } else {
      this.a1c_1.u1a(value);
    }
  };
  protoOf(StreamingJsonEncoder).uq = function (value) {
    if (this.g1c_1) {
      this.br(value.toString());
    } else {
      this.a1c_1.p1a(value);
    }
  };
  protoOf(StreamingJsonEncoder).vq = function (value) {
    if (this.g1c_1) {
      this.br(value.toString());
    } else {
      this.a1c_1.r1a(value);
    }
  };
  protoOf(StreamingJsonEncoder).wq = function (value) {
    if (this.g1c_1) {
      this.br(value.toString());
    } else {
      this.a1c_1.s1a(value);
    }
  };
  protoOf(StreamingJsonEncoder).xq = function (value) {
    if (this.g1c_1) {
      this.br(value.toString());
    } else {
      this.a1c_1.t1a(value);
    }
  };
  protoOf(StreamingJsonEncoder).yq = function (value) {
    if (this.g1c_1) {
      this.br(value.toString());
    } else {
      this.a1c_1.n1a(value);
    }
    if (!this.f1c_1.o18_1 && !isFinite(value)) {
      throw InvalidFloatingPointEncoded(value, toString(this.a1c_1.d1a_1));
    }
  };
  protoOf(StreamingJsonEncoder).zq = function (value) {
    if (this.g1c_1) {
      this.br(value.toString());
    } else {
      this.a1c_1.o1a(value);
    }
    if (!this.f1c_1.o18_1 && !isFinite_0(value)) {
      throw InvalidFloatingPointEncoded(value, toString(this.a1c_1.d1a_1));
    }
  };
  protoOf(StreamingJsonEncoder).ar = function (value) {
    this.br(toString_1(value));
  };
  protoOf(StreamingJsonEncoder).br = function (value) {
    return this.a1c_1.v1a(value);
  };
  protoOf(StreamingJsonEncoder).cr = function (enumDescriptor, index) {
    this.br(enumDescriptor.io(index));
  };
  function get_isUnsignedNumber(_this__u8e3s4) {
    _init_properties_StreamingJsonEncoder_kt__pn1bsi();
    return _this__u8e3s4.do() && get_unsignedNumberDescriptors().r(_this__u8e3s4);
  }
  function get_isUnquotedLiteral(_this__u8e3s4) {
    _init_properties_StreamingJsonEncoder_kt__pn1bsi();
    return _this__u8e3s4.do() && equals(_this__u8e3s4, get_jsonUnquotedLiteralDescriptor());
  }
  var properties_initialized_StreamingJsonEncoder_kt_6ifwwk;
  function _init_properties_StreamingJsonEncoder_kt__pn1bsi() {
    if (!properties_initialized_StreamingJsonEncoder_kt_6ifwwk) {
      properties_initialized_StreamingJsonEncoder_kt_6ifwwk = true;
      unsignedNumberDescriptors = setOf([serializer_1(Companion_getInstance_0()).pm(), serializer_0(Companion_getInstance()).pm(), serializer_2(Companion_getInstance_1()).pm(), serializer_3(Companion_getInstance_2()).pm()]);
    }
  }
  function get_ESCAPE_STRINGS() {
    _init_properties_StringOps_kt__fcy1db();
    return ESCAPE_STRINGS;
  }
  var ESCAPE_STRINGS;
  var ESCAPE_MARKERS;
  function toHexChar(i) {
    _init_properties_StringOps_kt__fcy1db();
    var d = i & 15;
    var tmp;
    if (d < 10) {
      // Inline function 'kotlin.code' call
      var this_0 = _Char___init__impl__6a9atx(48);
      var tmp$ret$0 = Char__toInt_impl_vasixd(this_0);
      tmp = numberToChar(d + tmp$ret$0 | 0);
    } else {
      var tmp_0 = d - 10 | 0;
      // Inline function 'kotlin.code' call
      var this_1 = _Char___init__impl__6a9atx(97);
      var tmp$ret$1 = Char__toInt_impl_vasixd(this_1);
      tmp = numberToChar(tmp_0 + tmp$ret$1 | 0);
    }
    return tmp;
  }
  function printQuoted(_this__u8e3s4, value) {
    _init_properties_StringOps_kt__fcy1db();
    _this__u8e3s4.v7(_Char___init__impl__6a9atx(34));
    var lastPos = 0;
    var inductionVariable = 0;
    var last = charSequenceLength(value) - 1 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.code' call
        var this_0 = charSequenceGet(value, i);
        var c = Char__toInt_impl_vasixd(this_0);
        if (c < get_ESCAPE_STRINGS().length && !(get_ESCAPE_STRINGS()[c] == null)) {
          _this__u8e3s4.vb(value, lastPos, i);
          _this__u8e3s4.u7(get_ESCAPE_STRINGS()[c]);
          lastPos = i + 1 | 0;
        }
      }
       while (inductionVariable <= last);
    if (!(lastPos === 0)) {
      _this__u8e3s4.vb(value, lastPos, value.length);
    } else {
      _this__u8e3s4.u7(value);
    }
    _this__u8e3s4.v7(_Char___init__impl__6a9atx(34));
  }
  function toBooleanStrictOrNull_0(_this__u8e3s4) {
    _init_properties_StringOps_kt__fcy1db();
    return equals_0(_this__u8e3s4, 'true', true) ? true : equals_0(_this__u8e3s4, 'false', true) ? false : null;
  }
  var properties_initialized_StringOps_kt_wzaea7;
  function _init_properties_StringOps_kt__fcy1db() {
    if (!properties_initialized_StringOps_kt_wzaea7) {
      properties_initialized_StringOps_kt_wzaea7 = true;
      // Inline function 'kotlin.arrayOfNulls' call
      // Inline function 'kotlin.apply' call
      var this_0 = fillArrayVal(Array(93), null);
      // Inline function 'kotlinx.serialization.json.internal.ESCAPE_STRINGS.<anonymous>' call
      var inductionVariable = 0;
      if (inductionVariable <= 31)
        do {
          var c = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var c1 = toHexChar(c >> 12);
          var c2 = toHexChar(c >> 8);
          var c3 = toHexChar(c >> 4);
          var c4 = toHexChar(c);
          this_0[c] = '\\u' + toString_1(c1) + toString_1(c2) + toString_1(c3) + toString_1(c4);
        }
         while (inductionVariable <= 31);
      // Inline function 'kotlin.code' call
      var this_1 = _Char___init__impl__6a9atx(34);
      this_0[Char__toInt_impl_vasixd(this_1)] = '\\"';
      // Inline function 'kotlin.code' call
      var this_2 = _Char___init__impl__6a9atx(92);
      this_0[Char__toInt_impl_vasixd(this_2)] = '\\\\';
      // Inline function 'kotlin.code' call
      var this_3 = _Char___init__impl__6a9atx(9);
      this_0[Char__toInt_impl_vasixd(this_3)] = '\\t';
      // Inline function 'kotlin.code' call
      var this_4 = _Char___init__impl__6a9atx(8);
      this_0[Char__toInt_impl_vasixd(this_4)] = '\\b';
      // Inline function 'kotlin.code' call
      var this_5 = _Char___init__impl__6a9atx(10);
      this_0[Char__toInt_impl_vasixd(this_5)] = '\\n';
      // Inline function 'kotlin.code' call
      var this_6 = _Char___init__impl__6a9atx(13);
      this_0[Char__toInt_impl_vasixd(this_6)] = '\\r';
      this_0[12] = '\\f';
      ESCAPE_STRINGS = this_0;
      // Inline function 'kotlin.apply' call
      var this_7 = new Int8Array(93);
      // Inline function 'kotlinx.serialization.json.internal.ESCAPE_MARKERS.<anonymous>' call
      var inductionVariable_0 = 0;
      if (inductionVariable_0 <= 31)
        do {
          var c_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this_7[c_0] = 1;
        }
         while (inductionVariable_0 <= 31);
      // Inline function 'kotlin.code' call
      var this_8 = _Char___init__impl__6a9atx(34);
      var tmp = Char__toInt_impl_vasixd(this_8);
      // Inline function 'kotlin.code' call
      var this_9 = _Char___init__impl__6a9atx(34);
      var tmp$ret$1 = Char__toInt_impl_vasixd(this_9);
      this_7[tmp] = toByte(tmp$ret$1);
      // Inline function 'kotlin.code' call
      var this_10 = _Char___init__impl__6a9atx(92);
      var tmp_0 = Char__toInt_impl_vasixd(this_10);
      // Inline function 'kotlin.code' call
      var this_11 = _Char___init__impl__6a9atx(92);
      var tmp$ret$3 = Char__toInt_impl_vasixd(this_11);
      this_7[tmp_0] = toByte(tmp$ret$3);
      // Inline function 'kotlin.code' call
      var this_12 = _Char___init__impl__6a9atx(9);
      var tmp_1 = Char__toInt_impl_vasixd(this_12);
      // Inline function 'kotlin.code' call
      var this_13 = _Char___init__impl__6a9atx(116);
      var tmp$ret$5 = Char__toInt_impl_vasixd(this_13);
      this_7[tmp_1] = toByte(tmp$ret$5);
      // Inline function 'kotlin.code' call
      var this_14 = _Char___init__impl__6a9atx(8);
      var tmp_2 = Char__toInt_impl_vasixd(this_14);
      // Inline function 'kotlin.code' call
      var this_15 = _Char___init__impl__6a9atx(98);
      var tmp$ret$7 = Char__toInt_impl_vasixd(this_15);
      this_7[tmp_2] = toByte(tmp$ret$7);
      // Inline function 'kotlin.code' call
      var this_16 = _Char___init__impl__6a9atx(10);
      var tmp_3 = Char__toInt_impl_vasixd(this_16);
      // Inline function 'kotlin.code' call
      var this_17 = _Char___init__impl__6a9atx(110);
      var tmp$ret$9 = Char__toInt_impl_vasixd(this_17);
      this_7[tmp_3] = toByte(tmp$ret$9);
      // Inline function 'kotlin.code' call
      var this_18 = _Char___init__impl__6a9atx(13);
      var tmp_4 = Char__toInt_impl_vasixd(this_18);
      // Inline function 'kotlin.code' call
      var this_19 = _Char___init__impl__6a9atx(114);
      var tmp$ret$11 = Char__toInt_impl_vasixd(this_19);
      this_7[tmp_4] = toByte(tmp$ret$11);
      // Inline function 'kotlin.code' call
      var this_20 = _Char___init__impl__6a9atx(102);
      var tmp$ret$12 = Char__toInt_impl_vasixd(this_20);
      this_7[12] = toByte(tmp$ret$12);
      ESCAPE_MARKERS = this_7;
    }
  }
  function unparsedPrimitive($this, primitive) {
    throw JsonDecodingException_0(-1, "Failed to parse literal as '" + primitive + "' value", toString($this.z1e()));
  }
  function asLiteral(_this__u8e3s4, $this, type) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof JsonLiteral ? _this__u8e3s4 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw JsonDecodingException_1(-1, "Unexpected 'null' literal when non-nullable " + type + ' was expected');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function AbstractJsonTreeDecoder(json, value) {
    NamedValueDecoder.call(this);
    this.w1e_1 = json;
    this.x1e_1 = value;
    this.y1e_1 = this.t18().s16_1;
  }
  protoOf(AbstractJsonTreeDecoder).t18 = function () {
    return this.w1e_1;
  };
  protoOf(AbstractJsonTreeDecoder).a2 = function () {
    return this.x1e_1;
  };
  protoOf(AbstractJsonTreeDecoder).mq = function () {
    return this.t18().mq();
  };
  protoOf(AbstractJsonTreeDecoder).z1e = function () {
    var tmp0_safe_receiver = this.b14();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.currentObject.<anonymous>' call
      tmp = this.a1f(tmp0_safe_receiver);
    }
    var tmp1_elvis_lhs = tmp;
    return tmp1_elvis_lhs == null ? this.a2() : tmp1_elvis_lhs;
  };
  protoOf(AbstractJsonTreeDecoder).u18 = function () {
    return this.z1e();
  };
  protoOf(AbstractJsonTreeDecoder).wp = function (deserializer) {
    return decodeSerializableValuePolymorphic(this, deserializer);
  };
  protoOf(AbstractJsonTreeDecoder).c14 = function (parentName, childName) {
    return childName;
  };
  protoOf(AbstractJsonTreeDecoder).xp = function (descriptor) {
    var currentObject = this.z1e();
    var tmp0_subject = descriptor.eo();
    var tmp;
    var tmp_0;
    if (equals(tmp0_subject, LIST_getInstance())) {
      tmp_0 = true;
    } else {
      tmp_0 = tmp0_subject instanceof PolymorphicKind;
    }
    if (tmp_0) {
      var tmp_1 = this.t18();
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      if (!(currentObject instanceof JsonArray)) {
        throw JsonDecodingException_1(-1, 'Expected ' + toString(getKClass(JsonArray)) + ' as the serialized body of ' + descriptor.qn() + ', but had ' + toString(getKClassFromExpression(currentObject)));
      }
      tmp = new JsonTreeListDecoder(tmp_1, currentObject);
    } else {
      if (equals(tmp0_subject, MAP_getInstance())) {
        // Inline function 'kotlinx.serialization.json.internal.selectMapMode' call
        var this_0 = this.t18();
        var keyDescriptor = carrierDescriptor(descriptor.go(0), this_0.mq());
        var keyKind = keyDescriptor.eo();
        var tmp_2;
        var tmp_3;
        if (keyKind instanceof PrimitiveKind) {
          tmp_3 = true;
        } else {
          tmp_3 = equals(keyKind, ENUM_getInstance());
        }
        if (tmp_3) {
          // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.beginStructure.<anonymous>' call
          var tmp_4 = this.t18();
          // Inline function 'kotlinx.serialization.json.internal.cast' call
          if (!(currentObject instanceof JsonObject)) {
            throw JsonDecodingException_1(-1, 'Expected ' + toString(getKClass(JsonObject)) + ' as the serialized body of ' + descriptor.qn() + ', but had ' + toString(getKClassFromExpression(currentObject)));
          }
          tmp_2 = new JsonTreeMapDecoder(tmp_4, currentObject);
        } else {
          if (this_0.s16_1.h18_1) {
            // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.beginStructure.<anonymous>' call
            var tmp_5 = this.t18();
            // Inline function 'kotlinx.serialization.json.internal.cast' call
            if (!(currentObject instanceof JsonArray)) {
              throw JsonDecodingException_1(-1, 'Expected ' + toString(getKClass(JsonArray)) + ' as the serialized body of ' + descriptor.qn() + ', but had ' + toString(getKClassFromExpression(currentObject)));
            }
            tmp_2 = new JsonTreeListDecoder(tmp_5, currentObject);
          } else {
            throw InvalidKeyKindException(keyDescriptor);
          }
        }
        tmp = tmp_2;
      } else {
        var tmp_6 = this.t18();
        // Inline function 'kotlinx.serialization.json.internal.cast' call
        if (!(currentObject instanceof JsonObject)) {
          throw JsonDecodingException_1(-1, 'Expected ' + toString(getKClass(JsonObject)) + ' as the serialized body of ' + descriptor.qn() + ', but had ' + toString(getKClassFromExpression(currentObject)));
        }
        tmp = new JsonTreeDecoder(tmp_6, currentObject);
      }
    }
    return tmp;
  };
  protoOf(AbstractJsonTreeDecoder).yp = function (descriptor) {
  };
  protoOf(AbstractJsonTreeDecoder).ip = function () {
    var tmp = this.z1e();
    return !(tmp instanceof JsonNull);
  };
  protoOf(AbstractJsonTreeDecoder).b1f = function (tag) {
    var currentElement = this.a1f(tag);
    var tmp0_elvis_lhs = currentElement instanceof JsonPrimitive ? currentElement : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw JsonDecodingException_0(-1, 'Expected JsonPrimitive at ' + tag + ', found ' + toString(currentElement), toString(this.z1e()));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(AbstractJsonTreeDecoder).c1f = function (tag, enumDescriptor) {
    return getJsonNameIndexOrThrow(enumDescriptor, this.t18(), this.b1f(tag).y18());
  };
  protoOf(AbstractJsonTreeDecoder).o14 = function (tag, enumDescriptor) {
    return this.c1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE(), enumDescriptor);
  };
  protoOf(AbstractJsonTreeDecoder).d1f = function (tag) {
    return !(this.a1f(tag) === JsonNull_getInstance());
  };
  protoOf(AbstractJsonTreeDecoder).e14 = function (tag) {
    return this.d1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).e1f = function (tag) {
    var tmp1 = this.b1f(tag);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.primitive' call
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedBoolean.stub_for_inlining' call
        var tmp0_elvis_lhs = get_booleanOrNull(tmp1);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, 'boolean');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, 'boolean');
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$1;
  };
  protoOf(AbstractJsonTreeDecoder).f14 = function (tag) {
    return this.e1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).f1f = function (tag) {
    var tmp1 = this.b1f(tag);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.primitive' call
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedByte.<anonymous>' call
        var result = get_int(tmp1);
        var tmp0_elvis_lhs = (-128 <= result ? result <= 127 : false) ? toByte(result) : null;
        var tmp;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, 'byte');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, 'byte');
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$1;
  };
  protoOf(AbstractJsonTreeDecoder).g14 = function (tag) {
    return this.f1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).g1f = function (tag) {
    var tmp1 = this.b1f(tag);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.primitive' call
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedShort.<anonymous>' call
        var result = get_int(tmp1);
        var tmp0_elvis_lhs = (-32768 <= result ? result <= 32767 : false) ? toShort(result) : null;
        var tmp;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, 'short');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, 'short');
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$1;
  };
  protoOf(AbstractJsonTreeDecoder).h14 = function (tag) {
    return this.g1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).h1f = function (tag) {
    var tmp1 = this.b1f(tag);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.primitive' call
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedInt.<anonymous>' call
        var tmp0_elvis_lhs = get_int(tmp1);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, 'int');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, 'int');
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$1;
  };
  protoOf(AbstractJsonTreeDecoder).i14 = function (tag) {
    return this.h1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).i1f = function (tag) {
    var tmp1 = this.b1f(tag);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.primitive' call
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedLong.<anonymous>' call
        var tmp0_elvis_lhs = get_long(tmp1);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, 'long');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, 'long');
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$1;
  };
  protoOf(AbstractJsonTreeDecoder).j14 = function (tag) {
    return this.i1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).j1f = function (tag) {
    var tmp1 = this.b1f(tag);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.primitive' call
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedFloat.<anonymous>' call
        var tmp0_elvis_lhs = get_float(tmp1);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, 'float');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, 'float');
        } else {
          throw $p;
        }
      }
    }
    var result = tmp$ret$1;
    var specialFp = this.t18().s16_1.o18_1;
    if (specialFp || isFinite(result))
      return result;
    throw InvalidFloatingPointDecoded(result, tag, toString(this.z1e()));
  };
  protoOf(AbstractJsonTreeDecoder).k14 = function (tag) {
    return this.j1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).k1f = function (tag) {
    var tmp1 = this.b1f(tag);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.primitive' call
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedDouble.<anonymous>' call
        var tmp0_elvis_lhs = get_double(tmp1);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, 'double');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, 'double');
        } else {
          throw $p;
        }
      }
    }
    var result = tmp$ret$1;
    var specialFp = this.t18().s16_1.o18_1;
    if (specialFp || isFinite_0(result))
      return result;
    throw InvalidFloatingPointDecoded(result, tag, toString(this.z1e()));
  };
  protoOf(AbstractJsonTreeDecoder).l14 = function (tag) {
    return this.k1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).l1f = function (tag) {
    var tmp1 = this.b1f(tag);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.primitive' call
      try {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonTreeDecoder.decodeTaggedChar.<anonymous>' call
        var tmp0_elvis_lhs = new Char(single(tmp1.y18()));
        var tmp;
        if (tmp0_elvis_lhs == null) {
          unparsedPrimitive(this, 'char');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp.m1_1;
        break $l$block;
      } catch ($p) {
        if ($p instanceof IllegalArgumentException) {
          var e = $p;
          unparsedPrimitive(this, 'char');
        } else {
          throw $p;
        }
      }
    }
    return tmp$ret$1;
  };
  protoOf(AbstractJsonTreeDecoder).m14 = function (tag) {
    return this.l1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).m1f = function (tag) {
    var value = this.b1f(tag);
    if (!this.t18().s16_1.g18_1) {
      var literal = asLiteral(value, this, 'string');
      if (!literal.b19_1)
        throw JsonDecodingException_0(-1, "String literal for key '" + tag + "' should be quoted.\nUse 'isLenient = true' in 'Json {}' builder to accept non-compliant JSON.", toString(this.z1e()));
    }
    if (value instanceof JsonNull)
      throw JsonDecodingException_0(-1, "Unexpected 'null' value instead of string literal", toString(this.z1e()));
    return value.y18();
  };
  protoOf(AbstractJsonTreeDecoder).n14 = function (tag) {
    return this.m1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(AbstractJsonTreeDecoder).n1f = function (tag, inlineDescriptor) {
    return get_isUnsignedNumber(inlineDescriptor) ? new JsonDecoderForUnsignedTypes(new StringJsonLexer(this.b1f(tag).y18()), this.t18()) : protoOf(NamedValueDecoder).p14.call(this, tag, inlineDescriptor);
  };
  protoOf(AbstractJsonTreeDecoder).p14 = function (tag, inlineDescriptor) {
    return this.n1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE(), inlineDescriptor);
  };
  protoOf(AbstractJsonTreeDecoder).up = function (descriptor) {
    return !(this.b14() == null) ? protoOf(NamedValueDecoder).up.call(this, descriptor) : (new JsonPrimitiveDecoder(this.t18(), this.a2())).up(descriptor);
  };
  function coerceInputValue_0($this, descriptor, index, tag) {
    var tmp0 = $this.t18();
    var tmp1 = descriptor.go(index);
    var tmp$ret$1;
    $l$block_2: {
      // Inline function 'kotlinx.serialization.json.internal.tryCoerceValue' call
      var tmp;
      if (!tmp1.xn()) {
        // Inline function 'kotlinx.serialization.json.internal.JsonTreeDecoder.coerceInputValue.<anonymous>' call
        var tmp_0 = $this.a1f(tag);
        tmp = tmp_0 instanceof JsonNull;
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$1 = true;
        break $l$block_2;
      }
      if (equals(tmp1.eo(), ENUM_getInstance())) {
        var tmp_1;
        if (tmp1.xn()) {
          // Inline function 'kotlinx.serialization.json.internal.JsonTreeDecoder.coerceInputValue.<anonymous>' call
          var tmp_2 = $this.a1f(tag);
          tmp_1 = tmp_2 instanceof JsonNull;
        } else {
          tmp_1 = false;
        }
        if (tmp_1) {
          tmp$ret$1 = false;
          break $l$block_2;
        }
        // Inline function 'kotlinx.serialization.json.internal.JsonTreeDecoder.coerceInputValue.<anonymous>' call
        var tmp_3 = $this.a1f(tag);
        var tmp0_safe_receiver = tmp_3 instanceof JsonPrimitive ? tmp_3 : null;
        var tmp0_elvis_lhs = tmp0_safe_receiver == null ? null : get_contentOrNull(tmp0_safe_receiver);
        var tmp_4;
        if (tmp0_elvis_lhs == null) {
          tmp$ret$1 = false;
          break $l$block_2;
        } else {
          tmp_4 = tmp0_elvis_lhs;
        }
        var enumValue = tmp_4;
        var enumIndex = getJsonNameIndex(tmp1, tmp0, enumValue);
        if (enumIndex === -3) {
          // Inline function 'kotlinx.serialization.json.internal.tryCoerceValue.<anonymous>' call
          tmp$ret$1 = true;
          break $l$block_2;
        }
      }
      tmp$ret$1 = false;
    }
    return tmp$ret$1;
  }
  function absenceIsNull($this, descriptor, index) {
    $this.x1f_1 = (!$this.t18().s16_1.j18_1 && !descriptor.jo(index) && descriptor.go(index).xn());
    return $this.x1f_1;
  }
  function JsonTreeDecoder(json, value, polyDiscriminator, polyDescriptor) {
    polyDiscriminator = polyDiscriminator === VOID ? null : polyDiscriminator;
    polyDescriptor = polyDescriptor === VOID ? null : polyDescriptor;
    AbstractJsonTreeDecoder.call(this, json, value);
    this.t1f_1 = value;
    this.u1f_1 = polyDiscriminator;
    this.v1f_1 = polyDescriptor;
    this.w1f_1 = 0;
    this.x1f_1 = false;
  }
  protoOf(JsonTreeDecoder).a2 = function () {
    return this.t1f_1;
  };
  protoOf(JsonTreeDecoder).oq = function (descriptor) {
    while (this.w1f_1 < descriptor.co()) {
      var tmp1 = this.w1f_1;
      this.w1f_1 = tmp1 + 1 | 0;
      var name = this.w13(descriptor, tmp1);
      var index = this.w1f_1 - 1 | 0;
      this.x1f_1 = false;
      var tmp;
      var tmp_0;
      // Inline function 'kotlin.collections.contains' call
      // Inline function 'kotlin.collections.containsKey' call
      var this_0 = this.a2();
      if ((isInterface(this_0, KtMap) ? this_0 : THROW_CCE()).b2(name)) {
        tmp_0 = true;
      } else {
        tmp_0 = absenceIsNull(this, descriptor, index);
      }
      if (tmp_0) {
        tmp = !this.y1e_1.l18_1 || !coerceInputValue_0(this, descriptor, index, name);
      } else {
        tmp = false;
      }
      if (tmp) {
        return index;
      }
    }
    return -1;
  };
  protoOf(JsonTreeDecoder).ip = function () {
    return !this.x1f_1 && protoOf(AbstractJsonTreeDecoder).ip.call(this);
  };
  protoOf(JsonTreeDecoder).x13 = function (descriptor, index) {
    var strategy = namingStrategy(descriptor, this.t18());
    var baseName = descriptor.io(index);
    if (strategy == null) {
      if (!this.y1e_1.p18_1)
        return baseName;
      if (this.a2().e2().r(baseName))
        return baseName;
    }
    var deserializationNamesMap_0 = deserializationNamesMap(this.t18(), descriptor);
    // Inline function 'kotlin.collections.find' call
    var tmp0 = this.a2().e2();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var _iterator__ex2g4s = tmp0.k();
      while (_iterator__ex2g4s.l()) {
        var element = _iterator__ex2g4s.m();
        // Inline function 'kotlinx.serialization.json.internal.JsonTreeDecoder.elementName.<anonymous>' call
        if (deserializationNamesMap_0.d2(element) === index) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    var tmp0_safe_receiver = tmp$ret$1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      return tmp0_safe_receiver;
    }
    var fallbackName = strategy == null ? null : strategy.q1b(descriptor, index, baseName);
    return fallbackName == null ? baseName : fallbackName;
  };
  protoOf(JsonTreeDecoder).a1f = function (tag) {
    return getValue(this.a2(), tag);
  };
  protoOf(JsonTreeDecoder).xp = function (descriptor) {
    if (descriptor === this.v1f_1) {
      var tmp = this.t18();
      var tmp0 = this.z1e();
      // Inline function 'kotlinx.serialization.json.internal.cast' call
      var descriptor_0 = this.v1f_1;
      if (!(tmp0 instanceof JsonObject)) {
        throw JsonDecodingException_1(-1, 'Expected ' + toString(getKClass(JsonObject)) + ' as the serialized body of ' + descriptor_0.qn() + ', but had ' + toString(getKClassFromExpression(tmp0)));
      }
      return new JsonTreeDecoder(tmp, tmp0, this.u1f_1, this.v1f_1);
    }
    return protoOf(AbstractJsonTreeDecoder).xp.call(this, descriptor);
  };
  protoOf(JsonTreeDecoder).yp = function (descriptor) {
    var tmp;
    if (this.y1e_1.f18_1) {
      tmp = true;
    } else {
      var tmp_0 = descriptor.eo();
      tmp = tmp_0 instanceof PolymorphicKind;
    }
    if (tmp)
      return Unit_instance;
    var strategy = namingStrategy(descriptor, this.t18());
    var tmp_1;
    if (strategy == null && !this.y1e_1.p18_1) {
      tmp_1 = jsonCachedSerialNames(descriptor);
    } else if (!(strategy == null)) {
      tmp_1 = deserializationNamesMap(this.t18(), descriptor).e2();
    } else {
      var tmp_2 = jsonCachedSerialNames(descriptor);
      var tmp0_safe_receiver = get_schemaCache(this.t18()).b1e(descriptor, get_JsonDeserializationNamesKey());
      // Inline function 'kotlin.collections.orEmpty' call
      var tmp0_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.e2();
      var tmp$ret$0 = tmp0_elvis_lhs == null ? emptySet() : tmp0_elvis_lhs;
      tmp_1 = plus_0(tmp_2, tmp$ret$0);
    }
    var names = tmp_1;
    var tmp1_iterator = this.a2().e2().k();
    while (tmp1_iterator.l()) {
      var key = tmp1_iterator.m();
      if (!names.r(key) && !(key === this.u1f_1)) {
        throw UnknownKeyException(key, this.a2().toString());
      }
    }
  };
  function JsonTreeListDecoder(json, value) {
    AbstractJsonTreeDecoder.call(this, json, value);
    this.d1g_1 = value;
    this.e1g_1 = this.d1g_1.n();
    this.f1g_1 = -1;
  }
  protoOf(JsonTreeListDecoder).a2 = function () {
    return this.d1g_1;
  };
  protoOf(JsonTreeListDecoder).x13 = function (descriptor, index) {
    return index.toString();
  };
  protoOf(JsonTreeListDecoder).a1f = function (tag) {
    return this.d1g_1.q(toInt(tag));
  };
  protoOf(JsonTreeListDecoder).oq = function (descriptor) {
    while (this.f1g_1 < (this.e1g_1 - 1 | 0)) {
      this.f1g_1 = this.f1g_1 + 1 | 0;
      return this.f1g_1;
    }
    return -1;
  };
  function JsonPrimitiveDecoder(json, value) {
    AbstractJsonTreeDecoder.call(this, json, value);
    this.l1g_1 = value;
    this.q14('primitive');
  }
  protoOf(JsonPrimitiveDecoder).a2 = function () {
    return this.l1g_1;
  };
  protoOf(JsonPrimitiveDecoder).oq = function (descriptor) {
    return 0;
  };
  protoOf(JsonPrimitiveDecoder).a1f = function (tag) {
    // Inline function 'kotlin.require' call
    if (!(tag === 'primitive')) {
      // Inline function 'kotlinx.serialization.json.internal.JsonPrimitiveDecoder.currentElement.<anonymous>' call
      var message = "This input can only handle primitives with 'primitive' tag";
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return this.l1g_1;
  };
  function JsonTreeMapDecoder(json, value) {
    JsonTreeDecoder.call(this, json, value);
    this.w1g_1 = value;
    this.x1g_1 = toList(this.w1g_1.e2());
    this.y1g_1 = imul(this.x1g_1.n(), 2);
    this.z1g_1 = -1;
  }
  protoOf(JsonTreeMapDecoder).a2 = function () {
    return this.w1g_1;
  };
  protoOf(JsonTreeMapDecoder).x13 = function (descriptor, index) {
    var i = index / 2 | 0;
    return this.x1g_1.q(i);
  };
  protoOf(JsonTreeMapDecoder).oq = function (descriptor) {
    while (this.z1g_1 < (this.y1g_1 - 1 | 0)) {
      this.z1g_1 = this.z1g_1 + 1 | 0;
      return this.z1g_1;
    }
    return -1;
  };
  protoOf(JsonTreeMapDecoder).a1f = function (tag) {
    return (this.z1g_1 % 2 | 0) === 0 ? JsonPrimitive_2(tag) : getValue(this.w1g_1, tag);
  };
  protoOf(JsonTreeMapDecoder).yp = function (descriptor) {
  };
  function readPolymorphicJson(_this__u8e3s4, discriminator, element, deserializer) {
    return (new JsonTreeDecoder(_this__u8e3s4, element, discriminator, deserializer.pm())).wp(deserializer);
  }
  var WriteMode_OBJ_instance;
  var WriteMode_LIST_instance;
  var WriteMode_MAP_instance;
  var WriteMode_POLY_OBJ_instance;
  function values() {
    return [WriteMode_OBJ_getInstance(), WriteMode_LIST_getInstance(), WriteMode_MAP_getInstance(), WriteMode_POLY_OBJ_getInstance()];
  }
  function get_entries() {
    if ($ENTRIES == null)
      $ENTRIES = enumEntries(values());
    return $ENTRIES;
  }
  var WriteMode_entriesInitialized;
  function WriteMode_initEntries() {
    if (WriteMode_entriesInitialized)
      return Unit_instance;
    WriteMode_entriesInitialized = true;
    WriteMode_OBJ_instance = new WriteMode('OBJ', 0, _Char___init__impl__6a9atx(123), _Char___init__impl__6a9atx(125));
    WriteMode_LIST_instance = new WriteMode('LIST', 1, _Char___init__impl__6a9atx(91), _Char___init__impl__6a9atx(93));
    WriteMode_MAP_instance = new WriteMode('MAP', 2, _Char___init__impl__6a9atx(123), _Char___init__impl__6a9atx(125));
    WriteMode_POLY_OBJ_instance = new WriteMode('POLY_OBJ', 3, _Char___init__impl__6a9atx(91), _Char___init__impl__6a9atx(93));
  }
  var $ENTRIES;
  function WriteMode(name, ordinal, begin, end) {
    Enum.call(this, name, ordinal);
    this.o1e_1 = begin;
    this.p1e_1 = end;
  }
  function switchMode(_this__u8e3s4, desc) {
    var tmp0_subject = desc.eo();
    var tmp;
    if (tmp0_subject instanceof PolymorphicKind) {
      tmp = WriteMode_POLY_OBJ_getInstance();
    } else {
      if (equals(tmp0_subject, LIST_getInstance())) {
        tmp = WriteMode_LIST_getInstance();
      } else {
        if (equals(tmp0_subject, MAP_getInstance())) {
          // Inline function 'kotlinx.serialization.json.internal.selectMapMode' call
          var keyDescriptor = carrierDescriptor(desc.go(0), _this__u8e3s4.mq());
          var keyKind = keyDescriptor.eo();
          var tmp_0;
          var tmp_1;
          if (keyKind instanceof PrimitiveKind) {
            tmp_1 = true;
          } else {
            tmp_1 = equals(keyKind, ENUM_getInstance());
          }
          if (tmp_1) {
            // Inline function 'kotlinx.serialization.json.internal.switchMode.<anonymous>' call
            tmp_0 = WriteMode_MAP_getInstance();
          } else {
            if (_this__u8e3s4.s16_1.h18_1) {
              // Inline function 'kotlinx.serialization.json.internal.switchMode.<anonymous>' call
              tmp_0 = WriteMode_LIST_getInstance();
            } else {
              throw InvalidKeyKindException(keyDescriptor);
            }
          }
          tmp = tmp_0;
        } else {
          tmp = WriteMode_OBJ_getInstance();
        }
      }
    }
    return tmp;
  }
  function carrierDescriptor(_this__u8e3s4, module_0) {
    var tmp;
    if (equals(_this__u8e3s4.eo(), CONTEXTUAL_getInstance())) {
      var tmp0_safe_receiver = getContextualDescriptor(module_0, _this__u8e3s4);
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : carrierDescriptor(tmp0_safe_receiver, module_0);
      tmp = tmp1_elvis_lhs == null ? _this__u8e3s4 : tmp1_elvis_lhs;
    } else if (_this__u8e3s4.do()) {
      tmp = carrierDescriptor(_this__u8e3s4.go(0), module_0);
    } else {
      tmp = _this__u8e3s4;
    }
    return tmp;
  }
  function WriteMode_OBJ_getInstance() {
    WriteMode_initEntries();
    return WriteMode_OBJ_instance;
  }
  function WriteMode_LIST_getInstance() {
    WriteMode_initEntries();
    return WriteMode_LIST_instance;
  }
  function WriteMode_MAP_getInstance() {
    WriteMode_initEntries();
    return WriteMode_MAP_instance;
  }
  function WriteMode_POLY_OBJ_getInstance() {
    WriteMode_initEntries();
    return WriteMode_POLY_OBJ_instance;
  }
  function appendEscape($this, lastPosition, current) {
    $this.a1h(lastPosition, current);
    return appendEsc($this, current + 1 | 0);
  }
  function decodedString($this, lastPosition, currentPosition) {
    $this.a1h(lastPosition, currentPosition);
    var result = $this.l17_1.toString();
    $this.l17_1.ac(0);
    return result;
  }
  function takePeeked($this) {
    // Inline function 'kotlin.also' call
    var this_0 = ensureNotNull($this.k17_1);
    // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.takePeeked.<anonymous>' call
    $this.k17_1 = null;
    return this_0;
  }
  function wasUnquotedString($this) {
    return !(charSequenceGet($this.b1h(), $this.i17_1 - 1 | 0) === _Char___init__impl__6a9atx(34));
  }
  function appendEsc($this, startPosition) {
    var currentPosition = startPosition;
    currentPosition = $this.c1h(currentPosition);
    if (currentPosition === -1) {
      $this.m1b('Expected escape sequence to continue, got EOF');
    }
    var tmp = $this.b1h();
    var tmp0 = currentPosition;
    currentPosition = tmp0 + 1 | 0;
    var currentChar = charSequenceGet(tmp, tmp0);
    if (currentChar === _Char___init__impl__6a9atx(117)) {
      return appendHex($this, $this.b1h(), currentPosition);
    }
    // Inline function 'kotlin.code' call
    var tmp$ret$0 = Char__toInt_impl_vasixd(currentChar);
    var c = escapeToChar(tmp$ret$0);
    if (c === _Char___init__impl__6a9atx(0)) {
      $this.m1b("Invalid escaped char '" + toString_1(currentChar) + "'");
    }
    $this.l17_1.v7(c);
    return currentPosition;
  }
  function appendHex($this, source, startPos) {
    if ((startPos + 4 | 0) >= charSequenceLength(source)) {
      $this.i17_1 = startPos;
      $this.d1h();
      if (($this.i17_1 + 4 | 0) >= charSequenceLength(source)) {
        $this.m1b('Unexpected EOF during unicode escape');
      }
      return appendHex($this, source, $this.i17_1);
    }
    $this.l17_1.v7(numberToChar((((fromHexChar($this, source, startPos) << 12) + (fromHexChar($this, source, startPos + 1 | 0) << 8) | 0) + (fromHexChar($this, source, startPos + 2 | 0) << 4) | 0) + fromHexChar($this, source, startPos + 3 | 0) | 0));
    return startPos + 4 | 0;
  }
  function fromHexChar($this, source, currentPosition) {
    var character = charSequenceGet(source, currentPosition);
    var tmp;
    if (_Char___init__impl__6a9atx(48) <= character ? character <= _Char___init__impl__6a9atx(57) : false) {
      // Inline function 'kotlin.code' call
      var tmp_0 = Char__toInt_impl_vasixd(character);
      // Inline function 'kotlin.code' call
      var this_0 = _Char___init__impl__6a9atx(48);
      tmp = tmp_0 - Char__toInt_impl_vasixd(this_0) | 0;
    } else if (_Char___init__impl__6a9atx(97) <= character ? character <= _Char___init__impl__6a9atx(102) : false) {
      // Inline function 'kotlin.code' call
      var tmp_1 = Char__toInt_impl_vasixd(character);
      // Inline function 'kotlin.code' call
      var this_1 = _Char___init__impl__6a9atx(97);
      tmp = (tmp_1 - Char__toInt_impl_vasixd(this_1) | 0) + 10 | 0;
    } else if (_Char___init__impl__6a9atx(65) <= character ? character <= _Char___init__impl__6a9atx(70) : false) {
      // Inline function 'kotlin.code' call
      var tmp_2 = Char__toInt_impl_vasixd(character);
      // Inline function 'kotlin.code' call
      var this_2 = _Char___init__impl__6a9atx(65);
      tmp = (tmp_2 - Char__toInt_impl_vasixd(this_2) | 0) + 10 | 0;
    } else {
      $this.m1b("Invalid toHexChar char '" + toString_1(character) + "' in unicode escape");
    }
    return tmp;
  }
  function consumeBoolean2($this, start) {
    var current = $this.c1h(start);
    if (current >= charSequenceLength($this.b1h()) || current === -1) {
      $this.m1b('EOF');
    }
    var tmp = $this.b1h();
    var tmp0 = current;
    current = tmp0 + 1 | 0;
    // Inline function 'kotlin.code' call
    var this_0 = charSequenceGet(tmp, tmp0);
    var tmp1_subject = Char__toInt_impl_vasixd(this_0) | 32;
    var tmp_0;
    // Inline function 'kotlin.code' call
    var this_1 = _Char___init__impl__6a9atx(116);
    if (tmp1_subject === Char__toInt_impl_vasixd(this_1)) {
      consumeBooleanLiteral($this, 'rue', current);
      tmp_0 = true;
    } else {
      // Inline function 'kotlin.code' call
      var this_2 = _Char___init__impl__6a9atx(102);
      if (tmp1_subject === Char__toInt_impl_vasixd(this_2)) {
        consumeBooleanLiteral($this, 'alse', current);
        tmp_0 = false;
      } else {
        $this.m1b("Expected valid boolean literal prefix, but had '" + $this.q1c() + "'");
      }
    }
    return tmp_0;
  }
  function consumeBooleanLiteral($this, literalSuffix, current) {
    if ((charSequenceLength($this.b1h()) - current | 0) < literalSuffix.length) {
      $this.m1b('Unexpected end of boolean literal');
    }
    var inductionVariable = 0;
    var last = charSequenceLength(literalSuffix) - 1 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var expected = charSequenceGet(literalSuffix, i);
        var actual = charSequenceGet($this.b1h(), current + i | 0);
        // Inline function 'kotlin.code' call
        var tmp = Char__toInt_impl_vasixd(expected);
        // Inline function 'kotlin.code' call
        if (!(tmp === (Char__toInt_impl_vasixd(actual) | 32))) {
          $this.m1b("Expected valid boolean literal prefix, but had '" + $this.q1c() + "'");
        }
      }
       while (inductionVariable <= last);
    $this.i17_1 = current + literalSuffix.length | 0;
  }
  function consumeNumericLiteral$calculateExponent(exponentAccumulator, isExponentPositive) {
    var tmp;
    switch (isExponentPositive) {
      case false:
        // Inline function 'kotlin.math.pow' call

        var x = -exponentAccumulator.h3();
        tmp = Math.pow(10.0, x);
        break;
      case true:
        // Inline function 'kotlin.math.pow' call

        var x_0 = exponentAccumulator.h3();
        tmp = Math.pow(10.0, x_0);
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  function AbstractJsonLexer() {
    this.i17_1 = 0;
    this.j17_1 = new JsonPath();
    this.k17_1 = null;
    this.l17_1 = StringBuilder_init_$Create$();
  }
  protoOf(AbstractJsonLexer).d1h = function () {
  };
  protoOf(AbstractJsonLexer).e1h = function (c) {
    return (((c === _Char___init__impl__6a9atx(125) ? true : c === _Char___init__impl__6a9atx(93)) ? true : c === _Char___init__impl__6a9atx(58)) ? true : c === _Char___init__impl__6a9atx(44)) ? false : true;
  };
  protoOf(AbstractJsonLexer).m17 = function () {
    var nextToken = this.s1c();
    if (!(nextToken === 10)) {
      this.m1b('Expected EOF after parsing, but had ' + toString_1(charSequenceGet(this.b1h(), this.i17_1 - 1 | 0)) + ' instead');
    }
  };
  protoOf(AbstractJsonLexer).m1c = function (expected) {
    var token = this.s1c();
    if (!(token === expected)) {
      this.f1h(expected);
    }
    return token;
  };
  protoOf(AbstractJsonLexer).d1e = function (expected) {
    this.d1h();
    var source = this.b1h();
    var cpos = this.i17_1;
    $l$loop_0: while (true) {
      cpos = this.c1h(cpos);
      if (cpos === -1)
        break $l$loop_0;
      var tmp0 = cpos;
      cpos = tmp0 + 1 | 0;
      var c = charSequenceGet(source, tmp0);
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9))
        continue $l$loop_0;
      this.i17_1 = cpos;
      if (c === expected)
        return Unit_instance;
      this.g1h(expected);
    }
    this.i17_1 = cpos;
    this.g1h(expected);
  };
  protoOf(AbstractJsonLexer).g1h = function (expected) {
    if (this.i17_1 > 0 && expected === _Char___init__impl__6a9atx(34)) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.withPositionRollback' call
        var snapshot = this.i17_1;
        try {
          // Inline function 'kotlinx.serialization.json.internal.AbstractJsonLexer.unexpectedToken.<anonymous>' call
          this.i17_1 = this.i17_1 - 1 | 0;
          tmp$ret$1 = this.q1c();
          break $l$block;
        }finally {
          this.i17_1 = snapshot;
        }
      }
      var inputLiteral = tmp$ret$1;
      if (inputLiteral === 'null') {
        this.l1b("Expected string literal but 'null' literal was found", this.i17_1 - 1 | 0, "Use 'coerceInputValues = true' in 'Json {}' builder to coerce nulls to default values.");
      }
    }
    this.f1h(charToTokenClass(expected));
  };
  protoOf(AbstractJsonLexer).h1h = function (expectedToken, wasConsumed) {
    var expected = tokenDescription(expectedToken);
    var position = wasConsumed ? this.i17_1 - 1 | 0 : this.i17_1;
    var s = this.i17_1 === charSequenceLength(this.b1h()) || position < 0 ? 'EOF' : toString_1(charSequenceGet(this.b1h(), position));
    this.m1b('Expected ' + expected + ", but had '" + s + "' instead", position);
  };
  protoOf(AbstractJsonLexer).f1h = function (expectedToken, wasConsumed, $super) {
    wasConsumed = wasConsumed === VOID ? true : wasConsumed;
    return $super === VOID ? this.h1h(expectedToken, wasConsumed) : $super.h1h.call(this, expectedToken, wasConsumed);
  };
  protoOf(AbstractJsonLexer).n1c = function () {
    var source = this.b1h();
    var cpos = this.i17_1;
    $l$loop_0: while (true) {
      cpos = this.c1h(cpos);
      if (cpos === -1)
        break $l$loop_0;
      var ch = charSequenceGet(source, cpos);
      if (ch === _Char___init__impl__6a9atx(32) || ch === _Char___init__impl__6a9atx(10) || ch === _Char___init__impl__6a9atx(13) || ch === _Char___init__impl__6a9atx(9)) {
        cpos = cpos + 1 | 0;
        continue $l$loop_0;
      }
      this.i17_1 = cpos;
      return charToTokenClass(ch);
    }
    this.i17_1 = cpos;
    return 10;
  };
  protoOf(AbstractJsonLexer).f1e = function (doConsume) {
    var current = this.i1h();
    current = this.c1h(current);
    var len = charSequenceLength(this.b1h()) - current | 0;
    if (len < 4 || current === -1)
      return false;
    var inductionVariable = 0;
    if (inductionVariable <= 3)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(charSequenceGet('null', i) === charSequenceGet(this.b1h(), current + i | 0)))
          return false;
      }
       while (inductionVariable <= 3);
    if (len > 4 && charToTokenClass(charSequenceGet(this.b1h(), current + 4 | 0)) === 0)
      return false;
    if (doConsume) {
      this.i17_1 = current + 4 | 0;
    }
    return true;
  };
  protoOf(AbstractJsonLexer).q1e = function (doConsume, $super) {
    doConsume = doConsume === VOID ? true : doConsume;
    return $super === VOID ? this.f1e(doConsume) : $super.f1e.call(this, doConsume);
  };
  protoOf(AbstractJsonLexer).i1h = function () {
    var current = this.i17_1;
    $l$loop_0: while (true) {
      current = this.c1h(current);
      if (current === -1)
        break $l$loop_0;
      var c = charSequenceGet(this.b1h(), current);
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9)) {
        current = current + 1 | 0;
      } else {
        break $l$loop_0;
      }
    }
    this.i17_1 = current;
    return current;
  };
  protoOf(AbstractJsonLexer).g1e = function (isLenient) {
    var token = this.n1c();
    var tmp;
    if (isLenient) {
      if (!(token === 1) && !(token === 0))
        return null;
      tmp = this.q1c();
    } else {
      if (!(token === 1))
        return null;
      tmp = this.p1c();
    }
    var string = tmp;
    this.k17_1 = string;
    return string;
  };
  protoOf(AbstractJsonLexer).j1h = function () {
    this.k17_1 = null;
  };
  protoOf(AbstractJsonLexer).k1h = function (startPos, endPos) {
    // Inline function 'kotlin.text.substring' call
    var this_0 = this.b1h();
    return toString(charSequenceSubSequence(this_0, startPos, endPos));
  };
  protoOf(AbstractJsonLexer).p1c = function () {
    if (!(this.k17_1 == null)) {
      return takePeeked(this);
    }
    return this.j1e();
  };
  protoOf(AbstractJsonLexer).consumeString2 = function (source, startPosition, current) {
    var currentPosition = current;
    var lastPosition = startPosition;
    var char = charSequenceGet(source, currentPosition);
    var usedAppend = false;
    while (!(char === _Char___init__impl__6a9atx(34))) {
      if (char === _Char___init__impl__6a9atx(92)) {
        usedAppend = true;
        currentPosition = this.c1h(appendEscape(this, lastPosition, currentPosition));
        if (currentPosition === -1) {
          this.m1b('Unexpected EOF', currentPosition);
        }
        lastPosition = currentPosition;
      } else {
        currentPosition = currentPosition + 1 | 0;
        if (currentPosition >= charSequenceLength(source)) {
          usedAppend = true;
          this.a1h(lastPosition, currentPosition);
          currentPosition = this.c1h(currentPosition);
          if (currentPosition === -1) {
            this.m1b('Unexpected EOF', currentPosition);
          }
          lastPosition = currentPosition;
        }
      }
      char = charSequenceGet(source, currentPosition);
    }
    var tmp;
    if (!usedAppend) {
      tmp = this.k1h(lastPosition, currentPosition);
    } else {
      tmp = decodedString(this, lastPosition, currentPosition);
    }
    var string = tmp;
    this.i17_1 = currentPosition + 1 | 0;
    return string;
  };
  protoOf(AbstractJsonLexer).k1e = function () {
    var result = this.q1c();
    if (result === 'null' && wasUnquotedString(this)) {
      this.m1b("Unexpected 'null' value instead of string literal");
    }
    return result;
  };
  protoOf(AbstractJsonLexer).q1c = function () {
    if (!(this.k17_1 == null)) {
      return takePeeked(this);
    }
    var current = this.i1h();
    if (current >= charSequenceLength(this.b1h()) || current === -1) {
      this.m1b('EOF', current);
    }
    var token = charToTokenClass(charSequenceGet(this.b1h(), current));
    if (token === 1) {
      return this.p1c();
    }
    if (!(token === 0)) {
      this.m1b('Expected beginning of the string, but got ' + toString_1(charSequenceGet(this.b1h(), current)));
    }
    var usedAppend = false;
    while (charToTokenClass(charSequenceGet(this.b1h(), current)) === 0) {
      current = current + 1 | 0;
      if (current >= charSequenceLength(this.b1h())) {
        usedAppend = true;
        this.a1h(this.i17_1, current);
        var eof = this.c1h(current);
        if (eof === -1) {
          this.i17_1 = current;
          return decodedString(this, 0, 0);
        } else {
          current = eof;
        }
      }
    }
    var tmp;
    if (!usedAppend) {
      tmp = this.k1h(this.i17_1, current);
    } else {
      tmp = decodedString(this, this.i17_1, current);
    }
    var result = tmp;
    this.i17_1 = current;
    return result;
  };
  protoOf(AbstractJsonLexer).a1h = function (fromIndex, toIndex) {
    this.l17_1.vb(this.b1h(), fromIndex, toIndex);
  };
  protoOf(AbstractJsonLexer).i1e = function (allowLenientStrings) {
    // Inline function 'kotlin.collections.mutableListOf' call
    var tokenStack = ArrayList_init_$Create$();
    var lastToken = this.n1c();
    if (!(lastToken === 8) && !(lastToken === 6)) {
      this.q1c();
      return Unit_instance;
    }
    $l$loop: while (true) {
      lastToken = this.n1c();
      if (lastToken === 1) {
        if (allowLenientStrings) {
          this.q1c();
        } else {
          this.j1e();
        }
        continue $l$loop;
      }
      var tmp0_subject = lastToken;
      if (tmp0_subject === 8 ? true : tmp0_subject === 6) {
        tokenStack.e(lastToken);
      } else if (tmp0_subject === 9) {
        if (!(last(tokenStack) === 8))
          throw JsonDecodingException_0(this.i17_1, 'found ] instead of } at path: ' + this.j17_1.toString(), this.b1h());
        removeLast(tokenStack);
      } else if (tmp0_subject === 7) {
        if (!(last(tokenStack) === 6))
          throw JsonDecodingException_0(this.i17_1, 'found } instead of ] at path: ' + this.j17_1.toString(), this.b1h());
        removeLast(tokenStack);
      } else if (tmp0_subject === 10) {
        this.m1b('Unexpected end of input due to malformed JSON during ignoring unknown keys');
      }
      this.s1c();
      if (tokenStack.n() === 0)
        return Unit_instance;
    }
  };
  protoOf(AbstractJsonLexer).toString = function () {
    return "JsonReader(source='" + toString(this.b1h()) + "', currentPosition=" + this.i17_1 + ')';
  };
  protoOf(AbstractJsonLexer).h1e = function (key) {
    var processed = this.k1h(0, this.i17_1);
    var lastIndexOf_0 = lastIndexOf(processed, key);
    this.l1b("Encountered an unknown key '" + key + "'", lastIndexOf_0, "Use 'ignoreUnknownKeys = true' in 'Json {}' builder to ignore unknown keys.");
  };
  protoOf(AbstractJsonLexer).l1b = function (message, position, hint) {
    var tmp;
    // Inline function 'kotlin.text.isEmpty' call
    if (charSequenceLength(hint) === 0) {
      tmp = '';
    } else {
      tmp = '\n' + hint;
    }
    var hintMessage = tmp;
    throw JsonDecodingException_0(position, message + ' at path: ' + this.j17_1.z1b() + hintMessage, this.b1h());
  };
  protoOf(AbstractJsonLexer).m1b = function (message, position, hint, $super) {
    position = position === VOID ? this.i17_1 : position;
    hint = hint === VOID ? '' : hint;
    return $super === VOID ? this.l1b(message, position, hint) : $super.l1b.call(this, message, position, hint);
  };
  protoOf(AbstractJsonLexer).h19 = function () {
    var current = this.i1h();
    current = this.c1h(current);
    if (current >= charSequenceLength(this.b1h()) || current === -1) {
      this.m1b('EOF');
    }
    var tmp;
    if (charSequenceGet(this.b1h(), current) === _Char___init__impl__6a9atx(34)) {
      current = current + 1 | 0;
      if (current === charSequenceLength(this.b1h())) {
        this.m1b('EOF');
      }
      tmp = true;
    } else {
      tmp = false;
    }
    var hasQuotation = tmp;
    var accumulator = new Long(0, 0);
    var exponentAccumulator = new Long(0, 0);
    var isNegative = false;
    var isExponentPositive = false;
    var hasExponent = false;
    var start = current;
    $l$loop_4: while (!(current === charSequenceLength(this.b1h()))) {
      var ch = charSequenceGet(this.b1h(), current);
      if ((ch === _Char___init__impl__6a9atx(101) || ch === _Char___init__impl__6a9atx(69)) && !hasExponent) {
        if (current === start) {
          this.m1b('Unexpected symbol ' + toString_1(ch) + ' in numeric literal');
        }
        isExponentPositive = true;
        hasExponent = true;
        current = current + 1 | 0;
        continue $l$loop_4;
      }
      if (ch === _Char___init__impl__6a9atx(45) && hasExponent) {
        if (current === start) {
          this.m1b("Unexpected symbol '-' in numeric literal");
        }
        isExponentPositive = false;
        current = current + 1 | 0;
        continue $l$loop_4;
      }
      if (ch === _Char___init__impl__6a9atx(43) && hasExponent) {
        if (current === start) {
          this.m1b("Unexpected symbol '+' in numeric literal");
        }
        isExponentPositive = true;
        current = current + 1 | 0;
        continue $l$loop_4;
      }
      if (ch === _Char___init__impl__6a9atx(45)) {
        if (!(current === start)) {
          this.m1b("Unexpected symbol '-' in numeric literal");
        }
        isNegative = true;
        current = current + 1 | 0;
        continue $l$loop_4;
      }
      var token = charToTokenClass(ch);
      if (!(token === 0))
        break $l$loop_4;
      current = current + 1 | 0;
      var digit = Char__minus_impl_a2frrh(ch, _Char___init__impl__6a9atx(48));
      if (!(0 <= digit ? digit <= 9 : false)) {
        this.m1b("Unexpected symbol '" + toString_1(ch) + "' in numeric literal");
      }
      if (hasExponent) {
        // Inline function 'kotlin.Long.times' call
        // Inline function 'kotlin.Long.plus' call
        exponentAccumulator = exponentAccumulator.u2(toLong(10)).s2(toLong(digit));
        continue $l$loop_4;
      }
      // Inline function 'kotlin.Long.times' call
      // Inline function 'kotlin.Long.minus' call
      accumulator = accumulator.u2(toLong(10)).t2(toLong(digit));
      if (accumulator.g1(new Long(0, 0)) > 0) {
        this.m1b('Numeric value overflow');
      }
    }
    var hasChars = !(current === start);
    if (start === current || (isNegative && start === (current - 1 | 0))) {
      this.m1b('Expected numeric literal');
    }
    if (hasQuotation) {
      if (!hasChars) {
        this.m1b('EOF');
      }
      if (!(charSequenceGet(this.b1h(), current) === _Char___init__impl__6a9atx(34))) {
        this.m1b('Expected closing quotation mark');
      }
      current = current + 1 | 0;
    }
    this.i17_1 = current;
    if (hasExponent) {
      var doubleAccumulator = accumulator.h3() * consumeNumericLiteral$calculateExponent(exponentAccumulator, isExponentPositive);
      if (doubleAccumulator > (new Long(-1, 2147483647)).h3() || doubleAccumulator < (new Long(0, -2147483648)).h3()) {
        this.m1b('Numeric value overflow');
      }
      // Inline function 'kotlin.math.floor' call
      if (!(Math.floor(doubleAccumulator) === doubleAccumulator)) {
        this.m1b("Can't convert " + doubleAccumulator + ' to Long');
      }
      accumulator = numberToLong(doubleAccumulator);
    }
    var tmp_0;
    if (isNegative) {
      tmp_0 = accumulator;
    } else if (!accumulator.equals(new Long(0, -2147483648))) {
      tmp_0 = accumulator.x2();
    } else {
      this.m1b('Numeric value overflow');
    }
    return tmp_0;
  };
  protoOf(AbstractJsonLexer).r1e = function () {
    var current = this.i1h();
    if (current === charSequenceLength(this.b1h())) {
      this.m1b('EOF');
    }
    var tmp;
    if (charSequenceGet(this.b1h(), current) === _Char___init__impl__6a9atx(34)) {
      current = current + 1 | 0;
      tmp = true;
    } else {
      tmp = false;
    }
    var hasQuotation = tmp;
    var result = consumeBoolean2(this, current);
    if (hasQuotation) {
      if (this.i17_1 === charSequenceLength(this.b1h())) {
        this.m1b('EOF');
      }
      if (!(charSequenceGet(this.b1h(), this.i17_1) === _Char___init__impl__6a9atx(34))) {
        this.m1b('Expected closing quotation mark');
      }
      this.i17_1 = this.i17_1 + 1 | 0;
    }
    return result;
  };
  function charToTokenClass(c) {
    var tmp;
    // Inline function 'kotlin.code' call
    if (Char__toInt_impl_vasixd(c) < 126) {
      var tmp_0 = CharMappings_getInstance().m1h_1;
      // Inline function 'kotlin.code' call
      tmp = tmp_0[Char__toInt_impl_vasixd(c)];
    } else {
      tmp = 0;
    }
    return tmp;
  }
  function tokenDescription(token) {
    return token === 1 ? "quotation mark '\"'" : token === 2 ? "string escape sequence '\\'" : token === 4 ? "comma ','" : token === 5 ? "colon ':'" : token === 6 ? "start of the object '{'" : token === 7 ? "end of the object '}'" : token === 8 ? "start of the array '['" : token === 9 ? "end of the array ']'" : token === 10 ? 'end of the input' : token === 127 ? 'invalid token' : 'valid token';
  }
  function escapeToChar(c) {
    return c < 117 ? CharMappings_getInstance().l1h_1[c] : _Char___init__impl__6a9atx(0);
  }
  function initEscape($this) {
    var inductionVariable = 0;
    if (inductionVariable <= 31)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        initC2ESC($this, i, _Char___init__impl__6a9atx(117));
      }
       while (inductionVariable <= 31);
    initC2ESC($this, 8, _Char___init__impl__6a9atx(98));
    initC2ESC($this, 9, _Char___init__impl__6a9atx(116));
    initC2ESC($this, 10, _Char___init__impl__6a9atx(110));
    initC2ESC($this, 12, _Char___init__impl__6a9atx(102));
    initC2ESC($this, 13, _Char___init__impl__6a9atx(114));
    initC2ESC_0($this, _Char___init__impl__6a9atx(47), _Char___init__impl__6a9atx(47));
    initC2ESC_0($this, _Char___init__impl__6a9atx(34), _Char___init__impl__6a9atx(34));
    initC2ESC_0($this, _Char___init__impl__6a9atx(92), _Char___init__impl__6a9atx(92));
  }
  function initCharToToken($this) {
    var inductionVariable = 0;
    if (inductionVariable <= 32)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        initC2TC($this, i, 127);
      }
       while (inductionVariable <= 32);
    initC2TC($this, 9, 3);
    initC2TC($this, 10, 3);
    initC2TC($this, 13, 3);
    initC2TC($this, 32, 3);
    initC2TC_0($this, _Char___init__impl__6a9atx(44), 4);
    initC2TC_0($this, _Char___init__impl__6a9atx(58), 5);
    initC2TC_0($this, _Char___init__impl__6a9atx(123), 6);
    initC2TC_0($this, _Char___init__impl__6a9atx(125), 7);
    initC2TC_0($this, _Char___init__impl__6a9atx(91), 8);
    initC2TC_0($this, _Char___init__impl__6a9atx(93), 9);
    initC2TC_0($this, _Char___init__impl__6a9atx(34), 1);
    initC2TC_0($this, _Char___init__impl__6a9atx(92), 2);
  }
  function initC2ESC($this, c, esc) {
    if (!(esc === _Char___init__impl__6a9atx(117))) {
      // Inline function 'kotlin.code' call
      var tmp$ret$0 = Char__toInt_impl_vasixd(esc);
      $this.l1h_1[tmp$ret$0] = numberToChar(c);
    }
  }
  function initC2ESC_0($this, c, esc) {
    // Inline function 'kotlin.code' call
    var tmp$ret$0 = Char__toInt_impl_vasixd(c);
    return initC2ESC($this, tmp$ret$0, esc);
  }
  function initC2TC($this, c, cl) {
    $this.m1h_1[c] = cl;
  }
  function initC2TC_0($this, c, cl) {
    // Inline function 'kotlin.code' call
    var tmp$ret$0 = Char__toInt_impl_vasixd(c);
    return initC2TC($this, tmp$ret$0, cl);
  }
  function CharMappings() {
    CharMappings_instance = this;
    this.l1h_1 = charArray(117);
    this.m1h_1 = new Int8Array(126);
    initEscape(this);
    initCharToToken(this);
  }
  var CharMappings_instance;
  function CharMappings_getInstance() {
    if (CharMappings_instance == null)
      new CharMappings();
    return CharMappings_instance;
  }
  function StringJsonLexer(source) {
    AbstractJsonLexer.call(this);
    this.r1h_1 = source;
  }
  protoOf(StringJsonLexer).b1h = function () {
    return this.r1h_1;
  };
  protoOf(StringJsonLexer).c1h = function (position) {
    return position < this.r1h_1.length ? position : -1;
  };
  protoOf(StringJsonLexer).s1c = function () {
    var source = this.r1h_1;
    $l$loop: while (!(this.i17_1 === -1) && this.i17_1 < source.length) {
      var tmp1 = this.i17_1;
      this.i17_1 = tmp1 + 1 | 0;
      var ch = charSequenceGet(source, tmp1);
      var tc = charToTokenClass(ch);
      var tmp;
      if (tc === 3) {
        continue $l$loop;
      } else {
        tmp = tc;
      }
      return tmp;
    }
    return 10;
  };
  protoOf(StringJsonLexer).e1e = function () {
    var current = this.i1h();
    if (current === this.r1h_1.length || current === -1)
      return false;
    if (charSequenceGet(this.r1h_1, current) === _Char___init__impl__6a9atx(44)) {
      this.i17_1 = this.i17_1 + 1 | 0;
      return true;
    }
    return false;
  };
  protoOf(StringJsonLexer).o1c = function () {
    var current = this.i17_1;
    if (current === -1)
      return false;
    $l$loop: while (current < this.r1h_1.length) {
      var c = charSequenceGet(this.r1h_1, current);
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9)) {
        current = current + 1 | 0;
        continue $l$loop;
      }
      this.i17_1 = current;
      return this.e1h(c);
    }
    this.i17_1 = current;
    return false;
  };
  protoOf(StringJsonLexer).i1h = function () {
    var current = this.i17_1;
    if (current === -1)
      return current;
    $l$loop: while (current < this.r1h_1.length) {
      var c = charSequenceGet(this.r1h_1, current);
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9)) {
        current = current + 1 | 0;
      } else {
        break $l$loop;
      }
    }
    this.i17_1 = current;
    return current;
  };
  protoOf(StringJsonLexer).d1e = function (expected) {
    if (this.i17_1 === -1) {
      this.g1h(expected);
    }
    var source = this.r1h_1;
    $l$loop: while (this.i17_1 < source.length) {
      var tmp1 = this.i17_1;
      this.i17_1 = tmp1 + 1 | 0;
      var c = charSequenceGet(source, tmp1);
      if (c === _Char___init__impl__6a9atx(32) || c === _Char___init__impl__6a9atx(10) || c === _Char___init__impl__6a9atx(13) || c === _Char___init__impl__6a9atx(9))
        continue $l$loop;
      if (c === expected)
        return Unit_instance;
      this.g1h(expected);
    }
    this.i17_1 = -1;
    this.g1h(expected);
  };
  protoOf(StringJsonLexer).j1e = function () {
    this.d1e(_Char___init__impl__6a9atx(34));
    var current = this.i17_1;
    var closingQuote = indexOf(this.r1h_1, _Char___init__impl__6a9atx(34), current);
    if (closingQuote === -1) {
      this.q1c();
      this.h1h(1, false);
    }
    var inductionVariable = current;
    if (inductionVariable < closingQuote)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (charSequenceGet(this.r1h_1, i) === _Char___init__impl__6a9atx(92)) {
          return this.consumeString2(this.r1h_1, this.i17_1, i);
        }
      }
       while (inductionVariable < closingQuote);
    this.i17_1 = closingQuote + 1 | 0;
    // Inline function 'kotlin.text.substring' call
    // Inline function 'kotlin.js.asDynamic' call
    return this.r1h_1.substring(current, closingQuote);
  };
  protoOf(StringJsonLexer).l1e = function (keyToMatch, isLenient) {
    var positionSnapshot = this.i17_1;
    try {
      if (!(this.s1c() === 6))
        return null;
      var firstKey = this.g1e(isLenient);
      if (!(firstKey === keyToMatch))
        return null;
      this.j1h();
      if (!(this.s1c() === 5))
        return null;
      return this.g1e(isLenient);
    }finally {
      this.i17_1 = positionSnapshot;
      this.j1h();
    }
  };
  function decodeFromDynamic(_this__u8e3s4, deserializer, dynamic) {
    return decodeDynamic(_this__u8e3s4, deserializer, dynamic);
  }
  function decodeDynamic(_this__u8e3s4, deserializer, dynamic) {
    var tmp;
    switch (typeof dynamic) {
      case 'boolean':
      case 'number':
      case 'string':
        tmp = new PrimitiveDynamicInput(dynamic, _this__u8e3s4);
        break;
      default:
        var tmp_0;
        if (Array.isArray(dynamic)) {
          tmp_0 = new DynamicListInput(dynamic, _this__u8e3s4);
        } else {
          tmp_0 = new DynamicInput(dynamic, _this__u8e3s4);
        }

        tmp = tmp_0;
        break;
    }
    var input = tmp;
    return input.wp(deserializer);
  }
  function coerceInputValue_1($this, descriptor, index, tag) {
    var tmp0 = $this.t18();
    var tmp1 = descriptor.go(index);
    var tmp$ret$1;
    $l$block_2: {
      // Inline function 'kotlinx.serialization.json.internal.tryCoerceValue' call
      var tmp;
      if (!tmp1.xn()) {
        // Inline function 'kotlinx.serialization.json.internal.DynamicInput.coerceInputValue.<anonymous>' call
        tmp = $this.a1i(tag) == null;
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$1 = true;
        break $l$block_2;
      }
      if (equals(tmp1.eo(), ENUM_getInstance())) {
        var tmp_0;
        if (tmp1.xn()) {
          // Inline function 'kotlinx.serialization.json.internal.DynamicInput.coerceInputValue.<anonymous>' call
          tmp_0 = $this.a1i(tag) == null;
        } else {
          tmp_0 = false;
        }
        if (tmp_0) {
          tmp$ret$1 = false;
          break $l$block_2;
        }
        // Inline function 'kotlinx.serialization.json.internal.DynamicInput.coerceInputValue.<anonymous>' call
        var tmp_1 = $this.a1i(tag);
        var tmp0_elvis_lhs = (!(tmp_1 == null) ? typeof tmp_1 === 'string' : false) ? tmp_1 : null;
        var tmp_2;
        if (tmp0_elvis_lhs == null) {
          tmp$ret$1 = false;
          break $l$block_2;
        } else {
          tmp_2 = tmp0_elvis_lhs;
        }
        var enumValue = tmp_2;
        var enumIndex = getJsonNameIndex(tmp1, tmp0, enumValue);
        if (enumIndex === -3) {
          // Inline function 'kotlinx.serialization.json.internal.tryCoerceValue.<anonymous>' call
          tmp$ret$1 = true;
          break $l$block_2;
        }
      }
      tmp$ret$1 = false;
    }
    return tmp$ret$1;
  }
  function hasName($this, name) {
    return $this.u1h_1[name] !== undefined;
  }
  function absenceIsNull_0($this, descriptor, index) {
    $this.y1h_1 = (!$this.t18().s16_1.j18_1 && !descriptor.jo(index) && descriptor.go(index).xn());
    return $this.y1h_1;
  }
  function throwMissingTag($this, tag) {
    throw SerializationException_init_$Create$('Value for field ' + tag + ' is missing');
  }
  function DynamicInput(value, json) {
    NamedValueDecoder.call(this);
    this.u1h_1 = value;
    this.v1h_1 = json;
    var tmp = this;
    var tmp0_elvis_lhs = this.u1h_1;
    tmp.w1h_1 = Object.keys(tmp0_elvis_lhs == null ? {} : tmp0_elvis_lhs);
    var tmp_0 = this;
    var tmp_1 = this.w1h_1.length;
    tmp_0.x1h_1 = (!(tmp_1 == null) ? typeof tmp_1 === 'number' : false) ? tmp_1 : THROW_CCE();
    this.y1h_1 = false;
    this.z1h_1 = 0;
  }
  protoOf(DynamicInput).t18 = function () {
    return this.v1h_1;
  };
  protoOf(DynamicInput).n = function () {
    return this.x1h_1;
  };
  protoOf(DynamicInput).mq = function () {
    return this.t18().mq();
  };
  protoOf(DynamicInput).u18 = function () {
    var tag = this.b14();
    if (!(tag == null)) {
      return decodeFromDynamic(this.t18(), Companion_instance.v18(), this.u1h_1[tag]);
    }
    if (this.u1h_1 == null) {
      return JsonNull_getInstance();
    }
    // Inline function 'kotlinx.serialization.json.buildJsonObject' call
    // Inline function 'kotlin.contracts.contract' call
    var builder = new JsonObjectBuilder();
    // Inline function 'kotlinx.serialization.json.internal.DynamicInput.decodeJsonElement.<anonymous>' call
    var inductionVariable = 0;
    var last = this.n();
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var key = this.w1h_1[i];
        var value = decodeDynamic(this.t18(), Companion_instance.v18(), this.u1h_1[key]);
        builder.j19(toString(key), value);
      }
       while (inductionVariable < last);
    return builder.d18();
  };
  protoOf(DynamicInput).wp = function (deserializer) {
    return decodeSerializableValuePolymorphic(this, deserializer);
  };
  protoOf(DynamicInput).c14 = function (parentName, childName) {
    return childName;
  };
  protoOf(DynamicInput).oq = function (descriptor) {
    while (this.z1h_1 < descriptor.co()) {
      var tmp1 = this.z1h_1;
      this.z1h_1 = tmp1 + 1 | 0;
      var name = this.w13(descriptor, tmp1);
      var index = this.z1h_1 - 1 | 0;
      this.y1h_1 = false;
      if ((hasName(this, name) || absenceIsNull_0(this, descriptor, index)) && (!this.t18().s16_1.l18_1 || !coerceInputValue_1(this, descriptor, index, name))) {
        return index;
      }
    }
    return -1;
  };
  protoOf(DynamicInput).x13 = function (descriptor, index) {
    var strategy = namingStrategy(descriptor, this.t18());
    var mainName = descriptor.io(index);
    if (strategy == null) {
      if (!this.t18().s16_1.p18_1)
        return mainName;
      if (hasName(this, mainName))
        return mainName;
    }
    var deserializationNamesMap_0 = deserializationNamesMap(this.t18(), descriptor);
    var tmp = this.w1h_1;
    // Inline function 'kotlin.collections.find' call
    var tmp0 = (!(tmp == null) ? isArray(tmp) : false) ? tmp : THROW_CCE();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var inductionVariable = 0;
      var last = tmp0.length;
      while (inductionVariable < last) {
        var element = tmp0[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlinx.serialization.json.internal.DynamicInput.elementName.<anonymous>' call
        if (deserializationNamesMap_0.d2(element) === index) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    var tmp0_safe_receiver = tmp$ret$1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      return tmp0_safe_receiver;
    }
    var fallbackName = strategy == null ? null : strategy.q1b(descriptor, index, mainName);
    return fallbackName == null ? mainName : fallbackName;
  };
  protoOf(DynamicInput).c1f = function (tag, enumDescriptor) {
    var byTag = this.a1i(tag);
    var tmp0_elvis_lhs = (!(byTag == null) ? typeof byTag === 'string' : false) ? byTag : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw SerializationException_init_$Create$("Enum value must be a string, got '" + byTag + "'");
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var enumValue = tmp;
    return getJsonNameIndexOrThrow(enumDescriptor, this.t18(), enumValue);
  };
  protoOf(DynamicInput).o14 = function (tag, enumDescriptor) {
    return this.c1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE(), enumDescriptor);
  };
  protoOf(DynamicInput).a1i = function (tag) {
    return this.u1h_1[tag];
  };
  protoOf(DynamicInput).l1f = function (tag) {
    var value = this.a1i(tag);
    var tmp;
    if (!(value == null) ? typeof value === 'string' : false) {
      var tmp_0;
      if (value.length === 1) {
        tmp_0 = charSequenceGet(value, 0);
      } else {
        throw SerializationException_init_$Create$('' + value + " can't be represented as Char");
      }
      tmp = tmp_0;
    } else {
      if (isNumber(value)) {
        var tmp0_elvis_lhs = (!(value == null) ? typeof value === 'number' : false) ? value : null;
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          throw SerializationException_init_$Create$('' + value + ' is not a Number');
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        var num = tmp_1;
        var codePoint = this.b1i(num);
        var tmp_2;
        if (codePoint.g1(new Long(0, 0)) < 0) {
          tmp_2 = true;
        } else {
          // Inline function 'kotlin.code' call
          var this_0 = _Char___init__impl__6a9atx(65535);
          var tmp$ret$0 = Char__toInt_impl_vasixd(this_0);
          tmp_2 = codePoint.g1(toLong(tmp$ret$0)) > 0;
        }
        if (tmp_2)
          throw SerializationException_init_$Create$('' + value + " can't be represented as Char because it's not in bounds of Char.MIN_VALUE..Char.MAX_VALUE");
        tmp = numberToChar(codePoint.i1());
      } else {
        throw SerializationException_init_$Create$('' + value + " can't be represented as Char");
      }
    }
    return tmp;
  };
  protoOf(DynamicInput).m14 = function (tag) {
    return this.l1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicInput).i1f = function (tag) {
    var value = this.a1i(tag);
    var tmp0_elvis_lhs = (!(value == null) ? typeof value === 'number' : false) ? value : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw SerializationException_init_$Create$('' + value + ' is not a Number');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var number = tmp;
    return this.b1i(number);
  };
  protoOf(DynamicInput).j14 = function (tag) {
    return this.i1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicInput).b1i = function (number) {
    var tmp;
    if (isFinite_0(number)) {
      // Inline function 'kotlin.math.floor' call
      tmp = Math.floor(number) === number;
    } else {
      tmp = false;
    }
    var canBeConverted = tmp;
    if (!canBeConverted)
      throw SerializationException_init_$Create$('' + number + " can't be represented as Long because it is not finite or has non-zero fractional part");
    // Inline function 'kotlin.math.abs' call
    var inBound = Math.abs(number) <= 9.007199254740991E15;
    if (!inBound)
      throw SerializationException_init_$Create$('' + number + " can't be deserialized to Long due to a potential precision loss");
    return numberToLong(number);
  };
  protoOf(DynamicInput).c1i = function (tag) {
    var tmp0_elvis_lhs = this.a1i(tag);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throwMissingTag(this, tag);
      tmp = Unit_instance;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var o = tmp;
    return !(o == null) ? o : THROW_CCE();
  };
  protoOf(DynamicInput).d14 = function (tag) {
    return this.c1i((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicInput).d1f = function (tag) {
    if (this.y1h_1) {
      return false;
    }
    var o = this.a1i(tag);
    if (o === undefined) {
      throwMissingTag(this, tag);
    }
    return o != null;
  };
  protoOf(DynamicInput).e14 = function (tag) {
    return this.d1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicInput).xp = function (descriptor) {
    var tmp0_safe_receiver = this.b14();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlinx.serialization.json.internal.DynamicInput.beginStructure.<anonymous>' call
      tmp = this.u1h_1[tmp0_safe_receiver];
    }
    var tmp1_elvis_lhs = tmp;
    var currentValue = tmp1_elvis_lhs == null ? this.u1h_1 : tmp1_elvis_lhs;
    var tmp_0;
    if (descriptor.eo() instanceof PolymorphicKind) {
      tmp_0 = this.t18().s16_1.m18_1 ? LIST_getInstance() : MAP_getInstance();
    } else {
      tmp_0 = descriptor.eo();
    }
    var kind = tmp_0;
    return equals(kind, LIST_getInstance()) ? new DynamicListInput(currentValue, this.t18()) : equals(kind, MAP_getInstance()) ? new DynamicMapInput(currentValue, this.t18()) : new DynamicInput(currentValue, this.t18());
  };
  function PrimitiveDynamicInput(value, json) {
    DynamicInput.call(this, value, json);
    this.q14('primitive');
  }
  protoOf(PrimitiveDynamicInput).a1i = function (tag) {
    return this.u1h_1;
  };
  protoOf(PrimitiveDynamicInput).u18 = function () {
    var str = toString(this.u1h_1);
    var tmp;
    switch (typeof this.u1h_1) {
      case 'boolean':
        tmp = JsonPrimitive_1(toBoolean(str));
        break;
      case 'number':
        var l = toLongOrNull(str);
        if (!(l == null))
          return JsonPrimitive_0(l);
        var d = toDoubleOrNull(str);
        if (!(d == null))
          return JsonPrimitive_0(d);
        return JsonPrimitive_2(str);
      default:
        tmp = JsonPrimitive_2(str);
        break;
    }
    return tmp;
  };
  function DynamicListInput(value, json) {
    DynamicInput.call(this, value, json);
    var tmp = this;
    var tmp_0 = value.length;
    tmp.t1i_1 = (!(tmp_0 == null) ? typeof tmp_0 === 'number' : false) ? tmp_0 : THROW_CCE();
    this.u1i_1 = -1;
  }
  protoOf(DynamicListInput).n = function () {
    return this.t1i_1;
  };
  protoOf(DynamicListInput).x13 = function (descriptor, index) {
    return index.toString();
  };
  protoOf(DynamicListInput).oq = function (descriptor) {
    while (this.u1i_1 < (this.t1i_1 - 1 | 0)) {
      this.u1i_1 = this.u1i_1 + 1 | 0;
      var o = this.u1h_1[this.u1i_1];
      if (o !== undefined)
        return this.u1i_1;
    }
    return -1;
  };
  protoOf(DynamicListInput).u18 = function () {
    var tag = this.b14();
    if (!(tag == null)) {
      return decodeFromDynamic(this.t18(), Companion_instance.v18(), this.u1h_1[tag]);
    }
    // Inline function 'kotlinx.serialization.json.buildJsonArray' call
    // Inline function 'kotlin.contracts.contract' call
    var builder = new JsonArrayBuilder();
    // Inline function 'kotlinx.serialization.json.internal.DynamicListInput.decodeJsonElement.<anonymous>' call
    var inductionVariable = 0;
    var last = this.t1i_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        builder.l19(decodeFromDynamic(this.t18(), Companion_instance.v18(), this.u1h_1[i]));
      }
       while (inductionVariable < last);
    return builder.d18();
  };
  function _get_isKey__g0dpic($this) {
    return ($this.e1j_1 % 2 | 0) === 0;
  }
  function throwIllegalKeyType($this, tag, value, type) {
    throw SerializationException_init_$Create$('Property ' + tag + ' is not valid type ' + type + ': ' + toString(value));
  }
  function DynamicMapInput(value, json) {
    DynamicInput.call(this, value, json);
    var tmp = this;
    var tmp_0 = this.w1h_1.length;
    tmp.d1j_1 = imul((!(tmp_0 == null) ? typeof tmp_0 === 'number' : false) ? tmp_0 : THROW_CCE(), 2);
    this.e1j_1 = -1;
  }
  protoOf(DynamicMapInput).n = function () {
    return this.d1j_1;
  };
  protoOf(DynamicMapInput).x13 = function (descriptor, index) {
    var i = index / 2 | 0;
    var tmp = this.w1h_1[i];
    return (!(tmp == null) ? typeof tmp === 'string' : false) ? tmp : THROW_CCE();
  };
  protoOf(DynamicMapInput).f1f = function (tag) {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeMapKey' call
      if (_get_isKey__g0dpic(this)) {
        var value = this.c1i(tag);
        if (!(typeof value === 'string')) {
          // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedByte.<anonymous>' call
          tmp$ret$1 = protoOf(DynamicInput).g14.call(this, tag);
          break $l$block_0;
        }
        // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedByte.<anonymous>' call
        var $this$decodeMapKey = toString(value);
        var tmp0_elvis_lhs = toByteOrNull($this$decodeMapKey);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          throwIllegalKeyType(this, tag, value, 'byte');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block_0;
      }
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedByte.<anonymous>' call
      tmp$ret$1 = protoOf(DynamicInput).g14.call(this, tag);
    }
    return tmp$ret$1;
  };
  protoOf(DynamicMapInput).g14 = function (tag) {
    return this.f1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicMapInput).g1f = function (tag) {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeMapKey' call
      if (_get_isKey__g0dpic(this)) {
        var value = this.c1i(tag);
        if (!(typeof value === 'string')) {
          // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedShort.<anonymous>' call
          tmp$ret$1 = protoOf(DynamicInput).h14.call(this, tag);
          break $l$block_0;
        }
        // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedShort.<anonymous>' call
        var $this$decodeMapKey = toString(value);
        var tmp0_elvis_lhs = toShortOrNull($this$decodeMapKey);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          throwIllegalKeyType(this, tag, value, 'short');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block_0;
      }
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedShort.<anonymous>' call
      tmp$ret$1 = protoOf(DynamicInput).h14.call(this, tag);
    }
    return tmp$ret$1;
  };
  protoOf(DynamicMapInput).h14 = function (tag) {
    return this.g1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicMapInput).h1f = function (tag) {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeMapKey' call
      if (_get_isKey__g0dpic(this)) {
        var value = this.c1i(tag);
        if (!(typeof value === 'string')) {
          // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedInt.<anonymous>' call
          tmp$ret$1 = protoOf(DynamicInput).i14.call(this, tag);
          break $l$block_0;
        }
        // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedInt.<anonymous>' call
        var $this$decodeMapKey = toString(value);
        var tmp0_elvis_lhs = toIntOrNull($this$decodeMapKey);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          throwIllegalKeyType(this, tag, value, 'int');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block_0;
      }
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedInt.<anonymous>' call
      tmp$ret$1 = protoOf(DynamicInput).i14.call(this, tag);
    }
    return tmp$ret$1;
  };
  protoOf(DynamicMapInput).i14 = function (tag) {
    return this.h1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicMapInput).i1f = function (tag) {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeMapKey' call
      if (_get_isKey__g0dpic(this)) {
        var value = this.c1i(tag);
        if (!(typeof value === 'string')) {
          // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedLong.<anonymous>' call
          tmp$ret$1 = protoOf(DynamicInput).i1f.call(this, tag);
          break $l$block_0;
        }
        // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedLong.<anonymous>' call
        var $this$decodeMapKey = toString(value);
        var tmp0_elvis_lhs = toDoubleOrNull($this$decodeMapKey);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          throwIllegalKeyType(this, tag, $this$decodeMapKey, 'long');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        var tmp0_elvis_lhs_0 = this.b1i(tmp);
        var tmp_0;
        if (tmp0_elvis_lhs_0 == null) {
          throwIllegalKeyType(this, tag, value, 'long');
        } else {
          tmp_0 = tmp0_elvis_lhs_0;
        }
        tmp$ret$1 = tmp_0;
        break $l$block_0;
      }
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedLong.<anonymous>' call
      tmp$ret$1 = protoOf(DynamicInput).i1f.call(this, tag);
    }
    return tmp$ret$1;
  };
  protoOf(DynamicMapInput).j14 = function (tag) {
    return this.i1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicMapInput).j1f = function (tag) {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeMapKey' call
      if (_get_isKey__g0dpic(this)) {
        var value = this.c1i(tag);
        if (!(typeof value === 'string')) {
          // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedFloat.<anonymous>' call
          tmp$ret$1 = protoOf(DynamicInput).k14.call(this, tag);
          break $l$block_0;
        }
        // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedFloat.<anonymous>' call
        // Inline function 'kotlin.text.toFloatOrNull' call
        var this_0 = toString(value);
        // Inline function 'kotlin.js.unsafeCast' call
        // Inline function 'kotlin.js.asDynamic' call
        var tmp0_elvis_lhs = toDoubleOrNull(this_0);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          throwIllegalKeyType(this, tag, value, 'float');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block_0;
      }
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedFloat.<anonymous>' call
      tmp$ret$1 = protoOf(DynamicInput).k14.call(this, tag);
    }
    return tmp$ret$1;
  };
  protoOf(DynamicMapInput).k14 = function (tag) {
    return this.j1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicMapInput).k1f = function (tag) {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeMapKey' call
      if (_get_isKey__g0dpic(this)) {
        var value = this.c1i(tag);
        if (!(typeof value === 'string')) {
          // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedDouble.<anonymous>' call
          tmp$ret$1 = protoOf(DynamicInput).l14.call(this, tag);
          break $l$block_0;
        }
        // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedDouble.<anonymous>' call
        var $this$decodeMapKey = toString(value);
        var tmp0_elvis_lhs = toDoubleOrNull($this$decodeMapKey);
        var tmp;
        if (tmp0_elvis_lhs == null) {
          throwIllegalKeyType(this, tag, value, 'double');
        } else {
          tmp = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp;
        break $l$block_0;
      }
      // Inline function 'kotlinx.serialization.json.internal.DynamicMapInput.decodeTaggedDouble.<anonymous>' call
      tmp$ret$1 = protoOf(DynamicInput).l14.call(this, tag);
    }
    return tmp$ret$1;
  };
  protoOf(DynamicMapInput).l14 = function (tag) {
    return this.k1f((!(tag == null) ? typeof tag === 'string' : false) ? tag : THROW_CCE());
  };
  protoOf(DynamicMapInput).oq = function (descriptor) {
    while (this.e1j_1 < (this.d1j_1 - 1 | 0)) {
      var tmp1 = this.e1j_1;
      this.e1j_1 = tmp1 + 1 | 0;
      var i = tmp1 / 2 | 0;
      var tmp = this.w1h_1[i];
      var name = (!(tmp == null) ? typeof tmp === 'string' : false) ? tmp : THROW_CCE();
      if (this.u1h_1[name] !== undefined)
        return this.e1j_1;
    }
    return -1;
  };
  protoOf(DynamicMapInput).a1i = function (tag) {
    return (this.e1j_1 % 2 | 0) === 0 ? tag : this.u1h_1[tag];
  };
  function get_schemaCache(_this__u8e3s4) {
    return _this__u8e3s4.u16_1;
  }
  function JsonToStringWriter() {
    this.y16_1 = StringBuilder_init_$Create$_0(128);
  }
  protoOf(JsonToStringWriter).q1a = function (value) {
    this.y16_1.yb(value);
  };
  protoOf(JsonToStringWriter).k1a = function (char) {
    this.y16_1.v7(char);
  };
  protoOf(JsonToStringWriter).m1a = function (text) {
    this.y16_1.u7(text);
  };
  protoOf(JsonToStringWriter).w1a = function (text) {
    printQuoted(this.y16_1, text);
  };
  protoOf(JsonToStringWriter).z16 = function () {
    this.y16_1.bc();
  };
  protoOf(JsonToStringWriter).toString = function () {
    return this.y16_1.toString();
  };
  function createMapForCache(initialCapacity) {
    return HashMap_init_$Create$(initialCapacity);
  }
  //region block: post-declaration
  protoOf(defer$1).xn = get_isNullable;
  protoOf(defer$1).do = get_isInline;
  protoOf(defer$1).bo = get_annotations;
  protoOf(PolymorphismValidator).l16 = contextual;
  //endregion
  //region block: init
  Companion_instance = new Companion();
  Companion_instance_0 = new Companion_0();
  Companion_instance_1 = new Companion_1();
  Companion_instance_2 = new Companion_2();
  Tombstone_instance = new Tombstone();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Default_getInstance;
  _.$_$.b = Json_0;
  _.$_$.c = decodeFromDynamic;
  _.$_$.d = get_jsonObject;
  _.$_$.e = get_jsonPrimitive;
  //endregion
  return _;
}));

