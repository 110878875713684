(function (factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', 'react', './kotlin-kotlin-stdlib.js', './kotlin-js.js', './kotlinx-coroutines-core.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('react'), require('./kotlin-kotlin-stdlib.js'), require('./kotlin-js.js'), require('./kotlinx-coroutines-core.js'));
  else {
    if (typeof react === 'undefined') {
      throw new Error("Error loading module 'kotlin-react-core'. Its dependency 'react' was not found. Please, check whether 'react' is loaded prior to 'kotlin-react-core'.");
    }
    if (typeof globalThis['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'kotlin-react-core'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'kotlin-react-core'.");
    }
    if (typeof globalThis['kotlin-js'] === 'undefined') {
      throw new Error("Error loading module 'kotlin-react-core'. Its dependency 'kotlin-js' was not found. Please, check whether 'kotlin-js' is loaded prior to 'kotlin-react-core'.");
    }
    if (typeof globalThis['kotlinx-coroutines-core'] === 'undefined') {
      throw new Error("Error loading module 'kotlin-react-core'. Its dependency 'kotlinx-coroutines-core' was not found. Please, check whether 'kotlinx-coroutines-core' is loaded prior to 'kotlin-react-core'.");
    }
    globalThis['kotlin-react-core'] = factory(typeof globalThis['kotlin-react-core'] === 'undefined' ? {} : globalThis['kotlin-react-core'], react, globalThis['kotlin-kotlin-stdlib'], globalThis['kotlin-js'], globalThis['kotlinx-coroutines-core']);
  }
}(function (_, $module$react, kotlin_kotlin, kotlin_org_jetbrains_kotlin_wrappers_kotlin_js, kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core) {
  'use strict';
  //region block: imports
  var createElement = $module$react.createElement;
  var useEffect = $module$react.useEffect;
  var Unit_instance = kotlin_kotlin.$_$.v4;
  var VOID = kotlin_kotlin.$_$.g;
  var toString = kotlin_kotlin.$_$.n2;
  var IsolatedCoroutineScope = kotlin_org_jetbrains_kotlin_wrappers_kotlin_js.$_$.b;
  var CoroutineStart_UNDISPATCHED_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.a;
  var launch = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.e;
  //endregion
  //region block: pre-declaration
  //endregion
  function get_CHILD_ARRAY() {
    _init_properties_ChildrenBuilder_kt__gexuom();
    return CHILD_ARRAY;
  }
  var CHILD_ARRAY;
  function get_DEFAULT_KEY() {
    _init_properties_ChildrenBuilder_kt__gexuom();
    return DEFAULT_KEY;
  }
  var DEFAULT_KEY;
  function addChildNode(_this__u8e3s4, node) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    // Inline function 'react.childArray' call
    // Inline function 'kotlin.js.asDynamic' call
    if (!(_this__u8e3s4[get_CHILD_ARRAY()] == null)) {
      // Inline function 'react.childArray' call
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'kotlin.js.asDynamic' call
      _this__u8e3s4[get_CHILD_ARRAY()].push(node);
    } else {
      // Inline function 'kotlin.arrayOf' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      // Inline function 'react.childArray' call
      var value = [node];
      // Inline function 'kotlin.js.asDynamic' call
      _this__u8e3s4[get_CHILD_ARRAY()] = value;
    }
  }
  function addChild(_this__u8e3s4, type, props) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    addChildElement(_this__u8e3s4, type, props, VOID, getDefaultKey(_this__u8e3s4));
  }
  function addChild_0(_this__u8e3s4, type) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    addChildElement(_this__u8e3s4, type, VOID, VOID, getDefaultKey(_this__u8e3s4));
  }
  function addChild_1(_this__u8e3s4, type, block) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    var defaultKey = getDefaultKey(_this__u8e3s4);
    // Inline function 'js.objects.jso' call
    // Inline function 'js.objects.jso' call
    // Inline function 'kotlin.apply' call
    var this_0 = {};
    block(this_0);
    var props = this_0;
    addChildElement(_this__u8e3s4, type, props, getChildArray(props), defaultKey);
  }
  function addChild_2(_this__u8e3s4, provider, value, block) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    addChild_1(_this__u8e3s4, provider, addChild$lambda(value, block));
  }
  function addChildElement(_this__u8e3s4, type, props, children, defaultKey) {
    props = props === VOID ? null : props;
    children = children === VOID ? null : children;
    _init_properties_ChildrenBuilder_kt__gexuom();
    var childProps_0 = childProps(props, defaultKey);
    var tmp;
    if (!(children == null)) {
      tmp = createElement.apply(null, [type, childProps_0].concat([].slice.call(children.slice())));
    } else {
      tmp = createElement(type, childProps_0);
    }
    var element = tmp;
    addChildNode(_this__u8e3s4, element);
  }
  function getDefaultKey(_this__u8e3s4) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    // Inline function 'kotlin.js.asDynamic' call
    var key = _this__u8e3s4[get_DEFAULT_KEY()];
    Reflect.deleteProperty(_this__u8e3s4, get_DEFAULT_KEY());
    return key;
  }
  function getChildArray(_this__u8e3s4) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4[get_CHILD_ARRAY()];
  }
  function childProps(props, defaultKey) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    if (defaultKey == null)
      return props;
    if (props == null) {
      // Inline function 'js.objects.jso' call
      // Inline function 'js.objects.jso' call
      // Inline function 'kotlin.apply' call
      var this_0 = {};
      // Inline function 'react.childProps.<anonymous>' call
      this_0.key = defaultKey;
      return this_0;
    }
    if (!(props.key == null))
      return props;
    // Inline function 'js.objects.jso' call
    // Inline function 'js.objects.jso' call
    // Inline function 'kotlin.apply' call
    var this_1 = {};
    // Inline function 'react.childProps.<anonymous>' call
    // Inline function 'react.Props.unaryPlus' call
    Object.assign(this_1, props);
    this_1.key = defaultKey;
    return this_1;
  }
  function getChildArray_0(_this__u8e3s4) {
    _init_properties_ChildrenBuilder_kt__gexuom();
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4[get_CHILD_ARRAY()];
  }
  function addChild$lambda($value, $block) {
    return function ($this$addChild) {
      $this$addChild.value = $value;
      $block($this$addChild);
      return Unit_instance;
    };
  }
  var properties_initialized_ChildrenBuilder_kt_gby2z0;
  function _init_properties_ChildrenBuilder_kt__gexuom() {
    if (!properties_initialized_ChildrenBuilder_kt_gby2z0) {
      properties_initialized_ChildrenBuilder_kt_gby2z0 = true;
      CHILD_ARRAY = Symbol('@@child-array');
      DEFAULT_KEY = Symbol('@@default-key');
    }
  }
  function copy(_this__u8e3s4, default_pjqibl) {
    default_pjqibl = default_pjqibl === VOID ? VOID : default_pjqibl;
    return Object.assign({}, _this__u8e3s4, {'default': default_pjqibl});
  }
  function invoke(_this__u8e3s4, default_pjqibl) {
    return {'default': default_pjqibl};
  }
  function copy_0(_this__u8e3s4, componentStack, digest) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    digest = digest === VOID ? VOID : digest;
    return Object.assign({}, _this__u8e3s4, {componentStack: componentStack, digest: digest});
  }
  function invoke_0(_this__u8e3s4, componentStack, digest) {
    componentStack = componentStack === VOID ? VOID : componentStack;
    digest = digest === VOID ? VOID : digest;
    return {componentStack: componentStack, digest: digest};
  }
  function ReactNode(source) {
    // Inline function 'react.ReactNode' call
    // Inline function 'js.reflect.unsafeCast' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return toString(source);
  }
  function buildCleanup(cleanups) {
    // Inline function 'kotlin.collections.isEmpty' call
    if (cleanups.length === 0)
      return undefined;
    return buildCleanup$lambda(cleanups);
  }
  function buildCleanup$lambda($cleanups) {
    return function () {
      var indexedObject = $cleanups;
      var inductionVariable = 0;
      var last = indexedObject.length;
      while (inductionVariable < last) {
        var cleanup = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        cleanup();
      }
      return Unit_instance;
    };
  }
  function buildCleanupCallback(block) {
    return buildCleanupCallback$lambda(block);
  }
  function buildCleanupCallback$lambda($block) {
    return function () {
      // Inline function 'kotlin.arrayOf' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      var cleanups = [];
      // Inline function 'js.reflect.unsafeCast' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      $block(cleanups);
      return buildCleanup(cleanups);
    };
  }
  function createCleanupCallback(block) {
    return createCleanupCallback$lambda(block);
  }
  function createCleanupCallback$lambda$lambda($job) {
    return function () {
      $job.z1t();
      return Unit_instance;
    };
  }
  function createCleanupCallback$lambda($block) {
    return function () {
      var job = isolatedJob($block);
      return createCleanupCallback$lambda$lambda(job);
    };
  }
  function isolatedJob(block) {
    return launch(IsolatedCoroutineScope(), VOID, CoroutineStart_UNDISPATCHED_getInstance(), block);
  }
  function useEffectOnce(effect) {
    var callback = createCleanupCallback(effect);
    // Inline function 'kotlin.emptyArray' call
    var tmp$ret$0 = [];
    useEffect(callback, tmp$ret$0);
  }
  function useEffect_0(dependencies, effect) {
    var callback = createCleanupCallback(effect);
    useEffect(callback, dependencies);
  }
  function useEffectOnceWithCleanup(effect) {
    var callback = buildCleanupCallback(effect);
    // Inline function 'kotlin.emptyArray' call
    var tmp$ret$0 = [];
    useEffect(callback, tmp$ret$0);
  }
  function useEffectWithCleanup(effect) {
    var callback = buildCleanupCallback(effect);
    useEffect(callback);
  }
  function useEffectWithCleanup_0(dependencies, effect) {
    var callback = buildCleanupCallback(effect);
    useEffect(callback, dependencies);
  }
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = addChildNode;
  _.$_$.b = addChild_1;
  _.$_$.c = getChildArray_0;
  _.$_$.d = useEffectOnceWithCleanup;
  _.$_$.e = useEffectOnce;
  _.$_$.f = useEffectWithCleanup;
  _.$_$.g = useEffectWithCleanup_0;
  _.$_$.h = useEffect_0;
  //endregion
  return _;
}));

