(function (factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', '@js-joda/core', './kotlin-kotlin-stdlib.js', './kotlinx-serialization-kotlinx-serialization-core.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('@js-joda/core'), require('./kotlin-kotlin-stdlib.js'), require('./kotlinx-serialization-kotlinx-serialization-core.js'));
  else {
    if (typeof globalThis['@js-joda/core'] === 'undefined') {
      throw new Error("Error loading module 'Kotlin-DateTime-library-kotlinx-datetime-js-ir'. Its dependency '@js-joda/core' was not found. Please, check whether '@js-joda/core' is loaded prior to 'Kotlin-DateTime-library-kotlinx-datetime-js-ir'.");
    }
    if (typeof globalThis['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'Kotlin-DateTime-library-kotlinx-datetime-js-ir'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'Kotlin-DateTime-library-kotlinx-datetime-js-ir'.");
    }
    if (typeof globalThis['kotlinx-serialization-kotlinx-serialization-core'] === 'undefined') {
      throw new Error("Error loading module 'Kotlin-DateTime-library-kotlinx-datetime-js-ir'. Its dependency 'kotlinx-serialization-kotlinx-serialization-core' was not found. Please, check whether 'kotlinx-serialization-kotlinx-serialization-core' is loaded prior to 'Kotlin-DateTime-library-kotlinx-datetime-js-ir'.");
    }
    globalThis['Kotlin-DateTime-library-kotlinx-datetime-js-ir'] = factory(typeof globalThis['Kotlin-DateTime-library-kotlinx-datetime-js-ir'] === 'undefined' ? {} : globalThis['Kotlin-DateTime-library-kotlinx-datetime-js-ir'], globalThis['@js-joda/core'], globalThis['kotlin-kotlin-stdlib'], globalThis['kotlinx-serialization-kotlinx-serialization-core']);
  }
}(function (_, $module$_js_joda_core_gcv2k, kotlin_kotlin, kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core) {
  'use strict';
  //region block: imports
  var Instant = $module$_js_joda_core_gcv2k.Instant;
  var Clock = $module$_js_joda_core_gcv2k.Clock;
  var OffsetDateTime = $module$_js_joda_core_gcv2k.OffsetDateTime;
  var Duration = $module$_js_joda_core_gcv2k.Duration;
  var protoOf = kotlin_kotlin.$_$.ab;
  var initMetadataForObject = kotlin_kotlin.$_$.fa;
  var IllegalArgumentException_init_$Init$ = kotlin_kotlin.$_$.o1;
  var objectCreate = kotlin_kotlin.$_$.za;
  var captureStack = kotlin_kotlin.$_$.k9;
  var IllegalArgumentException_init_$Init$_0 = kotlin_kotlin.$_$.n1;
  var IllegalArgumentException = kotlin_kotlin.$_$.td;
  var initMetadataForClass = kotlin_kotlin.$_$.aa;
  var STRING_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.f;
  var PrimitiveSerialDescriptor = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.c1;
  var THROW_CCE = kotlin_kotlin.$_$.yd;
  var KSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.i2;
  var VOID = kotlin_kotlin.$_$.g;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.k2;
  var indexOf = kotlin_kotlin.$_$.zb;
  var charSequenceLength = kotlin_kotlin.$_$.o9;
  var charSequenceGet = kotlin_kotlin.$_$.n9;
  var Long = kotlin_kotlin.$_$.ud;
  var initMetadataForCompanion = kotlin_kotlin.$_$.ba;
  var _Duration___get_inWholeSeconds__impl__hpy7b3 = kotlin_kotlin.$_$.e2;
  var _Duration___get_nanosecondsComponent__impl__nh19kq = kotlin_kotlin.$_$.f2;
  var numberToDouble = kotlin_kotlin.$_$.wa;
  var Duration__unaryMinus_impl_x2k1y0 = kotlin_kotlin.$_$.j2;
  var Companion_getInstance = kotlin_kotlin.$_$.n4;
  var DurationUnit_SECONDS_getInstance = kotlin_kotlin.$_$.m;
  var toDuration = kotlin_kotlin.$_$.jd;
  var DurationUnit_NANOSECONDS_getInstance = kotlin_kotlin.$_$.l;
  var Duration__plus_impl_yu9v8f = kotlin_kotlin.$_$.g2;
  var numberToInt = kotlin_kotlin.$_$.xa;
  var equals = kotlin_kotlin.$_$.s9;
  var Comparable = kotlin_kotlin.$_$.md;
  //endregion
  //region block: pre-declaration
  initMetadataForObject(System, 'System');
  initMetadataForClass(DateTimeFormatException, 'DateTimeFormatException', DateTimeFormatException_init_$Create$, IllegalArgumentException);
  initMetadataForObject(InstantIso8601Serializer, 'InstantIso8601Serializer', VOID, VOID, [KSerializer]);
  initMetadataForCompanion(Companion);
  initMetadataForClass(Instant_0, 'Instant', VOID, VOID, [Comparable], VOID, VOID, {0: InstantIso8601Serializer_getInstance});
  //endregion
  function System() {
  }
  protoOf(System).f1j = function () {
    return Companion_getInstance_0().f1j();
  };
  var System_instance;
  function System_getInstance() {
    return System_instance;
  }
  function DateTimeFormatException_init_$Init$($this) {
    IllegalArgumentException_init_$Init$($this);
    DateTimeFormatException.call($this);
    return $this;
  }
  function DateTimeFormatException_init_$Create$() {
    var tmp = DateTimeFormatException_init_$Init$(objectCreate(protoOf(DateTimeFormatException)));
    captureStack(tmp, DateTimeFormatException_init_$Create$);
    return tmp;
  }
  function DateTimeFormatException_init_$Init$_0(cause, $this) {
    IllegalArgumentException_init_$Init$_0(cause, $this);
    DateTimeFormatException.call($this);
    return $this;
  }
  function DateTimeFormatException_init_$Create$_0(cause) {
    var tmp = DateTimeFormatException_init_$Init$_0(cause, objectCreate(protoOf(DateTimeFormatException)));
    captureStack(tmp, DateTimeFormatException_init_$Create$_0);
    return tmp;
  }
  function DateTimeFormatException() {
    captureStack(this, DateTimeFormatException);
  }
  function InstantIso8601Serializer() {
    InstantIso8601Serializer_instance = this;
    this.k1j_1 = PrimitiveSerialDescriptor('Instant', STRING_getInstance());
  }
  protoOf(InstantIso8601Serializer).pm = function () {
    return this.k1j_1;
  };
  protoOf(InstantIso8601Serializer).rm = function (decoder) {
    return Companion_getInstance_0().l1j(decoder.sp());
  };
  protoOf(InstantIso8601Serializer).m1j = function (encoder, value) {
    encoder.br(value.toString());
  };
  protoOf(InstantIso8601Serializer).qm = function (encoder, value) {
    return this.m1j(encoder, value instanceof Instant_0 ? value : THROW_CCE());
  };
  var InstantIso8601Serializer_instance;
  function InstantIso8601Serializer_getInstance() {
    if (InstantIso8601Serializer_instance == null)
      new InstantIso8601Serializer();
    return InstantIso8601Serializer_instance;
  }
  function fixOffsetRepresentation($this, isoString) {
    var time = indexOf(isoString, _Char___init__impl__6a9atx(84), VOID, true);
    if (time === -1)
      return isoString;
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.text.indexOfLast' call
      var inductionVariable = charSequenceLength(isoString) - 1 | 0;
      if (0 <= inductionVariable)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          // Inline function 'kotlinx.datetime.Companion.fixOffsetRepresentation.<anonymous>' call
          var c = charSequenceGet(isoString, index);
          if (c === _Char___init__impl__6a9atx(43) || c === _Char___init__impl__6a9atx(45)) {
            tmp$ret$1 = index;
            break $l$block;
          }
        }
         while (0 <= inductionVariable);
      tmp$ret$1 = -1;
    }
    var offset = tmp$ret$1;
    if (offset < time)
      return isoString;
    var separator = indexOf(isoString, _Char___init__impl__6a9atx(58), offset);
    return !(separator === -1) ? isoString : isoString + ':00';
  }
  function Companion() {
    Companion_instance = this;
    this.g1j_1 = new Instant_0(Instant.ofEpochSecond(new Long(-931914497, -750), 999999999));
    this.h1j_1 = new Instant_0(Instant.ofEpochSecond(new Long(1151527680, 720), 0));
    this.i1j_1 = new Instant_0(Instant.MIN);
    this.j1j_1 = new Instant_0(Instant.MAX);
  }
  protoOf(Companion).f1j = function () {
    return new Instant_0(Clock.systemUTC().instant());
  };
  protoOf(Companion).l1j = function (isoString) {
    var tmp;
    try {
      tmp = new Instant_0(OffsetDateTime.parse(fixOffsetRepresentation(this, isoString)).toInstant());
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        if (isJodaDateTimeParseException(e))
          throw DateTimeFormatException_init_$Create$_0(e);
        throw e;
      } else {
        throw $p;
      }
    }
    return tmp;
  };
  var Companion_instance;
  function Companion_getInstance_0() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function Instant_0(value) {
    Companion_getInstance_0();
    this.n1j_1 = value;
  }
  protoOf(Instant_0).o1j = function (duration) {
    // Inline function 'kotlin.time.Duration.toComponents' call
    var tmp1 = _Duration___get_inWholeSeconds__impl__hpy7b3(duration);
    var nanoseconds = _Duration___get_nanosecondsComponent__impl__nh19kq(duration);
    var tmp;
    try {
      tmp = new Instant_0(this.p1j(tmp1.h3(), nanoseconds));
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        if (!isJodaDateTimeException(e))
          throw e;
        tmp_0 = tmp1.g1(new Long(0, 0)) > 0 ? Companion_getInstance_0().j1j_1 : Companion_getInstance_0().i1j_1;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(Instant_0).p1j = function (seconds, nanos) {
    var newSeconds = numberToDouble(this.n1j_1.epochSecond()) + seconds;
    var newNanos = numberToDouble(this.n1j_1.nano()) + nanos;
    return Instant.ofEpochSecond(newSeconds, newNanos);
  };
  protoOf(Instant_0).q1j = function (duration) {
    return this.o1j(Duration__unaryMinus_impl_x2k1y0(duration));
  };
  protoOf(Instant_0).r1j = function (other) {
    var diff = Duration.between(other.n1j_1, this.n1j_1);
    Companion_getInstance();
    // Inline function 'kotlin.time.Companion.seconds' call
    var this_0 = numberToDouble(diff.seconds());
    var tmp = toDuration(this_0, DurationUnit_SECONDS_getInstance());
    Companion_getInstance();
    // Inline function 'kotlin.time.Companion.nanoseconds' call
    var this_1 = numberToDouble(diff.nano());
    var tmp$ret$1 = toDuration(this_1, DurationUnit_NANOSECONDS_getInstance());
    return Duration__plus_impl_yu9v8f(tmp, tmp$ret$1);
  };
  protoOf(Instant_0).s1j = function (other) {
    return numberToInt(this.n1j_1.compareTo(other.n1j_1));
  };
  protoOf(Instant_0).d = function (other) {
    return this.s1j(other instanceof Instant_0 ? other : THROW_CCE());
  };
  protoOf(Instant_0).equals = function (other) {
    var tmp;
    if (this === other) {
      tmp = true;
    } else {
      var tmp_0;
      if (other instanceof Instant_0) {
        tmp_0 = equals(this.n1j_1, other.n1j_1);
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(Instant_0).hashCode = function () {
    return numberToInt(this.n1j_1.hashCode());
  };
  protoOf(Instant_0).toString = function () {
    return this.n1j_1.toString();
  };
  function isJodaDateTimeException(_this__u8e3s4) {
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4.name == 'DateTimeException';
  }
  function isJodaDateTimeParseException(_this__u8e3s4) {
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4.name == 'DateTimeParseException';
  }
  //region block: init
  System_instance = new System();
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = InstantIso8601Serializer_getInstance;
  _.$_$.b = System_instance;
  _.$_$.c = Companion_getInstance_0;
  _.$_$.d = Instant_0;
  //endregion
  return _;
}));

