(function (factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof globalThis['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'kotlin-web'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'kotlin-web'.");
    }
    globalThis['kotlin-web'] = factory(typeof globalThis['kotlin-web'] === 'undefined' ? {} : globalThis['kotlin-web'], globalThis['kotlin-kotlin-stdlib']);
  }
}(function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var VOID = kotlin_kotlin.$_$.g;
  var protoOf = kotlin_kotlin.$_$.ab;
  var initMetadataForClass = kotlin_kotlin.$_$.aa;
  var Unit_instance = kotlin_kotlin.$_$.v4;
  var DurationUnit_MILLISECONDS_getInstance = kotlin_kotlin.$_$.k;
  var Duration__toInt_impl_nnev71 = kotlin_kotlin.$_$.h2;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.q;
  var sequenceOf = kotlin_kotlin.$_$.sb;
  //endregion
  //region block: pre-declaration
  initMetadataForClass(EventInstance, 'EventInstance');
  //endregion
  function copy(_this__u8e3s4, signal) {
    signal = signal === VOID ? VOID : signal;
    return Object.assign({}, _this__u8e3s4, {signal: signal});
  }
  function copy_0(_this__u8e3s4, endings, type) {
    endings = endings === VOID ? VOID : endings;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {endings: endings, type: type});
  }
  function invoke(_this__u8e3s4, endings, type) {
    endings = endings === VOID ? VOID : endings;
    type = type === VOID ? VOID : type;
    return {endings: endings, type: type};
  }
  function copy_1(_this__u8e3s4, capture, signal, once, signal_0, passive) {
    capture = capture === VOID ? VOID : capture;
    signal = signal === VOID ? VOID : signal;
    once = once === VOID ? VOID : once;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    passive = passive === VOID ? VOID : passive;
    return Object.assign({}, _this__u8e3s4, {capture: capture, signal: signal_0, once: once, signal: signal_0, passive: passive});
  }
  function invoke_0(_this__u8e3s4, capture, signal, once, signal_0, passive) {
    capture = capture === VOID ? VOID : capture;
    signal = signal === VOID ? VOID : signal;
    once = once === VOID ? VOID : once;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    passive = passive === VOID ? VOID : passive;
    return {capture: capture, signal: signal_0, once: once, signal: signal_0, passive: passive};
  }
  function copy_2(_this__u8e3s4, bubbles, cancelable, composed) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed});
  }
  function invoke_1(_this__u8e3s4, bubbles, cancelable, composed) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed};
  }
  function EventInstance(target, type) {
    this.f23_1 = target;
    this.g23_1 = type;
  }
  function copy_3(_this__u8e3s4, capture) {
    capture = capture === VOID ? VOID : capture;
    return Object.assign({}, _this__u8e3s4, {capture: capture});
  }
  function invoke_2(_this__u8e3s4, capture) {
    capture = capture === VOID ? VOID : capture;
    return {capture: capture};
  }
  function addEventListener(_this__u8e3s4, type, handler, options) {
    options = options === VOID ? undefined : options;
    _this__u8e3s4.addEventListener(type, handler, options);
  }
  function removeEventListener(_this__u8e3s4, type, handler, options) {
    options = options === VOID ? undefined : options;
    _this__u8e3s4.removeEventListener(type, handler, options);
  }
  function addEventHandler(_this__u8e3s4, type, handler) {
    return addEventHandler_0(_this__u8e3s4, type, undefined, handler);
  }
  function addEventHandler_0(_this__u8e3s4, type, options, handler) {
    _this__u8e3s4.addEventListener(type, handler, options);
    return addEventHandler$lambda(_this__u8e3s4, type, handler, options);
  }
  function addEventHandler$lambda($this_addEventHandler, $type, $handler, $options) {
    return function () {
      $this_addEventHandler.removeEventListener($type, $handler, $options);
      return Unit_instance;
    };
  }
  function copy_4(_this__u8e3s4, bubbles, cancelable, composed, total, loaded, lengthComputable) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    total = total === VOID ? VOID : total;
    loaded = loaded === VOID ? VOID : loaded;
    lengthComputable = lengthComputable === VOID ? VOID : lengthComputable;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, total: total, loaded: loaded, lengthComputable: lengthComputable});
  }
  function invoke_3(_this__u8e3s4, bubbles, cancelable, composed, total, loaded, lengthComputable) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    total = total === VOID ? VOID : total;
    loaded = loaded === VOID ? VOID : loaded;
    lengthComputable = lengthComputable === VOID ? VOID : lengthComputable;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, total: total, loaded: loaded, lengthComputable: lengthComputable};
  }
  function copy_5(_this__u8e3s4, endings, type, lastModified) {
    endings = endings === VOID ? VOID : endings;
    type = type === VOID ? VOID : type;
    lastModified = lastModified === VOID ? VOID : lastModified;
    return Object.assign({}, _this__u8e3s4, {endings: endings, type: type, lastModified: lastModified});
  }
  function invoke_4(_this__u8e3s4, endings, type, lastModified) {
    endings = endings === VOID ? VOID : endings;
    type = type === VOID ? VOID : type;
    lastModified = lastModified === VOID ? VOID : lastModified;
    return {endings: endings, type: type, lastModified: lastModified};
  }
  function copy_6(_this__u8e3s4, signal, referrer, signal_0, body, redirect, cache, method, integrity, mode, credentials, keepalive, priority, headers, referrerPolicy, window_0) {
    signal = signal === VOID ? VOID : signal;
    referrer = referrer === VOID ? VOID : referrer;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    body = body === VOID ? VOID : body;
    redirect = redirect === VOID ? VOID : redirect;
    cache = cache === VOID ? VOID : cache;
    method = method === VOID ? VOID : method;
    integrity = integrity === VOID ? VOID : integrity;
    mode = mode === VOID ? VOID : mode;
    credentials = credentials === VOID ? VOID : credentials;
    keepalive = keepalive === VOID ? VOID : keepalive;
    priority = priority === VOID ? VOID : priority;
    headers = headers === VOID ? VOID : headers;
    referrerPolicy = referrerPolicy === VOID ? VOID : referrerPolicy;
    window_0 = window_0 === VOID ? VOID : window_0;
    return Object.assign({}, _this__u8e3s4, {signal: signal_0, referrer: referrer, signal: signal_0, body: body, redirect: redirect, cache: cache, method: method, integrity: integrity, mode: mode, credentials: credentials, keepalive: keepalive, priority: priority, headers: headers, referrerPolicy: referrerPolicy, window: window_0});
  }
  function invoke_5(_this__u8e3s4, signal, referrer, signal_0, body, redirect, cache, method, integrity, mode, credentials, keepalive, priority, headers, referrerPolicy, window_0) {
    signal = signal === VOID ? VOID : signal;
    referrer = referrer === VOID ? VOID : referrer;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    body = body === VOID ? VOID : body;
    redirect = redirect === VOID ? VOID : redirect;
    cache = cache === VOID ? VOID : cache;
    method = method === VOID ? VOID : method;
    integrity = integrity === VOID ? VOID : integrity;
    mode = mode === VOID ? VOID : mode;
    credentials = credentials === VOID ? VOID : credentials;
    keepalive = keepalive === VOID ? VOID : keepalive;
    priority = priority === VOID ? VOID : priority;
    headers = headers === VOID ? VOID : headers;
    referrerPolicy = referrerPolicy === VOID ? VOID : referrerPolicy;
    window_0 = window_0 === VOID ? VOID : window_0;
    return {signal: signal_0, referrer: referrer, signal: signal_0, body: body, redirect: redirect, cache: cache, method: method, integrity: integrity, mode: mode, credentials: credentials, keepalive: keepalive, priority: priority, headers: headers, referrerPolicy: referrerPolicy, window: window_0};
  }
  function copy_7(_this__u8e3s4, status, headers, statusText) {
    status = status === VOID ? VOID : status;
    headers = headers === VOID ? VOID : headers;
    statusText = statusText === VOID ? VOID : statusText;
    return Object.assign({}, _this__u8e3s4, {status: status, headers: headers, statusText: statusText});
  }
  function invoke_6(_this__u8e3s4, status, headers, statusText) {
    status = status === VOID ? VOID : status;
    headers = headers === VOID ? VOID : headers;
    statusText = statusText === VOID ? VOID : statusText;
    return {status: status, headers: headers, statusText: statusText};
  }
  function copy_8(_this__u8e3s4, bubbles, cancelable, composed, lastEventId, data, source, origin, ports) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    lastEventId = lastEventId === VOID ? VOID : lastEventId;
    data = data === VOID ? VOID : data;
    source = source === VOID ? VOID : source;
    origin = origin === VOID ? VOID : origin;
    ports = ports === VOID ? VOID : ports;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, lastEventId: lastEventId, data: data, source: source, origin: origin, ports: ports});
  }
  function invoke_7(_this__u8e3s4, bubbles, cancelable, composed, lastEventId, data, source, origin, ports) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    lastEventId = lastEventId === VOID ? VOID : lastEventId;
    data = data === VOID ? VOID : data;
    source = source === VOID ? VOID : source;
    origin = origin === VOID ? VOID : origin;
    ports = ports === VOID ? VOID : ports;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, lastEventId: lastEventId, data: data, source: source, origin: origin, ports: ports};
  }
  function copy_9(_this__u8e3s4, bubbles, cancelable, composed, promise, reason) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    promise = promise === VOID ? VOID : promise;
    reason = reason === VOID ? VOID : reason;
    return Object.assign({}, _this__u8e3s4, {bubbles: bubbles, cancelable: cancelable, composed: composed, promise: promise, reason: reason});
  }
  function invoke_8(_this__u8e3s4, bubbles, cancelable, composed, promise, reason) {
    bubbles = bubbles === VOID ? VOID : bubbles;
    cancelable = cancelable === VOID ? VOID : cancelable;
    composed = composed === VOID ? VOID : composed;
    reason = reason === VOID ? VOID : reason;
    return {bubbles: bubbles, cancelable: cancelable, composed: composed, promise: promise, reason: reason};
  }
  function copy_10(_this__u8e3s4, transfer) {
    transfer = transfer === VOID ? VOID : transfer;
    return Object.assign({}, _this__u8e3s4, {transfer: transfer});
  }
  function invoke_9(_this__u8e3s4, transfer) {
    transfer = transfer === VOID ? VOID : transfer;
    return {transfer: transfer};
  }
  function copy_11(_this__u8e3s4, mode) {
    mode = mode === VOID ? VOID : mode;
    return Object.assign({}, _this__u8e3s4, {mode: mode});
  }
  function invoke_10(_this__u8e3s4, mode) {
    mode = mode === VOID ? VOID : mode;
    return {mode: mode};
  }
  function copy_12(_this__u8e3s4, readable, writable) {
    readable = readable === VOID ? VOID : readable;
    writable = writable === VOID ? VOID : writable;
    return Object.assign({}, _this__u8e3s4, {readable: readable, writable: writable});
  }
  function invoke_11(_this__u8e3s4, readable, writable) {
    return {readable: readable, writable: writable};
  }
  function copy_13(_this__u8e3s4, signal, signal_0, preventCancel, preventClose, preventAbort) {
    signal = signal === VOID ? VOID : signal;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    preventCancel = preventCancel === VOID ? VOID : preventCancel;
    preventClose = preventClose === VOID ? VOID : preventClose;
    preventAbort = preventAbort === VOID ? VOID : preventAbort;
    return Object.assign({}, _this__u8e3s4, {signal: signal_0, signal: signal_0, preventCancel: preventCancel, preventClose: preventClose, preventAbort: preventAbort});
  }
  function invoke_12(_this__u8e3s4, signal, signal_0, preventCancel, preventClose, preventAbort) {
    signal = signal === VOID ? VOID : signal;
    signal_0 = signal_0 === VOID ? VOID : signal_0;
    preventCancel = preventCancel === VOID ? VOID : preventCancel;
    preventClose = preventClose === VOID ? VOID : preventClose;
    preventAbort = preventAbort === VOID ? VOID : preventAbort;
    return {signal: signal_0, signal: signal_0, preventCancel: preventCancel, preventClose: preventClose, preventAbort: preventAbort};
  }
  function copy_14(_this__u8e3s4, start, write, close, type, abort) {
    start = start === VOID ? VOID : start;
    write = write === VOID ? VOID : write;
    close = close === VOID ? VOID : close;
    type = type === VOID ? VOID : type;
    abort = abort === VOID ? VOID : abort;
    return Object.assign({}, _this__u8e3s4, {start: start, write: write, close: close, type: type, abort: abort});
  }
  function invoke_13(_this__u8e3s4, start, write, close, type, abort) {
    start = start === VOID ? VOID : start;
    write = write === VOID ? VOID : write;
    close = close === VOID ? VOID : close;
    type = type === VOID ? VOID : type;
    abort = abort === VOID ? VOID : abort;
    return {start: start, write: write, close: close, type: type, abort: abort};
  }
  function copy_15(_this__u8e3s4, start, autoAllocateChunkSize, pull, cancel, type) {
    start = start === VOID ? VOID : start;
    autoAllocateChunkSize = autoAllocateChunkSize === VOID ? VOID : autoAllocateChunkSize;
    pull = pull === VOID ? VOID : pull;
    cancel = cancel === VOID ? VOID : cancel;
    type = type === VOID ? VOID : type;
    return Object.assign({}, _this__u8e3s4, {start: start, autoAllocateChunkSize: autoAllocateChunkSize, pull: pull, cancel: cancel, type: type});
  }
  function invoke_14(_this__u8e3s4, start, autoAllocateChunkSize, pull, cancel, type) {
    start = start === VOID ? VOID : start;
    autoAllocateChunkSize = autoAllocateChunkSize === VOID ? VOID : autoAllocateChunkSize;
    pull = pull === VOID ? VOID : pull;
    cancel = cancel === VOID ? VOID : cancel;
    type = type === VOID ? VOID : type;
    return {start: start, autoAllocateChunkSize: autoAllocateChunkSize, pull: pull, cancel: cancel, type: type};
  }
  function setTimeout_0(timeout, handler) {
    return setTimeout(handler, Duration__toInt_impl_nnev71(timeout, DurationUnit_MILLISECONDS_getInstance()));
  }
  function safeAny(signal1, signal2) {
    if (signal1 == null)
      return signal2;
    if (signal1.aborted)
      return signal1;
    if (signal2.aborted)
      return signal2;
    var controller = new AbortController();
    // Inline function 'kotlin.collections.mutableListOf' call
    var handlers = ArrayList_init_$Create$();
    // Inline function 'web.events.EventHandler' call
    // Inline function 'js.reflect.unsafeCast' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    var abortHandler = safeAny$lambda(handlers, controller);
    // Inline function 'kotlin.sequences.mapTo' call
    var _iterator__ex2g4s = sequenceOf([signal1, signal2]).k();
    while (_iterator__ex2g4s.l()) {
      var item = _iterator__ex2g4s.m();
      // Inline function 'web.abort.internal.safeAny.<anonymous>' call
      // Inline function 'web.events.Event.Companion.ABORT' call
      var tmp$ret$6 = addEventHandler(item, 'abort', abortHandler);
      handlers.e(tmp$ret$6);
    }
    return controller.signal;
  }
  function safeAny$lambda($handlers, $controller) {
    return function (event) {
      var _iterator__ex2g4s = $handlers.k();
      while (_iterator__ex2g4s.l()) {
        var element = _iterator__ex2g4s.m();
        // Inline function 'web.abort.internal.safeAny.<anonymous>.<anonymous>' call
        element();
      }
      $controller.abort(event.currentTarget.reason);
      return Unit_instance;
    };
  }
  function patchAbortOptions(options, controller) {
    // Inline function 'web.abort.Abortable.Companion.invoke' call
    // Inline function 'web.abort.invoke' call
    var abortOptions = {signal: safeAny(options == null ? null : options.signal, controller.signal)};
    var tmp = Object;
    // Inline function 'js.objects.jso' call
    var tmp$ret$2 = {};
    return tmp.assign(tmp$ret$2, options, abortOptions);
  }
  function awaitPromiseLike$lambda($controller) {
    return function (it) {
      $controller.abort();
      return Unit_instance;
    };
  }
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = addEventListener;
  _.$_$.b = removeEventListener;
  _.$_$.c = setTimeout_0;
  //endregion
  return _;
}));

