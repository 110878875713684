(function (factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js', './kotlinx-coroutines-core.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'), require('./kotlinx-coroutines-core.js'));
  else {
    if (typeof globalThis['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'kotlin-js'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'kotlin-js'.");
    }
    if (typeof globalThis['kotlinx-coroutines-core'] === 'undefined') {
      throw new Error("Error loading module 'kotlin-js'. Its dependency 'kotlinx-coroutines-core' was not found. Please, check whether 'kotlinx-coroutines-core' is loaded prior to 'kotlin-js'.");
    }
    globalThis['kotlin-js'] = factory(typeof globalThis['kotlin-js'] === 'undefined' ? {} : globalThis['kotlin-js'], globalThis['kotlin-kotlin-stdlib'], globalThis['kotlinx-coroutines-core']);
  }
}(function (_, kotlin_kotlin, kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core) {
  'use strict';
  //region block: imports
  var constructor = (class IteratorYieldResult {
    static [Symbol.hasInstance](instance) {
        return instance && (typeof instance === 'object') && (instance.done === false)
    }
    }.prototype).constructor;
  var VOID = kotlin_kotlin.$_$.g;
  var AbstractList = kotlin_kotlin.$_$.w4;
  var protoOf = kotlin_kotlin.$_$.ab;
  var get_lastIndex = kotlin_kotlin.$_$.w6;
  var IndexOutOfBoundsException_init_$Create$ = kotlin_kotlin.$_$.w1;
  var initMetadataForClass = kotlin_kotlin.$_$.aa;
  var EmptyCoroutineContext_getInstance = kotlin_kotlin.$_$.d4;
  var CoroutineScope = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.c;
  var iterator = kotlin_kotlin.$_$.rb;
  var CoroutineImpl = kotlin_kotlin.$_$.a9;
  var Unit_instance = kotlin_kotlin.$_$.v4;
  var THROW_CCE = kotlin_kotlin.$_$.yd;
  var SequenceScope = kotlin_kotlin.$_$.qb;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.l8;
  var initMetadataForLambda = kotlin_kotlin.$_$.ea;
  var intercepted = kotlin_kotlin.$_$.n8;
  var CancellableContinuationImpl = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.b;
  var resume = kotlin_kotlin.$_$.c9;
  var resumeWithException = kotlin_kotlin.$_$.b9;
  //endregion
  //region block: pre-declaration
  initMetadataForClass(asList$1, VOID, VOID, AbstractList);
  initMetadataForClass(AsyncIteratorAdapter, 'AsyncIteratorAdapter', VOID, VOID, VOID, [0]);
  initMetadataForLambda(iteratorFromJsIteratorLike$slambda, CoroutineImpl, VOID, [1]);
  //endregion
  function copy(_this__u8e3s4, hourCycle, second, timeZone, calendar, era, dateStyle, formatMatcher, day, localeMatcher, hour12, fractionalSecondDigits, month, hour, dayPeriod, weekday, minute, timeStyle, timeZoneName, year, numberingSystem) {
    hourCycle = hourCycle === VOID ? VOID : hourCycle;
    second = second === VOID ? VOID : second;
    timeZone = timeZone === VOID ? VOID : timeZone;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    dateStyle = dateStyle === VOID ? VOID : dateStyle;
    formatMatcher = formatMatcher === VOID ? VOID : formatMatcher;
    day = day === VOID ? VOID : day;
    localeMatcher = localeMatcher === VOID ? VOID : localeMatcher;
    hour12 = hour12 === VOID ? VOID : hour12;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    month = month === VOID ? VOID : month;
    hour = hour === VOID ? VOID : hour;
    dayPeriod = dayPeriod === VOID ? VOID : dayPeriod;
    weekday = weekday === VOID ? VOID : weekday;
    minute = minute === VOID ? VOID : minute;
    timeStyle = timeStyle === VOID ? VOID : timeStyle;
    timeZoneName = timeZoneName === VOID ? VOID : timeZoneName;
    year = year === VOID ? VOID : year;
    numberingSystem = numberingSystem === VOID ? VOID : numberingSystem;
    return Object.assign({}, _this__u8e3s4, {hourCycle: hourCycle, second: second, timeZone: timeZone, calendar: calendar, era: era, dateStyle: dateStyle, formatMatcher: formatMatcher, day: day, localeMatcher: localeMatcher, hour12: hour12, fractionalSecondDigits: fractionalSecondDigits, month: month, hour: hour, dayPeriod: dayPeriod, weekday: weekday, minute: minute, timeStyle: timeStyle, timeZoneName: timeZoneName, year: year, numberingSystem: numberingSystem});
  }
  function invoke(_this__u8e3s4, hourCycle, second, timeZone, calendar, era, dateStyle, formatMatcher, day, localeMatcher, hour12, fractionalSecondDigits, month, hour, dayPeriod, weekday, minute, timeStyle, timeZoneName, year, numberingSystem) {
    hourCycle = hourCycle === VOID ? VOID : hourCycle;
    second = second === VOID ? VOID : second;
    timeZone = timeZone === VOID ? VOID : timeZone;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    dateStyle = dateStyle === VOID ? VOID : dateStyle;
    formatMatcher = formatMatcher === VOID ? VOID : formatMatcher;
    day = day === VOID ? VOID : day;
    localeMatcher = localeMatcher === VOID ? VOID : localeMatcher;
    hour12 = hour12 === VOID ? VOID : hour12;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    month = month === VOID ? VOID : month;
    hour = hour === VOID ? VOID : hour;
    dayPeriod = dayPeriod === VOID ? VOID : dayPeriod;
    weekday = weekday === VOID ? VOID : weekday;
    minute = minute === VOID ? VOID : minute;
    timeStyle = timeStyle === VOID ? VOID : timeStyle;
    timeZoneName = timeZoneName === VOID ? VOID : timeZoneName;
    year = year === VOID ? VOID : year;
    numberingSystem = numberingSystem === VOID ? VOID : numberingSystem;
    return {hourCycle: hourCycle, second: second, timeZone: timeZone, calendar: calendar, era: era, dateStyle: dateStyle, formatMatcher: formatMatcher, day: day, localeMatcher: localeMatcher, hour12: hour12, fractionalSecondDigits: fractionalSecondDigits, month: month, hour: hour, dayPeriod: dayPeriod, weekday: weekday, minute: minute, timeStyle: timeStyle, timeZoneName: timeZoneName, year: year, numberingSystem: numberingSystem};
  }
  function asList(_this__u8e3s4) {
    return new asList$1(_this__u8e3s4);
  }
  function asList$1($this_asList) {
    this.n22_1 = $this_asList;
    AbstractList.call(this);
  }
  protoOf(asList$1).n = function () {
    return this.n22_1.length;
  };
  protoOf(asList$1).q = function (index) {
    var tmp;
    if (0 <= index ? index <= get_lastIndex(this) : false) {
      tmp = this.n22_1[index];
    } else {
      throw IndexOutOfBoundsException_init_$Create$('index ' + index + ' is not in range [0..' + get_lastIndex(this) + ']');
    }
    return tmp;
  };
  function copy_0(_this__u8e3s4, maxByteLength) {
    maxByteLength = maxByteLength === VOID ? VOID : maxByteLength;
    return Object.assign({}, _this__u8e3s4, {maxByteLength: maxByteLength});
  }
  function invoke_0(_this__u8e3s4, maxByteLength) {
    return {maxByteLength: maxByteLength};
  }
  function get_POW() {
    _init_properties_BigInt_kt__agomkx();
    return POW;
  }
  var POW;
  function get_n(_this__u8e3s4) {
    _init_properties_BigInt_kt__agomkx();
    return BigInt(_this__u8e3s4);
  }
  var properties_initialized_BigInt_kt_nhzooh;
  function _init_properties_BigInt_kt__agomkx() {
    if (!properties_initialized_BigInt_kt_nhzooh) {
      properties_initialized_BigInt_kt_nhzooh = true;
      POW = Function('base', 'exponent', 'return base ** exponent');
    }
  }
  function IsolatedCoroutineScope() {
    return CoroutineScope(EmptyCoroutineContext_getInstance());
  }
  function invoke_1(_this__u8e3s4, p1, p2) {
    // Inline function 'js.reflect.unsafeCast' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return _this__u8e3s4(p1, p2);
  }
  function copy_1(_this__u8e3s4, with_2685i) {
    with_2685i = with_2685i === VOID ? VOID : with_2685i;
    return Object.assign({}, _this__u8e3s4, {'with': with_2685i});
  }
  function invoke_2(_this__u8e3s4, with_2685i) {
    with_2685i = with_2685i === VOID ? VOID : with_2685i;
    return {'with': with_2685i};
  }
  function iteratorFromAsyncIterable(source) {
    // Inline function 'js.iterable.AsyncIterator.iterator' call
    var this_0 = source[Symbol.asyncIterator]();
    return iteratorFromAsyncIteratorLike(this_0);
  }
  function iteratorFromAsyncIteratorLike(source) {
    return new AsyncIteratorAdapter(source);
  }
  function AsyncIteratorAdapter(source) {
    this.o22_1 = source;
    this.p22_1 = null;
  }
  function iteratorFromJsIterable(source) {
    // Inline function 'js.iterable.JsIterator.iterator' call
    var this_0 = source[Symbol.iterator]();
    return iteratorFromJsIteratorLike(this_0);
  }
  function iteratorFromJsIteratorLike(source) {
    return iterator(iteratorFromJsIteratorLike$slambda_0(source, null));
  }
  function iteratorFromJsIteratorLike$slambda($source, resultContinuation) {
    this.y22_1 = $source;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(iteratorFromJsIteratorLike$slambda).d23 = function ($this$iterator, $completion) {
    var tmp = this.e23($this$iterator, $completion);
    tmp.i8_1 = Unit_instance;
    tmp.j8_1 = null;
    return tmp.o8();
  };
  protoOf(iteratorFromJsIteratorLike$slambda).d9 = function (p1, $completion) {
    return this.d23(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  };
  protoOf(iteratorFromJsIteratorLike$slambda).o8 = function () {
    var suspendResult = this.i8_1;
    $sm: do
      try {
        var tmp = this.g8_1;
        switch (tmp) {
          case 0:
            this.h8_1 = 5;
            this.g8_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            var tmp_1 = this.y22_1.next();
            tmp_0.a23_1 = tmp_1 instanceof constructor ? tmp_1 : null;
            if (this.a23_1 == null) {
              this.g8_1 = 4;
              var tmp_2 = this;
              continue $sm;
            } else {
              this.b23_1 = this.a23_1;
              this.g8_1 = 2;
              continue $sm;
            }

          case 2:
            this.c23_1 = this.b23_1;
            this.g8_1 = 3;
            suspendResult = this.z22_1.rf(this.c23_1.value, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            this.g8_1 = 1;
            continue $sm;
          case 4:
            return Unit_instance;
          case 5:
            throw this.j8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.h8_1 === 5) {
          throw e;
        } else {
          this.g8_1 = this.h8_1;
          this.j8_1 = e;
        }
      }
     while (true);
  };
  protoOf(iteratorFromJsIteratorLike$slambda).e23 = function ($this$iterator, completion) {
    var i = new iteratorFromJsIteratorLike$slambda(this.y22_1, completion);
    i.z22_1 = $this$iterator;
    return i;
  };
  function iteratorFromJsIteratorLike$slambda_0($source, resultContinuation) {
    var i = new iteratorFromJsIteratorLike$slambda($source, resultContinuation);
    var l = function ($this$iterator, $completion) {
      return i.d23($this$iterator, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function copy_2(_this__u8e3s4, enumerable, value, get, writable, set, configurable) {
    enumerable = enumerable === VOID ? VOID : enumerable;
    value = value === VOID ? VOID : value;
    get = get === VOID ? VOID : get;
    writable = writable === VOID ? VOID : writable;
    set = set === VOID ? VOID : set;
    configurable = configurable === VOID ? VOID : configurable;
    return Object.assign({}, _this__u8e3s4, {enumerable: enumerable, value: value, get: get, writable: writable, set: set, configurable: configurable});
  }
  function invoke_3(_this__u8e3s4, enumerable, value, get, writable, set, configurable) {
    enumerable = enumerable === VOID ? VOID : enumerable;
    value = value === VOID ? VOID : value;
    get = get === VOID ? VOID : get;
    writable = writable === VOID ? VOID : writable;
    set = set === VOID ? VOID : set;
    configurable = configurable === VOID ? VOID : configurable;
    return {enumerable: enumerable, value: value, get: get, writable: writable, set: set, configurable: configurable};
  }
  function awaitPromiseLike(promise, $completion) {
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var cancellable = new CancellableContinuationImpl(intercepted($completion), 1);
    cancellable.v1v();
    // Inline function 'js.promise.internal.awaitPromiseLike.stub_for_inlining' call
    thenToContinuation(promise, cancellable);
    return cancellable.f1w();
  }
  function thenToContinuation(promise, continuation) {
    var tmp = resume$ref(continuation);
    promise.then(tmp, resumeWithException$ref(continuation));
  }
  function resume$ref($boundThis) {
    var l = function (p0) {
      resume($boundThis, p0);
      return Unit_instance;
    };
    l.callableName = 'resume';
    return l;
  }
  function resumeWithException$ref($boundThis) {
    var l = function (p0) {
      resumeWithException($boundThis, p0);
      return Unit_instance;
    };
    l.callableName = 'resumeWithException';
    return l;
  }
  function copy_3(_this__u8e3s4, overflow) {
    overflow = overflow === VOID ? VOID : overflow;
    return Object.assign({}, _this__u8e3s4, {overflow: overflow});
  }
  function invoke_4(_this__u8e3s4, overflow) {
    overflow = overflow === VOID ? VOID : overflow;
    return {overflow: overflow};
  }
  function copy_4(_this__u8e3s4, overflow) {
    overflow = overflow === VOID ? VOID : overflow;
    return Object.assign({}, _this__u8e3s4, {overflow: overflow});
  }
  function invoke_5(_this__u8e3s4, overflow) {
    overflow = overflow === VOID ? VOID : overflow;
    return {overflow: overflow};
  }
  function copy_5(_this__u8e3s4, era, eraYear, year, month, monthCode, day) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    day = day === VOID ? VOID : day;
    return Object.assign({}, _this__u8e3s4, {era: era, eraYear: eraYear, year: year, month: month, monthCode: monthCode, day: day});
  }
  function invoke_6(_this__u8e3s4, era, eraYear, year, month, monthCode, day) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    return {era: era, eraYear: eraYear, year: year, month: month, monthCode: monthCode, day: day};
  }
  function copy_6(_this__u8e3s4, era, eraYear, year, month, monthCode) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    return Object.assign({}, _this__u8e3s4, {era: era, eraYear: eraYear, year: year, month: month, monthCode: monthCode});
  }
  function invoke_7(_this__u8e3s4, era, eraYear, year, month, monthCode) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    return {era: era, eraYear: eraYear, year: year, month: month, monthCode: monthCode};
  }
  function copy_7(_this__u8e3s4, era, eraYear, year, month, monthCode, day) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    day = day === VOID ? VOID : day;
    return Object.assign({}, _this__u8e3s4, {era: era, eraYear: eraYear, year: year, month: month, monthCode: monthCode, day: day});
  }
  function invoke_8(_this__u8e3s4, era, eraYear, year, month, monthCode, day) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    return {era: era, eraYear: eraYear, year: year, month: month, monthCode: monthCode, day: day};
  }
  function copy_8(_this__u8e3s4, smallestUnit, roundingMode, fractionalSecondDigits, calendarName) {
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    calendarName = calendarName === VOID ? VOID : calendarName;
    return Object.assign({}, _this__u8e3s4, {smallestUnit: smallestUnit, roundingMode: roundingMode, fractionalSecondDigits: fractionalSecondDigits, calendarName: calendarName});
  }
  function invoke_9(_this__u8e3s4, smallestUnit, roundingMode, fractionalSecondDigits, calendarName) {
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    calendarName = calendarName === VOID ? VOID : calendarName;
    return {smallestUnit: smallestUnit, roundingMode: roundingMode, fractionalSecondDigits: fractionalSecondDigits, calendarName: calendarName};
  }
  function copy_9(_this__u8e3s4, roundingIncrement, smallestUnit, roundingMode, largestUnit) {
    roundingIncrement = roundingIncrement === VOID ? VOID : roundingIncrement;
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    largestUnit = largestUnit === VOID ? VOID : largestUnit;
    return Object.assign({}, _this__u8e3s4, {roundingIncrement: roundingIncrement, smallestUnit: smallestUnit, roundingMode: roundingMode, largestUnit: largestUnit});
  }
  function invoke_10(_this__u8e3s4, roundingIncrement, smallestUnit, roundingMode, largestUnit) {
    roundingIncrement = roundingIncrement === VOID ? VOID : roundingIncrement;
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    largestUnit = largestUnit === VOID ? VOID : largestUnit;
    return {roundingIncrement: roundingIncrement, smallestUnit: smallestUnit, roundingMode: roundingMode, largestUnit: largestUnit};
  }
  function copy_10(_this__u8e3s4, relativeTo) {
    relativeTo = relativeTo === VOID ? VOID : relativeTo;
    return Object.assign({}, _this__u8e3s4, {relativeTo: relativeTo});
  }
  function invoke_11(_this__u8e3s4, relativeTo) {
    relativeTo = relativeTo === VOID ? VOID : relativeTo;
    return {relativeTo: relativeTo};
  }
  function copy_11(_this__u8e3s4, microseconds, milliseconds, days, nanoseconds, months, hours, minutes, years, seconds, weeks) {
    microseconds = microseconds === VOID ? VOID : microseconds;
    milliseconds = milliseconds === VOID ? VOID : milliseconds;
    days = days === VOID ? VOID : days;
    nanoseconds = nanoseconds === VOID ? VOID : nanoseconds;
    months = months === VOID ? VOID : months;
    hours = hours === VOID ? VOID : hours;
    minutes = minutes === VOID ? VOID : minutes;
    years = years === VOID ? VOID : years;
    seconds = seconds === VOID ? VOID : seconds;
    weeks = weeks === VOID ? VOID : weeks;
    return Object.assign({}, _this__u8e3s4, {microseconds: microseconds, milliseconds: milliseconds, days: days, nanoseconds: nanoseconds, months: months, hours: hours, minutes: minutes, years: years, seconds: seconds, weeks: weeks});
  }
  function invoke_12(_this__u8e3s4, microseconds, milliseconds, days, nanoseconds, months, hours, minutes, years, seconds, weeks) {
    microseconds = microseconds === VOID ? VOID : microseconds;
    milliseconds = milliseconds === VOID ? VOID : milliseconds;
    days = days === VOID ? VOID : days;
    nanoseconds = nanoseconds === VOID ? VOID : nanoseconds;
    months = months === VOID ? VOID : months;
    hours = hours === VOID ? VOID : hours;
    minutes = minutes === VOID ? VOID : minutes;
    years = years === VOID ? VOID : years;
    seconds = seconds === VOID ? VOID : seconds;
    weeks = weeks === VOID ? VOID : weeks;
    return {microseconds: microseconds, milliseconds: milliseconds, days: days, nanoseconds: nanoseconds, months: months, hours: hours, minutes: minutes, years: years, seconds: seconds, weeks: weeks};
  }
  function copy_12(_this__u8e3s4, smallestUnit, roundingMode, fractionalSecondDigits, timeZone) {
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    timeZone = timeZone === VOID ? VOID : timeZone;
    return Object.assign({}, _this__u8e3s4, {smallestUnit: smallestUnit, roundingMode: roundingMode, fractionalSecondDigits: fractionalSecondDigits, timeZone: timeZone});
  }
  function invoke_13(_this__u8e3s4, smallestUnit, roundingMode, fractionalSecondDigits, timeZone) {
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    return {smallestUnit: smallestUnit, roundingMode: roundingMode, fractionalSecondDigits: fractionalSecondDigits, timeZone: timeZone};
  }
  function copy_13(_this__u8e3s4, era, eraYear, year, month, monthCode) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    return Object.assign({}, _this__u8e3s4, {era: era, eraYear: eraYear, year: year, month: month, monthCode: monthCode});
  }
  function invoke_14(_this__u8e3s4, era, eraYear, year, month, monthCode) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    return {era: era, eraYear: eraYear, year: year, month: month, monthCode: monthCode};
  }
  function copy_14(_this__u8e3s4, month, monthCode) {
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    return Object.assign({}, _this__u8e3s4, {month: month, monthCode: monthCode});
  }
  function invoke_15(_this__u8e3s4, month, monthCode) {
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    return {month: month, monthCode: monthCode};
  }
  function copy_15(_this__u8e3s4, offset) {
    offset = offset === VOID ? VOID : offset;
    return Object.assign({}, _this__u8e3s4, {offset: offset});
  }
  function invoke_16(_this__u8e3s4, offset) {
    offset = offset === VOID ? VOID : offset;
    return {offset: offset};
  }
  function copy_16(_this__u8e3s4, isoDay, calendar, isoMonth, isoYear) {
    isoDay = isoDay === VOID ? VOID : isoDay;
    calendar = calendar === VOID ? VOID : calendar;
    isoMonth = isoMonth === VOID ? VOID : isoMonth;
    isoYear = isoYear === VOID ? VOID : isoYear;
    return Object.assign({}, _this__u8e3s4, {isoDay: isoDay, calendar: calendar, isoMonth: isoMonth, isoYear: isoYear});
  }
  function invoke_17(_this__u8e3s4, isoDay, calendar, isoMonth, isoYear) {
    return {isoDay: isoDay, calendar: calendar, isoMonth: isoMonth, isoYear: isoYear};
  }
  function copy_17(_this__u8e3s4, month, monthCode, calendar, era, eraYear, day, year) {
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    day = day === VOID ? VOID : day;
    year = year === VOID ? VOID : year;
    return Object.assign({}, _this__u8e3s4, {month: month, monthCode: monthCode, calendar: calendar, era: era, eraYear: eraYear, day: day, year: year});
  }
  function invoke_18(_this__u8e3s4, month, monthCode, calendar, era, eraYear, day, year) {
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    day = day === VOID ? VOID : day;
    year = year === VOID ? VOID : year;
    return {month: month, monthCode: monthCode, calendar: calendar, era: era, eraYear: eraYear, day: day, year: year};
  }
  function copy_18(_this__u8e3s4, isoDay, isoSecond, calendar, isoMonth, isoMinute, isoMicrosecond, isoNanosecond, isoYear, isoHour, isoMillisecond) {
    isoDay = isoDay === VOID ? VOID : isoDay;
    isoSecond = isoSecond === VOID ? VOID : isoSecond;
    calendar = calendar === VOID ? VOID : calendar;
    isoMonth = isoMonth === VOID ? VOID : isoMonth;
    isoMinute = isoMinute === VOID ? VOID : isoMinute;
    isoMicrosecond = isoMicrosecond === VOID ? VOID : isoMicrosecond;
    isoNanosecond = isoNanosecond === VOID ? VOID : isoNanosecond;
    isoYear = isoYear === VOID ? VOID : isoYear;
    isoHour = isoHour === VOID ? VOID : isoHour;
    isoMillisecond = isoMillisecond === VOID ? VOID : isoMillisecond;
    return Object.assign({}, _this__u8e3s4, {isoDay: isoDay, isoSecond: isoSecond, calendar: calendar, isoMonth: isoMonth, isoMinute: isoMinute, isoMicrosecond: isoMicrosecond, isoNanosecond: isoNanosecond, isoYear: isoYear, isoHour: isoHour, isoMillisecond: isoMillisecond});
  }
  function invoke_19(_this__u8e3s4, isoDay, isoSecond, calendar, isoMonth, isoMinute, isoMicrosecond, isoNanosecond, isoYear, isoHour, isoMillisecond) {
    return {isoDay: isoDay, isoSecond: isoSecond, calendar: calendar, isoMonth: isoMonth, isoMinute: isoMinute, isoMicrosecond: isoMicrosecond, isoNanosecond: isoNanosecond, isoYear: isoYear, isoHour: isoHour, isoMillisecond: isoMillisecond};
  }
  function copy_19(_this__u8e3s4, second, calendar, era, microsecond, nanosecond, day, millisecond, month, hour, monthCode, minute, eraYear, year) {
    second = second === VOID ? VOID : second;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    microsecond = microsecond === VOID ? VOID : microsecond;
    nanosecond = nanosecond === VOID ? VOID : nanosecond;
    day = day === VOID ? VOID : day;
    millisecond = millisecond === VOID ? VOID : millisecond;
    month = month === VOID ? VOID : month;
    hour = hour === VOID ? VOID : hour;
    monthCode = monthCode === VOID ? VOID : monthCode;
    minute = minute === VOID ? VOID : minute;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    return Object.assign({}, _this__u8e3s4, {second: second, calendar: calendar, era: era, microsecond: microsecond, nanosecond: nanosecond, day: day, millisecond: millisecond, month: month, hour: hour, monthCode: monthCode, minute: minute, eraYear: eraYear, year: year});
  }
  function invoke_20(_this__u8e3s4, second, calendar, era, microsecond, nanosecond, day, millisecond, month, hour, monthCode, minute, eraYear, year) {
    second = second === VOID ? VOID : second;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    microsecond = microsecond === VOID ? VOID : microsecond;
    nanosecond = nanosecond === VOID ? VOID : nanosecond;
    day = day === VOID ? VOID : day;
    millisecond = millisecond === VOID ? VOID : millisecond;
    month = month === VOID ? VOID : month;
    hour = hour === VOID ? VOID : hour;
    monthCode = monthCode === VOID ? VOID : monthCode;
    minute = minute === VOID ? VOID : minute;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    return {second: second, calendar: calendar, era: era, microsecond: microsecond, nanosecond: nanosecond, day: day, millisecond: millisecond, month: month, hour: hour, monthCode: monthCode, minute: minute, eraYear: eraYear, year: year};
  }
  function copy_20(_this__u8e3s4, month, monthCode, calendar, era, eraYear, day, year) {
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    day = day === VOID ? VOID : day;
    year = year === VOID ? VOID : year;
    return Object.assign({}, _this__u8e3s4, {month: month, monthCode: monthCode, calendar: calendar, era: era, eraYear: eraYear, day: day, year: year});
  }
  function invoke_21(_this__u8e3s4, month, monthCode, calendar, era, eraYear, day, year) {
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    day = day === VOID ? VOID : day;
    year = year === VOID ? VOID : year;
    return {month: month, monthCode: monthCode, calendar: calendar, era: era, eraYear: eraYear, day: day, year: year};
  }
  function copy_21(_this__u8e3s4, isoSecond, isoMinute, isoMicrosecond, isoNanosecond, isoHour, isoMillisecond) {
    isoSecond = isoSecond === VOID ? VOID : isoSecond;
    isoMinute = isoMinute === VOID ? VOID : isoMinute;
    isoMicrosecond = isoMicrosecond === VOID ? VOID : isoMicrosecond;
    isoNanosecond = isoNanosecond === VOID ? VOID : isoNanosecond;
    isoHour = isoHour === VOID ? VOID : isoHour;
    isoMillisecond = isoMillisecond === VOID ? VOID : isoMillisecond;
    return Object.assign({}, _this__u8e3s4, {isoSecond: isoSecond, isoMinute: isoMinute, isoMicrosecond: isoMicrosecond, isoNanosecond: isoNanosecond, isoHour: isoHour, isoMillisecond: isoMillisecond});
  }
  function invoke_22(_this__u8e3s4, isoSecond, isoMinute, isoMicrosecond, isoNanosecond, isoHour, isoMillisecond) {
    return {isoSecond: isoSecond, isoMinute: isoMinute, isoMicrosecond: isoMicrosecond, isoNanosecond: isoNanosecond, isoHour: isoHour, isoMillisecond: isoMillisecond};
  }
  function copy_22(_this__u8e3s4, hour, second, minute, microsecond, nanosecond, millisecond) {
    hour = hour === VOID ? VOID : hour;
    second = second === VOID ? VOID : second;
    minute = minute === VOID ? VOID : minute;
    microsecond = microsecond === VOID ? VOID : microsecond;
    nanosecond = nanosecond === VOID ? VOID : nanosecond;
    millisecond = millisecond === VOID ? VOID : millisecond;
    return Object.assign({}, _this__u8e3s4, {hour: hour, second: second, minute: minute, microsecond: microsecond, nanosecond: nanosecond, millisecond: millisecond});
  }
  function invoke_23(_this__u8e3s4, hour, second, minute, microsecond, nanosecond, millisecond) {
    hour = hour === VOID ? VOID : hour;
    second = second === VOID ? VOID : second;
    minute = minute === VOID ? VOID : minute;
    microsecond = microsecond === VOID ? VOID : microsecond;
    nanosecond = nanosecond === VOID ? VOID : nanosecond;
    millisecond = millisecond === VOID ? VOID : millisecond;
    return {hour: hour, second: second, minute: minute, microsecond: microsecond, nanosecond: nanosecond, millisecond: millisecond};
  }
  function copy_23(_this__u8e3s4, month, monthCode, calendar, era, eraYear, year) {
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    return Object.assign({}, _this__u8e3s4, {month: month, monthCode: monthCode, calendar: calendar, era: era, eraYear: eraYear, year: year});
  }
  function invoke_24(_this__u8e3s4, month, monthCode, calendar, era, eraYear, year) {
    month = month === VOID ? VOID : month;
    monthCode = monthCode === VOID ? VOID : monthCode;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    return {month: month, monthCode: monthCode, calendar: calendar, era: era, eraYear: eraYear, year: year};
  }
  function copy_24(_this__u8e3s4, roundingIncrement, smallestUnit, roundingMode) {
    roundingIncrement = roundingIncrement === VOID ? VOID : roundingIncrement;
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    return Object.assign({}, _this__u8e3s4, {roundingIncrement: roundingIncrement, smallestUnit: smallestUnit, roundingMode: roundingMode});
  }
  function invoke_25(_this__u8e3s4, roundingIncrement, smallestUnit, roundingMode) {
    roundingIncrement = roundingIncrement === VOID ? VOID : roundingIncrement;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    return {roundingIncrement: roundingIncrement, smallestUnit: smallestUnit, roundingMode: roundingMode};
  }
  function copy_25(_this__u8e3s4, calendarName) {
    calendarName = calendarName === VOID ? VOID : calendarName;
    return Object.assign({}, _this__u8e3s4, {calendarName: calendarName});
  }
  function invoke_26(_this__u8e3s4, calendarName) {
    calendarName = calendarName === VOID ? VOID : calendarName;
    return {calendarName: calendarName};
  }
  function copy_26(_this__u8e3s4, getPlainDateTimeFor, id, toString, getOffsetNanosecondsFor, getPreviousTransition, getPossibleInstantsFor, toJSON, getOffsetStringFor, getInstantFor, getNextTransition) {
    getPlainDateTimeFor = getPlainDateTimeFor === VOID ? VOID : getPlainDateTimeFor;
    id = id === VOID ? VOID : id;
    toString = toString === VOID ? VOID : toString;
    getOffsetNanosecondsFor = getOffsetNanosecondsFor === VOID ? VOID : getOffsetNanosecondsFor;
    getPreviousTransition = getPreviousTransition === VOID ? VOID : getPreviousTransition;
    getPossibleInstantsFor = getPossibleInstantsFor === VOID ? VOID : getPossibleInstantsFor;
    toJSON = toJSON === VOID ? VOID : toJSON;
    getOffsetStringFor = getOffsetStringFor === VOID ? VOID : getOffsetStringFor;
    getInstantFor = getInstantFor === VOID ? VOID : getInstantFor;
    getNextTransition = getNextTransition === VOID ? VOID : getNextTransition;
    return Object.assign({}, _this__u8e3s4, {getPlainDateTimeFor: getPlainDateTimeFor, id: id, toString: toString, getOffsetNanosecondsFor: getOffsetNanosecondsFor, getPreviousTransition: getPreviousTransition, getPossibleInstantsFor: getPossibleInstantsFor, toJSON: toJSON, getOffsetStringFor: getOffsetStringFor, getInstantFor: getInstantFor, getNextTransition: getNextTransition});
  }
  function invoke_27(_this__u8e3s4, getPlainDateTimeFor, id, toString, getOffsetNanosecondsFor, getPreviousTransition, getPossibleInstantsFor, toJSON, getOffsetStringFor, getInstantFor, getNextTransition) {
    getPlainDateTimeFor = getPlainDateTimeFor === VOID ? VOID : getPlainDateTimeFor;
    toString = toString === VOID ? VOID : toString;
    getPreviousTransition = getPreviousTransition === VOID ? VOID : getPreviousTransition;
    toJSON = toJSON === VOID ? VOID : toJSON;
    getOffsetStringFor = getOffsetStringFor === VOID ? VOID : getOffsetStringFor;
    getInstantFor = getInstantFor === VOID ? VOID : getInstantFor;
    getNextTransition = getNextTransition === VOID ? VOID : getNextTransition;
    return {getPlainDateTimeFor: getPlainDateTimeFor, id: id, toString: toString, getOffsetNanosecondsFor: getOffsetNanosecondsFor, getPreviousTransition: getPreviousTransition, getPossibleInstantsFor: getPossibleInstantsFor, toJSON: toJSON, getOffsetStringFor: getOffsetStringFor, getInstantFor: getInstantFor, getNextTransition: getNextTransition};
  }
  function copy_27(_this__u8e3s4, disambiguation) {
    disambiguation = disambiguation === VOID ? VOID : disambiguation;
    return Object.assign({}, _this__u8e3s4, {disambiguation: disambiguation});
  }
  function invoke_28(_this__u8e3s4, disambiguation) {
    disambiguation = disambiguation === VOID ? VOID : disambiguation;
    return {disambiguation: disambiguation};
  }
  function copy_28(_this__u8e3s4, smallestUnit, roundingMode, fractionalSecondDigits) {
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    return Object.assign({}, _this__u8e3s4, {smallestUnit: smallestUnit, roundingMode: roundingMode, fractionalSecondDigits: fractionalSecondDigits});
  }
  function invoke_29(_this__u8e3s4, smallestUnit, roundingMode, fractionalSecondDigits) {
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    return {smallestUnit: smallestUnit, roundingMode: roundingMode, fractionalSecondDigits: fractionalSecondDigits};
  }
  function copy_29(_this__u8e3s4, era, eraYear, year) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    return Object.assign({}, _this__u8e3s4, {era: era, eraYear: eraYear, year: year});
  }
  function invoke_30(_this__u8e3s4, era, eraYear, year) {
    era = era === VOID ? VOID : era;
    eraYear = eraYear === VOID ? VOID : eraYear;
    year = year === VOID ? VOID : year;
    return {era: era, eraYear: eraYear, year: year};
  }
  function copy_30(_this__u8e3s4, overflow, disambiguation, offset) {
    overflow = overflow === VOID ? VOID : overflow;
    disambiguation = disambiguation === VOID ? VOID : disambiguation;
    offset = offset === VOID ? VOID : offset;
    return Object.assign({}, _this__u8e3s4, {overflow: overflow, disambiguation: disambiguation, offset: offset});
  }
  function invoke_31(_this__u8e3s4, overflow, disambiguation, offset) {
    overflow = overflow === VOID ? VOID : overflow;
    disambiguation = disambiguation === VOID ? VOID : disambiguation;
    offset = offset === VOID ? VOID : offset;
    return {overflow: overflow, disambiguation: disambiguation, offset: offset};
  }
  function copy_31(_this__u8e3s4, isoDay, timeZone, isoSecond, calendar, isoMonth, isoNanosecond, isoHour, isoMillisecond, isoMinute, isoMicrosecond, offset, isoYear) {
    isoDay = isoDay === VOID ? VOID : isoDay;
    timeZone = timeZone === VOID ? VOID : timeZone;
    isoSecond = isoSecond === VOID ? VOID : isoSecond;
    calendar = calendar === VOID ? VOID : calendar;
    isoMonth = isoMonth === VOID ? VOID : isoMonth;
    isoNanosecond = isoNanosecond === VOID ? VOID : isoNanosecond;
    isoHour = isoHour === VOID ? VOID : isoHour;
    isoMillisecond = isoMillisecond === VOID ? VOID : isoMillisecond;
    isoMinute = isoMinute === VOID ? VOID : isoMinute;
    isoMicrosecond = isoMicrosecond === VOID ? VOID : isoMicrosecond;
    offset = offset === VOID ? VOID : offset;
    isoYear = isoYear === VOID ? VOID : isoYear;
    return Object.assign({}, _this__u8e3s4, {isoDay: isoDay, timeZone: timeZone, isoSecond: isoSecond, calendar: calendar, isoMonth: isoMonth, isoNanosecond: isoNanosecond, isoHour: isoHour, isoMillisecond: isoMillisecond, isoMinute: isoMinute, isoMicrosecond: isoMicrosecond, offset: offset, isoYear: isoYear});
  }
  function invoke_32(_this__u8e3s4, isoDay, timeZone, isoSecond, calendar, isoMonth, isoNanosecond, isoHour, isoMillisecond, isoMinute, isoMicrosecond, offset, isoYear) {
    return {isoDay: isoDay, timeZone: timeZone, isoSecond: isoSecond, calendar: calendar, isoMonth: isoMonth, isoNanosecond: isoNanosecond, isoHour: isoHour, isoMillisecond: isoMillisecond, isoMinute: isoMinute, isoMicrosecond: isoMicrosecond, offset: offset, isoYear: isoYear};
  }
  function copy_32(_this__u8e3s4, second, timeZone, calendar, era, microsecond, nanosecond, day, millisecond, month, hour, monthCode, minute, eraYear, offset, year) {
    second = second === VOID ? VOID : second;
    timeZone = timeZone === VOID ? VOID : timeZone;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    microsecond = microsecond === VOID ? VOID : microsecond;
    nanosecond = nanosecond === VOID ? VOID : nanosecond;
    day = day === VOID ? VOID : day;
    millisecond = millisecond === VOID ? VOID : millisecond;
    month = month === VOID ? VOID : month;
    hour = hour === VOID ? VOID : hour;
    monthCode = monthCode === VOID ? VOID : monthCode;
    minute = minute === VOID ? VOID : minute;
    eraYear = eraYear === VOID ? VOID : eraYear;
    offset = offset === VOID ? VOID : offset;
    year = year === VOID ? VOID : year;
    return Object.assign({}, _this__u8e3s4, {second: second, timeZone: timeZone, calendar: calendar, era: era, microsecond: microsecond, nanosecond: nanosecond, day: day, millisecond: millisecond, month: month, hour: hour, monthCode: monthCode, minute: minute, eraYear: eraYear, offset: offset, year: year});
  }
  function invoke_33(_this__u8e3s4, second, timeZone, calendar, era, microsecond, nanosecond, day, millisecond, month, hour, monthCode, minute, eraYear, offset, year) {
    second = second === VOID ? VOID : second;
    timeZone = timeZone === VOID ? VOID : timeZone;
    calendar = calendar === VOID ? VOID : calendar;
    era = era === VOID ? VOID : era;
    microsecond = microsecond === VOID ? VOID : microsecond;
    nanosecond = nanosecond === VOID ? VOID : nanosecond;
    day = day === VOID ? VOID : day;
    millisecond = millisecond === VOID ? VOID : millisecond;
    month = month === VOID ? VOID : month;
    hour = hour === VOID ? VOID : hour;
    monthCode = monthCode === VOID ? VOID : monthCode;
    minute = minute === VOID ? VOID : minute;
    eraYear = eraYear === VOID ? VOID : eraYear;
    offset = offset === VOID ? VOID : offset;
    year = year === VOID ? VOID : year;
    return {second: second, timeZone: timeZone, calendar: calendar, era: era, microsecond: microsecond, nanosecond: nanosecond, day: day, millisecond: millisecond, month: month, hour: hour, monthCode: monthCode, minute: minute, eraYear: eraYear, offset: offset, year: year};
  }
  function copy_33(_this__u8e3s4, smallestUnit, roundingMode, fractionalSecondDigits, calendarName, offset, timeZoneName) {
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    calendarName = calendarName === VOID ? VOID : calendarName;
    offset = offset === VOID ? VOID : offset;
    timeZoneName = timeZoneName === VOID ? VOID : timeZoneName;
    return Object.assign({}, _this__u8e3s4, {smallestUnit: smallestUnit, roundingMode: roundingMode, fractionalSecondDigits: fractionalSecondDigits, calendarName: calendarName, offset: offset, timeZoneName: timeZoneName});
  }
  function invoke_34(_this__u8e3s4, smallestUnit, roundingMode, fractionalSecondDigits, calendarName, offset, timeZoneName) {
    smallestUnit = smallestUnit === VOID ? VOID : smallestUnit;
    roundingMode = roundingMode === VOID ? VOID : roundingMode;
    fractionalSecondDigits = fractionalSecondDigits === VOID ? VOID : fractionalSecondDigits;
    calendarName = calendarName === VOID ? VOID : calendarName;
    offset = offset === VOID ? VOID : offset;
    timeZoneName = timeZoneName === VOID ? VOID : timeZoneName;
    return {smallestUnit: smallestUnit, roundingMode: roundingMode, fractionalSecondDigits: fractionalSecondDigits, calendarName: calendarName, offset: offset, timeZoneName: timeZoneName};
  }
  function copy_34(_this__u8e3s4, lastChunkHandling, alphabet) {
    lastChunkHandling = lastChunkHandling === VOID ? VOID : lastChunkHandling;
    alphabet = alphabet === VOID ? VOID : alphabet;
    return Object.assign({}, _this__u8e3s4, {lastChunkHandling: lastChunkHandling, alphabet: alphabet});
  }
  function invoke_35(_this__u8e3s4, lastChunkHandling, alphabet) {
    lastChunkHandling = lastChunkHandling === VOID ? VOID : lastChunkHandling;
    alphabet = alphabet === VOID ? VOID : alphabet;
    return {lastChunkHandling: lastChunkHandling, alphabet: alphabet};
  }
  function copy_35(_this__u8e3s4, written, read) {
    written = written === VOID ? VOID : written;
    read = read === VOID ? VOID : read;
    return Object.assign({}, _this__u8e3s4, {written: written, read: read});
  }
  function invoke_36(_this__u8e3s4, written, read) {
    return {written: written, read: read};
  }
  function copy_36(_this__u8e3s4, omitPadding, alphabet) {
    omitPadding = omitPadding === VOID ? VOID : omitPadding;
    alphabet = alphabet === VOID ? VOID : alphabet;
    return Object.assign({}, _this__u8e3s4, {omitPadding: omitPadding, alphabet: alphabet});
  }
  function invoke_37(_this__u8e3s4, omitPadding, alphabet) {
    omitPadding = omitPadding === VOID ? VOID : omitPadding;
    alphabet = alphabet === VOID ? VOID : alphabet;
    return {omitPadding: omitPadding, alphabet: alphabet};
  }
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = asList;
  _.$_$.b = IsolatedCoroutineScope;
  //endregion
  return _;
}));

