(function (factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib.js'));
  else {
    if (typeof globalThis['kotlin-kotlin-stdlib'] === 'undefined') {
      throw new Error("Error loading module 'kotlinx-serialization-kotlinx-serialization-core'. Its dependency 'kotlin-kotlin-stdlib' was not found. Please, check whether 'kotlin-kotlin-stdlib' is loaded prior to 'kotlinx-serialization-kotlinx-serialization-core'.");
    }
    globalThis['kotlinx-serialization-kotlinx-serialization-core'] = factory(typeof globalThis['kotlinx-serialization-kotlinx-serialization-core'] === 'undefined' ? {} : globalThis['kotlinx-serialization-kotlinx-serialization-core'], globalThis['kotlin-kotlin-stdlib']);
  }
}(function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var protoOf = kotlin_kotlin.$_$.ab;
  var initMetadataForInterface = kotlin_kotlin.$_$.da;
  var VOID = kotlin_kotlin.$_$.g;
  var StringCompanionObject_instance = kotlin_kotlin.$_$.l4;
  var Unit_instance = kotlin_kotlin.$_$.v4;
  var emptyList = kotlin_kotlin.$_$.j6;
  var LazyThreadSafetyMode_PUBLICATION_getInstance = kotlin_kotlin.$_$.n;
  var lazy = kotlin_kotlin.$_$.ve;
  var toString = kotlin_kotlin.$_$.eb;
  var initMetadataForClass = kotlin_kotlin.$_$.aa;
  var getKClassFromExpression = kotlin_kotlin.$_$.e;
  var KProperty1 = kotlin_kotlin.$_$.ob;
  var getPropertyCallableRef = kotlin_kotlin.$_$.x9;
  var asList = kotlin_kotlin.$_$.n5;
  var objectCreate = kotlin_kotlin.$_$.za;
  var THROW_CCE = kotlin_kotlin.$_$.yd;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.r1;
  var zip = kotlin_kotlin.$_$.h8;
  var toMap = kotlin_kotlin.$_$.c8;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.z;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.u1;
  var mapCapacity = kotlin_kotlin.$_$.d7;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.y;
  var KtMap = kotlin_kotlin.$_$.f5;
  var isInterface = kotlin_kotlin.$_$.oa;
  var IllegalArgumentException_init_$Init$ = kotlin_kotlin.$_$.o1;
  var captureStack = kotlin_kotlin.$_$.k9;
  var IllegalArgumentException_init_$Init$_0 = kotlin_kotlin.$_$.q1;
  var IllegalArgumentException_init_$Init$_1 = kotlin_kotlin.$_$.s1;
  var IllegalArgumentException = kotlin_kotlin.$_$.td;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.s5;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.p;
  var KClass = kotlin_kotlin.$_$.mb;
  var Triple = kotlin_kotlin.$_$.be;
  var getKClass = kotlin_kotlin.$_$.f;
  var Pair = kotlin_kotlin.$_$.vd;
  var Entry = kotlin_kotlin.$_$.e5;
  var LinkedHashMap = kotlin_kotlin.$_$.b5;
  var KtMutableMap = kotlin_kotlin.$_$.h5;
  var HashMap = kotlin_kotlin.$_$.z4;
  var LinkedHashSet = kotlin_kotlin.$_$.c5;
  var KtMutableSet = kotlin_kotlin.$_$.i5;
  var KtSet = kotlin_kotlin.$_$.j5;
  var HashSet = kotlin_kotlin.$_$.a5;
  var ArrayList = kotlin_kotlin.$_$.x4;
  var KtMutableList = kotlin_kotlin.$_$.g5;
  var KtList = kotlin_kotlin.$_$.d5;
  var Collection = kotlin_kotlin.$_$.y4;
  var copyToArray = kotlin_kotlin.$_$.h6;
  var _Result___get_value__impl__bjfvqg = kotlin_kotlin.$_$.r2;
  var _Result___get_isFailure__impl__jpiriv = kotlin_kotlin.$_$.q2;
  var Result = kotlin_kotlin.$_$.wd;
  var ensureNotNull = kotlin_kotlin.$_$.qe;
  var equals = kotlin_kotlin.$_$.s9;
  var getStringHashCode = kotlin_kotlin.$_$.y9;
  var isBlank = kotlin_kotlin.$_$.ac;
  var toList = kotlin_kotlin.$_$.a8;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.q;
  var HashSet_init_$Create$ = kotlin_kotlin.$_$.w;
  var toHashSet = kotlin_kotlin.$_$.y7;
  var toBooleanArray = kotlin_kotlin.$_$.x7;
  var withIndex = kotlin_kotlin.$_$.g8;
  var to = kotlin_kotlin.$_$.bf;
  var lazy_0 = kotlin_kotlin.$_$.we;
  var contentEquals = kotlin_kotlin.$_$.v5;
  var until = kotlin_kotlin.$_$.lb;
  var joinToString = kotlin_kotlin.$_$.u6;
  var initMetadataForObject = kotlin_kotlin.$_$.fa;
  var Long = kotlin_kotlin.$_$.ud;
  var Char = kotlin_kotlin.$_$.ld;
  var Duration__toIsoString_impl_9h6wsm = kotlin_kotlin.$_$.i2;
  var Duration = kotlin_kotlin.$_$.id;
  var Companion_getInstance = kotlin_kotlin.$_$.n4;
  var toIntOrNull = kotlin_kotlin.$_$.vc;
  var hashCode = kotlin_kotlin.$_$.z9;
  var ArrayList_init_$Create$_1 = kotlin_kotlin.$_$.r;
  var HashSet_init_$Create$_0 = kotlin_kotlin.$_$.x;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.b1;
  var LinkedHashSet_init_$Create$_0 = kotlin_kotlin.$_$.c1;
  var HashMap_init_$Create$ = kotlin_kotlin.$_$.t;
  var HashMap_init_$Create$_0 = kotlin_kotlin.$_$.u;
  var LinkedHashMap_init_$Create$_1 = kotlin_kotlin.$_$.a1;
  var isArray = kotlin_kotlin.$_$.ga;
  var arrayIterator = kotlin_kotlin.$_$.i9;
  var step = kotlin_kotlin.$_$.kb;
  var getValue = kotlin_kotlin.$_$.p6;
  var longArray = kotlin_kotlin.$_$.ta;
  var initMetadataForCompanion = kotlin_kotlin.$_$.ba;
  var get_lastIndex = kotlin_kotlin.$_$.v6;
  var countTrailingZeroBits = kotlin_kotlin.$_$.oe;
  var indexOf = kotlin_kotlin.$_$.q6;
  var contentToString = kotlin_kotlin.$_$.x5;
  var Enum = kotlin_kotlin.$_$.qd;
  var fillArrayVal = kotlin_kotlin.$_$.t9;
  var HashSet_init_$Create$_1 = kotlin_kotlin.$_$.v;
  var toString_0 = kotlin_kotlin.$_$.af;
  var KTypeParameter = kotlin_kotlin.$_$.pb;
  var booleanArray = kotlin_kotlin.$_$.j9;
  var emptyMap = kotlin_kotlin.$_$.k6;
  var contentHashCode = kotlin_kotlin.$_$.w5;
  var Companion_getInstance_0 = kotlin_kotlin.$_$.o4;
  var isCharArray = kotlin_kotlin.$_$.ja;
  var charArray = kotlin_kotlin.$_$.m9;
  var DoubleCompanionObject_instance = kotlin_kotlin.$_$.h4;
  var isDoubleArray = kotlin_kotlin.$_$.la;
  var FloatCompanionObject_instance = kotlin_kotlin.$_$.i4;
  var isFloatArray = kotlin_kotlin.$_$.ma;
  var Companion_getInstance_1 = kotlin_kotlin.$_$.p4;
  var isLongArray = kotlin_kotlin.$_$.pa;
  var Companion_getInstance_2 = kotlin_kotlin.$_$.t4;
  var _ULongArray___get_size__impl__ju6dtr = kotlin_kotlin.$_$.r3;
  var ULongArray = kotlin_kotlin.$_$.ge;
  var _ULongArray___init__impl__twm1l3 = kotlin_kotlin.$_$.n3;
  var _ULong___init__impl__c78o9k = kotlin_kotlin.$_$.k3;
  var ULongArray__get_impl_pr71q9 = kotlin_kotlin.$_$.p3;
  var _ULong___get_data__impl__fggpzb = kotlin_kotlin.$_$.l3;
  var IntCompanionObject_instance = kotlin_kotlin.$_$.j4;
  var isIntArray = kotlin_kotlin.$_$.na;
  var Companion_getInstance_3 = kotlin_kotlin.$_$.s4;
  var _UIntArray___get_size__impl__r6l8ci = kotlin_kotlin.$_$.i3;
  var UIntArray = kotlin_kotlin.$_$.ee;
  var _UIntArray___init__impl__ghjpc6 = kotlin_kotlin.$_$.e3;
  var _UInt___init__impl__l7qpdl = kotlin_kotlin.$_$.b3;
  var UIntArray__get_impl_gp5kza = kotlin_kotlin.$_$.g3;
  var _UInt___get_data__impl__f0vqqw = kotlin_kotlin.$_$.c3;
  var ShortCompanionObject_instance = kotlin_kotlin.$_$.k4;
  var isShortArray = kotlin_kotlin.$_$.ra;
  var Companion_getInstance_4 = kotlin_kotlin.$_$.u4;
  var _UShortArray___get_size__impl__jqto1b = kotlin_kotlin.$_$.a4;
  var UShortArray = kotlin_kotlin.$_$.ie;
  var _UShortArray___init__impl__9b26ef = kotlin_kotlin.$_$.w3;
  var _UShort___init__impl__jigrne = kotlin_kotlin.$_$.t3;
  var UShortArray__get_impl_fnbhmx = kotlin_kotlin.$_$.y3;
  var _UShort___get_data__impl__g0245 = kotlin_kotlin.$_$.u3;
  var ByteCompanionObject_instance = kotlin_kotlin.$_$.g4;
  var isByteArray = kotlin_kotlin.$_$.ia;
  var Companion_getInstance_5 = kotlin_kotlin.$_$.r4;
  var _UByteArray___get_size__impl__h6pkdv = kotlin_kotlin.$_$.z2;
  var UByteArray = kotlin_kotlin.$_$.ce;
  var _UByteArray___init__impl__ip4y9n = kotlin_kotlin.$_$.w2;
  var _UByte___init__impl__g9hnc4 = kotlin_kotlin.$_$.s2;
  var UByteArray__get_impl_t5f3hv = kotlin_kotlin.$_$.x2;
  var _UByte___get_data__impl__jof9qr = kotlin_kotlin.$_$.t2;
  var BooleanCompanionObject_instance = kotlin_kotlin.$_$.f4;
  var isBooleanArray = kotlin_kotlin.$_$.ha;
  var coerceAtLeast = kotlin_kotlin.$_$.hb;
  var copyOf = kotlin_kotlin.$_$.b6;
  var copyOf_0 = kotlin_kotlin.$_$.d6;
  var copyOf_1 = kotlin_kotlin.$_$.e6;
  var copyOf_2 = kotlin_kotlin.$_$.z5;
  var _ULongArray___get_storage__impl__28e64j = kotlin_kotlin.$_$.s3;
  var _ULongArray___init__impl__twm1l3_0 = kotlin_kotlin.$_$.o3;
  var ULongArray__set_impl_z19mvh = kotlin_kotlin.$_$.q3;
  var copyOf_3 = kotlin_kotlin.$_$.g6;
  var _UIntArray___get_storage__impl__92a0v0 = kotlin_kotlin.$_$.j3;
  var _UIntArray___init__impl__ghjpc6_0 = kotlin_kotlin.$_$.f3;
  var UIntArray__set_impl_7f2zu2 = kotlin_kotlin.$_$.h3;
  var copyOf_4 = kotlin_kotlin.$_$.y5;
  var _UShortArray___get_storage__impl__t2jpv5 = kotlin_kotlin.$_$.b4;
  var _UShortArray___init__impl__9b26ef_0 = kotlin_kotlin.$_$.x3;
  var UShortArray__set_impl_6d8whp = kotlin_kotlin.$_$.z3;
  var copyOf_5 = kotlin_kotlin.$_$.c6;
  var _UByteArray___get_storage__impl__d4kctt = kotlin_kotlin.$_$.a3;
  var _UByteArray___init__impl__ip4y9n_0 = kotlin_kotlin.$_$.v2;
  var UByteArray__set_impl_jvcicn = kotlin_kotlin.$_$.y2;
  var copyOf_6 = kotlin_kotlin.$_$.a6;
  var Unit = kotlin_kotlin.$_$.ke;
  var trimIndent = kotlin_kotlin.$_$.gd;
  var equals_0 = kotlin_kotlin.$_$.yb;
  var charSequenceLength = kotlin_kotlin.$_$.o9;
  var charSequenceGet = kotlin_kotlin.$_$.n9;
  var toString_1 = kotlin_kotlin.$_$.n2;
  var titlecase = kotlin_kotlin.$_$.pc;
  var isLowerCase = kotlin_kotlin.$_$.bc;
  var PrimitiveClasses_getInstance = kotlin_kotlin.$_$.m4;
  var ULong = kotlin_kotlin.$_$.he;
  var UInt = kotlin_kotlin.$_$.fe;
  var UShort = kotlin_kotlin.$_$.je;
  var UByte = kotlin_kotlin.$_$.de;
  var mapOf = kotlin_kotlin.$_$.e7;
  var lastOrNull = kotlin_kotlin.$_$.x6;
  var get_lastIndex_0 = kotlin_kotlin.$_$.w6;
  var get_js = kotlin_kotlin.$_$.sa;
  var findAssociatedObject = kotlin_kotlin.$_$.d;
  var get_indices = kotlin_kotlin.$_$.s6;
  var IndexOutOfBoundsException_init_$Create$ = kotlin_kotlin.$_$.w1;
  var get_indices_0 = kotlin_kotlin.$_$.r6;
  var Companion_instance = kotlin_kotlin.$_$.q4;
  var _Result___init__impl__xyqfz8 = kotlin_kotlin.$_$.o2;
  var createFailure = kotlin_kotlin.$_$.pe;
  //endregion
  //region block: pre-declaration
  initMetadataForInterface(SerializationStrategy, 'SerializationStrategy');
  initMetadataForInterface(DeserializationStrategy, 'DeserializationStrategy');
  initMetadataForInterface(KSerializer, 'KSerializer', VOID, VOID, [SerializationStrategy, DeserializationStrategy]);
  initMetadataForClass(AbstractPolymorphicSerializer, 'AbstractPolymorphicSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(PolymorphicSerializer, 'PolymorphicSerializer', VOID, AbstractPolymorphicSerializer);
  initMetadataForClass(SealedClassSerializer$$inlined$groupingBy$1);
  initMetadataForClass(SealedClassSerializer, 'SealedClassSerializer', VOID, AbstractPolymorphicSerializer);
  initMetadataForClass(SerializationException, 'SerializationException', SerializationException_init_$Create$, IllegalArgumentException);
  initMetadataForClass(UnknownFieldException, 'UnknownFieldException', VOID, SerializationException);
  initMetadataForClass(MissingFieldException, 'MissingFieldException', VOID, SerializationException);
  function get_isNullable() {
    return false;
  }
  function get_isInline() {
    return false;
  }
  function get_annotations() {
    return emptyList();
  }
  initMetadataForInterface(SerialDescriptor, 'SerialDescriptor');
  initMetadataForClass(ContextDescriptor, 'ContextDescriptor', VOID, VOID, [SerialDescriptor]);
  initMetadataForClass(elementDescriptors$1);
  initMetadataForClass(elementDescriptors$$inlined$Iterable$1);
  initMetadataForClass(elementNames$1);
  initMetadataForClass(elementNames$$inlined$Iterable$1);
  initMetadataForClass(ClassSerialDescriptorBuilder, 'ClassSerialDescriptorBuilder');
  initMetadataForInterface(CachedNames, 'CachedNames');
  initMetadataForClass(SerialDescriptorImpl, 'SerialDescriptorImpl', VOID, VOID, [SerialDescriptor, CachedNames]);
  initMetadataForClass(SerialKind, 'SerialKind');
  initMetadataForObject(ENUM, 'ENUM', VOID, SerialKind);
  initMetadataForObject(CONTEXTUAL, 'CONTEXTUAL', VOID, SerialKind);
  initMetadataForClass(PrimitiveKind, 'PrimitiveKind', VOID, SerialKind);
  initMetadataForObject(BOOLEAN, 'BOOLEAN', VOID, PrimitiveKind);
  initMetadataForObject(BYTE, 'BYTE', VOID, PrimitiveKind);
  initMetadataForObject(CHAR, 'CHAR', VOID, PrimitiveKind);
  initMetadataForObject(SHORT, 'SHORT', VOID, PrimitiveKind);
  initMetadataForObject(INT, 'INT', VOID, PrimitiveKind);
  initMetadataForObject(LONG, 'LONG', VOID, PrimitiveKind);
  initMetadataForObject(FLOAT, 'FLOAT', VOID, PrimitiveKind);
  initMetadataForObject(DOUBLE, 'DOUBLE', VOID, PrimitiveKind);
  initMetadataForObject(STRING, 'STRING', VOID, PrimitiveKind);
  initMetadataForClass(StructureKind, 'StructureKind', VOID, SerialKind);
  initMetadataForObject(CLASS, 'CLASS', VOID, StructureKind);
  initMetadataForObject(LIST, 'LIST', VOID, StructureKind);
  initMetadataForObject(MAP, 'MAP', VOID, StructureKind);
  initMetadataForObject(OBJECT, 'OBJECT', VOID, StructureKind);
  initMetadataForClass(PolymorphicKind, 'PolymorphicKind', VOID, SerialKind);
  initMetadataForObject(SEALED, 'SEALED', VOID, PolymorphicKind);
  initMetadataForObject(OPEN, 'OPEN', VOID, PolymorphicKind);
  function decodeSerializableValue(deserializer) {
    return deserializer.rm(this);
  }
  initMetadataForInterface(Decoder, 'Decoder');
  function decodeSequentially() {
    return false;
  }
  function decodeCollectionSize(descriptor) {
    return -1;
  }
  function decodeSerializableElement$default(descriptor, index, deserializer, previousValue, $super) {
    previousValue = previousValue === VOID ? null : previousValue;
    return $super === VOID ? this.jq(descriptor, index, deserializer, previousValue) : $super.jq.call(this, descriptor, index, deserializer, previousValue);
  }
  initMetadataForInterface(CompositeDecoder, 'CompositeDecoder');
  initMetadataForClass(AbstractDecoder, 'AbstractDecoder', VOID, VOID, [Decoder, CompositeDecoder]);
  function encodeNotNullMark() {
  }
  function beginCollection(descriptor, collectionSize) {
    return this.xp(descriptor);
  }
  function encodeSerializableValue(serializer, value) {
    serializer.qm(this, value);
  }
  function encodeNullableSerializableValue(serializer, value) {
    var isNullabilitySupported = serializer.pm().xn();
    if (isNullabilitySupported) {
      return this.pr(isInterface(serializer, SerializationStrategy) ? serializer : THROW_CCE(), value);
    }
    if (value == null) {
      this.sq();
    } else {
      this.sr();
      this.pr(serializer, value);
    }
  }
  initMetadataForInterface(Encoder, 'Encoder');
  function shouldEncodeElementDefault(descriptor, index) {
    return true;
  }
  initMetadataForInterface(CompositeEncoder, 'CompositeEncoder');
  initMetadataForClass(AbstractEncoder, 'AbstractEncoder', VOID, VOID, [Encoder, CompositeEncoder]);
  initMetadataForObject(NothingSerializer_0, 'NothingSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(DurationSerializer, 'DurationSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(ListLikeDescriptor, 'ListLikeDescriptor', VOID, VOID, [SerialDescriptor]);
  initMetadataForClass(ArrayListClassDesc, 'ArrayListClassDesc', VOID, ListLikeDescriptor);
  initMetadataForClass(HashSetClassDesc, 'HashSetClassDesc', VOID, ListLikeDescriptor);
  initMetadataForClass(LinkedHashSetClassDesc, 'LinkedHashSetClassDesc', VOID, ListLikeDescriptor);
  initMetadataForClass(MapLikeDescriptor, 'MapLikeDescriptor', VOID, VOID, [SerialDescriptor]);
  initMetadataForClass(HashMapClassDesc, 'HashMapClassDesc', VOID, MapLikeDescriptor);
  initMetadataForClass(LinkedHashMapClassDesc, 'LinkedHashMapClassDesc', VOID, MapLikeDescriptor);
  initMetadataForClass(ArrayClassDesc, 'ArrayClassDesc', VOID, ListLikeDescriptor);
  initMetadataForClass(PrimitiveArrayDescriptor, 'PrimitiveArrayDescriptor', VOID, ListLikeDescriptor);
  initMetadataForClass(AbstractCollectionSerializer, 'AbstractCollectionSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(CollectionLikeSerializer, 'CollectionLikeSerializer', VOID, AbstractCollectionSerializer);
  initMetadataForClass(CollectionSerializer, 'CollectionSerializer', VOID, CollectionLikeSerializer);
  initMetadataForClass(ArrayListSerializer, 'ArrayListSerializer', VOID, CollectionSerializer);
  initMetadataForClass(HashSetSerializer, 'HashSetSerializer', VOID, CollectionSerializer);
  initMetadataForClass(LinkedHashSetSerializer, 'LinkedHashSetSerializer', VOID, CollectionSerializer);
  initMetadataForClass(MapLikeSerializer, 'MapLikeSerializer', VOID, AbstractCollectionSerializer);
  initMetadataForClass(HashMapSerializer, 'HashMapSerializer', VOID, MapLikeSerializer);
  initMetadataForClass(LinkedHashMapSerializer, 'LinkedHashMapSerializer', VOID, MapLikeSerializer);
  initMetadataForClass(ReferenceArraySerializer, 'ReferenceArraySerializer', VOID, CollectionLikeSerializer);
  initMetadataForClass(PrimitiveArraySerializer, 'PrimitiveArraySerializer', VOID, CollectionLikeSerializer);
  initMetadataForClass(PrimitiveArrayBuilder, 'PrimitiveArrayBuilder');
  initMetadataForCompanion(Companion);
  initMetadataForClass(ElementMarker, 'ElementMarker');
  initMetadataForClass(EnumSerializer, 'EnumSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(PluginGeneratedSerialDescriptor, 'PluginGeneratedSerialDescriptor', VOID, VOID, [SerialDescriptor, CachedNames]);
  initMetadataForClass(EnumDescriptor, 'EnumDescriptor', VOID, PluginGeneratedSerialDescriptor);
  initMetadataForClass(InlineClassDescriptor, 'InlineClassDescriptor', VOID, PluginGeneratedSerialDescriptor);
  function typeParametersSerializers() {
    return get_EMPTY_SERIALIZER_ARRAY();
  }
  initMetadataForInterface(GeneratedSerializer, 'GeneratedSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(InlinePrimitiveDescriptor$1, VOID, VOID, VOID, [GeneratedSerializer]);
  initMetadataForObject(NoOpEncoder, 'NoOpEncoder', VOID, AbstractEncoder);
  initMetadataForObject(NothingSerialDescriptor, 'NothingSerialDescriptor', VOID, VOID, [SerialDescriptor]);
  initMetadataForClass(NullableSerializer, 'NullableSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(SerialDescriptorForNullable, 'SerialDescriptorForNullable', VOID, VOID, [SerialDescriptor, CachedNames]);
  initMetadataForClass(ObjectSerializer, 'ObjectSerializer', VOID, VOID, [KSerializer]);
  initMetadataForInterface(SerializerFactory, 'SerializerFactory');
  initMetadataForObject(CharArraySerializer_0, 'CharArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(DoubleArraySerializer_0, 'DoubleArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(FloatArraySerializer_0, 'FloatArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(LongArraySerializer_0, 'LongArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(ULongArraySerializer_0, 'ULongArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(IntArraySerializer_0, 'IntArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(UIntArraySerializer_0, 'UIntArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(ShortArraySerializer_0, 'ShortArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(UShortArraySerializer_0, 'UShortArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(ByteArraySerializer_0, 'ByteArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(UByteArraySerializer_0, 'UByteArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForObject(BooleanArraySerializer_0, 'BooleanArraySerializer', VOID, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  initMetadataForClass(CharArrayBuilder, 'CharArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(DoubleArrayBuilder, 'DoubleArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(FloatArrayBuilder, 'FloatArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(LongArrayBuilder, 'LongArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(ULongArrayBuilder, 'ULongArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(IntArrayBuilder, 'IntArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(UIntArrayBuilder, 'UIntArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(ShortArrayBuilder, 'ShortArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(UShortArrayBuilder, 'UShortArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(ByteArrayBuilder, 'ByteArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(UByteArrayBuilder, 'UByteArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForClass(BooleanArrayBuilder, 'BooleanArrayBuilder', VOID, PrimitiveArrayBuilder);
  initMetadataForObject(StringSerializer, 'StringSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(CharSerializer, 'CharSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(DoubleSerializer, 'DoubleSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(FloatSerializer, 'FloatSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(LongSerializer, 'LongSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(IntSerializer, 'IntSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(ShortSerializer, 'ShortSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(ByteSerializer, 'ByteSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(BooleanSerializer, 'BooleanSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(UnitSerializer, 'UnitSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(PrimitiveSerialDescriptor_0, 'PrimitiveSerialDescriptor', VOID, VOID, [SerialDescriptor]);
  initMetadataForClass(TaggedDecoder, 'TaggedDecoder', VOID, VOID, [Decoder, CompositeDecoder]);
  initMetadataForClass(NamedValueDecoder, 'NamedValueDecoder', VOID, TaggedDecoder);
  initMetadataForClass(MapEntry, 'MapEntry', VOID, VOID, [Entry]);
  initMetadataForClass(KeyValueSerializer, 'KeyValueSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(MapEntrySerializer_0, 'MapEntrySerializer', VOID, KeyValueSerializer);
  initMetadataForClass(PairSerializer_0, 'PairSerializer', VOID, KeyValueSerializer);
  initMetadataForClass(TripleSerializer_0, 'TripleSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(ULongSerializer, 'ULongSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(UIntSerializer, 'UIntSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(UShortSerializer, 'UShortSerializer', VOID, VOID, [KSerializer]);
  initMetadataForObject(UByteSerializer, 'UByteSerializer', VOID, VOID, [KSerializer]);
  initMetadataForClass(SerializersModule, 'SerializersModule');
  initMetadataForClass(SerialModuleImpl, 'SerialModuleImpl', VOID, SerializersModule);
  initMetadataForClass(ContextualProvider, 'ContextualProvider');
  initMetadataForClass(Argless, 'Argless', VOID, ContextualProvider);
  initMetadataForClass(WithTypeArguments, 'WithTypeArguments', VOID, ContextualProvider);
  function contextual(kClass, serializer) {
    return this.j16(kClass, SerializersModuleCollector$contextual$lambda(serializer));
  }
  initMetadataForInterface(SerializersModuleCollector, 'SerializersModuleCollector');
  initMetadataForClass(SerializableWith, 'SerializableWith', VOID, VOID, VOID, VOID, 0);
  initMetadataForClass(createCache$1);
  initMetadataForClass(createParametrizedCache$1);
  //endregion
  function KSerializer() {
  }
  function SerializationStrategy() {
  }
  function DeserializationStrategy() {
  }
  function PolymorphicSerializer$descriptor$delegate$lambda$lambda(this$0) {
    return function ($this$buildSerialDescriptor) {
      $this$buildSerialDescriptor.an('type', serializer_0(StringCompanionObject_instance).pm());
      $this$buildSerialDescriptor.an('value', buildSerialDescriptor('kotlinx.serialization.Polymorphic<' + this$0.bn_1.ka() + '>', CONTEXTUAL_getInstance(), []));
      $this$buildSerialDescriptor.um_1 = this$0.cn_1;
      return Unit_instance;
    };
  }
  function PolymorphicSerializer$descriptor$delegate$lambda(this$0) {
    return function () {
      var tmp = OPEN_getInstance();
      return withContext(buildSerialDescriptor('kotlinx.serialization.Polymorphic', tmp, [], PolymorphicSerializer$descriptor$delegate$lambda$lambda(this$0)), this$0.bn_1);
    };
  }
  function PolymorphicSerializer(baseClass) {
    AbstractPolymorphicSerializer.call(this);
    this.bn_1 = baseClass;
    this.cn_1 = emptyList();
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.dn_1 = lazy(tmp_0, PolymorphicSerializer$descriptor$delegate$lambda(this));
  }
  protoOf(PolymorphicSerializer).en = function () {
    return this.bn_1;
  };
  protoOf(PolymorphicSerializer).pm = function () {
    var tmp0 = this.dn_1;
    // Inline function 'kotlin.getValue' call
    descriptor$factory();
    return tmp0.a2();
  };
  protoOf(PolymorphicSerializer).toString = function () {
    return 'kotlinx.serialization.PolymorphicSerializer(baseClass: ' + toString(this.bn_1) + ')';
  };
  function findPolymorphicSerializer(_this__u8e3s4, encoder, value) {
    var tmp0_elvis_lhs = _this__u8e3s4.hn(encoder, value);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throwSubtypeNotRegistered(getKClassFromExpression(value), _this__u8e3s4.en());
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function findPolymorphicSerializer_0(_this__u8e3s4, decoder, klassName) {
    var tmp0_elvis_lhs = _this__u8e3s4.gn(decoder, klassName);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throwSubtypeNotRegistered_0(klassName, _this__u8e3s4.en());
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function descriptor$factory() {
    return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
      return receiver.pm();
    }, null);
  }
  function SealedClassSerializer_init_$Init$(serialName, baseClass, subclasses, subclassSerializers, classAnnotations, $this) {
    SealedClassSerializer.call($this, serialName, baseClass, subclasses, subclassSerializers);
    $this.jn_1 = asList(classAnnotations);
    return $this;
  }
  function SealedClassSerializer_init_$Create$(serialName, baseClass, subclasses, subclassSerializers, classAnnotations) {
    return SealedClassSerializer_init_$Init$(serialName, baseClass, subclasses, subclassSerializers, classAnnotations, objectCreate(protoOf(SealedClassSerializer)));
  }
  function SealedClassSerializer$descriptor$delegate$lambda$lambda$lambda(this$0) {
    return function ($this$buildSerialDescriptor) {
      // Inline function 'kotlin.collections.iterator' call
      var _iterator__ex2g4s = this$0.mn_1.g2().k();
      while (_iterator__ex2g4s.l()) {
        var element = _iterator__ex2g4s.m();
        // Inline function 'kotlinx.serialization.SealedClassSerializer.descriptor$delegate.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.collections.component1' call
        var name = element.z1();
        // Inline function 'kotlin.collections.component2' call
        var serializer = element.a2();
        $this$buildSerialDescriptor.an(name, serializer.pm());
      }
      return Unit_instance;
    };
  }
  function SealedClassSerializer$descriptor$delegate$lambda$lambda(this$0) {
    return function ($this$buildSerialDescriptor) {
      $this$buildSerialDescriptor.an('type', serializer_0(StringCompanionObject_instance).pm());
      var tmp = 'kotlinx.serialization.Sealed<' + this$0.in_1.ka() + '>';
      var tmp_0 = CONTEXTUAL_getInstance();
      var elementDescriptor = buildSerialDescriptor(tmp, tmp_0, [], SealedClassSerializer$descriptor$delegate$lambda$lambda$lambda(this$0));
      $this$buildSerialDescriptor.an('value', elementDescriptor);
      $this$buildSerialDescriptor.um_1 = this$0.jn_1;
      return Unit_instance;
    };
  }
  function SealedClassSerializer$descriptor$delegate$lambda($serialName, this$0) {
    return function () {
      var tmp = SEALED_getInstance();
      return buildSerialDescriptor($serialName, tmp, [], SealedClassSerializer$descriptor$delegate$lambda$lambda(this$0));
    };
  }
  function SealedClassSerializer$$inlined$groupingBy$1($this) {
    this.nn_1 = $this;
  }
  protoOf(SealedClassSerializer$$inlined$groupingBy$1).on = function () {
    return this.nn_1.k();
  };
  protoOf(SealedClassSerializer$$inlined$groupingBy$1).pn = function (element) {
    // Inline function 'kotlinx.serialization.SealedClassSerializer.<anonymous>' call
    return element.a2().pm().qn();
  };
  protoOf(SealedClassSerializer$$inlined$groupingBy$1).rn = function (element) {
    return this.pn((element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  function SealedClassSerializer(serialName, baseClass, subclasses, subclassSerializers) {
    AbstractPolymorphicSerializer.call(this);
    this.in_1 = baseClass;
    this.jn_1 = emptyList();
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.kn_1 = lazy(tmp_0, SealedClassSerializer$descriptor$delegate$lambda(serialName, this));
    if (!(subclasses.length === subclassSerializers.length)) {
      throw IllegalArgumentException_init_$Create$('All subclasses of sealed class ' + this.in_1.ka() + ' should be marked @Serializable');
    }
    this.ln_1 = toMap(zip(subclasses, subclassSerializers));
    var tmp_1 = this;
    // Inline function 'kotlin.collections.groupingBy' call
    var this_0 = this.ln_1.g2();
    // Inline function 'kotlin.collections.aggregate' call
    var tmp0 = new SealedClassSerializer$$inlined$groupingBy$1(this_0);
    // Inline function 'kotlin.collections.mutableMapOf' call
    // Inline function 'kotlin.collections.aggregateTo' call
    var destination = LinkedHashMap_init_$Create$();
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s = tmp0.on();
    while (_iterator__ex2g4s.l()) {
      var e = _iterator__ex2g4s.m();
      var key = tmp0.rn(e);
      var accumulator = destination.d2(key);
      // Inline function 'kotlinx.serialization.SealedClassSerializer.<anonymous>' call
      accumulator == null && !destination.b2(key);
      if (!(accumulator == null)) {
        // Inline function 'kotlin.error' call
        var message = "Multiple sealed subclasses of '" + toString(this.in_1) + "' have the same serial name '" + key + "':" + (" '" + toString(accumulator.z1()) + "', '" + toString(e.z1()) + "'");
        throw IllegalStateException_init_$Create$(toString(message));
      }
      // Inline function 'kotlin.collections.set' call
      destination.j2(key, e);
    }
    // Inline function 'kotlin.collections.mapValues' call
    // Inline function 'kotlin.collections.mapValuesTo' call
    var destination_0 = LinkedHashMap_init_$Create$_0(mapCapacity(destination.n()));
    // Inline function 'kotlin.collections.associateByTo' call
    var _iterator__ex2g4s_0 = destination.g2().k();
    while (_iterator__ex2g4s_0.l()) {
      var element = _iterator__ex2g4s_0.m();
      // Inline function 'kotlin.collections.mapValuesTo.<anonymous>' call
      var tmp_2 = element.z1();
      // Inline function 'kotlinx.serialization.SealedClassSerializer.<anonymous>' call
      var tmp$ret$7 = element.a2().a2();
      destination_0.j2(tmp_2, tmp$ret$7);
    }
    tmp_1.mn_1 = destination_0;
  }
  protoOf(SealedClassSerializer).en = function () {
    return this.in_1;
  };
  protoOf(SealedClassSerializer).pm = function () {
    var tmp0 = this.kn_1;
    // Inline function 'kotlin.getValue' call
    descriptor$factory_0();
    return tmp0.a2();
  };
  protoOf(SealedClassSerializer).gn = function (decoder, klassName) {
    // Inline function 'kotlin.collections.get' call
    var this_0 = this.mn_1;
    var tmp0_elvis_lhs = (isInterface(this_0, KtMap) ? this_0 : THROW_CCE()).d2(klassName);
    return tmp0_elvis_lhs == null ? protoOf(AbstractPolymorphicSerializer).gn.call(this, decoder, klassName) : tmp0_elvis_lhs;
  };
  protoOf(SealedClassSerializer).hn = function (encoder, value) {
    var tmp0_elvis_lhs = this.ln_1.d2(getKClassFromExpression(value));
    var tmp1_safe_receiver = tmp0_elvis_lhs == null ? protoOf(AbstractPolymorphicSerializer).hn.call(this, encoder, value) : tmp0_elvis_lhs;
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp = isInterface(tmp1_safe_receiver, SerializationStrategy) ? tmp1_safe_receiver : THROW_CCE();
    }
    return tmp;
  };
  function descriptor$factory_0() {
    return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
      return receiver.pm();
    }, null);
  }
  function SerializationException_init_$Init$($this) {
    IllegalArgumentException_init_$Init$($this);
    SerializationException.call($this);
    return $this;
  }
  function SerializationException_init_$Create$() {
    var tmp = SerializationException_init_$Init$(objectCreate(protoOf(SerializationException)));
    captureStack(tmp, SerializationException_init_$Create$);
    return tmp;
  }
  function SerializationException_init_$Init$_0(message, $this) {
    IllegalArgumentException_init_$Init$_0(message, $this);
    SerializationException.call($this);
    return $this;
  }
  function SerializationException_init_$Create$_0(message) {
    var tmp = SerializationException_init_$Init$_0(message, objectCreate(protoOf(SerializationException)));
    captureStack(tmp, SerializationException_init_$Create$_0);
    return tmp;
  }
  function SerializationException_init_$Init$_1(message, cause, $this) {
    IllegalArgumentException_init_$Init$_1(message, cause, $this);
    SerializationException.call($this);
    return $this;
  }
  function SerializationException() {
    captureStack(this, SerializationException);
  }
  function UnknownFieldException_init_$Init$(index, $this) {
    UnknownFieldException.call($this, 'An unknown field for index ' + index);
    return $this;
  }
  function UnknownFieldException_init_$Create$(index) {
    var tmp = UnknownFieldException_init_$Init$(index, objectCreate(protoOf(UnknownFieldException)));
    captureStack(tmp, UnknownFieldException_init_$Create$);
    return tmp;
  }
  function UnknownFieldException(message) {
    SerializationException_init_$Init$_0(message, this);
    captureStack(this, UnknownFieldException);
  }
  function MissingFieldException_init_$Init$(missingFields, serialName, $this) {
    MissingFieldException.call($this, missingFields, missingFields.n() === 1 ? "Field '" + missingFields.q(0) + "' is required for type with serial name '" + serialName + "', but it was missing" : 'Fields ' + toString(missingFields) + " are required for type with serial name '" + serialName + "', but they were missing", null);
    return $this;
  }
  function MissingFieldException_init_$Create$(missingFields, serialName) {
    var tmp = MissingFieldException_init_$Init$(missingFields, serialName, objectCreate(protoOf(MissingFieldException)));
    captureStack(tmp, MissingFieldException_init_$Create$);
    return tmp;
  }
  function MissingFieldException(missingFields, message, cause) {
    SerializationException_init_$Init$_1(message, cause, this);
    captureStack(this, MissingFieldException);
    this.sn_1 = missingFields;
  }
  function serializerOrNull(_this__u8e3s4) {
    var tmp0_elvis_lhs = compiledSerializerImpl(_this__u8e3s4);
    return tmp0_elvis_lhs == null ? builtinSerializerOrNull(_this__u8e3s4) : tmp0_elvis_lhs;
  }
  function serializersForParameters(_this__u8e3s4, typeArguments, failOnMissingTypeArgSerializer) {
    var tmp;
    if (failOnMissingTypeArgSerializer) {
      // Inline function 'kotlin.collections.map' call
      // Inline function 'kotlin.collections.mapTo' call
      var destination = ArrayList_init_$Create$(collectionSizeOrDefault(typeArguments, 10));
      var _iterator__ex2g4s = typeArguments.k();
      while (_iterator__ex2g4s.l()) {
        var item = _iterator__ex2g4s.m();
        // Inline function 'kotlinx.serialization.serializersForParameters.<anonymous>' call
        var tmp$ret$0 = serializer(_this__u8e3s4, item);
        destination.e(tmp$ret$0);
      }
      tmp = destination;
    } else {
      // Inline function 'kotlin.collections.map' call
      // Inline function 'kotlin.collections.mapTo' call
      var destination_0 = ArrayList_init_$Create$(collectionSizeOrDefault(typeArguments, 10));
      var _iterator__ex2g4s_0 = typeArguments.k();
      while (_iterator__ex2g4s_0.l()) {
        var item_0 = _iterator__ex2g4s_0.m();
        // Inline function 'kotlinx.serialization.serializersForParameters.<anonymous>' call
        var tmp0_elvis_lhs = serializerOrNull_0(_this__u8e3s4, item_0);
        var tmp_0;
        if (tmp0_elvis_lhs == null) {
          return null;
        } else {
          tmp_0 = tmp0_elvis_lhs;
        }
        var tmp$ret$3 = tmp_0;
        destination_0.e(tmp$ret$3);
      }
      tmp = destination_0;
    }
    var serializers = tmp;
    return serializers;
  }
  function parametrizedSerializerOrNull(_this__u8e3s4, serializers, elementClassifierIfArray) {
    var tmp0_elvis_lhs = builtinParametrizedSerializer(_this__u8e3s4, serializers, elementClassifierIfArray);
    return tmp0_elvis_lhs == null ? compiledParametrizedSerializer(_this__u8e3s4, serializers) : tmp0_elvis_lhs;
  }
  function serializer(_this__u8e3s4, type) {
    var tmp0_elvis_lhs = serializerByKTypeImpl(_this__u8e3s4, type, true);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      platformSpecificSerializerNotRegistered(kclass(type));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function serializerOrNull_0(_this__u8e3s4, type) {
    return serializerByKTypeImpl(_this__u8e3s4, type, false);
  }
  function builtinParametrizedSerializer(_this__u8e3s4, serializers, elementClassifierIfArray) {
    var tmp;
    if (((_this__u8e3s4.equals(getKClass(Collection)) ? true : _this__u8e3s4.equals(getKClass(KtList))) ? true : _this__u8e3s4.equals(getKClass(KtMutableList))) ? true : _this__u8e3s4.equals(getKClass(ArrayList))) {
      tmp = new ArrayListSerializer(serializers.q(0));
    } else if (_this__u8e3s4.equals(getKClass(HashSet))) {
      tmp = new HashSetSerializer(serializers.q(0));
    } else if ((_this__u8e3s4.equals(getKClass(KtSet)) ? true : _this__u8e3s4.equals(getKClass(KtMutableSet))) ? true : _this__u8e3s4.equals(getKClass(LinkedHashSet))) {
      tmp = new LinkedHashSetSerializer(serializers.q(0));
    } else if (_this__u8e3s4.equals(getKClass(HashMap))) {
      tmp = new HashMapSerializer(serializers.q(0), serializers.q(1));
    } else if ((_this__u8e3s4.equals(getKClass(KtMap)) ? true : _this__u8e3s4.equals(getKClass(KtMutableMap))) ? true : _this__u8e3s4.equals(getKClass(LinkedHashMap))) {
      tmp = new LinkedHashMapSerializer(serializers.q(0), serializers.q(1));
    } else if (_this__u8e3s4.equals(getKClass(Entry))) {
      tmp = MapEntrySerializer(serializers.q(0), serializers.q(1));
    } else if (_this__u8e3s4.equals(getKClass(Pair))) {
      tmp = PairSerializer(serializers.q(0), serializers.q(1));
    } else if (_this__u8e3s4.equals(getKClass(Triple))) {
      tmp = TripleSerializer(serializers.q(0), serializers.q(1), serializers.q(2));
    } else {
      var tmp_0;
      if (isReferenceArray(_this__u8e3s4)) {
        var tmp_1 = elementClassifierIfArray();
        tmp_0 = ArraySerializer((!(tmp_1 == null) ? isInterface(tmp_1, KClass) : false) ? tmp_1 : THROW_CCE(), serializers.q(0));
      } else {
        tmp_0 = null;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  function compiledParametrizedSerializer(_this__u8e3s4, serializers) {
    // Inline function 'kotlin.collections.toTypedArray' call
    var tmp$ret$0 = copyToArray(serializers);
    return constructSerializerForGivenTypeArgs(_this__u8e3s4, tmp$ret$0.slice());
  }
  function serializerByKTypeImpl(_this__u8e3s4, type, failOnMissingTypeArgSerializer) {
    var rootClass = kclass(type);
    var isNullable = type.ab();
    // Inline function 'kotlin.collections.map' call
    var this_0 = type.za();
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList_init_$Create$(collectionSizeOrDefault(this_0, 10));
    var _iterator__ex2g4s = this_0.k();
    while (_iterator__ex2g4s.l()) {
      var item = _iterator__ex2g4s.m();
      // Inline function 'kotlinx.serialization.serializerByKTypeImpl.<anonymous>' call
      var tmp0 = item.hj_1;
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlin.requireNotNull' call
        if (tmp0 == null) {
          // Inline function 'kotlinx.serialization.serializerByKTypeImpl.<anonymous>.<anonymous>' call
          var message = 'Star projections in type arguments are not allowed, but had ' + toString(type);
          throw IllegalArgumentException_init_$Create$(toString(message));
        } else {
          tmp$ret$1 = tmp0;
          break $l$block;
        }
      }
      var tmp$ret$2 = tmp$ret$1;
      destination.e(tmp$ret$2);
    }
    var typeArguments = destination;
    var tmp;
    if (typeArguments.p()) {
      tmp = findCachedSerializer(rootClass, isNullable);
    } else {
      // Inline function 'kotlin.Result.getOrNull' call
      var this_1 = findParametrizedCachedSerializer(rootClass, typeArguments, isNullable);
      var tmp_0;
      if (_Result___get_isFailure__impl__jpiriv(this_1)) {
        tmp_0 = null;
      } else {
        var tmp_1 = _Result___get_value__impl__bjfvqg(this_1);
        tmp_0 = (tmp_1 == null ? true : !(tmp_1 == null)) ? tmp_1 : THROW_CCE();
      }
      tmp = tmp_0;
    }
    var cachedSerializer = tmp;
    if (cachedSerializer == null)
      null;
    else {
      // Inline function 'kotlin.let' call
      return cachedSerializer;
    }
    var tmp_2;
    if (typeArguments.p()) {
      tmp_2 = _this__u8e3s4.un(rootClass);
    } else {
      var tmp1_elvis_lhs = serializersForParameters(_this__u8e3s4, typeArguments, failOnMissingTypeArgSerializer);
      var tmp_3;
      if (tmp1_elvis_lhs == null) {
        return null;
      } else {
        tmp_3 = tmp1_elvis_lhs;
      }
      var serializers = tmp_3;
      var tmp2_elvis_lhs = parametrizedSerializerOrNull(rootClass, serializers, serializerByKTypeImpl$lambda(typeArguments));
      tmp_2 = tmp2_elvis_lhs == null ? _this__u8e3s4.tn(rootClass, serializers) : tmp2_elvis_lhs;
    }
    var contextualSerializer = tmp_2;
    var tmp_4;
    if (contextualSerializer == null) {
      tmp_4 = null;
    } else {
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp_4 = isInterface(contextualSerializer, KSerializer) ? contextualSerializer : THROW_CCE();
    }
    var tmp4_safe_receiver = tmp_4;
    return tmp4_safe_receiver == null ? null : nullable(tmp4_safe_receiver, isNullable);
  }
  function nullable(_this__u8e3s4, shouldBeNullable) {
    if (shouldBeNullable)
      return get_nullable(_this__u8e3s4);
    return isInterface(_this__u8e3s4, KSerializer) ? _this__u8e3s4 : THROW_CCE();
  }
  function serializerByKTypeImpl$lambda($typeArguments) {
    return function () {
      return $typeArguments.q(0).ya();
    };
  }
  function get_SERIALIZERS_CACHE() {
    _init_properties_SerializersCache_kt__hgwi2p();
    return SERIALIZERS_CACHE;
  }
  var SERIALIZERS_CACHE;
  function get_SERIALIZERS_CACHE_NULLABLE() {
    _init_properties_SerializersCache_kt__hgwi2p();
    return SERIALIZERS_CACHE_NULLABLE;
  }
  var SERIALIZERS_CACHE_NULLABLE;
  function get_PARAMETRIZED_SERIALIZERS_CACHE() {
    _init_properties_SerializersCache_kt__hgwi2p();
    return PARAMETRIZED_SERIALIZERS_CACHE;
  }
  var PARAMETRIZED_SERIALIZERS_CACHE;
  function get_PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE() {
    _init_properties_SerializersCache_kt__hgwi2p();
    return PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE;
  }
  var PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE;
  function findCachedSerializer(clazz, isNullable) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var tmp;
    if (!isNullable) {
      var tmp0_safe_receiver = get_SERIALIZERS_CACHE().vn(clazz);
      var tmp_0;
      if (tmp0_safe_receiver == null) {
        tmp_0 = null;
      } else {
        // Inline function 'kotlinx.serialization.internal.cast' call
        tmp_0 = isInterface(tmp0_safe_receiver, KSerializer) ? tmp0_safe_receiver : THROW_CCE();
      }
      tmp = tmp_0;
    } else {
      tmp = get_SERIALIZERS_CACHE_NULLABLE().vn(clazz);
    }
    return tmp;
  }
  function findParametrizedCachedSerializer(clazz, types, isNullable) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var tmp;
    if (!isNullable) {
      var tmp_0 = get_PARAMETRIZED_SERIALIZERS_CACHE().wn(clazz, types);
      tmp = new Result(tmp_0) instanceof Result ? tmp_0 : THROW_CCE();
    } else {
      tmp = get_PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE().wn(clazz, types);
    }
    return tmp;
  }
  function SERIALIZERS_CACHE$lambda(it) {
    _init_properties_SerializersCache_kt__hgwi2p();
    return serializerOrNull(it);
  }
  function SERIALIZERS_CACHE_NULLABLE$lambda(it) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var tmp0_safe_receiver = serializerOrNull(it);
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_nullable(tmp0_safe_receiver);
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp = isInterface(tmp1_safe_receiver, KSerializer) ? tmp1_safe_receiver : THROW_CCE();
    }
    return tmp;
  }
  function PARAMETRIZED_SERIALIZERS_CACHE$lambda(clazz, types) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var serializers = ensureNotNull(serializersForParameters(EmptySerializersModule_0(), types, true));
    return parametrizedSerializerOrNull(clazz, serializers, PARAMETRIZED_SERIALIZERS_CACHE$lambda$lambda(types));
  }
  function PARAMETRIZED_SERIALIZERS_CACHE$lambda$lambda($types) {
    return function () {
      return $types.q(0).ya();
    };
  }
  function PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda(clazz, types) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var serializers = ensureNotNull(serializersForParameters(EmptySerializersModule_0(), types, true));
    var tmp0_safe_receiver = parametrizedSerializerOrNull(clazz, serializers, PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda$lambda(types));
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_nullable(tmp0_safe_receiver);
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp = isInterface(tmp1_safe_receiver, KSerializer) ? tmp1_safe_receiver : THROW_CCE();
    }
    return tmp;
  }
  function PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda$lambda($types) {
    return function () {
      return $types.q(0).ya();
    };
  }
  var properties_initialized_SerializersCache_kt_q8kf25;
  function _init_properties_SerializersCache_kt__hgwi2p() {
    if (!properties_initialized_SerializersCache_kt_q8kf25) {
      properties_initialized_SerializersCache_kt_q8kf25 = true;
      SERIALIZERS_CACHE = createCache(SERIALIZERS_CACHE$lambda);
      SERIALIZERS_CACHE_NULLABLE = createCache(SERIALIZERS_CACHE_NULLABLE$lambda);
      PARAMETRIZED_SERIALIZERS_CACHE = createParametrizedCache(PARAMETRIZED_SERIALIZERS_CACHE$lambda);
      PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE = createParametrizedCache(PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda);
    }
  }
  function get_nullable(_this__u8e3s4) {
    var tmp;
    if (_this__u8e3s4.pm().xn()) {
      tmp = isInterface(_this__u8e3s4, KSerializer) ? _this__u8e3s4 : THROW_CCE();
    } else {
      tmp = new NullableSerializer(_this__u8e3s4);
    }
    return tmp;
  }
  function serializer_0(_this__u8e3s4) {
    return StringSerializer_getInstance();
  }
  function serializer_1(_this__u8e3s4) {
    return CharSerializer_getInstance();
  }
  function CharArraySerializer() {
    return CharArraySerializer_getInstance();
  }
  function serializer_2(_this__u8e3s4) {
    return DoubleSerializer_getInstance();
  }
  function DoubleArraySerializer() {
    return DoubleArraySerializer_getInstance();
  }
  function serializer_3(_this__u8e3s4) {
    return FloatSerializer_getInstance();
  }
  function FloatArraySerializer() {
    return FloatArraySerializer_getInstance();
  }
  function serializer_4(_this__u8e3s4) {
    return LongSerializer_getInstance();
  }
  function LongArraySerializer() {
    return LongArraySerializer_getInstance();
  }
  function serializer_5(_this__u8e3s4) {
    return ULongSerializer_getInstance();
  }
  function ULongArraySerializer() {
    return ULongArraySerializer_getInstance();
  }
  function serializer_6(_this__u8e3s4) {
    return IntSerializer_getInstance();
  }
  function IntArraySerializer() {
    return IntArraySerializer_getInstance();
  }
  function serializer_7(_this__u8e3s4) {
    return UIntSerializer_getInstance();
  }
  function UIntArraySerializer() {
    return UIntArraySerializer_getInstance();
  }
  function serializer_8(_this__u8e3s4) {
    return ShortSerializer_getInstance();
  }
  function ShortArraySerializer() {
    return ShortArraySerializer_getInstance();
  }
  function serializer_9(_this__u8e3s4) {
    return UShortSerializer_getInstance();
  }
  function UShortArraySerializer() {
    return UShortArraySerializer_getInstance();
  }
  function serializer_10(_this__u8e3s4) {
    return ByteSerializer_getInstance();
  }
  function ByteArraySerializer() {
    return ByteArraySerializer_getInstance();
  }
  function serializer_11(_this__u8e3s4) {
    return UByteSerializer_getInstance();
  }
  function UByteArraySerializer() {
    return UByteArraySerializer_getInstance();
  }
  function serializer_12(_this__u8e3s4) {
    return BooleanSerializer_getInstance();
  }
  function BooleanArraySerializer() {
    return BooleanArraySerializer_getInstance();
  }
  function serializer_13(_this__u8e3s4) {
    return UnitSerializer_getInstance();
  }
  function NothingSerializer() {
    return NothingSerializer_getInstance();
  }
  function serializer_14(_this__u8e3s4) {
    return DurationSerializer_getInstance();
  }
  function MapEntrySerializer(keySerializer, valueSerializer) {
    return new MapEntrySerializer_0(keySerializer, valueSerializer);
  }
  function PairSerializer(keySerializer, valueSerializer) {
    return new PairSerializer_0(keySerializer, valueSerializer);
  }
  function TripleSerializer(aSerializer, bSerializer, cSerializer) {
    return new TripleSerializer_0(aSerializer, bSerializer, cSerializer);
  }
  function ArraySerializer(kClass, elementSerializer) {
    return new ReferenceArraySerializer(kClass, elementSerializer);
  }
  function MapSerializer(keySerializer, valueSerializer) {
    return new LinkedHashMapSerializer(keySerializer, valueSerializer);
  }
  function ListSerializer(elementSerializer) {
    return new ArrayListSerializer(elementSerializer);
  }
  function withContext(_this__u8e3s4, context) {
    return new ContextDescriptor(_this__u8e3s4, context);
  }
  function ContextDescriptor(original, kClass) {
    this.yn_1 = original;
    this.zn_1 = kClass;
    this.ao_1 = this.yn_1.qn() + '<' + this.zn_1.ka() + '>';
  }
  protoOf(ContextDescriptor).bo = function () {
    return this.yn_1.bo();
  };
  protoOf(ContextDescriptor).co = function () {
    return this.yn_1.co();
  };
  protoOf(ContextDescriptor).do = function () {
    return this.yn_1.do();
  };
  protoOf(ContextDescriptor).xn = function () {
    return this.yn_1.xn();
  };
  protoOf(ContextDescriptor).eo = function () {
    return this.yn_1.eo();
  };
  protoOf(ContextDescriptor).fo = function (index) {
    return this.yn_1.fo(index);
  };
  protoOf(ContextDescriptor).go = function (index) {
    return this.yn_1.go(index);
  };
  protoOf(ContextDescriptor).ho = function (name) {
    return this.yn_1.ho(name);
  };
  protoOf(ContextDescriptor).io = function (index) {
    return this.yn_1.io(index);
  };
  protoOf(ContextDescriptor).jo = function (index) {
    return this.yn_1.jo(index);
  };
  protoOf(ContextDescriptor).qn = function () {
    return this.ao_1;
  };
  protoOf(ContextDescriptor).equals = function (other) {
    var tmp0_elvis_lhs = other instanceof ContextDescriptor ? other : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var another = tmp;
    return equals(this.yn_1, another.yn_1) && another.zn_1.equals(this.zn_1);
  };
  protoOf(ContextDescriptor).hashCode = function () {
    var result = this.zn_1.hashCode();
    result = imul(31, result) + getStringHashCode(this.ao_1) | 0;
    return result;
  };
  protoOf(ContextDescriptor).toString = function () {
    return 'ContextDescriptor(kClass: ' + toString(this.zn_1) + ', original: ' + toString(this.yn_1) + ')';
  };
  function getContextualDescriptor(_this__u8e3s4, descriptor) {
    var tmp0_safe_receiver = get_capturedKClass(descriptor);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.let' call
      // Inline function 'kotlinx.serialization.descriptors.getContextualDescriptor.<anonymous>' call
      var tmp0_safe_receiver_0 = _this__u8e3s4.un(tmp0_safe_receiver);
      tmp = tmp0_safe_receiver_0 == null ? null : tmp0_safe_receiver_0.pm();
    }
    return tmp;
  }
  function get_capturedKClass(_this__u8e3s4) {
    var tmp;
    if (_this__u8e3s4 instanceof ContextDescriptor) {
      tmp = _this__u8e3s4.zn_1;
    } else {
      if (_this__u8e3s4 instanceof SerialDescriptorForNullable) {
        tmp = get_capturedKClass(_this__u8e3s4.ko_1);
      } else {
        tmp = null;
      }
    }
    return tmp;
  }
  function SerialDescriptor() {
  }
  function get_elementDescriptors(_this__u8e3s4) {
    // Inline function 'kotlin.collections.Iterable' call
    return new elementDescriptors$$inlined$Iterable$1(_this__u8e3s4);
  }
  function get_elementNames(_this__u8e3s4) {
    // Inline function 'kotlin.collections.Iterable' call
    return new elementNames$$inlined$Iterable$1(_this__u8e3s4);
  }
  function elementDescriptors$1($this_elementDescriptors) {
    this.oo_1 = $this_elementDescriptors;
    this.no_1 = $this_elementDescriptors.co();
  }
  protoOf(elementDescriptors$1).l = function () {
    return this.no_1 > 0;
  };
  protoOf(elementDescriptors$1).m = function () {
    var tmp = this.oo_1.co();
    var tmp1 = this.no_1;
    this.no_1 = tmp1 - 1 | 0;
    return this.oo_1.go(tmp - tmp1 | 0);
  };
  function elementDescriptors$$inlined$Iterable$1($this_elementDescriptors) {
    this.po_1 = $this_elementDescriptors;
  }
  protoOf(elementDescriptors$$inlined$Iterable$1).k = function () {
    // Inline function 'kotlinx.serialization.descriptors.<get-elementDescriptors>.<anonymous>' call
    return new elementDescriptors$1(this.po_1);
  };
  function elementNames$1($this_elementNames) {
    this.ro_1 = $this_elementNames;
    this.qo_1 = $this_elementNames.co();
  }
  protoOf(elementNames$1).l = function () {
    return this.qo_1 > 0;
  };
  protoOf(elementNames$1).m = function () {
    var tmp = this.ro_1.co();
    var tmp1 = this.qo_1;
    this.qo_1 = tmp1 - 1 | 0;
    return this.ro_1.io(tmp - tmp1 | 0);
  };
  function elementNames$$inlined$Iterable$1($this_elementNames) {
    this.so_1 = $this_elementNames;
  }
  protoOf(elementNames$$inlined$Iterable$1).k = function () {
    // Inline function 'kotlinx.serialization.descriptors.<get-elementNames>.<anonymous>' call
    return new elementNames$1(this.so_1);
  };
  function buildSerialDescriptor(serialName, kind, typeParameters, builder) {
    var tmp;
    if (builder === VOID) {
      tmp = buildSerialDescriptor$lambda;
    } else {
      tmp = builder;
    }
    builder = tmp;
    // Inline function 'kotlin.text.isNotBlank' call
    // Inline function 'kotlin.require' call
    if (!!isBlank(serialName)) {
      // Inline function 'kotlinx.serialization.descriptors.buildSerialDescriptor.<anonymous>' call
      var message = 'Blank serial names are prohibited';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.require' call
    if (!!equals(kind, CLASS_getInstance())) {
      // Inline function 'kotlinx.serialization.descriptors.buildSerialDescriptor.<anonymous>' call
      var message_0 = "For StructureKind.CLASS please use 'buildClassSerialDescriptor' instead";
      throw IllegalArgumentException_init_$Create$(toString(message_0));
    }
    var sdBuilder = new ClassSerialDescriptorBuilder(serialName);
    builder(sdBuilder);
    return new SerialDescriptorImpl(serialName, kind, sdBuilder.vm_1.n(), toList(typeParameters), sdBuilder);
  }
  function ClassSerialDescriptorBuilder(serialName) {
    this.sm_1 = serialName;
    this.tm_1 = false;
    this.um_1 = emptyList();
    this.vm_1 = ArrayList_init_$Create$_0();
    this.wm_1 = HashSet_init_$Create$();
    this.xm_1 = ArrayList_init_$Create$_0();
    this.ym_1 = ArrayList_init_$Create$_0();
    this.zm_1 = ArrayList_init_$Create$_0();
  }
  protoOf(ClassSerialDescriptorBuilder).to = function (elementName, descriptor, annotations, isOptional) {
    // Inline function 'kotlin.require' call
    if (!this.wm_1.e(elementName)) {
      // Inline function 'kotlinx.serialization.descriptors.ClassSerialDescriptorBuilder.element.<anonymous>' call
      var message = "Element with name '" + elementName + "' is already registered in " + this.sm_1;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.collections.plusAssign' call
    this.vm_1.e(elementName);
    // Inline function 'kotlin.collections.plusAssign' call
    this.xm_1.e(descriptor);
    // Inline function 'kotlin.collections.plusAssign' call
    this.ym_1.e(annotations);
    // Inline function 'kotlin.collections.plusAssign' call
    this.zm_1.e(isOptional);
  };
  protoOf(ClassSerialDescriptorBuilder).an = function (elementName, descriptor, annotations, isOptional, $super) {
    annotations = annotations === VOID ? emptyList() : annotations;
    isOptional = isOptional === VOID ? false : isOptional;
    var tmp;
    if ($super === VOID) {
      this.to(elementName, descriptor, annotations, isOptional);
      tmp = Unit_instance;
    } else {
      tmp = $super.to.call(this, elementName, descriptor, annotations, isOptional);
    }
    return tmp;
  };
  function buildClassSerialDescriptor(serialName, typeParameters, builderAction) {
    var tmp;
    if (builderAction === VOID) {
      tmp = buildClassSerialDescriptor$lambda;
    } else {
      tmp = builderAction;
    }
    builderAction = tmp;
    // Inline function 'kotlin.text.isNotBlank' call
    // Inline function 'kotlin.require' call
    if (!!isBlank(serialName)) {
      // Inline function 'kotlinx.serialization.descriptors.buildClassSerialDescriptor.<anonymous>' call
      var message = 'Blank serial names are prohibited';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var sdBuilder = new ClassSerialDescriptorBuilder(serialName);
    builderAction(sdBuilder);
    return new SerialDescriptorImpl(serialName, CLASS_getInstance(), sdBuilder.vm_1.n(), toList(typeParameters), sdBuilder);
  }
  function _get__hashCode__tgwhef($this) {
    var tmp0 = $this.fp_1;
    // Inline function 'kotlin.getValue' call
    _hashCode$factory();
    return tmp0.a2();
  }
  function SerialDescriptorImpl$_hashCode$delegate$lambda(this$0) {
    return function () {
      return hashCodeImpl(this$0, this$0.ep_1);
    };
  }
  function SerialDescriptorImpl$toString$lambda(this$0) {
    return function (it) {
      return this$0.io(it) + ': ' + this$0.go(it).qn();
    };
  }
  function SerialDescriptorImpl(serialName, kind, elementsCount, typeParameters, builder) {
    this.uo_1 = serialName;
    this.vo_1 = kind;
    this.wo_1 = elementsCount;
    this.xo_1 = builder.um_1;
    this.yo_1 = toHashSet(builder.vm_1);
    var tmp = this;
    // Inline function 'kotlin.collections.toTypedArray' call
    var this_0 = builder.vm_1;
    tmp.zo_1 = copyToArray(this_0);
    this.ap_1 = compactArray(builder.xm_1);
    var tmp_0 = this;
    // Inline function 'kotlin.collections.toTypedArray' call
    var this_1 = builder.ym_1;
    tmp_0.bp_1 = copyToArray(this_1);
    this.cp_1 = toBooleanArray(builder.zm_1);
    var tmp_1 = this;
    // Inline function 'kotlin.collections.map' call
    var this_2 = withIndex(this.zo_1);
    // Inline function 'kotlin.collections.mapTo' call
    var destination = ArrayList_init_$Create$(collectionSizeOrDefault(this_2, 10));
    var _iterator__ex2g4s = this_2.k();
    while (_iterator__ex2g4s.l()) {
      var item = _iterator__ex2g4s.m();
      // Inline function 'kotlinx.serialization.descriptors.SerialDescriptorImpl.name2Index.<anonymous>' call
      var tmp$ret$2 = to(item.bf_1, item.af_1);
      destination.e(tmp$ret$2);
    }
    tmp_1.dp_1 = toMap(destination);
    this.ep_1 = compactArray(typeParameters);
    var tmp_2 = this;
    tmp_2.fp_1 = lazy_0(SerialDescriptorImpl$_hashCode$delegate$lambda(this));
  }
  protoOf(SerialDescriptorImpl).qn = function () {
    return this.uo_1;
  };
  protoOf(SerialDescriptorImpl).eo = function () {
    return this.vo_1;
  };
  protoOf(SerialDescriptorImpl).co = function () {
    return this.wo_1;
  };
  protoOf(SerialDescriptorImpl).bo = function () {
    return this.xo_1;
  };
  protoOf(SerialDescriptorImpl).gp = function () {
    return this.yo_1;
  };
  protoOf(SerialDescriptorImpl).io = function (index) {
    return getChecked(this.zo_1, index);
  };
  protoOf(SerialDescriptorImpl).ho = function (name) {
    var tmp0_elvis_lhs = this.dp_1.d2(name);
    return tmp0_elvis_lhs == null ? -3 : tmp0_elvis_lhs;
  };
  protoOf(SerialDescriptorImpl).fo = function (index) {
    return getChecked(this.bp_1, index);
  };
  protoOf(SerialDescriptorImpl).go = function (index) {
    return getChecked(this.ap_1, index);
  };
  protoOf(SerialDescriptorImpl).jo = function (index) {
    return getChecked_0(this.cp_1, index);
  };
  protoOf(SerialDescriptorImpl).equals = function (other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof SerialDescriptorImpl)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.qn() === other.qn())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      // Inline function 'kotlinx.serialization.descriptors.SerialDescriptorImpl.equals.<anonymous>' call
      if (!contentEquals(this.ep_1, other.ep_1)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.co() === other.co())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.co();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.go(index).qn() === other.go(index).qn())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.go(index).eo(), other.go(index).eo())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(SerialDescriptorImpl).hashCode = function () {
    return _get__hashCode__tgwhef(this);
  };
  protoOf(SerialDescriptorImpl).toString = function () {
    var tmp = until(0, this.wo_1);
    var tmp_0 = this.uo_1 + '(';
    return joinToString(tmp, ', ', tmp_0, ')', VOID, VOID, SerialDescriptorImpl$toString$lambda(this));
  };
  function PrimitiveSerialDescriptor(serialName, kind) {
    // Inline function 'kotlin.text.isNotBlank' call
    // Inline function 'kotlin.require' call
    if (!!isBlank(serialName)) {
      // Inline function 'kotlinx.serialization.descriptors.PrimitiveSerialDescriptor.<anonymous>' call
      var message = 'Blank serial names are prohibited';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return PrimitiveDescriptorSafe(serialName, kind);
  }
  function buildSerialDescriptor$lambda($this$null) {
    return Unit_instance;
  }
  function buildClassSerialDescriptor$lambda($this$null) {
    return Unit_instance;
  }
  function _hashCode$factory() {
    return getPropertyCallableRef('_hashCode', 1, KProperty1, function (receiver) {
      return _get__hashCode__tgwhef(receiver);
    }, null);
  }
  function ENUM() {
    ENUM_instance = this;
    SerialKind.call(this);
  }
  var ENUM_instance;
  function ENUM_getInstance() {
    if (ENUM_instance == null)
      new ENUM();
    return ENUM_instance;
  }
  function CONTEXTUAL() {
    CONTEXTUAL_instance = this;
    SerialKind.call(this);
  }
  var CONTEXTUAL_instance;
  function CONTEXTUAL_getInstance() {
    if (CONTEXTUAL_instance == null)
      new CONTEXTUAL();
    return CONTEXTUAL_instance;
  }
  function SerialKind() {
  }
  protoOf(SerialKind).toString = function () {
    return ensureNotNull(getKClassFromExpression(this).ka());
  };
  protoOf(SerialKind).hashCode = function () {
    return getStringHashCode(this.toString());
  };
  function BOOLEAN() {
    BOOLEAN_instance = this;
    PrimitiveKind.call(this);
  }
  var BOOLEAN_instance;
  function BOOLEAN_getInstance() {
    if (BOOLEAN_instance == null)
      new BOOLEAN();
    return BOOLEAN_instance;
  }
  function BYTE() {
    BYTE_instance = this;
    PrimitiveKind.call(this);
  }
  var BYTE_instance;
  function BYTE_getInstance() {
    if (BYTE_instance == null)
      new BYTE();
    return BYTE_instance;
  }
  function CHAR() {
    CHAR_instance = this;
    PrimitiveKind.call(this);
  }
  var CHAR_instance;
  function CHAR_getInstance() {
    if (CHAR_instance == null)
      new CHAR();
    return CHAR_instance;
  }
  function SHORT() {
    SHORT_instance = this;
    PrimitiveKind.call(this);
  }
  var SHORT_instance;
  function SHORT_getInstance() {
    if (SHORT_instance == null)
      new SHORT();
    return SHORT_instance;
  }
  function INT() {
    INT_instance = this;
    PrimitiveKind.call(this);
  }
  var INT_instance;
  function INT_getInstance() {
    if (INT_instance == null)
      new INT();
    return INT_instance;
  }
  function LONG() {
    LONG_instance = this;
    PrimitiveKind.call(this);
  }
  var LONG_instance;
  function LONG_getInstance() {
    if (LONG_instance == null)
      new LONG();
    return LONG_instance;
  }
  function FLOAT() {
    FLOAT_instance = this;
    PrimitiveKind.call(this);
  }
  var FLOAT_instance;
  function FLOAT_getInstance() {
    if (FLOAT_instance == null)
      new FLOAT();
    return FLOAT_instance;
  }
  function DOUBLE() {
    DOUBLE_instance = this;
    PrimitiveKind.call(this);
  }
  var DOUBLE_instance;
  function DOUBLE_getInstance() {
    if (DOUBLE_instance == null)
      new DOUBLE();
    return DOUBLE_instance;
  }
  function STRING() {
    STRING_instance = this;
    PrimitiveKind.call(this);
  }
  var STRING_instance;
  function STRING_getInstance() {
    if (STRING_instance == null)
      new STRING();
    return STRING_instance;
  }
  function PrimitiveKind() {
    SerialKind.call(this);
  }
  function CLASS() {
    CLASS_instance = this;
    StructureKind.call(this);
  }
  var CLASS_instance;
  function CLASS_getInstance() {
    if (CLASS_instance == null)
      new CLASS();
    return CLASS_instance;
  }
  function LIST() {
    LIST_instance = this;
    StructureKind.call(this);
  }
  var LIST_instance;
  function LIST_getInstance() {
    if (LIST_instance == null)
      new LIST();
    return LIST_instance;
  }
  function MAP() {
    MAP_instance = this;
    StructureKind.call(this);
  }
  var MAP_instance;
  function MAP_getInstance() {
    if (MAP_instance == null)
      new MAP();
    return MAP_instance;
  }
  function OBJECT() {
    OBJECT_instance = this;
    StructureKind.call(this);
  }
  var OBJECT_instance;
  function OBJECT_getInstance() {
    if (OBJECT_instance == null)
      new OBJECT();
    return OBJECT_instance;
  }
  function StructureKind() {
    SerialKind.call(this);
  }
  function SEALED() {
    SEALED_instance = this;
    PolymorphicKind.call(this);
  }
  var SEALED_instance;
  function SEALED_getInstance() {
    if (SEALED_instance == null)
      new SEALED();
    return SEALED_instance;
  }
  function OPEN() {
    OPEN_instance = this;
    PolymorphicKind.call(this);
  }
  var OPEN_instance;
  function OPEN_getInstance() {
    if (OPEN_instance == null)
      new OPEN();
    return OPEN_instance;
  }
  function PolymorphicKind() {
    SerialKind.call(this);
  }
  function AbstractDecoder() {
  }
  protoOf(AbstractDecoder).hp = function () {
    throw SerializationException_init_$Create$_0(toString(getKClassFromExpression(this)) + " can't retrieve untyped values");
  };
  protoOf(AbstractDecoder).ip = function () {
    return true;
  };
  protoOf(AbstractDecoder).jp = function () {
    return null;
  };
  protoOf(AbstractDecoder).kp = function () {
    var tmp = this.hp();
    return typeof tmp === 'boolean' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).lp = function () {
    var tmp = this.hp();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).mp = function () {
    var tmp = this.hp();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).np = function () {
    var tmp = this.hp();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).op = function () {
    var tmp = this.hp();
    return tmp instanceof Long ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).pp = function () {
    var tmp = this.hp();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).qp = function () {
    var tmp = this.hp();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).rp = function () {
    var tmp = this.hp();
    return tmp instanceof Char ? tmp.m1_1 : THROW_CCE();
  };
  protoOf(AbstractDecoder).sp = function () {
    var tmp = this.hp();
    return typeof tmp === 'string' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).tp = function (enumDescriptor) {
    var tmp = this.hp();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).up = function (descriptor) {
    return this;
  };
  protoOf(AbstractDecoder).vp = function (deserializer, previousValue) {
    return this.wp(deserializer);
  };
  protoOf(AbstractDecoder).xp = function (descriptor) {
    return this;
  };
  protoOf(AbstractDecoder).yp = function (descriptor) {
  };
  protoOf(AbstractDecoder).zp = function (descriptor, index) {
    return this.kp();
  };
  protoOf(AbstractDecoder).aq = function (descriptor, index) {
    return this.lp();
  };
  protoOf(AbstractDecoder).bq = function (descriptor, index) {
    return this.mp();
  };
  protoOf(AbstractDecoder).cq = function (descriptor, index) {
    return this.np();
  };
  protoOf(AbstractDecoder).dq = function (descriptor, index) {
    return this.op();
  };
  protoOf(AbstractDecoder).eq = function (descriptor, index) {
    return this.pp();
  };
  protoOf(AbstractDecoder).fq = function (descriptor, index) {
    return this.qp();
  };
  protoOf(AbstractDecoder).gq = function (descriptor, index) {
    return this.rp();
  };
  protoOf(AbstractDecoder).hq = function (descriptor, index) {
    return this.sp();
  };
  protoOf(AbstractDecoder).iq = function (descriptor, index) {
    return this.up(descriptor.go(index));
  };
  protoOf(AbstractDecoder).jq = function (descriptor, index, deserializer, previousValue) {
    return this.vp(deserializer, previousValue);
  };
  protoOf(AbstractDecoder).lq = function (descriptor, index, deserializer, previousValue) {
    // Inline function 'kotlinx.serialization.encoding.decodeIfNullable' call
    var isNullabilitySupported = deserializer.pm().xn();
    var tmp;
    if (isNullabilitySupported || this.ip()) {
      // Inline function 'kotlinx.serialization.encoding.AbstractDecoder.decodeNullableSerializableElement.<anonymous>' call
      tmp = this.vp(deserializer, previousValue);
    } else {
      tmp = this.jp();
    }
    return tmp;
  };
  function AbstractEncoder() {
  }
  protoOf(AbstractEncoder).xp = function (descriptor) {
    return this;
  };
  protoOf(AbstractEncoder).yp = function (descriptor) {
  };
  protoOf(AbstractEncoder).qq = function (descriptor, index) {
    return true;
  };
  protoOf(AbstractEncoder).rq = function (value) {
    throw SerializationException_init_$Create$_0('Non-serializable ' + toString(getKClassFromExpression(value)) + ' is not supported by ' + toString(getKClassFromExpression(this)) + ' encoder');
  };
  protoOf(AbstractEncoder).sq = function () {
    throw SerializationException_init_$Create$_0("'null' is not supported by default");
  };
  protoOf(AbstractEncoder).tq = function (value) {
    return this.rq(value);
  };
  protoOf(AbstractEncoder).uq = function (value) {
    return this.rq(value);
  };
  protoOf(AbstractEncoder).vq = function (value) {
    return this.rq(value);
  };
  protoOf(AbstractEncoder).wq = function (value) {
    return this.rq(value);
  };
  protoOf(AbstractEncoder).xq = function (value) {
    return this.rq(value);
  };
  protoOf(AbstractEncoder).yq = function (value) {
    return this.rq(value);
  };
  protoOf(AbstractEncoder).zq = function (value) {
    return this.rq(value);
  };
  protoOf(AbstractEncoder).ar = function (value) {
    return this.rq(new Char(value));
  };
  protoOf(AbstractEncoder).br = function (value) {
    return this.rq(value);
  };
  protoOf(AbstractEncoder).cr = function (enumDescriptor, index) {
    return this.rq(index);
  };
  protoOf(AbstractEncoder).dr = function (descriptor) {
    return this;
  };
  protoOf(AbstractEncoder).er = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.tq(value);
    }
  };
  protoOf(AbstractEncoder).fr = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.uq(value);
    }
  };
  protoOf(AbstractEncoder).gr = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.vq(value);
    }
  };
  protoOf(AbstractEncoder).hr = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.wq(value);
    }
  };
  protoOf(AbstractEncoder).ir = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.xq(value);
    }
  };
  protoOf(AbstractEncoder).jr = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.yq(value);
    }
  };
  protoOf(AbstractEncoder).kr = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.zq(value);
    }
  };
  protoOf(AbstractEncoder).lr = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.ar(value);
    }
  };
  protoOf(AbstractEncoder).mr = function (descriptor, index, value) {
    if (this.qq(descriptor, index)) {
      this.br(value);
    }
  };
  protoOf(AbstractEncoder).nr = function (descriptor, index) {
    return this.qq(descriptor, index) ? this.dr(descriptor.go(index)) : NoOpEncoder_getInstance();
  };
  protoOf(AbstractEncoder).or = function (descriptor, index, serializer, value) {
    if (this.qq(descriptor, index)) {
      this.pr(serializer, value);
    }
  };
  protoOf(AbstractEncoder).qr = function (descriptor, index, serializer, value) {
    if (this.qq(descriptor, index)) {
      this.rr(serializer, value);
    }
  };
  function Decoder() {
  }
  function CompositeDecoder() {
  }
  function Encoder() {
  }
  function CompositeEncoder() {
  }
  function decodeSequentially_0($this, compositeDecoder) {
    var klassName = compositeDecoder.hq($this.pm(), 0);
    var serializer = findPolymorphicSerializer_0($this, compositeDecoder, klassName);
    return compositeDecoder.kq($this.pm(), 1, serializer);
  }
  function AbstractPolymorphicSerializer() {
  }
  protoOf(AbstractPolymorphicSerializer).fn = function (encoder, value) {
    var actualSerializer = findPolymorphicSerializer(this, encoder, value);
    // Inline function 'kotlinx.serialization.encoding.encodeStructure' call
    var descriptor = this.pm();
    var composite = encoder.xp(descriptor);
    // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.serialize.<anonymous>' call
    composite.mr(this.pm(), 0, actualSerializer.pm().qn());
    var tmp = this.pm();
    // Inline function 'kotlinx.serialization.internal.cast' call
    var tmp$ret$0 = isInterface(actualSerializer, SerializationStrategy) ? actualSerializer : THROW_CCE();
    composite.or(tmp, 1, tmp$ret$0, value);
    composite.yp(descriptor);
  };
  protoOf(AbstractPolymorphicSerializer).qm = function (encoder, value) {
    return this.fn(encoder, !(value == null) ? value : THROW_CCE());
  };
  protoOf(AbstractPolymorphicSerializer).rm = function (decoder) {
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var descriptor = this.pm();
    var composite = decoder.xp(descriptor);
    var tmp$ret$0;
    $l$block: {
      // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>' call
      var klassName = null;
      var value = null;
      if (composite.nq()) {
        tmp$ret$0 = decodeSequentially_0(this, composite);
        break $l$block;
      }
      mainLoop: while (true) {
        var index = composite.oq(this.pm());
        switch (index) {
          case -1:
            break mainLoop;
          case 0:
            klassName = composite.hq(this.pm(), index);
            break;
          case 1:
            var tmp0 = klassName;
            var tmp$ret$2;
            $l$block_0: {
              // Inline function 'kotlin.requireNotNull' call
              if (tmp0 == null) {
                // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>.<anonymous>' call
                var message = 'Cannot read polymorphic value before its type token';
                throw IllegalArgumentException_init_$Create$(toString(message));
              } else {
                tmp$ret$2 = tmp0;
                break $l$block_0;
              }
            }

            klassName = tmp$ret$2;
            var serializer = findPolymorphicSerializer_0(this, composite, klassName);
            value = composite.kq(this.pm(), index, serializer);
            break;
          default:
            var tmp0_elvis_lhs = klassName;
            throw SerializationException_init_$Create$_0('Invalid index in polymorphic deserialization of ' + (tmp0_elvis_lhs == null ? 'unknown class' : tmp0_elvis_lhs) + ('\n Expected 0, 1 or DECODE_DONE(-1), but found ' + index));
        }
      }
      var tmp1 = value;
      var tmp$ret$4;
      $l$block_1: {
        // Inline function 'kotlin.requireNotNull' call
        if (tmp1 == null) {
          // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>.<anonymous>' call
          var message_0 = 'Polymorphic value has not been read for class ' + klassName;
          throw IllegalArgumentException_init_$Create$(toString(message_0));
        } else {
          tmp$ret$4 = tmp1;
          break $l$block_1;
        }
      }
      var tmp = tmp$ret$4;
      tmp$ret$0 = !(tmp == null) ? tmp : THROW_CCE();
    }
    var result = tmp$ret$0;
    composite.yp(descriptor);
    return result;
  };
  protoOf(AbstractPolymorphicSerializer).gn = function (decoder, klassName) {
    return decoder.mq().vr(this.en(), klassName);
  };
  protoOf(AbstractPolymorphicSerializer).hn = function (encoder, value) {
    return encoder.mq().wr(this.en(), value);
  };
  function throwSubtypeNotRegistered(subClass, baseClass) {
    var tmp0_elvis_lhs = subClass.ka();
    throwSubtypeNotRegistered_0(tmp0_elvis_lhs == null ? toString(subClass) : tmp0_elvis_lhs, baseClass);
  }
  function throwSubtypeNotRegistered_0(subClassName, baseClass) {
    var scope = "in the polymorphic scope of '" + baseClass.ka() + "'";
    throw SerializationException_init_$Create$_0(subClassName == null ? 'Class discriminator was missing and no default serializers were registered ' + scope + '.' : "Serializer for subclass '" + subClassName + "' is not found " + scope + '.\n' + ("Check if class with serial name '" + subClassName + "' exists and serializer is registered in a corresponding SerializersModule.\n") + ("To be registered automatically, class '" + subClassName + "' has to be '@Serializable', and the base class '" + baseClass.ka() + "' has to be sealed and '@Serializable'."));
  }
  function NothingSerializer_0() {
    NothingSerializer_instance = this;
    this.xr_1 = NothingSerialDescriptor_getInstance();
  }
  protoOf(NothingSerializer_0).pm = function () {
    return this.xr_1;
  };
  protoOf(NothingSerializer_0).yr = function (encoder, value) {
    throw SerializationException_init_$Create$_0("'kotlin.Nothing' cannot be serialized");
  };
  protoOf(NothingSerializer_0).qm = function (encoder, value) {
    var tmp;
    if (false) {
      tmp = value;
    } else {
      tmp = THROW_CCE();
    }
    return this.yr(encoder, tmp);
  };
  protoOf(NothingSerializer_0).rm = function (decoder) {
    throw SerializationException_init_$Create$_0("'kotlin.Nothing' does not have instances");
  };
  var NothingSerializer_instance;
  function NothingSerializer_getInstance() {
    if (NothingSerializer_instance == null)
      new NothingSerializer_0();
    return NothingSerializer_instance;
  }
  function DurationSerializer() {
    DurationSerializer_instance = this;
    this.zr_1 = new PrimitiveSerialDescriptor_0('kotlin.time.Duration', STRING_getInstance());
  }
  protoOf(DurationSerializer).pm = function () {
    return this.zr_1;
  };
  protoOf(DurationSerializer).as = function (encoder, value) {
    encoder.br(Duration__toIsoString_impl_9h6wsm(value));
  };
  protoOf(DurationSerializer).qm = function (encoder, value) {
    return this.as(encoder, value instanceof Duration ? value.fk_1 : THROW_CCE());
  };
  protoOf(DurationSerializer).bs = function (decoder) {
    return Companion_getInstance().ek(decoder.sp());
  };
  protoOf(DurationSerializer).rm = function (decoder) {
    return new Duration(this.bs(decoder));
  };
  var DurationSerializer_instance;
  function DurationSerializer_getInstance() {
    if (DurationSerializer_instance == null)
      new DurationSerializer();
    return DurationSerializer_instance;
  }
  function CachedNames() {
  }
  function ArrayListClassDesc(elementDesc) {
    ListLikeDescriptor.call(this, elementDesc);
  }
  protoOf(ArrayListClassDesc).qn = function () {
    return 'kotlin.collections.ArrayList';
  };
  function HashSetClassDesc(elementDesc) {
    ListLikeDescriptor.call(this, elementDesc);
  }
  protoOf(HashSetClassDesc).qn = function () {
    return 'kotlin.collections.HashSet';
  };
  function LinkedHashSetClassDesc(elementDesc) {
    ListLikeDescriptor.call(this, elementDesc);
  }
  protoOf(LinkedHashSetClassDesc).qn = function () {
    return 'kotlin.collections.LinkedHashSet';
  };
  function HashMapClassDesc(keyDesc, valueDesc) {
    MapLikeDescriptor.call(this, 'kotlin.collections.HashMap', keyDesc, valueDesc);
  }
  function LinkedHashMapClassDesc(keyDesc, valueDesc) {
    MapLikeDescriptor.call(this, 'kotlin.collections.LinkedHashMap', keyDesc, valueDesc);
  }
  function ArrayClassDesc(elementDesc) {
    ListLikeDescriptor.call(this, elementDesc);
  }
  protoOf(ArrayClassDesc).qn = function () {
    return 'kotlin.Array';
  };
  function ListLikeDescriptor(elementDescriptor) {
    this.es_1 = elementDescriptor;
    this.fs_1 = 1;
  }
  protoOf(ListLikeDescriptor).eo = function () {
    return LIST_getInstance();
  };
  protoOf(ListLikeDescriptor).co = function () {
    return this.fs_1;
  };
  protoOf(ListLikeDescriptor).io = function (index) {
    return index.toString();
  };
  protoOf(ListLikeDescriptor).ho = function (name) {
    var tmp0_elvis_lhs = toIntOrNull(name);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException_init_$Create$(name + ' is not a valid list index');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(ListLikeDescriptor).jo = function (index) {
    // Inline function 'kotlin.require' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.isElementOptional.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.qn() + ' expects only non-negative indices';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return false;
  };
  protoOf(ListLikeDescriptor).fo = function (index) {
    // Inline function 'kotlin.require' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.getElementAnnotations.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.qn() + ' expects only non-negative indices';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return emptyList();
  };
  protoOf(ListLikeDescriptor).go = function (index) {
    // Inline function 'kotlin.require' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.getElementDescriptor.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.qn() + ' expects only non-negative indices';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return this.es_1;
  };
  protoOf(ListLikeDescriptor).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ListLikeDescriptor))
      return false;
    if (equals(this.es_1, other.es_1) && this.qn() === other.qn())
      return true;
    return false;
  };
  protoOf(ListLikeDescriptor).hashCode = function () {
    return imul(hashCode(this.es_1), 31) + getStringHashCode(this.qn()) | 0;
  };
  protoOf(ListLikeDescriptor).toString = function () {
    return this.qn() + '(' + toString(this.es_1) + ')';
  };
  function MapLikeDescriptor(serialName, keyDescriptor, valueDescriptor) {
    this.ks_1 = serialName;
    this.ls_1 = keyDescriptor;
    this.ms_1 = valueDescriptor;
    this.ns_1 = 2;
  }
  protoOf(MapLikeDescriptor).qn = function () {
    return this.ks_1;
  };
  protoOf(MapLikeDescriptor).eo = function () {
    return MAP_getInstance();
  };
  protoOf(MapLikeDescriptor).co = function () {
    return this.ns_1;
  };
  protoOf(MapLikeDescriptor).io = function (index) {
    return index.toString();
  };
  protoOf(MapLikeDescriptor).ho = function (name) {
    var tmp0_elvis_lhs = toIntOrNull(name);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException_init_$Create$(name + ' is not a valid map index');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(MapLikeDescriptor).jo = function (index) {
    // Inline function 'kotlin.require' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.isElementOptional.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.qn() + ' expects only non-negative indices';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return false;
  };
  protoOf(MapLikeDescriptor).fo = function (index) {
    // Inline function 'kotlin.require' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.getElementAnnotations.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.qn() + ' expects only non-negative indices';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return emptyList();
  };
  protoOf(MapLikeDescriptor).go = function (index) {
    // Inline function 'kotlin.require' call
    if (!(index >= 0)) {
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.getElementDescriptor.<anonymous>' call
      var message = 'Illegal index ' + index + ', ' + this.qn() + ' expects only non-negative indices';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var tmp;
    switch (index % 2 | 0) {
      case 0:
        tmp = this.ls_1;
        break;
      case 1:
        tmp = this.ms_1;
        break;
      default:
        var message_0 = 'Unreached';
        throw IllegalStateException_init_$Create$(toString(message_0));
    }
    return tmp;
  };
  protoOf(MapLikeDescriptor).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MapLikeDescriptor))
      return false;
    if (!(this.qn() === other.qn()))
      return false;
    if (!equals(this.ls_1, other.ls_1))
      return false;
    if (!equals(this.ms_1, other.ms_1))
      return false;
    return true;
  };
  protoOf(MapLikeDescriptor).hashCode = function () {
    var result = getStringHashCode(this.qn());
    result = imul(31, result) + hashCode(this.ls_1) | 0;
    result = imul(31, result) + hashCode(this.ms_1) | 0;
    return result;
  };
  protoOf(MapLikeDescriptor).toString = function () {
    return this.qn() + '(' + toString(this.ls_1) + ', ' + toString(this.ms_1) + ')';
  };
  function PrimitiveArrayDescriptor(primitive) {
    ListLikeDescriptor.call(this, primitive);
    this.ss_1 = primitive.qn() + 'Array';
  }
  protoOf(PrimitiveArrayDescriptor).qn = function () {
    return this.ss_1;
  };
  function ArrayListSerializer(element) {
    CollectionSerializer.call(this, element);
    this.us_1 = new ArrayListClassDesc(element.pm());
  }
  protoOf(ArrayListSerializer).pm = function () {
    return this.us_1;
  };
  protoOf(ArrayListSerializer).vs = function () {
    // Inline function 'kotlin.collections.arrayListOf' call
    return ArrayList_init_$Create$_0();
  };
  protoOf(ArrayListSerializer).ws = function (_this__u8e3s4) {
    return _this__u8e3s4.n();
  };
  protoOf(ArrayListSerializer).xs = function (_this__u8e3s4) {
    return this.ws(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ArrayListSerializer).ys = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(ArrayListSerializer).zs = function (_this__u8e3s4) {
    return this.ys(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ArrayListSerializer).at = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? ArrayList_init_$Create$_1(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(ArrayListSerializer).bt = function (_this__u8e3s4) {
    return this.at((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtList) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ArrayListSerializer).ct = function (_this__u8e3s4, size) {
    return _this__u8e3s4.b5(size);
  };
  protoOf(ArrayListSerializer).dt = function (_this__u8e3s4, size) {
    return this.ct(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(ArrayListSerializer).et = function (_this__u8e3s4, index, element) {
    _this__u8e3s4.l4(index, element);
  };
  protoOf(ArrayListSerializer).ft = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE();
    return this.et(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  function HashSetSerializer(eSerializer) {
    CollectionSerializer.call(this, eSerializer);
    this.qt_1 = new HashSetClassDesc(eSerializer.pm());
  }
  protoOf(HashSetSerializer).pm = function () {
    return this.qt_1;
  };
  protoOf(HashSetSerializer).vs = function () {
    return HashSet_init_$Create$();
  };
  protoOf(HashSetSerializer).rt = function (_this__u8e3s4) {
    return _this__u8e3s4.n();
  };
  protoOf(HashSetSerializer).xs = function (_this__u8e3s4) {
    return this.rt(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashSetSerializer).st = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(HashSetSerializer).zs = function (_this__u8e3s4) {
    return this.st(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashSetSerializer).tt = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? HashSet_init_$Create$_0(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(HashSetSerializer).bt = function (_this__u8e3s4) {
    return this.tt((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtSet) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashSetSerializer).ut = function (_this__u8e3s4, size) {
  };
  protoOf(HashSetSerializer).dt = function (_this__u8e3s4, size) {
    return this.ut(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(HashSetSerializer).vt = function (_this__u8e3s4, index, element) {
    _this__u8e3s4.e(element);
  };
  protoOf(HashSetSerializer).ft = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE();
    return this.vt(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  function LinkedHashSetSerializer(eSerializer) {
    CollectionSerializer.call(this, eSerializer);
    this.xt_1 = new LinkedHashSetClassDesc(eSerializer.pm());
  }
  protoOf(LinkedHashSetSerializer).pm = function () {
    return this.xt_1;
  };
  protoOf(LinkedHashSetSerializer).vs = function () {
    // Inline function 'kotlin.collections.linkedSetOf' call
    return LinkedHashSet_init_$Create$();
  };
  protoOf(LinkedHashSetSerializer).yt = function (_this__u8e3s4) {
    return _this__u8e3s4.n();
  };
  protoOf(LinkedHashSetSerializer).xs = function (_this__u8e3s4) {
    return this.yt(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashSetSerializer).zt = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(LinkedHashSetSerializer).zs = function (_this__u8e3s4) {
    return this.zt(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashSetSerializer).tt = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? LinkedHashSet_init_$Create$_0(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(LinkedHashSetSerializer).bt = function (_this__u8e3s4) {
    return this.tt((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtSet) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashSetSerializer).au = function (_this__u8e3s4, size) {
  };
  protoOf(LinkedHashSetSerializer).dt = function (_this__u8e3s4, size) {
    return this.au(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(LinkedHashSetSerializer).bu = function (_this__u8e3s4, index, element) {
    _this__u8e3s4.e(element);
  };
  protoOf(LinkedHashSetSerializer).ft = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE();
    return this.bu(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  function HashMapSerializer(kSerializer, vSerializer) {
    MapLikeSerializer.call(this, kSerializer, vSerializer);
    this.eu_1 = new HashMapClassDesc(kSerializer.pm(), vSerializer.pm());
  }
  protoOf(HashMapSerializer).pm = function () {
    return this.eu_1;
  };
  protoOf(HashMapSerializer).fu = function (_this__u8e3s4) {
    return _this__u8e3s4.n();
  };
  protoOf(HashMapSerializer).gu = function (_this__u8e3s4) {
    return this.fu((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtMap) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).hu = function (_this__u8e3s4) {
    // Inline function 'kotlin.collections.iterator' call
    return _this__u8e3s4.g2().k();
  };
  protoOf(HashMapSerializer).iu = function (_this__u8e3s4) {
    return this.hu((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtMap) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).vs = function () {
    return HashMap_init_$Create$();
  };
  protoOf(HashMapSerializer).ju = function (_this__u8e3s4) {
    return imul(_this__u8e3s4.n(), 2);
  };
  protoOf(HashMapSerializer).xs = function (_this__u8e3s4) {
    return this.ju(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).ku = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(HashMapSerializer).zs = function (_this__u8e3s4) {
    return this.ku(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).lu = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? HashMap_init_$Create$_0(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(HashMapSerializer).bt = function (_this__u8e3s4) {
    return this.lu((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtMap) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).mu = function (_this__u8e3s4, size) {
  };
  protoOf(HashMapSerializer).dt = function (_this__u8e3s4, size) {
    return this.mu(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE(), size);
  };
  function LinkedHashMapSerializer(kSerializer, vSerializer) {
    MapLikeSerializer.call(this, kSerializer, vSerializer);
    this.tu_1 = new LinkedHashMapClassDesc(kSerializer.pm(), vSerializer.pm());
  }
  protoOf(LinkedHashMapSerializer).pm = function () {
    return this.tu_1;
  };
  protoOf(LinkedHashMapSerializer).fu = function (_this__u8e3s4) {
    return _this__u8e3s4.n();
  };
  protoOf(LinkedHashMapSerializer).gu = function (_this__u8e3s4) {
    return this.fu((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtMap) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).hu = function (_this__u8e3s4) {
    // Inline function 'kotlin.collections.iterator' call
    return _this__u8e3s4.g2().k();
  };
  protoOf(LinkedHashMapSerializer).iu = function (_this__u8e3s4) {
    return this.hu((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtMap) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).vs = function () {
    return LinkedHashMap_init_$Create$();
  };
  protoOf(LinkedHashMapSerializer).uu = function (_this__u8e3s4) {
    return imul(_this__u8e3s4.n(), 2);
  };
  protoOf(LinkedHashMapSerializer).xs = function (_this__u8e3s4) {
    return this.uu(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).vu = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(LinkedHashMapSerializer).zs = function (_this__u8e3s4) {
    return this.vu(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).lu = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? LinkedHashMap_init_$Create$_1(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(LinkedHashMapSerializer).bt = function (_this__u8e3s4) {
    return this.lu((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, KtMap) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).wu = function (_this__u8e3s4, size) {
  };
  protoOf(LinkedHashMapSerializer).dt = function (_this__u8e3s4, size) {
    return this.wu(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE(), size);
  };
  function ReferenceArraySerializer(kClass, eSerializer) {
    CollectionLikeSerializer.call(this, eSerializer);
    this.yu_1 = kClass;
    this.zu_1 = new ArrayClassDesc(eSerializer.pm());
  }
  protoOf(ReferenceArraySerializer).pm = function () {
    return this.zu_1;
  };
  protoOf(ReferenceArraySerializer).av = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(ReferenceArraySerializer).gu = function (_this__u8e3s4) {
    return this.av((!(_this__u8e3s4 == null) ? isArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).bv = function (_this__u8e3s4) {
    return arrayIterator(_this__u8e3s4);
  };
  protoOf(ReferenceArraySerializer).iu = function (_this__u8e3s4) {
    return this.bv((!(_this__u8e3s4 == null) ? isArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).vs = function () {
    // Inline function 'kotlin.collections.arrayListOf' call
    return ArrayList_init_$Create$_0();
  };
  protoOf(ReferenceArraySerializer).cv = function (_this__u8e3s4) {
    return _this__u8e3s4.n();
  };
  protoOf(ReferenceArraySerializer).xs = function (_this__u8e3s4) {
    return this.cv(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).dv = function (_this__u8e3s4) {
    return toNativeArrayImpl(_this__u8e3s4, this.yu_1);
  };
  protoOf(ReferenceArraySerializer).zs = function (_this__u8e3s4) {
    return this.dv(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).ev = function (_this__u8e3s4) {
    return ArrayList_init_$Create$_1(asList(_this__u8e3s4));
  };
  protoOf(ReferenceArraySerializer).bt = function (_this__u8e3s4) {
    return this.ev((!(_this__u8e3s4 == null) ? isArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).fv = function (_this__u8e3s4, size) {
    return _this__u8e3s4.b5(size);
  };
  protoOf(ReferenceArraySerializer).dt = function (_this__u8e3s4, size) {
    return this.fv(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(ReferenceArraySerializer).gv = function (_this__u8e3s4, index, element) {
    _this__u8e3s4.l4(index, element);
  };
  protoOf(ReferenceArraySerializer).ft = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE();
    return this.gv(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  function CollectionSerializer(element) {
    CollectionLikeSerializer.call(this, element);
  }
  protoOf(CollectionSerializer).ht = function (_this__u8e3s4) {
    return _this__u8e3s4.n();
  };
  protoOf(CollectionSerializer).gu = function (_this__u8e3s4) {
    return this.ht((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Collection) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(CollectionSerializer).it = function (_this__u8e3s4) {
    return _this__u8e3s4.k();
  };
  protoOf(CollectionSerializer).iu = function (_this__u8e3s4) {
    return this.it((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Collection) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  function MapLikeSerializer(keySerializer, valueSerializer) {
    AbstractCollectionSerializer.call(this);
    this.nu_1 = keySerializer;
    this.ou_1 = valueSerializer;
  }
  protoOf(MapLikeSerializer).pu = function (decoder, builder, startIndex, size) {
    // Inline function 'kotlin.require' call
    if (!(size >= 0)) {
      // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readAll.<anonymous>' call
      var message = 'Size must be known in advance when using READ_ALL';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var progression = step(until(0, imul(size, 2)), 2);
    var inductionVariable = progression.a1_1;
    var last = progression.b1_1;
    var step_0 = progression.c1_1;
    if (step_0 > 0 && inductionVariable <= last || (step_0 < 0 && last <= inductionVariable))
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        this.qu(decoder, startIndex + index | 0, builder, false);
      }
       while (!(index === last));
  };
  protoOf(MapLikeSerializer).lt = function (decoder, builder, startIndex, size) {
    return this.pu(decoder, (!(builder == null) ? isInterface(builder, KtMutableMap) : false) ? builder : THROW_CCE(), startIndex, size);
  };
  protoOf(MapLikeSerializer).qu = function (decoder, index, builder, checkIndex) {
    var key = decoder.kq(this.pm(), index, this.nu_1);
    var tmp;
    if (checkIndex) {
      // Inline function 'kotlin.also' call
      var this_0 = decoder.oq(this.pm());
      // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readElement.<anonymous>' call
      // Inline function 'kotlin.require' call
      if (!(this_0 === (index + 1 | 0))) {
        // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readElement.<anonymous>.<anonymous>' call
        var message = 'Value must follow key in a map, index for key: ' + index + ', returned index for value: ' + this_0;
        throw IllegalArgumentException_init_$Create$(toString(message));
      }
      tmp = this_0;
    } else {
      tmp = index + 1 | 0;
    }
    var vIndex = tmp;
    var tmp_0;
    var tmp_1;
    if (builder.b2(key)) {
      var tmp_2 = this.ou_1.pm().eo();
      tmp_1 = !(tmp_2 instanceof PrimitiveKind);
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = decoder.jq(this.pm(), vIndex, this.ou_1, getValue(builder, key));
    } else {
      tmp_0 = decoder.kq(this.pm(), vIndex, this.ou_1);
    }
    var value = tmp_0;
    // Inline function 'kotlin.collections.set' call
    builder.j2(key, value);
  };
  protoOf(MapLikeSerializer).mt = function (decoder, index, builder, checkIndex) {
    return this.qu(decoder, index, (!(builder == null) ? isInterface(builder, KtMutableMap) : false) ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(MapLikeSerializer).kt = function (encoder, value) {
    var size = this.gu(value);
    // Inline function 'kotlinx.serialization.encoding.encodeCollection' call
    var descriptor = this.pm();
    var composite = encoder.tr(descriptor, size);
    // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.serialize.<anonymous>' call
    var iterator = this.iu(value);
    var index = 0;
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s = iterator;
    while (_iterator__ex2g4s.l()) {
      var element = _iterator__ex2g4s.m();
      // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.serialize.<anonymous>.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var k = element.z1();
      // Inline function 'kotlin.collections.component2' call
      var v = element.a2();
      var tmp = this.pm();
      var tmp0 = index;
      index = tmp0 + 1 | 0;
      composite.or(tmp, tmp0, this.nu_1, k);
      var tmp_0 = this.pm();
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      composite.or(tmp_0, tmp1, this.ou_1, v);
    }
    composite.yp(descriptor);
  };
  protoOf(MapLikeSerializer).qm = function (encoder, value) {
    return this.kt(encoder, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  };
  function CollectionLikeSerializer(elementSerializer) {
    AbstractCollectionSerializer.call(this);
    this.jt_1 = elementSerializer;
  }
  protoOf(CollectionLikeSerializer).kt = function (encoder, value) {
    var size = this.gu(value);
    // Inline function 'kotlinx.serialization.encoding.encodeCollection' call
    var descriptor = this.pm();
    var composite = encoder.tr(descriptor, size);
    // Inline function 'kotlinx.serialization.internal.CollectionLikeSerializer.serialize.<anonymous>' call
    var iterator = this.iu(value);
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        composite.or(this.pm(), index, this.jt_1, iterator.m());
      }
       while (inductionVariable < size);
    composite.yp(descriptor);
  };
  protoOf(CollectionLikeSerializer).qm = function (encoder, value) {
    return this.kt(encoder, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  };
  protoOf(CollectionLikeSerializer).lt = function (decoder, builder, startIndex, size) {
    // Inline function 'kotlin.require' call
    if (!(size >= 0)) {
      // Inline function 'kotlinx.serialization.internal.CollectionLikeSerializer.readAll.<anonymous>' call
      var message = 'Size must be known in advance when using READ_ALL';
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.mt(decoder, startIndex + index | 0, builder, false);
      }
       while (inductionVariable < size);
  };
  protoOf(CollectionLikeSerializer).mt = function (decoder, index, builder, checkIndex) {
    this.ft(builder, index, decoder.kq(this.pm(), index, this.jt_1));
  };
  function readSize($this, decoder, builder) {
    var size = decoder.pq($this.pm());
    $this.dt(builder, size);
    return size;
  }
  function AbstractCollectionSerializer() {
  }
  protoOf(AbstractCollectionSerializer).ot = function (decoder, previous) {
    var tmp1_elvis_lhs = previous == null ? null : this.bt(previous);
    var builder = tmp1_elvis_lhs == null ? this.vs() : tmp1_elvis_lhs;
    var startIndex = this.xs(builder);
    var compositeDecoder = decoder.xp(this.pm());
    if (compositeDecoder.nq()) {
      this.lt(compositeDecoder, builder, startIndex, readSize(this, compositeDecoder, builder));
    } else {
      $l$loop: while (true) {
        var index = compositeDecoder.oq(this.pm());
        if (index === -1)
          break $l$loop;
        this.nt(compositeDecoder, startIndex + index | 0, builder);
      }
    }
    compositeDecoder.yp(this.pm());
    return this.zs(builder);
  };
  protoOf(AbstractCollectionSerializer).rm = function (decoder) {
    return this.ot(decoder, null);
  };
  protoOf(AbstractCollectionSerializer).nt = function (decoder, index, builder, checkIndex, $super) {
    checkIndex = checkIndex === VOID ? true : checkIndex;
    var tmp;
    if ($super === VOID) {
      this.mt(decoder, index, builder, checkIndex);
      tmp = Unit_instance;
    } else {
      tmp = $super.mt.call(this, decoder, index, builder, checkIndex);
    }
    return tmp;
  };
  function PrimitiveArraySerializer(primitiveSerializer) {
    CollectionLikeSerializer.call(this, primitiveSerializer);
    this.iv_1 = new PrimitiveArrayDescriptor(primitiveSerializer.pm());
  }
  protoOf(PrimitiveArraySerializer).pm = function () {
    return this.iv_1;
  };
  protoOf(PrimitiveArraySerializer).jv = function (_this__u8e3s4) {
    return _this__u8e3s4.kv();
  };
  protoOf(PrimitiveArraySerializer).xs = function (_this__u8e3s4) {
    return this.jv(_this__u8e3s4 instanceof PrimitiveArrayBuilder ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).lv = function (_this__u8e3s4) {
    return _this__u8e3s4.mv();
  };
  protoOf(PrimitiveArraySerializer).zs = function (_this__u8e3s4) {
    return this.lv(_this__u8e3s4 instanceof PrimitiveArrayBuilder ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).nv = function (_this__u8e3s4, size) {
    return _this__u8e3s4.ov(size);
  };
  protoOf(PrimitiveArraySerializer).dt = function (_this__u8e3s4, size) {
    return this.nv(_this__u8e3s4 instanceof PrimitiveArrayBuilder ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(PrimitiveArraySerializer).pv = function (_this__u8e3s4) {
    var message = 'This method lead to boxing and must not be used, use writeContents instead';
    throw IllegalStateException_init_$Create$(toString(message));
  };
  protoOf(PrimitiveArraySerializer).iu = function (_this__u8e3s4) {
    return this.pv((_this__u8e3s4 == null ? true : !(_this__u8e3s4 == null)) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).qv = function (_this__u8e3s4, index, element) {
    var message = 'This method lead to boxing and must not be used, use Builder.append instead';
    throw IllegalStateException_init_$Create$(toString(message));
  };
  protoOf(PrimitiveArraySerializer).ft = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof PrimitiveArrayBuilder ? _this__u8e3s4 : THROW_CCE();
    return this.qv(tmp, index, (element == null ? true : !(element == null)) ? element : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).vs = function () {
    return this.bt(this.rv());
  };
  protoOf(PrimitiveArraySerializer).uv = function (encoder, value) {
    var size = this.gu(value);
    // Inline function 'kotlinx.serialization.encoding.encodeCollection' call
    var descriptor = this.iv_1;
    var composite = encoder.tr(descriptor, size);
    // Inline function 'kotlinx.serialization.internal.PrimitiveArraySerializer.serialize.<anonymous>' call
    this.tv(composite, value, size);
    composite.yp(descriptor);
  };
  protoOf(PrimitiveArraySerializer).qm = function (encoder, value) {
    return this.uv(encoder, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).kt = function (encoder, value) {
    return this.uv(encoder, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).rm = function (decoder) {
    return this.ot(decoder, null);
  };
  function PrimitiveArrayBuilder() {
  }
  protoOf(PrimitiveArrayBuilder).vv = function (requiredCapacity, $super) {
    requiredCapacity = requiredCapacity === VOID ? this.kv() + 1 | 0 : requiredCapacity;
    var tmp;
    if ($super === VOID) {
      this.ov(requiredCapacity);
      tmp = Unit_instance;
    } else {
      tmp = $super.ov.call(this, requiredCapacity);
    }
    return tmp;
  };
  function Companion() {
    Companion_instance_0 = this;
    this.wv_1 = longArray(0);
  }
  var Companion_instance_0;
  function Companion_getInstance_6() {
    if (Companion_instance_0 == null)
      new Companion();
    return Companion_instance_0;
  }
  function prepareHighMarksArray($this, elementsCount) {
    var slotsCount = (elementsCount - 1 | 0) >>> 6 | 0;
    var elementsInLastSlot = elementsCount & 63;
    var highMarks = longArray(slotsCount);
    if (!(elementsInLastSlot === 0)) {
      highMarks[get_lastIndex(highMarks)] = (new Long(-1, -1)).z2(elementsCount);
    }
    return highMarks;
  }
  function markHigh($this, index) {
    var slot = (index >>> 6 | 0) - 1 | 0;
    var offsetInSlot = index & 63;
    $this.aw_1[slot] = $this.aw_1[slot].d3((new Long(1, 0)).z2(offsetInSlot));
  }
  function nextUnmarkedHighIndex($this) {
    var inductionVariable = 0;
    var last = $this.aw_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var slot = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var slotOffset = imul(slot + 1 | 0, 64);
        var slotMarks = $this.aw_1[slot];
        while (!slotMarks.equals(new Long(-1, -1))) {
          var indexInSlot = countTrailingZeroBits(slotMarks.y2());
          slotMarks = slotMarks.d3((new Long(1, 0)).z2(indexInSlot));
          var index = slotOffset + indexInSlot | 0;
          if ($this.yv_1($this.xv_1, index)) {
            $this.aw_1[slot] = slotMarks;
            return index;
          }
        }
        $this.aw_1[slot] = slotMarks;
      }
       while (inductionVariable <= last);
    return -1;
  }
  function ElementMarker(descriptor, readIfAbsent) {
    Companion_getInstance_6();
    this.xv_1 = descriptor;
    this.yv_1 = readIfAbsent;
    var elementsCount = this.xv_1.co();
    if (elementsCount <= 64) {
      var tmp = this;
      var tmp_0;
      if (elementsCount === 64) {
        tmp_0 = new Long(0, 0);
      } else {
        tmp_0 = (new Long(-1, -1)).z2(elementsCount);
      }
      tmp.zv_1 = tmp_0;
      this.aw_1 = Companion_getInstance_6().wv_1;
    } else {
      this.zv_1 = new Long(0, 0);
      this.aw_1 = prepareHighMarksArray(this, elementsCount);
    }
  }
  protoOf(ElementMarker).bw = function (index) {
    if (index < 64) {
      this.zv_1 = this.zv_1.d3((new Long(1, 0)).z2(index));
    } else {
      markHigh(this, index);
    }
  };
  protoOf(ElementMarker).cw = function () {
    var elementsCount = this.xv_1.co();
    while (!this.zv_1.equals(new Long(-1, -1))) {
      var index = countTrailingZeroBits(this.zv_1.y2());
      this.zv_1 = this.zv_1.d3((new Long(1, 0)).z2(index));
      if (this.yv_1(this.xv_1, index)) {
        return index;
      }
    }
    if (elementsCount > 64) {
      return nextUnmarkedHighIndex(this);
    }
    return -1;
  };
  function createSimpleEnumSerializer(serialName, values) {
    return new EnumSerializer(serialName, values);
  }
  function createUnmarkedDescriptor($this, serialName) {
    var d = new EnumDescriptor(serialName, $this.dw_1.length);
    // Inline function 'kotlin.collections.forEach' call
    var indexedObject = $this.dw_1;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlinx.serialization.internal.EnumSerializer.createUnmarkedDescriptor.<anonymous>' call
      d.sw(element.l2_1);
    }
    return d;
  }
  function EnumSerializer$descriptor$delegate$lambda(this$0, $serialName) {
    return function () {
      var tmp0_elvis_lhs = this$0.ew_1;
      return tmp0_elvis_lhs == null ? createUnmarkedDescriptor(this$0, $serialName) : tmp0_elvis_lhs;
    };
  }
  function EnumSerializer(serialName, values) {
    this.dw_1 = values;
    this.ew_1 = null;
    var tmp = this;
    tmp.fw_1 = lazy_0(EnumSerializer$descriptor$delegate$lambda(this, serialName));
  }
  protoOf(EnumSerializer).pm = function () {
    var tmp0 = this.fw_1;
    // Inline function 'kotlin.getValue' call
    descriptor$factory_1();
    return tmp0.a2();
  };
  protoOf(EnumSerializer).tw = function (encoder, value) {
    var index = indexOf(this.dw_1, value);
    if (index === -1) {
      throw SerializationException_init_$Create$_0(toString(value) + ' is not a valid enum ' + this.pm().qn() + ', ' + ('must be one of ' + contentToString(this.dw_1)));
    }
    encoder.cr(this.pm(), index);
  };
  protoOf(EnumSerializer).qm = function (encoder, value) {
    return this.tw(encoder, value instanceof Enum ? value : THROW_CCE());
  };
  protoOf(EnumSerializer).rm = function (decoder) {
    var index = decoder.tp(this.pm());
    if (!(0 <= index ? index <= (this.dw_1.length - 1 | 0) : false)) {
      throw SerializationException_init_$Create$_0('' + index + ' is not among valid ' + this.pm().qn() + ' enum values, ' + ('values size is ' + this.dw_1.length));
    }
    return this.dw_1[index];
  };
  protoOf(EnumSerializer).toString = function () {
    return 'kotlinx.serialization.internal.EnumSerializer<' + this.pm().qn() + '>';
  };
  function _get_elementDescriptors__y23q9p($this) {
    var tmp0 = $this.hx_1;
    // Inline function 'kotlin.getValue' call
    elementDescriptors$factory();
    return tmp0.a2();
  }
  function EnumDescriptor$elementDescriptors$delegate$lambda($elementsCount, $name, this$0) {
    return function () {
      var tmp = 0;
      var tmp_0 = $elementsCount;
      // Inline function 'kotlin.arrayOfNulls' call
      var tmp_1 = fillArrayVal(Array(tmp_0), null);
      while (tmp < tmp_0) {
        var tmp_2 = tmp;
        tmp_1[tmp_2] = buildSerialDescriptor($name + '.' + this$0.io(tmp_2), OBJECT_getInstance(), []);
        tmp = tmp + 1 | 0;
      }
      return tmp_1;
    };
  }
  function EnumDescriptor(name, elementsCount) {
    PluginGeneratedSerialDescriptor.call(this, name, VOID, elementsCount);
    this.gx_1 = ENUM_getInstance();
    var tmp = this;
    tmp.hx_1 = lazy_0(EnumDescriptor$elementDescriptors$delegate$lambda(elementsCount, name, this));
  }
  protoOf(EnumDescriptor).eo = function () {
    return this.gx_1;
  };
  protoOf(EnumDescriptor).go = function (index) {
    return getChecked(_get_elementDescriptors__y23q9p(this), index);
  };
  protoOf(EnumDescriptor).equals = function (other) {
    if (this === other)
      return true;
    if (other == null)
      return false;
    if (!(!(other == null) ? isInterface(other, SerialDescriptor) : false))
      return false;
    if (!(other.eo() === ENUM_getInstance()))
      return false;
    if (!(this.qn() === other.qn()))
      return false;
    if (!equals(cachedSerialNames(this), cachedSerialNames(other)))
      return false;
    return true;
  };
  protoOf(EnumDescriptor).toString = function () {
    return joinToString(get_elementNames(this), ', ', this.qn() + '(', ')');
  };
  protoOf(EnumDescriptor).hashCode = function () {
    var result = getStringHashCode(this.qn());
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
    // Inline function 'kotlin.collections.fold' call
    var accumulator = 1;
    var _iterator__ex2g4s = get_elementNames(this).k();
    while (_iterator__ex2g4s.l()) {
      var element = _iterator__ex2g4s.m();
      // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
      var hash = accumulator;
      var tmp = imul(31, hash);
      // Inline function 'kotlinx.serialization.internal.EnumDescriptor.hashCode.<anonymous>' call
      // Inline function 'kotlin.hashCode' call
      var tmp1_elvis_lhs = element == null ? null : hashCode(element);
      accumulator = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
    }
    var elementsHashCode = accumulator;
    result = imul(31, result) + elementsHashCode | 0;
    return result;
  };
  function descriptor$factory_1() {
    return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
      return receiver.pm();
    }, null);
  }
  function elementDescriptors$factory() {
    return getPropertyCallableRef('elementDescriptors', 1, KProperty1, function (receiver) {
      return _get_elementDescriptors__y23q9p(receiver);
    }, null);
  }
  function InlinePrimitiveDescriptor(name, primitiveSerializer) {
    return new InlineClassDescriptor(name, new InlinePrimitiveDescriptor$1(primitiveSerializer));
  }
  function InlineClassDescriptor(name, generatedSerializer) {
    PluginGeneratedSerialDescriptor.call(this, name, generatedSerializer, 1);
    this.wx_1 = true;
  }
  protoOf(InlineClassDescriptor).do = function () {
    return this.wx_1;
  };
  protoOf(InlineClassDescriptor).hashCode = function () {
    return imul(protoOf(PluginGeneratedSerialDescriptor).hashCode.call(this), 31);
  };
  protoOf(InlineClassDescriptor).equals = function (other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof InlineClassDescriptor)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.qn() === other.qn())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      // Inline function 'kotlinx.serialization.internal.InlineClassDescriptor.equals.<anonymous>' call
      if (!(other.wx_1 && contentEquals(this.ix(), other.ix()))) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.co() === other.co())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.co();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.go(index).qn() === other.go(index).qn())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.go(index).eo(), other.go(index).eo())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  function InlinePrimitiveDescriptor$1($primitiveSerializer) {
    this.xx_1 = $primitiveSerializer;
  }
  protoOf(InlinePrimitiveDescriptor$1).yx = function () {
    // Inline function 'kotlin.arrayOf' call
    // Inline function 'kotlin.js.unsafeCast' call
    // Inline function 'kotlin.js.asDynamic' call
    return [this.xx_1];
  };
  protoOf(InlinePrimitiveDescriptor$1).pm = function () {
    var message = 'unsupported';
    throw IllegalStateException_init_$Create$(toString(message));
  };
  protoOf(InlinePrimitiveDescriptor$1).qm = function (encoder, value) {
    // Inline function 'kotlin.error' call
    var message = 'unsupported';
    throw IllegalStateException_init_$Create$(toString(message));
  };
  protoOf(InlinePrimitiveDescriptor$1).rm = function (decoder) {
    // Inline function 'kotlin.error' call
    var message = 'unsupported';
    throw IllegalStateException_init_$Create$(toString(message));
  };
  function jsonCachedSerialNames(_this__u8e3s4) {
    return cachedSerialNames(_this__u8e3s4);
  }
  function NoOpEncoder() {
    NoOpEncoder_instance = this;
    AbstractEncoder.call(this);
    this.ay_1 = EmptySerializersModule_0();
  }
  protoOf(NoOpEncoder).mq = function () {
    return this.ay_1;
  };
  protoOf(NoOpEncoder).rq = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).sq = function () {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).tq = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).uq = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).vq = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).wq = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).xq = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).yq = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).zq = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).ar = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).br = function (value) {
    return Unit_instance;
  };
  protoOf(NoOpEncoder).cr = function (enumDescriptor, index) {
    return Unit_instance;
  };
  var NoOpEncoder_instance;
  function NoOpEncoder_getInstance() {
    if (NoOpEncoder_instance == null)
      new NoOpEncoder();
    return NoOpEncoder_instance;
  }
  function error($this) {
    throw IllegalStateException_init_$Create$('Descriptor for type `kotlin.Nothing` does not have elements');
  }
  function NothingSerialDescriptor() {
    NothingSerialDescriptor_instance = this;
    this.by_1 = OBJECT_getInstance();
    this.cy_1 = 'kotlin.Nothing';
  }
  protoOf(NothingSerialDescriptor).eo = function () {
    return this.by_1;
  };
  protoOf(NothingSerialDescriptor).qn = function () {
    return this.cy_1;
  };
  protoOf(NothingSerialDescriptor).co = function () {
    return 0;
  };
  protoOf(NothingSerialDescriptor).io = function (index) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).ho = function (name) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).jo = function (index) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).go = function (index) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).fo = function (index) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).toString = function () {
    return 'NothingSerialDescriptor';
  };
  protoOf(NothingSerialDescriptor).equals = function (other) {
    return this === other;
  };
  protoOf(NothingSerialDescriptor).hashCode = function () {
    return getStringHashCode(this.cy_1) + imul(31, this.by_1.hashCode()) | 0;
  };
  var NothingSerialDescriptor_instance;
  function NothingSerialDescriptor_getInstance() {
    if (NothingSerialDescriptor_instance == null)
      new NothingSerialDescriptor();
    return NothingSerialDescriptor_instance;
  }
  function NullableSerializer(serializer) {
    this.dy_1 = serializer;
    this.ey_1 = new SerialDescriptorForNullable(this.dy_1.pm());
  }
  protoOf(NullableSerializer).pm = function () {
    return this.ey_1;
  };
  protoOf(NullableSerializer).fy = function (encoder, value) {
    if (!(value == null)) {
      encoder.sr();
      encoder.pr(this.dy_1, value);
    } else {
      encoder.sq();
    }
  };
  protoOf(NullableSerializer).qm = function (encoder, value) {
    return this.fy(encoder, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  };
  protoOf(NullableSerializer).rm = function (decoder) {
    return decoder.ip() ? decoder.wp(this.dy_1) : decoder.jp();
  };
  protoOf(NullableSerializer).equals = function (other) {
    if (this === other)
      return true;
    if (other == null || !getKClassFromExpression(this).equals(getKClassFromExpression(other)))
      return false;
    if (!(other instanceof NullableSerializer))
      THROW_CCE();
    if (!equals(this.dy_1, other.dy_1))
      return false;
    return true;
  };
  protoOf(NullableSerializer).hashCode = function () {
    return hashCode(this.dy_1);
  };
  function SerialDescriptorForNullable(original) {
    this.ko_1 = original;
    this.lo_1 = this.ko_1.qn() + '?';
    this.mo_1 = cachedSerialNames(this.ko_1);
  }
  protoOf(SerialDescriptorForNullable).bo = function () {
    return this.ko_1.bo();
  };
  protoOf(SerialDescriptorForNullable).co = function () {
    return this.ko_1.co();
  };
  protoOf(SerialDescriptorForNullable).do = function () {
    return this.ko_1.do();
  };
  protoOf(SerialDescriptorForNullable).eo = function () {
    return this.ko_1.eo();
  };
  protoOf(SerialDescriptorForNullable).fo = function (index) {
    return this.ko_1.fo(index);
  };
  protoOf(SerialDescriptorForNullable).go = function (index) {
    return this.ko_1.go(index);
  };
  protoOf(SerialDescriptorForNullable).ho = function (name) {
    return this.ko_1.ho(name);
  };
  protoOf(SerialDescriptorForNullable).io = function (index) {
    return this.ko_1.io(index);
  };
  protoOf(SerialDescriptorForNullable).jo = function (index) {
    return this.ko_1.jo(index);
  };
  protoOf(SerialDescriptorForNullable).qn = function () {
    return this.lo_1;
  };
  protoOf(SerialDescriptorForNullable).gp = function () {
    return this.mo_1;
  };
  protoOf(SerialDescriptorForNullable).xn = function () {
    return true;
  };
  protoOf(SerialDescriptorForNullable).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SerialDescriptorForNullable))
      return false;
    if (!equals(this.ko_1, other.ko_1))
      return false;
    return true;
  };
  protoOf(SerialDescriptorForNullable).toString = function () {
    return toString(this.ko_1) + '?';
  };
  protoOf(SerialDescriptorForNullable).hashCode = function () {
    return imul(hashCode(this.ko_1), 31);
  };
  function ObjectSerializer$descriptor$delegate$lambda$lambda(this$0) {
    return function ($this$buildSerialDescriptor) {
      $this$buildSerialDescriptor.um_1 = this$0.hy_1;
      return Unit_instance;
    };
  }
  function ObjectSerializer$descriptor$delegate$lambda($serialName, this$0) {
    return function () {
      var tmp = OBJECT_getInstance();
      return buildSerialDescriptor($serialName, tmp, [], ObjectSerializer$descriptor$delegate$lambda$lambda(this$0));
    };
  }
  function ObjectSerializer(serialName, objectInstance) {
    this.gy_1 = objectInstance;
    this.hy_1 = emptyList();
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.iy_1 = lazy(tmp_0, ObjectSerializer$descriptor$delegate$lambda(serialName, this));
  }
  protoOf(ObjectSerializer).pm = function () {
    var tmp0 = this.iy_1;
    // Inline function 'kotlin.getValue' call
    descriptor$factory_2();
    return tmp0.a2();
  };
  protoOf(ObjectSerializer).fn = function (encoder, value) {
    encoder.xp(this.pm()).yp(this.pm());
  };
  protoOf(ObjectSerializer).qm = function (encoder, value) {
    return this.fn(encoder, !(value == null) ? value : THROW_CCE());
  };
  protoOf(ObjectSerializer).rm = function (decoder) {
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var descriptor = this.pm();
    var composite = decoder.xp(descriptor);
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.internal.ObjectSerializer.deserialize.<anonymous>' call
      if (composite.nq()) {
        tmp$ret$0 = Unit_instance;
        break $l$block_0;
      }
      var index = composite.oq(this.pm());
      if (index === -1) {
        tmp$ret$0 = Unit_instance;
        break $l$block_0;
      } else
        throw SerializationException_init_$Create$_0('Unexpected index ' + index);
    }
    var result = tmp$ret$0;
    composite.yp(descriptor);
    return this.gy_1;
  };
  function descriptor$factory_2() {
    return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
      return receiver.pm();
    }, null);
  }
  function get_EMPTY_DESCRIPTOR_ARRAY() {
    _init_properties_Platform_common_kt__3qzecs();
    return EMPTY_DESCRIPTOR_ARRAY;
  }
  var EMPTY_DESCRIPTOR_ARRAY;
  function cachedSerialNames(_this__u8e3s4) {
    _init_properties_Platform_common_kt__3qzecs();
    if (isInterface(_this__u8e3s4, CachedNames))
      return _this__u8e3s4.gp();
    var result = HashSet_init_$Create$_1(_this__u8e3s4.co());
    var inductionVariable = 0;
    var last = _this__u8e3s4.co();
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.collections.plusAssign' call
        var element = _this__u8e3s4.io(i);
        result.e(element);
      }
       while (inductionVariable < last);
    return result;
  }
  function kclass(_this__u8e3s4) {
    _init_properties_Platform_common_kt__3qzecs();
    var t = _this__u8e3s4.ya();
    var tmp;
    if (!(t == null) ? isInterface(t, KClass) : false) {
      tmp = t;
    } else {
      if (!(t == null) ? isInterface(t, KTypeParameter) : false) {
        var message = 'Captured type parameter ' + toString_0(t) + ' from generic non-reified function. ' + ('Such functionality cannot be supported as ' + toString_0(t) + ' is erased, either specify serializer explicitly or make ') + ('calling function inline with reified ' + toString_0(t));
        throw IllegalStateException_init_$Create$(toString(message));
      } else {
        var message_0 = 'Only KClass supported as classifier, got ' + toString_0(t);
        throw IllegalStateException_init_$Create$(toString(message_0));
      }
    }
    var tmp_0 = tmp;
    return isInterface(tmp_0, KClass) ? tmp_0 : THROW_CCE();
  }
  function notRegisteredMessage(_this__u8e3s4) {
    _init_properties_Platform_common_kt__3qzecs();
    var tmp0_elvis_lhs = _this__u8e3s4.ka();
    return notRegisteredMessage_0(tmp0_elvis_lhs == null ? '<local class name not available>' : tmp0_elvis_lhs);
  }
  function notRegisteredMessage_0(className) {
    _init_properties_Platform_common_kt__3qzecs();
    return "Serializer for class '" + className + "' is not found.\n" + "Please ensure that class is marked as '@Serializable' and that the serialization compiler plugin is applied.\n";
  }
  function compactArray(_this__u8e3s4) {
    _init_properties_Platform_common_kt__3qzecs();
    // Inline function 'kotlin.takeUnless' call
    var tmp;
    // Inline function 'kotlinx.serialization.internal.compactArray.<anonymous>' call
    // Inline function 'kotlin.collections.isNullOrEmpty' call
    if (!(_this__u8e3s4 == null || _this__u8e3s4.p())) {
      tmp = _this__u8e3s4;
    } else {
      tmp = null;
    }
    var tmp0_safe_receiver = tmp;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      // Inline function 'kotlin.collections.toTypedArray' call
      tmp_0 = copyToArray(tmp0_safe_receiver);
    }
    var tmp1_elvis_lhs = tmp_0;
    return tmp1_elvis_lhs == null ? get_EMPTY_DESCRIPTOR_ARRAY() : tmp1_elvis_lhs;
  }
  var properties_initialized_Platform_common_kt_i7q4ty;
  function _init_properties_Platform_common_kt__3qzecs() {
    if (!properties_initialized_Platform_common_kt_i7q4ty) {
      properties_initialized_Platform_common_kt_i7q4ty = true;
      // Inline function 'kotlin.arrayOf' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      EMPTY_DESCRIPTOR_ARRAY = [];
    }
  }
  function throwMissingFieldException(seen, goldenMask, descriptor) {
    // Inline function 'kotlin.collections.mutableListOf' call
    var missingFields = ArrayList_init_$Create$_0();
    var missingFieldsBits = goldenMask & ~seen;
    var inductionVariable = 0;
    if (inductionVariable < 32)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!((missingFieldsBits & 1) === 0)) {
          // Inline function 'kotlin.collections.plusAssign' call
          var element = descriptor.io(i);
          missingFields.e(element);
        }
        missingFieldsBits = missingFieldsBits >>> 1 | 0;
      }
       while (inductionVariable < 32);
    throw MissingFieldException_init_$Create$(missingFields, descriptor.qn());
  }
  function _get_childSerializers__7vnyfa($this) {
    var tmp0 = $this.pw_1;
    // Inline function 'kotlin.getValue' call
    childSerializers$factory();
    return tmp0.a2();
  }
  function _get__hashCode__tgwhef_0($this) {
    var tmp0 = $this.rw_1;
    // Inline function 'kotlin.getValue' call
    _hashCode$factory_0();
    return tmp0.a2();
  }
  function buildIndices($this) {
    var indices = HashMap_init_$Create$();
    var inductionVariable = 0;
    var last = $this.kw_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.collections.set' call
        var key = $this.kw_1[i];
        indices.j2(key, i);
      }
       while (inductionVariable <= last);
    return indices;
  }
  function PluginGeneratedSerialDescriptor$childSerializers$delegate$lambda(this$0) {
    return function () {
      var tmp0_safe_receiver = this$0.hw_1;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.yx();
      return tmp1_elvis_lhs == null ? get_EMPTY_SERIALIZER_ARRAY() : tmp1_elvis_lhs;
    };
  }
  function PluginGeneratedSerialDescriptor$typeParameterDescriptors$delegate$lambda(this$0) {
    return function () {
      var tmp0_safe_receiver = this$0.hw_1;
      var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.zx();
      var tmp;
      if (tmp1_safe_receiver == null) {
        tmp = null;
      } else {
        // Inline function 'kotlin.collections.map' call
        // Inline function 'kotlin.collections.mapTo' call
        var destination = ArrayList_init_$Create$(tmp1_safe_receiver.length);
        var inductionVariable = 0;
        var last = tmp1_safe_receiver.length;
        while (inductionVariable < last) {
          var item = tmp1_safe_receiver[inductionVariable];
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlinx.serialization.internal.PluginGeneratedSerialDescriptor.typeParameterDescriptors$delegate.<anonymous>.<anonymous>' call
          var tmp$ret$0 = item.pm();
          destination.e(tmp$ret$0);
        }
        tmp = destination;
      }
      return compactArray(tmp);
    };
  }
  function PluginGeneratedSerialDescriptor$_hashCode$delegate$lambda(this$0) {
    return function () {
      return hashCodeImpl(this$0, this$0.ix());
    };
  }
  function PluginGeneratedSerialDescriptor$toString$lambda(this$0) {
    return function (i) {
      return this$0.io(i) + ': ' + this$0.go(i).qn();
    };
  }
  function PluginGeneratedSerialDescriptor(serialName, generatedSerializer, elementsCount) {
    generatedSerializer = generatedSerializer === VOID ? null : generatedSerializer;
    this.gw_1 = serialName;
    this.hw_1 = generatedSerializer;
    this.iw_1 = elementsCount;
    this.jw_1 = -1;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = this.iw_1;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp_2 = fillArrayVal(Array(tmp_1), null);
    while (tmp_0 < tmp_1) {
      tmp_2[tmp_0] = '[UNINITIALIZED]';
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.kw_1 = tmp_2;
    var tmp_3 = this;
    // Inline function 'kotlin.arrayOfNulls' call
    var size = this.iw_1;
    tmp_3.lw_1 = fillArrayVal(Array(size), null);
    this.mw_1 = null;
    this.nw_1 = booleanArray(this.iw_1);
    this.ow_1 = emptyMap();
    var tmp_4 = this;
    var tmp_5 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_4.pw_1 = lazy(tmp_5, PluginGeneratedSerialDescriptor$childSerializers$delegate$lambda(this));
    var tmp_6 = this;
    var tmp_7 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_6.qw_1 = lazy(tmp_7, PluginGeneratedSerialDescriptor$typeParameterDescriptors$delegate$lambda(this));
    var tmp_8 = this;
    var tmp_9 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_8.rw_1 = lazy(tmp_9, PluginGeneratedSerialDescriptor$_hashCode$delegate$lambda(this));
  }
  protoOf(PluginGeneratedSerialDescriptor).qn = function () {
    return this.gw_1;
  };
  protoOf(PluginGeneratedSerialDescriptor).co = function () {
    return this.iw_1;
  };
  protoOf(PluginGeneratedSerialDescriptor).eo = function () {
    return CLASS_getInstance();
  };
  protoOf(PluginGeneratedSerialDescriptor).bo = function () {
    var tmp0_elvis_lhs = this.mw_1;
    return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
  };
  protoOf(PluginGeneratedSerialDescriptor).gp = function () {
    return this.ow_1.e2();
  };
  protoOf(PluginGeneratedSerialDescriptor).ix = function () {
    var tmp0 = this.qw_1;
    // Inline function 'kotlin.getValue' call
    typeParameterDescriptors$factory();
    return tmp0.a2();
  };
  protoOf(PluginGeneratedSerialDescriptor).jx = function (name, isOptional) {
    this.jw_1 = this.jw_1 + 1 | 0;
    this.kw_1[this.jw_1] = name;
    this.nw_1[this.jw_1] = isOptional;
    this.lw_1[this.jw_1] = null;
    if (this.jw_1 === (this.iw_1 - 1 | 0)) {
      this.ow_1 = buildIndices(this);
    }
  };
  protoOf(PluginGeneratedSerialDescriptor).sw = function (name, isOptional, $super) {
    isOptional = isOptional === VOID ? false : isOptional;
    var tmp;
    if ($super === VOID) {
      this.jx(name, isOptional);
      tmp = Unit_instance;
    } else {
      tmp = $super.jx.call(this, name, isOptional);
    }
    return tmp;
  };
  protoOf(PluginGeneratedSerialDescriptor).go = function (index) {
    return getChecked(_get_childSerializers__7vnyfa(this), index).pm();
  };
  protoOf(PluginGeneratedSerialDescriptor).jo = function (index) {
    return getChecked_0(this.nw_1, index);
  };
  protoOf(PluginGeneratedSerialDescriptor).fo = function (index) {
    var tmp0_elvis_lhs = getChecked(this.lw_1, index);
    return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
  };
  protoOf(PluginGeneratedSerialDescriptor).io = function (index) {
    return getChecked(this.kw_1, index);
  };
  protoOf(PluginGeneratedSerialDescriptor).ho = function (name) {
    var tmp0_elvis_lhs = this.ow_1.d2(name);
    return tmp0_elvis_lhs == null ? -3 : tmp0_elvis_lhs;
  };
  protoOf(PluginGeneratedSerialDescriptor).equals = function (other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof PluginGeneratedSerialDescriptor)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.qn() === other.qn())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      // Inline function 'kotlinx.serialization.internal.PluginGeneratedSerialDescriptor.equals.<anonymous>' call
      if (!contentEquals(this.ix(), other.ix())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.co() === other.co())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.co();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.go(index).qn() === other.go(index).qn())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.go(index).eo(), other.go(index).eo())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(PluginGeneratedSerialDescriptor).hashCode = function () {
    return _get__hashCode__tgwhef_0(this);
  };
  protoOf(PluginGeneratedSerialDescriptor).toString = function () {
    var tmp = until(0, this.iw_1);
    var tmp_0 = this.qn() + '(';
    return joinToString(tmp, ', ', tmp_0, ')', VOID, VOID, PluginGeneratedSerialDescriptor$toString$lambda(this));
  };
  function hashCodeImpl(_this__u8e3s4, typeParams) {
    var result = getStringHashCode(_this__u8e3s4.qn());
    result = imul(31, result) + contentHashCode(typeParams) | 0;
    var elementDescriptors = get_elementDescriptors(_this__u8e3s4);
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
    // Inline function 'kotlin.collections.fold' call
    var accumulator = 1;
    var _iterator__ex2g4s = elementDescriptors.k();
    while (_iterator__ex2g4s.l()) {
      var element = _iterator__ex2g4s.m();
      // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
      var hash = accumulator;
      var tmp = imul(31, hash);
      // Inline function 'kotlinx.serialization.internal.hashCodeImpl.<anonymous>' call
      // Inline function 'kotlin.hashCode' call
      var tmp0_safe_receiver = element.qn();
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
      accumulator = tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
    }
    var namesHash = accumulator;
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
    // Inline function 'kotlin.collections.fold' call
    var accumulator_0 = 1;
    var _iterator__ex2g4s_0 = elementDescriptors.k();
    while (_iterator__ex2g4s_0.l()) {
      var element_0 = _iterator__ex2g4s_0.m();
      // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
      var hash_0 = accumulator_0;
      var tmp_0 = imul(31, hash_0);
      // Inline function 'kotlinx.serialization.internal.hashCodeImpl.<anonymous>' call
      // Inline function 'kotlin.hashCode' call
      var tmp0_safe_receiver_0 = element_0.eo();
      var tmp1_elvis_lhs_0 = tmp0_safe_receiver_0 == null ? null : hashCode(tmp0_safe_receiver_0);
      accumulator_0 = tmp_0 + (tmp1_elvis_lhs_0 == null ? 0 : tmp1_elvis_lhs_0) | 0;
    }
    var kindHash = accumulator_0;
    result = imul(31, result) + namesHash | 0;
    result = imul(31, result) + kindHash | 0;
    return result;
  }
  function childSerializers$factory() {
    return getPropertyCallableRef('childSerializers', 1, KProperty1, function (receiver) {
      return _get_childSerializers__7vnyfa(receiver);
    }, null);
  }
  function typeParameterDescriptors$factory() {
    return getPropertyCallableRef('typeParameterDescriptors', 1, KProperty1, function (receiver) {
      return receiver.ix();
    }, null);
  }
  function _hashCode$factory_0() {
    return getPropertyCallableRef('_hashCode', 1, KProperty1, function (receiver) {
      return _get__hashCode__tgwhef_0(receiver);
    }, null);
  }
  function get_EMPTY_SERIALIZER_ARRAY() {
    _init_properties_PluginHelperInterfaces_kt__xgvzfp();
    return EMPTY_SERIALIZER_ARRAY;
  }
  var EMPTY_SERIALIZER_ARRAY;
  function SerializerFactory() {
  }
  function GeneratedSerializer() {
  }
  var properties_initialized_PluginHelperInterfaces_kt_ap8in1;
  function _init_properties_PluginHelperInterfaces_kt__xgvzfp() {
    if (!properties_initialized_PluginHelperInterfaces_kt_ap8in1) {
      properties_initialized_PluginHelperInterfaces_kt_ap8in1 = true;
      // Inline function 'kotlin.arrayOf' call
      // Inline function 'kotlin.js.unsafeCast' call
      // Inline function 'kotlin.js.asDynamic' call
      EMPTY_SERIALIZER_ARRAY = [];
    }
  }
  function CharArraySerializer_0() {
    CharArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_1(Companion_getInstance_0()));
  }
  protoOf(CharArraySerializer_0).my = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(CharArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.my((!(_this__u8e3s4 == null) ? isCharArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(CharArraySerializer_0).ny = function (_this__u8e3s4) {
    return new CharArrayBuilder(_this__u8e3s4);
  };
  protoOf(CharArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.ny((!(_this__u8e3s4 == null) ? isCharArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(CharArraySerializer_0).rv = function () {
    return charArray(0);
  };
  protoOf(CharArraySerializer_0).oy = function (decoder, index, builder, checkIndex) {
    builder.ry(decoder.gq(this.iv_1, index));
  };
  protoOf(CharArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.oy(decoder, index, builder instanceof CharArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(CharArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.oy(decoder, index, builder instanceof CharArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(CharArraySerializer_0).sy = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.lr(this.iv_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(CharArraySerializer_0).tv = function (encoder, content, size) {
    return this.sy(encoder, (!(content == null) ? isCharArray(content) : false) ? content : THROW_CCE(), size);
  };
  var CharArraySerializer_instance;
  function CharArraySerializer_getInstance() {
    if (CharArraySerializer_instance == null)
      new CharArraySerializer_0();
    return CharArraySerializer_instance;
  }
  function DoubleArraySerializer_0() {
    DoubleArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_2(DoubleCompanionObject_instance));
  }
  protoOf(DoubleArraySerializer_0).vy = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(DoubleArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.vy((!(_this__u8e3s4 == null) ? isDoubleArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(DoubleArraySerializer_0).wy = function (_this__u8e3s4) {
    return new DoubleArrayBuilder(_this__u8e3s4);
  };
  protoOf(DoubleArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.wy((!(_this__u8e3s4 == null) ? isDoubleArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(DoubleArraySerializer_0).rv = function () {
    return new Float64Array(0);
  };
  protoOf(DoubleArraySerializer_0).xy = function (decoder, index, builder, checkIndex) {
    builder.az(decoder.fq(this.iv_1, index));
  };
  protoOf(DoubleArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.xy(decoder, index, builder instanceof DoubleArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(DoubleArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.xy(decoder, index, builder instanceof DoubleArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(DoubleArraySerializer_0).bz = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.kr(this.iv_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(DoubleArraySerializer_0).tv = function (encoder, content, size) {
    return this.bz(encoder, (!(content == null) ? isDoubleArray(content) : false) ? content : THROW_CCE(), size);
  };
  var DoubleArraySerializer_instance;
  function DoubleArraySerializer_getInstance() {
    if (DoubleArraySerializer_instance == null)
      new DoubleArraySerializer_0();
    return DoubleArraySerializer_instance;
  }
  function FloatArraySerializer_0() {
    FloatArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_3(FloatCompanionObject_instance));
  }
  protoOf(FloatArraySerializer_0).ez = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(FloatArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.ez((!(_this__u8e3s4 == null) ? isFloatArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(FloatArraySerializer_0).fz = function (_this__u8e3s4) {
    return new FloatArrayBuilder(_this__u8e3s4);
  };
  protoOf(FloatArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.fz((!(_this__u8e3s4 == null) ? isFloatArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(FloatArraySerializer_0).rv = function () {
    return new Float32Array(0);
  };
  protoOf(FloatArraySerializer_0).gz = function (decoder, index, builder, checkIndex) {
    builder.jz(decoder.eq(this.iv_1, index));
  };
  protoOf(FloatArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.gz(decoder, index, builder instanceof FloatArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(FloatArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.gz(decoder, index, builder instanceof FloatArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(FloatArraySerializer_0).kz = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.jr(this.iv_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(FloatArraySerializer_0).tv = function (encoder, content, size) {
    return this.kz(encoder, (!(content == null) ? isFloatArray(content) : false) ? content : THROW_CCE(), size);
  };
  var FloatArraySerializer_instance;
  function FloatArraySerializer_getInstance() {
    if (FloatArraySerializer_instance == null)
      new FloatArraySerializer_0();
    return FloatArraySerializer_instance;
  }
  function LongArraySerializer_0() {
    LongArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_4(Companion_getInstance_1()));
  }
  protoOf(LongArraySerializer_0).nz = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(LongArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.nz((!(_this__u8e3s4 == null) ? isLongArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LongArraySerializer_0).oz = function (_this__u8e3s4) {
    return new LongArrayBuilder(_this__u8e3s4);
  };
  protoOf(LongArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.oz((!(_this__u8e3s4 == null) ? isLongArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LongArraySerializer_0).rv = function () {
    return longArray(0);
  };
  protoOf(LongArraySerializer_0).pz = function (decoder, index, builder, checkIndex) {
    builder.sz(decoder.dq(this.iv_1, index));
  };
  protoOf(LongArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.pz(decoder, index, builder instanceof LongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(LongArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.pz(decoder, index, builder instanceof LongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(LongArraySerializer_0).tz = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.ir(this.iv_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(LongArraySerializer_0).tv = function (encoder, content, size) {
    return this.tz(encoder, (!(content == null) ? isLongArray(content) : false) ? content : THROW_CCE(), size);
  };
  var LongArraySerializer_instance;
  function LongArraySerializer_getInstance() {
    if (LongArraySerializer_instance == null)
      new LongArraySerializer_0();
    return LongArraySerializer_instance;
  }
  function ULongArraySerializer_0() {
    ULongArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_5(Companion_getInstance_2()));
  }
  protoOf(ULongArraySerializer_0).wz = function (_this__u8e3s4) {
    return _ULongArray___get_size__impl__ju6dtr(_this__u8e3s4);
  };
  protoOf(ULongArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.wz(_this__u8e3s4 instanceof ULongArray ? _this__u8e3s4.cm_1 : THROW_CCE());
  };
  protoOf(ULongArraySerializer_0).xz = function (_this__u8e3s4) {
    return new ULongArrayBuilder(_this__u8e3s4);
  };
  protoOf(ULongArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.xz(_this__u8e3s4 instanceof ULongArray ? _this__u8e3s4.cm_1 : THROW_CCE());
  };
  protoOf(ULongArraySerializer_0).yz = function () {
    return _ULongArray___init__impl__twm1l3(0);
  };
  protoOf(ULongArraySerializer_0).rv = function () {
    return new ULongArray(this.yz());
  };
  protoOf(ULongArraySerializer_0).zz = function (decoder, index, builder, checkIndex) {
    // Inline function 'kotlin.toULong' call
    var this_0 = decoder.iq(this.iv_1, index).op();
    var tmp$ret$0 = _ULong___init__impl__c78o9k(this_0);
    builder.c10(tmp$ret$0);
  };
  protoOf(ULongArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.zz(decoder, index, builder instanceof ULongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ULongArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.zz(decoder, index, builder instanceof ULongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ULongArraySerializer_0).d10 = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = encoder.nr(this.iv_1, i);
        // Inline function 'kotlin.ULong.toLong' call
        var this_0 = ULongArray__get_impl_pr71q9(content, i);
        var tmp$ret$0 = _ULong___get_data__impl__fggpzb(this_0);
        tmp.xq(tmp$ret$0);
      }
       while (inductionVariable < size);
  };
  protoOf(ULongArraySerializer_0).tv = function (encoder, content, size) {
    return this.d10(encoder, content instanceof ULongArray ? content.cm_1 : THROW_CCE(), size);
  };
  var ULongArraySerializer_instance;
  function ULongArraySerializer_getInstance() {
    if (ULongArraySerializer_instance == null)
      new ULongArraySerializer_0();
    return ULongArraySerializer_instance;
  }
  function IntArraySerializer_0() {
    IntArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_6(IntCompanionObject_instance));
  }
  protoOf(IntArraySerializer_0).g10 = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(IntArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.g10((!(_this__u8e3s4 == null) ? isIntArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(IntArraySerializer_0).h10 = function (_this__u8e3s4) {
    return new IntArrayBuilder(_this__u8e3s4);
  };
  protoOf(IntArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.h10((!(_this__u8e3s4 == null) ? isIntArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(IntArraySerializer_0).rv = function () {
    return new Int32Array(0);
  };
  protoOf(IntArraySerializer_0).i10 = function (decoder, index, builder, checkIndex) {
    builder.l10(decoder.cq(this.iv_1, index));
  };
  protoOf(IntArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.i10(decoder, index, builder instanceof IntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(IntArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.i10(decoder, index, builder instanceof IntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(IntArraySerializer_0).m10 = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.hr(this.iv_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(IntArraySerializer_0).tv = function (encoder, content, size) {
    return this.m10(encoder, (!(content == null) ? isIntArray(content) : false) ? content : THROW_CCE(), size);
  };
  var IntArraySerializer_instance;
  function IntArraySerializer_getInstance() {
    if (IntArraySerializer_instance == null)
      new IntArraySerializer_0();
    return IntArraySerializer_instance;
  }
  function UIntArraySerializer_0() {
    UIntArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_7(Companion_getInstance_3()));
  }
  protoOf(UIntArraySerializer_0).p10 = function (_this__u8e3s4) {
    return _UIntArray___get_size__impl__r6l8ci(_this__u8e3s4);
  };
  protoOf(UIntArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.p10(_this__u8e3s4 instanceof UIntArray ? _this__u8e3s4.rl_1 : THROW_CCE());
  };
  protoOf(UIntArraySerializer_0).q10 = function (_this__u8e3s4) {
    return new UIntArrayBuilder(_this__u8e3s4);
  };
  protoOf(UIntArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.q10(_this__u8e3s4 instanceof UIntArray ? _this__u8e3s4.rl_1 : THROW_CCE());
  };
  protoOf(UIntArraySerializer_0).r10 = function () {
    return _UIntArray___init__impl__ghjpc6(0);
  };
  protoOf(UIntArraySerializer_0).rv = function () {
    return new UIntArray(this.r10());
  };
  protoOf(UIntArraySerializer_0).s10 = function (decoder, index, builder, checkIndex) {
    // Inline function 'kotlin.toUInt' call
    var this_0 = decoder.iq(this.iv_1, index).np();
    var tmp$ret$0 = _UInt___init__impl__l7qpdl(this_0);
    builder.v10(tmp$ret$0);
  };
  protoOf(UIntArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.s10(decoder, index, builder instanceof UIntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UIntArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.s10(decoder, index, builder instanceof UIntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UIntArraySerializer_0).w10 = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = encoder.nr(this.iv_1, i);
        // Inline function 'kotlin.UInt.toInt' call
        var this_0 = UIntArray__get_impl_gp5kza(content, i);
        var tmp$ret$0 = _UInt___get_data__impl__f0vqqw(this_0);
        tmp.wq(tmp$ret$0);
      }
       while (inductionVariable < size);
  };
  protoOf(UIntArraySerializer_0).tv = function (encoder, content, size) {
    return this.w10(encoder, content instanceof UIntArray ? content.rl_1 : THROW_CCE(), size);
  };
  var UIntArraySerializer_instance;
  function UIntArraySerializer_getInstance() {
    if (UIntArraySerializer_instance == null)
      new UIntArraySerializer_0();
    return UIntArraySerializer_instance;
  }
  function ShortArraySerializer_0() {
    ShortArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_8(ShortCompanionObject_instance));
  }
  protoOf(ShortArraySerializer_0).z10 = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(ShortArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.z10((!(_this__u8e3s4 == null) ? isShortArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ShortArraySerializer_0).a11 = function (_this__u8e3s4) {
    return new ShortArrayBuilder(_this__u8e3s4);
  };
  protoOf(ShortArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.a11((!(_this__u8e3s4 == null) ? isShortArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ShortArraySerializer_0).rv = function () {
    return new Int16Array(0);
  };
  protoOf(ShortArraySerializer_0).b11 = function (decoder, index, builder, checkIndex) {
    builder.e11(decoder.bq(this.iv_1, index));
  };
  protoOf(ShortArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.b11(decoder, index, builder instanceof ShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ShortArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.b11(decoder, index, builder instanceof ShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ShortArraySerializer_0).f11 = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.gr(this.iv_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(ShortArraySerializer_0).tv = function (encoder, content, size) {
    return this.f11(encoder, (!(content == null) ? isShortArray(content) : false) ? content : THROW_CCE(), size);
  };
  var ShortArraySerializer_instance;
  function ShortArraySerializer_getInstance() {
    if (ShortArraySerializer_instance == null)
      new ShortArraySerializer_0();
    return ShortArraySerializer_instance;
  }
  function UShortArraySerializer_0() {
    UShortArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_9(Companion_getInstance_4()));
  }
  protoOf(UShortArraySerializer_0).i11 = function (_this__u8e3s4) {
    return _UShortArray___get_size__impl__jqto1b(_this__u8e3s4);
  };
  protoOf(UShortArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.i11(_this__u8e3s4 instanceof UShortArray ? _this__u8e3s4.nm_1 : THROW_CCE());
  };
  protoOf(UShortArraySerializer_0).j11 = function (_this__u8e3s4) {
    return new UShortArrayBuilder(_this__u8e3s4);
  };
  protoOf(UShortArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.j11(_this__u8e3s4 instanceof UShortArray ? _this__u8e3s4.nm_1 : THROW_CCE());
  };
  protoOf(UShortArraySerializer_0).k11 = function () {
    return _UShortArray___init__impl__9b26ef(0);
  };
  protoOf(UShortArraySerializer_0).rv = function () {
    return new UShortArray(this.k11());
  };
  protoOf(UShortArraySerializer_0).l11 = function (decoder, index, builder, checkIndex) {
    // Inline function 'kotlin.toUShort' call
    var this_0 = decoder.iq(this.iv_1, index).mp();
    var tmp$ret$0 = _UShort___init__impl__jigrne(this_0);
    builder.o11(tmp$ret$0);
  };
  protoOf(UShortArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.l11(decoder, index, builder instanceof UShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UShortArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.l11(decoder, index, builder instanceof UShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UShortArraySerializer_0).p11 = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = encoder.nr(this.iv_1, i);
        // Inline function 'kotlin.UShort.toShort' call
        var this_0 = UShortArray__get_impl_fnbhmx(content, i);
        var tmp$ret$0 = _UShort___get_data__impl__g0245(this_0);
        tmp.vq(tmp$ret$0);
      }
       while (inductionVariable < size);
  };
  protoOf(UShortArraySerializer_0).tv = function (encoder, content, size) {
    return this.p11(encoder, content instanceof UShortArray ? content.nm_1 : THROW_CCE(), size);
  };
  var UShortArraySerializer_instance;
  function UShortArraySerializer_getInstance() {
    if (UShortArraySerializer_instance == null)
      new UShortArraySerializer_0();
    return UShortArraySerializer_instance;
  }
  function ByteArraySerializer_0() {
    ByteArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_10(ByteCompanionObject_instance));
  }
  protoOf(ByteArraySerializer_0).s11 = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(ByteArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.s11((!(_this__u8e3s4 == null) ? isByteArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ByteArraySerializer_0).t11 = function (_this__u8e3s4) {
    return new ByteArrayBuilder(_this__u8e3s4);
  };
  protoOf(ByteArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.t11((!(_this__u8e3s4 == null) ? isByteArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ByteArraySerializer_0).rv = function () {
    return new Int8Array(0);
  };
  protoOf(ByteArraySerializer_0).u11 = function (decoder, index, builder, checkIndex) {
    builder.x11(decoder.aq(this.iv_1, index));
  };
  protoOf(ByteArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.u11(decoder, index, builder instanceof ByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ByteArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.u11(decoder, index, builder instanceof ByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ByteArraySerializer_0).y11 = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.fr(this.iv_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(ByteArraySerializer_0).tv = function (encoder, content, size) {
    return this.y11(encoder, (!(content == null) ? isByteArray(content) : false) ? content : THROW_CCE(), size);
  };
  var ByteArraySerializer_instance;
  function ByteArraySerializer_getInstance() {
    if (ByteArraySerializer_instance == null)
      new ByteArraySerializer_0();
    return ByteArraySerializer_instance;
  }
  function UByteArraySerializer_0() {
    UByteArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_11(Companion_getInstance_5()));
  }
  protoOf(UByteArraySerializer_0).b12 = function (_this__u8e3s4) {
    return _UByteArray___get_size__impl__h6pkdv(_this__u8e3s4);
  };
  protoOf(UByteArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.b12(_this__u8e3s4 instanceof UByteArray ? _this__u8e3s4.gl_1 : THROW_CCE());
  };
  protoOf(UByteArraySerializer_0).c12 = function (_this__u8e3s4) {
    return new UByteArrayBuilder(_this__u8e3s4);
  };
  protoOf(UByteArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.c12(_this__u8e3s4 instanceof UByteArray ? _this__u8e3s4.gl_1 : THROW_CCE());
  };
  protoOf(UByteArraySerializer_0).d12 = function () {
    return _UByteArray___init__impl__ip4y9n(0);
  };
  protoOf(UByteArraySerializer_0).rv = function () {
    return new UByteArray(this.d12());
  };
  protoOf(UByteArraySerializer_0).e12 = function (decoder, index, builder, checkIndex) {
    // Inline function 'kotlin.toUByte' call
    var this_0 = decoder.iq(this.iv_1, index).lp();
    var tmp$ret$0 = _UByte___init__impl__g9hnc4(this_0);
    builder.h12(tmp$ret$0);
  };
  protoOf(UByteArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.e12(decoder, index, builder instanceof UByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UByteArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.e12(decoder, index, builder instanceof UByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UByteArraySerializer_0).i12 = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = encoder.nr(this.iv_1, i);
        // Inline function 'kotlin.UByte.toByte' call
        var this_0 = UByteArray__get_impl_t5f3hv(content, i);
        var tmp$ret$0 = _UByte___get_data__impl__jof9qr(this_0);
        tmp.uq(tmp$ret$0);
      }
       while (inductionVariable < size);
  };
  protoOf(UByteArraySerializer_0).tv = function (encoder, content, size) {
    return this.i12(encoder, content instanceof UByteArray ? content.gl_1 : THROW_CCE(), size);
  };
  var UByteArraySerializer_instance;
  function UByteArraySerializer_getInstance() {
    if (UByteArraySerializer_instance == null)
      new UByteArraySerializer_0();
    return UByteArraySerializer_instance;
  }
  function BooleanArraySerializer_0() {
    BooleanArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_12(BooleanCompanionObject_instance));
  }
  protoOf(BooleanArraySerializer_0).l12 = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(BooleanArraySerializer_0).gu = function (_this__u8e3s4) {
    return this.l12((!(_this__u8e3s4 == null) ? isBooleanArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(BooleanArraySerializer_0).m12 = function (_this__u8e3s4) {
    return new BooleanArrayBuilder(_this__u8e3s4);
  };
  protoOf(BooleanArraySerializer_0).bt = function (_this__u8e3s4) {
    return this.m12((!(_this__u8e3s4 == null) ? isBooleanArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(BooleanArraySerializer_0).rv = function () {
    return booleanArray(0);
  };
  protoOf(BooleanArraySerializer_0).n12 = function (decoder, index, builder, checkIndex) {
    builder.q12(decoder.zp(this.iv_1, index));
  };
  protoOf(BooleanArraySerializer_0).mt = function (decoder, index, builder, checkIndex) {
    return this.n12(decoder, index, builder instanceof BooleanArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(BooleanArraySerializer_0).sv = function (decoder, index, builder, checkIndex) {
    return this.n12(decoder, index, builder instanceof BooleanArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(BooleanArraySerializer_0).r12 = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.er(this.iv_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(BooleanArraySerializer_0).tv = function (encoder, content, size) {
    return this.r12(encoder, (!(content == null) ? isBooleanArray(content) : false) ? content : THROW_CCE(), size);
  };
  var BooleanArraySerializer_instance;
  function BooleanArraySerializer_getInstance() {
    if (BooleanArraySerializer_instance == null)
      new BooleanArraySerializer_0();
    return BooleanArraySerializer_instance;
  }
  function CharArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.py_1 = bufferWithData;
    this.qy_1 = bufferWithData.length;
    this.ov(10);
  }
  protoOf(CharArrayBuilder).kv = function () {
    return this.qy_1;
  };
  protoOf(CharArrayBuilder).ov = function (requiredCapacity) {
    if (this.py_1.length < requiredCapacity)
      this.py_1 = copyOf(this.py_1, coerceAtLeast(requiredCapacity, imul(this.py_1.length, 2)));
  };
  protoOf(CharArrayBuilder).ry = function (c) {
    this.vv();
    var tmp = this.py_1;
    var tmp1 = this.qy_1;
    this.qy_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(CharArrayBuilder).mv = function () {
    return copyOf(this.py_1, this.qy_1);
  };
  function DoubleArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.yy_1 = bufferWithData;
    this.zy_1 = bufferWithData.length;
    this.ov(10);
  }
  protoOf(DoubleArrayBuilder).kv = function () {
    return this.zy_1;
  };
  protoOf(DoubleArrayBuilder).ov = function (requiredCapacity) {
    if (this.yy_1.length < requiredCapacity)
      this.yy_1 = copyOf_0(this.yy_1, coerceAtLeast(requiredCapacity, imul(this.yy_1.length, 2)));
  };
  protoOf(DoubleArrayBuilder).az = function (c) {
    this.vv();
    var tmp = this.yy_1;
    var tmp1 = this.zy_1;
    this.zy_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(DoubleArrayBuilder).mv = function () {
    return copyOf_0(this.yy_1, this.zy_1);
  };
  function FloatArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.hz_1 = bufferWithData;
    this.iz_1 = bufferWithData.length;
    this.ov(10);
  }
  protoOf(FloatArrayBuilder).kv = function () {
    return this.iz_1;
  };
  protoOf(FloatArrayBuilder).ov = function (requiredCapacity) {
    if (this.hz_1.length < requiredCapacity)
      this.hz_1 = copyOf_1(this.hz_1, coerceAtLeast(requiredCapacity, imul(this.hz_1.length, 2)));
  };
  protoOf(FloatArrayBuilder).jz = function (c) {
    this.vv();
    var tmp = this.hz_1;
    var tmp1 = this.iz_1;
    this.iz_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(FloatArrayBuilder).mv = function () {
    return copyOf_1(this.hz_1, this.iz_1);
  };
  function LongArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.qz_1 = bufferWithData;
    this.rz_1 = bufferWithData.length;
    this.ov(10);
  }
  protoOf(LongArrayBuilder).kv = function () {
    return this.rz_1;
  };
  protoOf(LongArrayBuilder).ov = function (requiredCapacity) {
    if (this.qz_1.length < requiredCapacity)
      this.qz_1 = copyOf_2(this.qz_1, coerceAtLeast(requiredCapacity, imul(this.qz_1.length, 2)));
  };
  protoOf(LongArrayBuilder).sz = function (c) {
    this.vv();
    var tmp = this.qz_1;
    var tmp1 = this.rz_1;
    this.rz_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(LongArrayBuilder).mv = function () {
    return copyOf_2(this.qz_1, this.rz_1);
  };
  function ULongArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.a10_1 = bufferWithData;
    this.b10_1 = _ULongArray___get_size__impl__ju6dtr(bufferWithData);
    this.ov(10);
  }
  protoOf(ULongArrayBuilder).kv = function () {
    return this.b10_1;
  };
  protoOf(ULongArrayBuilder).ov = function (requiredCapacity) {
    if (_ULongArray___get_size__impl__ju6dtr(this.a10_1) < requiredCapacity) {
      var tmp = this;
      var tmp0 = this.a10_1;
      // Inline function 'kotlin.collections.copyOf' call
      var newSize = coerceAtLeast(requiredCapacity, imul(_ULongArray___get_size__impl__ju6dtr(this.a10_1), 2));
      tmp.a10_1 = _ULongArray___init__impl__twm1l3_0(copyOf_2(_ULongArray___get_storage__impl__28e64j(tmp0), newSize));
    }
  };
  protoOf(ULongArrayBuilder).c10 = function (c) {
    this.vv();
    var tmp = this.a10_1;
    var tmp1 = this.b10_1;
    this.b10_1 = tmp1 + 1 | 0;
    ULongArray__set_impl_z19mvh(tmp, tmp1, c);
  };
  protoOf(ULongArrayBuilder).s12 = function () {
    var tmp0 = this.a10_1;
    // Inline function 'kotlin.collections.copyOf' call
    var newSize = this.b10_1;
    return _ULongArray___init__impl__twm1l3_0(copyOf_2(_ULongArray___get_storage__impl__28e64j(tmp0), newSize));
  };
  protoOf(ULongArrayBuilder).mv = function () {
    return new ULongArray(this.s12());
  };
  function IntArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.j10_1 = bufferWithData;
    this.k10_1 = bufferWithData.length;
    this.ov(10);
  }
  protoOf(IntArrayBuilder).kv = function () {
    return this.k10_1;
  };
  protoOf(IntArrayBuilder).ov = function (requiredCapacity) {
    if (this.j10_1.length < requiredCapacity)
      this.j10_1 = copyOf_3(this.j10_1, coerceAtLeast(requiredCapacity, imul(this.j10_1.length, 2)));
  };
  protoOf(IntArrayBuilder).l10 = function (c) {
    this.vv();
    var tmp = this.j10_1;
    var tmp1 = this.k10_1;
    this.k10_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(IntArrayBuilder).mv = function () {
    return copyOf_3(this.j10_1, this.k10_1);
  };
  function UIntArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.t10_1 = bufferWithData;
    this.u10_1 = _UIntArray___get_size__impl__r6l8ci(bufferWithData);
    this.ov(10);
  }
  protoOf(UIntArrayBuilder).kv = function () {
    return this.u10_1;
  };
  protoOf(UIntArrayBuilder).ov = function (requiredCapacity) {
    if (_UIntArray___get_size__impl__r6l8ci(this.t10_1) < requiredCapacity) {
      var tmp = this;
      var tmp0 = this.t10_1;
      // Inline function 'kotlin.collections.copyOf' call
      var newSize = coerceAtLeast(requiredCapacity, imul(_UIntArray___get_size__impl__r6l8ci(this.t10_1), 2));
      tmp.t10_1 = _UIntArray___init__impl__ghjpc6_0(copyOf_3(_UIntArray___get_storage__impl__92a0v0(tmp0), newSize));
    }
  };
  protoOf(UIntArrayBuilder).v10 = function (c) {
    this.vv();
    var tmp = this.t10_1;
    var tmp1 = this.u10_1;
    this.u10_1 = tmp1 + 1 | 0;
    UIntArray__set_impl_7f2zu2(tmp, tmp1, c);
  };
  protoOf(UIntArrayBuilder).t12 = function () {
    var tmp0 = this.t10_1;
    // Inline function 'kotlin.collections.copyOf' call
    var newSize = this.u10_1;
    return _UIntArray___init__impl__ghjpc6_0(copyOf_3(_UIntArray___get_storage__impl__92a0v0(tmp0), newSize));
  };
  protoOf(UIntArrayBuilder).mv = function () {
    return new UIntArray(this.t12());
  };
  function ShortArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.c11_1 = bufferWithData;
    this.d11_1 = bufferWithData.length;
    this.ov(10);
  }
  protoOf(ShortArrayBuilder).kv = function () {
    return this.d11_1;
  };
  protoOf(ShortArrayBuilder).ov = function (requiredCapacity) {
    if (this.c11_1.length < requiredCapacity)
      this.c11_1 = copyOf_4(this.c11_1, coerceAtLeast(requiredCapacity, imul(this.c11_1.length, 2)));
  };
  protoOf(ShortArrayBuilder).e11 = function (c) {
    this.vv();
    var tmp = this.c11_1;
    var tmp1 = this.d11_1;
    this.d11_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(ShortArrayBuilder).mv = function () {
    return copyOf_4(this.c11_1, this.d11_1);
  };
  function UShortArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.m11_1 = bufferWithData;
    this.n11_1 = _UShortArray___get_size__impl__jqto1b(bufferWithData);
    this.ov(10);
  }
  protoOf(UShortArrayBuilder).kv = function () {
    return this.n11_1;
  };
  protoOf(UShortArrayBuilder).ov = function (requiredCapacity) {
    if (_UShortArray___get_size__impl__jqto1b(this.m11_1) < requiredCapacity) {
      var tmp = this;
      var tmp0 = this.m11_1;
      // Inline function 'kotlin.collections.copyOf' call
      var newSize = coerceAtLeast(requiredCapacity, imul(_UShortArray___get_size__impl__jqto1b(this.m11_1), 2));
      tmp.m11_1 = _UShortArray___init__impl__9b26ef_0(copyOf_4(_UShortArray___get_storage__impl__t2jpv5(tmp0), newSize));
    }
  };
  protoOf(UShortArrayBuilder).o11 = function (c) {
    this.vv();
    var tmp = this.m11_1;
    var tmp1 = this.n11_1;
    this.n11_1 = tmp1 + 1 | 0;
    UShortArray__set_impl_6d8whp(tmp, tmp1, c);
  };
  protoOf(UShortArrayBuilder).u12 = function () {
    var tmp0 = this.m11_1;
    // Inline function 'kotlin.collections.copyOf' call
    var newSize = this.n11_1;
    return _UShortArray___init__impl__9b26ef_0(copyOf_4(_UShortArray___get_storage__impl__t2jpv5(tmp0), newSize));
  };
  protoOf(UShortArrayBuilder).mv = function () {
    return new UShortArray(this.u12());
  };
  function ByteArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.v11_1 = bufferWithData;
    this.w11_1 = bufferWithData.length;
    this.ov(10);
  }
  protoOf(ByteArrayBuilder).kv = function () {
    return this.w11_1;
  };
  protoOf(ByteArrayBuilder).ov = function (requiredCapacity) {
    if (this.v11_1.length < requiredCapacity)
      this.v11_1 = copyOf_5(this.v11_1, coerceAtLeast(requiredCapacity, imul(this.v11_1.length, 2)));
  };
  protoOf(ByteArrayBuilder).x11 = function (c) {
    this.vv();
    var tmp = this.v11_1;
    var tmp1 = this.w11_1;
    this.w11_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(ByteArrayBuilder).mv = function () {
    return copyOf_5(this.v11_1, this.w11_1);
  };
  function UByteArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.f12_1 = bufferWithData;
    this.g12_1 = _UByteArray___get_size__impl__h6pkdv(bufferWithData);
    this.ov(10);
  }
  protoOf(UByteArrayBuilder).kv = function () {
    return this.g12_1;
  };
  protoOf(UByteArrayBuilder).ov = function (requiredCapacity) {
    if (_UByteArray___get_size__impl__h6pkdv(this.f12_1) < requiredCapacity) {
      var tmp = this;
      var tmp0 = this.f12_1;
      // Inline function 'kotlin.collections.copyOf' call
      var newSize = coerceAtLeast(requiredCapacity, imul(_UByteArray___get_size__impl__h6pkdv(this.f12_1), 2));
      tmp.f12_1 = _UByteArray___init__impl__ip4y9n_0(copyOf_5(_UByteArray___get_storage__impl__d4kctt(tmp0), newSize));
    }
  };
  protoOf(UByteArrayBuilder).h12 = function (c) {
    this.vv();
    var tmp = this.f12_1;
    var tmp1 = this.g12_1;
    this.g12_1 = tmp1 + 1 | 0;
    UByteArray__set_impl_jvcicn(tmp, tmp1, c);
  };
  protoOf(UByteArrayBuilder).v12 = function () {
    var tmp0 = this.f12_1;
    // Inline function 'kotlin.collections.copyOf' call
    var newSize = this.g12_1;
    return _UByteArray___init__impl__ip4y9n_0(copyOf_5(_UByteArray___get_storage__impl__d4kctt(tmp0), newSize));
  };
  protoOf(UByteArrayBuilder).mv = function () {
    return new UByteArray(this.v12());
  };
  function BooleanArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.o12_1 = bufferWithData;
    this.p12_1 = bufferWithData.length;
    this.ov(10);
  }
  protoOf(BooleanArrayBuilder).kv = function () {
    return this.p12_1;
  };
  protoOf(BooleanArrayBuilder).ov = function (requiredCapacity) {
    if (this.o12_1.length < requiredCapacity)
      this.o12_1 = copyOf_6(this.o12_1, coerceAtLeast(requiredCapacity, imul(this.o12_1.length, 2)));
  };
  protoOf(BooleanArrayBuilder).q12 = function (c) {
    this.vv();
    var tmp = this.o12_1;
    var tmp1 = this.p12_1;
    this.p12_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(BooleanArrayBuilder).mv = function () {
    return copyOf_6(this.o12_1, this.p12_1);
  };
  function get_BUILTIN_SERIALIZERS() {
    _init_properties_Primitives_kt__k0eto4();
    return BUILTIN_SERIALIZERS;
  }
  var BUILTIN_SERIALIZERS;
  function builtinSerializerOrNull(_this__u8e3s4) {
    _init_properties_Primitives_kt__k0eto4();
    var tmp = get_BUILTIN_SERIALIZERS().d2(_this__u8e3s4);
    return (tmp == null ? true : isInterface(tmp, KSerializer)) ? tmp : THROW_CCE();
  }
  function StringSerializer() {
    StringSerializer_instance = this;
    this.w12_1 = new PrimitiveSerialDescriptor_0('kotlin.String', STRING_getInstance());
  }
  protoOf(StringSerializer).pm = function () {
    return this.w12_1;
  };
  protoOf(StringSerializer).x12 = function (encoder, value) {
    return encoder.br(value);
  };
  protoOf(StringSerializer).qm = function (encoder, value) {
    return this.x12(encoder, (!(value == null) ? typeof value === 'string' : false) ? value : THROW_CCE());
  };
  protoOf(StringSerializer).rm = function (decoder) {
    return decoder.sp();
  };
  var StringSerializer_instance;
  function StringSerializer_getInstance() {
    if (StringSerializer_instance == null)
      new StringSerializer();
    return StringSerializer_instance;
  }
  function CharSerializer() {
    CharSerializer_instance = this;
    this.y12_1 = new PrimitiveSerialDescriptor_0('kotlin.Char', CHAR_getInstance());
  }
  protoOf(CharSerializer).pm = function () {
    return this.y12_1;
  };
  protoOf(CharSerializer).z12 = function (encoder, value) {
    return encoder.ar(value);
  };
  protoOf(CharSerializer).qm = function (encoder, value) {
    return this.z12(encoder, value instanceof Char ? value.m1_1 : THROW_CCE());
  };
  protoOf(CharSerializer).a13 = function (decoder) {
    return decoder.rp();
  };
  protoOf(CharSerializer).rm = function (decoder) {
    return new Char(this.a13(decoder));
  };
  var CharSerializer_instance;
  function CharSerializer_getInstance() {
    if (CharSerializer_instance == null)
      new CharSerializer();
    return CharSerializer_instance;
  }
  function DoubleSerializer() {
    DoubleSerializer_instance = this;
    this.b13_1 = new PrimitiveSerialDescriptor_0('kotlin.Double', DOUBLE_getInstance());
  }
  protoOf(DoubleSerializer).pm = function () {
    return this.b13_1;
  };
  protoOf(DoubleSerializer).c13 = function (encoder, value) {
    return encoder.zq(value);
  };
  protoOf(DoubleSerializer).qm = function (encoder, value) {
    return this.c13(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(DoubleSerializer).rm = function (decoder) {
    return decoder.qp();
  };
  var DoubleSerializer_instance;
  function DoubleSerializer_getInstance() {
    if (DoubleSerializer_instance == null)
      new DoubleSerializer();
    return DoubleSerializer_instance;
  }
  function FloatSerializer() {
    FloatSerializer_instance = this;
    this.d13_1 = new PrimitiveSerialDescriptor_0('kotlin.Float', FLOAT_getInstance());
  }
  protoOf(FloatSerializer).pm = function () {
    return this.d13_1;
  };
  protoOf(FloatSerializer).e13 = function (encoder, value) {
    return encoder.yq(value);
  };
  protoOf(FloatSerializer).qm = function (encoder, value) {
    return this.e13(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(FloatSerializer).rm = function (decoder) {
    return decoder.pp();
  };
  var FloatSerializer_instance;
  function FloatSerializer_getInstance() {
    if (FloatSerializer_instance == null)
      new FloatSerializer();
    return FloatSerializer_instance;
  }
  function LongSerializer() {
    LongSerializer_instance = this;
    this.f13_1 = new PrimitiveSerialDescriptor_0('kotlin.Long', LONG_getInstance());
  }
  protoOf(LongSerializer).pm = function () {
    return this.f13_1;
  };
  protoOf(LongSerializer).g13 = function (encoder, value) {
    return encoder.xq(value);
  };
  protoOf(LongSerializer).qm = function (encoder, value) {
    return this.g13(encoder, value instanceof Long ? value : THROW_CCE());
  };
  protoOf(LongSerializer).rm = function (decoder) {
    return decoder.op();
  };
  var LongSerializer_instance;
  function LongSerializer_getInstance() {
    if (LongSerializer_instance == null)
      new LongSerializer();
    return LongSerializer_instance;
  }
  function IntSerializer() {
    IntSerializer_instance = this;
    this.h13_1 = new PrimitiveSerialDescriptor_0('kotlin.Int', INT_getInstance());
  }
  protoOf(IntSerializer).pm = function () {
    return this.h13_1;
  };
  protoOf(IntSerializer).i13 = function (encoder, value) {
    return encoder.wq(value);
  };
  protoOf(IntSerializer).qm = function (encoder, value) {
    return this.i13(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(IntSerializer).rm = function (decoder) {
    return decoder.np();
  };
  var IntSerializer_instance;
  function IntSerializer_getInstance() {
    if (IntSerializer_instance == null)
      new IntSerializer();
    return IntSerializer_instance;
  }
  function ShortSerializer() {
    ShortSerializer_instance = this;
    this.j13_1 = new PrimitiveSerialDescriptor_0('kotlin.Short', SHORT_getInstance());
  }
  protoOf(ShortSerializer).pm = function () {
    return this.j13_1;
  };
  protoOf(ShortSerializer).k13 = function (encoder, value) {
    return encoder.vq(value);
  };
  protoOf(ShortSerializer).qm = function (encoder, value) {
    return this.k13(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(ShortSerializer).rm = function (decoder) {
    return decoder.mp();
  };
  var ShortSerializer_instance;
  function ShortSerializer_getInstance() {
    if (ShortSerializer_instance == null)
      new ShortSerializer();
    return ShortSerializer_instance;
  }
  function ByteSerializer() {
    ByteSerializer_instance = this;
    this.l13_1 = new PrimitiveSerialDescriptor_0('kotlin.Byte', BYTE_getInstance());
  }
  protoOf(ByteSerializer).pm = function () {
    return this.l13_1;
  };
  protoOf(ByteSerializer).m13 = function (encoder, value) {
    return encoder.uq(value);
  };
  protoOf(ByteSerializer).qm = function (encoder, value) {
    return this.m13(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(ByteSerializer).rm = function (decoder) {
    return decoder.lp();
  };
  var ByteSerializer_instance;
  function ByteSerializer_getInstance() {
    if (ByteSerializer_instance == null)
      new ByteSerializer();
    return ByteSerializer_instance;
  }
  function BooleanSerializer() {
    BooleanSerializer_instance = this;
    this.n13_1 = new PrimitiveSerialDescriptor_0('kotlin.Boolean', BOOLEAN_getInstance());
  }
  protoOf(BooleanSerializer).pm = function () {
    return this.n13_1;
  };
  protoOf(BooleanSerializer).o13 = function (encoder, value) {
    return encoder.tq(value);
  };
  protoOf(BooleanSerializer).qm = function (encoder, value) {
    return this.o13(encoder, (!(value == null) ? typeof value === 'boolean' : false) ? value : THROW_CCE());
  };
  protoOf(BooleanSerializer).rm = function (decoder) {
    return decoder.kp();
  };
  var BooleanSerializer_instance;
  function BooleanSerializer_getInstance() {
    if (BooleanSerializer_instance == null)
      new BooleanSerializer();
    return BooleanSerializer_instance;
  }
  function UnitSerializer() {
    UnitSerializer_instance = this;
    this.p13_1 = new ObjectSerializer('kotlin.Unit', Unit_instance);
  }
  protoOf(UnitSerializer).pm = function () {
    return this.p13_1.pm();
  };
  protoOf(UnitSerializer).q13 = function (decoder) {
    this.p13_1.rm(decoder);
  };
  protoOf(UnitSerializer).rm = function (decoder) {
    this.q13(decoder);
    return Unit_instance;
  };
  protoOf(UnitSerializer).r13 = function (encoder, value) {
    this.p13_1.fn(encoder, Unit_instance);
  };
  protoOf(UnitSerializer).qm = function (encoder, value) {
    return this.r13(encoder, value instanceof Unit ? value : THROW_CCE());
  };
  var UnitSerializer_instance;
  function UnitSerializer_getInstance() {
    if (UnitSerializer_instance == null)
      new UnitSerializer();
    return UnitSerializer_instance;
  }
  function error_0($this) {
    throw IllegalStateException_init_$Create$('Primitive descriptor does not have elements');
  }
  function PrimitiveSerialDescriptor_0(serialName, kind) {
    this.s13_1 = serialName;
    this.t13_1 = kind;
  }
  protoOf(PrimitiveSerialDescriptor_0).qn = function () {
    return this.s13_1;
  };
  protoOf(PrimitiveSerialDescriptor_0).eo = function () {
    return this.t13_1;
  };
  protoOf(PrimitiveSerialDescriptor_0).co = function () {
    return 0;
  };
  protoOf(PrimitiveSerialDescriptor_0).io = function (index) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).ho = function (name) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).jo = function (index) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).go = function (index) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).fo = function (index) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).toString = function () {
    return 'PrimitiveDescriptor(' + this.s13_1 + ')';
  };
  protoOf(PrimitiveSerialDescriptor_0).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PrimitiveSerialDescriptor_0))
      return false;
    if (this.s13_1 === other.s13_1 && equals(this.t13_1, other.t13_1))
      return true;
    return false;
  };
  protoOf(PrimitiveSerialDescriptor_0).hashCode = function () {
    return getStringHashCode(this.s13_1) + imul(31, this.t13_1.hashCode()) | 0;
  };
  function PrimitiveDescriptorSafe(serialName, kind) {
    _init_properties_Primitives_kt__k0eto4();
    checkName(serialName);
    return new PrimitiveSerialDescriptor_0(serialName, kind);
  }
  function checkName(serialName) {
    _init_properties_Primitives_kt__k0eto4();
    var keys = get_BUILTIN_SERIALIZERS().e2();
    var tmp0_iterator = keys.k();
    while (tmp0_iterator.l()) {
      var primitive = tmp0_iterator.m();
      var simpleName = capitalize(ensureNotNull(primitive.ka()));
      var qualifiedName = 'kotlin.' + simpleName;
      if (equals_0(serialName, qualifiedName, true) || equals_0(serialName, simpleName, true)) {
        throw IllegalArgumentException_init_$Create$(trimIndent('\n                The name of serial descriptor should uniquely identify associated serializer.\n                For serial name ' + serialName + ' there already exist ' + capitalize(simpleName) + 'Serializer.\n                Please refer to SerialDescriptor documentation for additional information.\n            '));
      }
    }
  }
  function capitalize(_this__u8e3s4) {
    _init_properties_Primitives_kt__k0eto4();
    // Inline function 'kotlin.text.replaceFirstChar' call
    var tmp;
    // Inline function 'kotlin.text.isNotEmpty' call
    if (charSequenceLength(_this__u8e3s4) > 0) {
      // Inline function 'kotlinx.serialization.internal.capitalize.<anonymous>' call
      var it = charSequenceGet(_this__u8e3s4, 0);
      var tmp$ret$1 = isLowerCase(it) ? titlecase(it) : toString_1(it);
      var tmp_0 = toString(tmp$ret$1);
      // Inline function 'kotlin.text.substring' call
      // Inline function 'kotlin.js.asDynamic' call
      tmp = tmp_0 + _this__u8e3s4.substring(1);
    } else {
      tmp = _this__u8e3s4;
    }
    return tmp;
  }
  var properties_initialized_Primitives_kt_6dpii6;
  function _init_properties_Primitives_kt__k0eto4() {
    if (!properties_initialized_Primitives_kt_6dpii6) {
      properties_initialized_Primitives_kt_6dpii6 = true;
      BUILTIN_SERIALIZERS = mapOf([to(PrimitiveClasses_getInstance().lb(), serializer_0(StringCompanionObject_instance)), to(getKClass(Char), serializer_1(Companion_getInstance_0())), to(PrimitiveClasses_getInstance().ob(), CharArraySerializer()), to(PrimitiveClasses_getInstance().jb(), serializer_2(DoubleCompanionObject_instance)), to(PrimitiveClasses_getInstance().ub(), DoubleArraySerializer()), to(PrimitiveClasses_getInstance().ib(), serializer_3(FloatCompanionObject_instance)), to(PrimitiveClasses_getInstance().tb(), FloatArraySerializer()), to(getKClass(Long), serializer_4(Companion_getInstance_1())), to(PrimitiveClasses_getInstance().sb(), LongArraySerializer()), to(getKClass(ULong), serializer_5(Companion_getInstance_2())), to(getKClass(ULongArray), ULongArraySerializer()), to(PrimitiveClasses_getInstance().hb(), serializer_6(IntCompanionObject_instance)), to(PrimitiveClasses_getInstance().rb(), IntArraySerializer()), to(getKClass(UInt), serializer_7(Companion_getInstance_3())), to(getKClass(UIntArray), UIntArraySerializer()), to(PrimitiveClasses_getInstance().gb(), serializer_8(ShortCompanionObject_instance)), to(PrimitiveClasses_getInstance().qb(), ShortArraySerializer()), to(getKClass(UShort), serializer_9(Companion_getInstance_4())), to(getKClass(UShortArray), UShortArraySerializer()), to(PrimitiveClasses_getInstance().fb(), serializer_10(ByteCompanionObject_instance)), to(PrimitiveClasses_getInstance().pb(), ByteArraySerializer()), to(getKClass(UByte), serializer_11(Companion_getInstance_5())), to(getKClass(UByteArray), UByteArraySerializer()), to(PrimitiveClasses_getInstance().eb(), serializer_12(BooleanCompanionObject_instance)), to(PrimitiveClasses_getInstance().nb(), BooleanArraySerializer()), to(getKClass(Unit), serializer_13(Unit_instance)), to(PrimitiveClasses_getInstance().db(), NothingSerializer()), to(getKClass(Duration), serializer_14(Companion_getInstance()))]);
    }
  }
  function NamedValueDecoder() {
    TaggedDecoder.call(this);
  }
  protoOf(NamedValueDecoder).w13 = function (_this__u8e3s4, index) {
    return this.y13(this.x13(_this__u8e3s4, index));
  };
  protoOf(NamedValueDecoder).y13 = function (nestedName) {
    var tmp0_elvis_lhs = this.b14();
    return this.c14(tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs, nestedName);
  };
  protoOf(NamedValueDecoder).x13 = function (descriptor, index) {
    return descriptor.io(index);
  };
  protoOf(NamedValueDecoder).c14 = function (parentName, childName) {
    var tmp;
    // Inline function 'kotlin.text.isEmpty' call
    if (charSequenceLength(parentName) === 0) {
      tmp = childName;
    } else {
      tmp = parentName + '.' + childName;
    }
    return tmp;
  };
  function tagBlock($this, tag, block) {
    $this.q14(tag);
    var r = block();
    if (!$this.a14_1) {
      $this.r14();
    }
    $this.a14_1 = false;
    return r;
  }
  function TaggedDecoder$decodeSerializableElement$lambda(this$0, $deserializer, $previousValue) {
    return function () {
      return this$0.vp($deserializer, $previousValue);
    };
  }
  function TaggedDecoder$decodeNullableSerializableElement$lambda(this$0, $deserializer, $previousValue) {
    return function () {
      var tmp0 = this$0;
      // Inline function 'kotlinx.serialization.encoding.decodeIfNullable' call
      var isNullabilitySupported = $deserializer.pm().xn();
      var tmp;
      if (isNullabilitySupported || tmp0.ip()) {
        // Inline function 'kotlinx.serialization.internal.TaggedDecoder.decodeNullableSerializableElement.<anonymous>.<anonymous>' call
        tmp = this$0.vp($deserializer, $previousValue);
      } else {
        tmp = tmp0.jp();
      }
      return tmp;
    };
  }
  function TaggedDecoder() {
    var tmp = this;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp.z13_1 = ArrayList_init_$Create$_0();
    this.a14_1 = false;
  }
  protoOf(TaggedDecoder).mq = function () {
    return EmptySerializersModule_0();
  };
  protoOf(TaggedDecoder).d14 = function (tag) {
    throw SerializationException_init_$Create$_0(toString(getKClassFromExpression(this)) + " can't retrieve untyped values");
  };
  protoOf(TaggedDecoder).e14 = function (tag) {
    return true;
  };
  protoOf(TaggedDecoder).f14 = function (tag) {
    var tmp = this.d14(tag);
    return typeof tmp === 'boolean' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).g14 = function (tag) {
    var tmp = this.d14(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).h14 = function (tag) {
    var tmp = this.d14(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).i14 = function (tag) {
    var tmp = this.d14(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).j14 = function (tag) {
    var tmp = this.d14(tag);
    return tmp instanceof Long ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).k14 = function (tag) {
    var tmp = this.d14(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).l14 = function (tag) {
    var tmp = this.d14(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).m14 = function (tag) {
    var tmp = this.d14(tag);
    return tmp instanceof Char ? tmp.m1_1 : THROW_CCE();
  };
  protoOf(TaggedDecoder).n14 = function (tag) {
    var tmp = this.d14(tag);
    return typeof tmp === 'string' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).o14 = function (tag, enumDescriptor) {
    var tmp = this.d14(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).p14 = function (tag, inlineDescriptor) {
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlinx.serialization.internal.TaggedDecoder.decodeTaggedInline.<anonymous>' call
    this.q14(tag);
    return this;
  };
  protoOf(TaggedDecoder).vp = function (deserializer, previousValue) {
    return this.wp(deserializer);
  };
  protoOf(TaggedDecoder).up = function (descriptor) {
    return this.p14(this.r14(), descriptor);
  };
  protoOf(TaggedDecoder).ip = function () {
    var tmp0_elvis_lhs = this.b14();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var currentTag = tmp;
    return this.e14(currentTag);
  };
  protoOf(TaggedDecoder).jp = function () {
    return null;
  };
  protoOf(TaggedDecoder).kp = function () {
    return this.f14(this.r14());
  };
  protoOf(TaggedDecoder).lp = function () {
    return this.g14(this.r14());
  };
  protoOf(TaggedDecoder).mp = function () {
    return this.h14(this.r14());
  };
  protoOf(TaggedDecoder).np = function () {
    return this.i14(this.r14());
  };
  protoOf(TaggedDecoder).op = function () {
    return this.j14(this.r14());
  };
  protoOf(TaggedDecoder).pp = function () {
    return this.k14(this.r14());
  };
  protoOf(TaggedDecoder).qp = function () {
    return this.l14(this.r14());
  };
  protoOf(TaggedDecoder).rp = function () {
    return this.m14(this.r14());
  };
  protoOf(TaggedDecoder).sp = function () {
    return this.n14(this.r14());
  };
  protoOf(TaggedDecoder).tp = function (enumDescriptor) {
    return this.o14(this.r14(), enumDescriptor);
  };
  protoOf(TaggedDecoder).xp = function (descriptor) {
    return this;
  };
  protoOf(TaggedDecoder).yp = function (descriptor) {
  };
  protoOf(TaggedDecoder).zp = function (descriptor, index) {
    return this.f14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).aq = function (descriptor, index) {
    return this.g14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).bq = function (descriptor, index) {
    return this.h14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).cq = function (descriptor, index) {
    return this.i14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).dq = function (descriptor, index) {
    return this.j14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).eq = function (descriptor, index) {
    return this.k14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).fq = function (descriptor, index) {
    return this.l14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).gq = function (descriptor, index) {
    return this.m14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).hq = function (descriptor, index) {
    return this.n14(this.w13(descriptor, index));
  };
  protoOf(TaggedDecoder).iq = function (descriptor, index) {
    return this.p14(this.w13(descriptor, index), descriptor.go(index));
  };
  protoOf(TaggedDecoder).jq = function (descriptor, index, deserializer, previousValue) {
    var tmp = this.w13(descriptor, index);
    return tagBlock(this, tmp, TaggedDecoder$decodeSerializableElement$lambda(this, deserializer, previousValue));
  };
  protoOf(TaggedDecoder).lq = function (descriptor, index, deserializer, previousValue) {
    var tmp = this.w13(descriptor, index);
    return tagBlock(this, tmp, TaggedDecoder$decodeNullableSerializableElement$lambda(this, deserializer, previousValue));
  };
  protoOf(TaggedDecoder).b14 = function () {
    return lastOrNull(this.z13_1);
  };
  protoOf(TaggedDecoder).q14 = function (name) {
    this.z13_1.e(name);
  };
  protoOf(TaggedDecoder).r14 = function () {
    var r = this.z13_1.i2(get_lastIndex_0(this.z13_1));
    this.a14_1 = true;
    return r;
  };
  function get_NULL() {
    _init_properties_Tuples_kt__dz0qyd();
    return NULL;
  }
  var NULL;
  function MapEntry(key, value) {
    this.s14_1 = key;
    this.t14_1 = value;
  }
  protoOf(MapEntry).z1 = function () {
    return this.s14_1;
  };
  protoOf(MapEntry).a2 = function () {
    return this.t14_1;
  };
  protoOf(MapEntry).toString = function () {
    return 'MapEntry(key=' + toString_0(this.s14_1) + ', value=' + toString_0(this.t14_1) + ')';
  };
  protoOf(MapEntry).hashCode = function () {
    var result = this.s14_1 == null ? 0 : hashCode(this.s14_1);
    result = imul(result, 31) + (this.t14_1 == null ? 0 : hashCode(this.t14_1)) | 0;
    return result;
  };
  protoOf(MapEntry).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MapEntry))
      return false;
    var tmp0_other_with_cast = other instanceof MapEntry ? other : THROW_CCE();
    if (!equals(this.s14_1, tmp0_other_with_cast.s14_1))
      return false;
    if (!equals(this.t14_1, tmp0_other_with_cast.t14_1))
      return false;
    return true;
  };
  function MapEntrySerializer$descriptor$lambda($keySerializer, $valueSerializer) {
    return function ($this$buildSerialDescriptor) {
      $this$buildSerialDescriptor.an('key', $keySerializer.pm());
      $this$buildSerialDescriptor.an('value', $valueSerializer.pm());
      return Unit_instance;
    };
  }
  function MapEntrySerializer_0(keySerializer, valueSerializer) {
    KeyValueSerializer.call(this, keySerializer, valueSerializer);
    var tmp = this;
    var tmp_0 = MAP_getInstance();
    tmp.w14_1 = buildSerialDescriptor('kotlin.collections.Map.Entry', tmp_0, [], MapEntrySerializer$descriptor$lambda(keySerializer, valueSerializer));
  }
  protoOf(MapEntrySerializer_0).pm = function () {
    return this.w14_1;
  };
  protoOf(MapEntrySerializer_0).x14 = function (_this__u8e3s4) {
    return _this__u8e3s4.z1();
  };
  protoOf(MapEntrySerializer_0).y14 = function (_this__u8e3s4) {
    return this.x14((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Entry) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(MapEntrySerializer_0).z14 = function (_this__u8e3s4) {
    return _this__u8e3s4.a2();
  };
  protoOf(MapEntrySerializer_0).a15 = function (_this__u8e3s4) {
    return this.z14((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Entry) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(MapEntrySerializer_0).b15 = function (key, value) {
    return new MapEntry(key, value);
  };
  function PairSerializer$descriptor$lambda($keySerializer, $valueSerializer) {
    return function ($this$buildClassSerialDescriptor) {
      $this$buildClassSerialDescriptor.an('first', $keySerializer.pm());
      $this$buildClassSerialDescriptor.an('second', $valueSerializer.pm());
      return Unit_instance;
    };
  }
  function PairSerializer_0(keySerializer, valueSerializer) {
    KeyValueSerializer.call(this, keySerializer, valueSerializer);
    var tmp = this;
    tmp.h15_1 = buildClassSerialDescriptor('kotlin.Pair', [], PairSerializer$descriptor$lambda(keySerializer, valueSerializer));
  }
  protoOf(PairSerializer_0).pm = function () {
    return this.h15_1;
  };
  protoOf(PairSerializer_0).i15 = function (_this__u8e3s4) {
    return _this__u8e3s4.jf_1;
  };
  protoOf(PairSerializer_0).y14 = function (_this__u8e3s4) {
    return this.i15(_this__u8e3s4 instanceof Pair ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(PairSerializer_0).j15 = function (_this__u8e3s4) {
    return _this__u8e3s4.kf_1;
  };
  protoOf(PairSerializer_0).a15 = function (_this__u8e3s4) {
    return this.j15(_this__u8e3s4 instanceof Pair ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(PairSerializer_0).b15 = function (key, value) {
    return to(key, value);
  };
  function decodeSequentially_1($this, composite) {
    var a = composite.kq($this.n15_1, 0, $this.k15_1);
    var b = composite.kq($this.n15_1, 1, $this.l15_1);
    var c = composite.kq($this.n15_1, 2, $this.m15_1);
    composite.yp($this.n15_1);
    return new Triple(a, b, c);
  }
  function decodeStructure($this, composite) {
    var a = get_NULL();
    var b = get_NULL();
    var c = get_NULL();
    mainLoop: while (true) {
      var index = composite.oq($this.n15_1);
      switch (index) {
        case -1:
          break mainLoop;
        case 0:
          a = composite.kq($this.n15_1, 0, $this.k15_1);
          break;
        case 1:
          b = composite.kq($this.n15_1, 1, $this.l15_1);
          break;
        case 2:
          c = composite.kq($this.n15_1, 2, $this.m15_1);
          break;
        default:
          throw SerializationException_init_$Create$_0('Unexpected index ' + index);
      }
    }
    composite.yp($this.n15_1);
    if (a === get_NULL())
      throw SerializationException_init_$Create$_0("Element 'first' is missing");
    if (b === get_NULL())
      throw SerializationException_init_$Create$_0("Element 'second' is missing");
    if (c === get_NULL())
      throw SerializationException_init_$Create$_0("Element 'third' is missing");
    var tmp = (a == null ? true : !(a == null)) ? a : THROW_CCE();
    var tmp_0 = (b == null ? true : !(b == null)) ? b : THROW_CCE();
    return new Triple(tmp, tmp_0, (c == null ? true : !(c == null)) ? c : THROW_CCE());
  }
  function TripleSerializer$descriptor$lambda(this$0) {
    return function ($this$buildClassSerialDescriptor) {
      $this$buildClassSerialDescriptor.an('first', this$0.k15_1.pm());
      $this$buildClassSerialDescriptor.an('second', this$0.l15_1.pm());
      $this$buildClassSerialDescriptor.an('third', this$0.m15_1.pm());
      return Unit_instance;
    };
  }
  function TripleSerializer_0(aSerializer, bSerializer, cSerializer) {
    this.k15_1 = aSerializer;
    this.l15_1 = bSerializer;
    this.m15_1 = cSerializer;
    var tmp = this;
    tmp.n15_1 = buildClassSerialDescriptor('kotlin.Triple', [], TripleSerializer$descriptor$lambda(this));
  }
  protoOf(TripleSerializer_0).pm = function () {
    return this.n15_1;
  };
  protoOf(TripleSerializer_0).o15 = function (encoder, value) {
    var structuredEncoder = encoder.xp(this.n15_1);
    structuredEncoder.or(this.n15_1, 0, this.k15_1, value.tk_1);
    structuredEncoder.or(this.n15_1, 1, this.l15_1, value.uk_1);
    structuredEncoder.or(this.n15_1, 2, this.m15_1, value.vk_1);
    structuredEncoder.yp(this.n15_1);
  };
  protoOf(TripleSerializer_0).qm = function (encoder, value) {
    return this.o15(encoder, value instanceof Triple ? value : THROW_CCE());
  };
  protoOf(TripleSerializer_0).rm = function (decoder) {
    var composite = decoder.xp(this.n15_1);
    if (composite.nq()) {
      return decodeSequentially_1(this, composite);
    }
    return decodeStructure(this, composite);
  };
  function KeyValueSerializer(keySerializer, valueSerializer) {
    this.c15_1 = keySerializer;
    this.d15_1 = valueSerializer;
  }
  protoOf(KeyValueSerializer).e15 = function (encoder, value) {
    var structuredEncoder = encoder.xp(this.pm());
    structuredEncoder.or(this.pm(), 0, this.c15_1, this.y14(value));
    structuredEncoder.or(this.pm(), 1, this.d15_1, this.a15(value));
    structuredEncoder.yp(this.pm());
  };
  protoOf(KeyValueSerializer).qm = function (encoder, value) {
    return this.e15(encoder, (value == null ? true : !(value == null)) ? value : THROW_CCE());
  };
  protoOf(KeyValueSerializer).rm = function (decoder) {
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var descriptor = this.pm();
    var composite = decoder.xp(descriptor);
    var tmp$ret$0;
    $l$block: {
      // Inline function 'kotlinx.serialization.internal.KeyValueSerializer.deserialize.<anonymous>' call
      if (composite.nq()) {
        var key = composite.kq(this.pm(), 0, this.c15_1);
        var value = composite.kq(this.pm(), 1, this.d15_1);
        tmp$ret$0 = this.b15(key, value);
        break $l$block;
      }
      var key_0 = get_NULL();
      var value_0 = get_NULL();
      mainLoop: while (true) {
        var idx = composite.oq(this.pm());
        switch (idx) {
          case -1:
            break mainLoop;
          case 0:
            key_0 = composite.kq(this.pm(), 0, this.c15_1);
            break;
          case 1:
            value_0 = composite.kq(this.pm(), 1, this.d15_1);
            break;
          default:
            throw SerializationException_init_$Create$_0('Invalid index: ' + idx);
        }
      }
      if (key_0 === get_NULL())
        throw SerializationException_init_$Create$_0("Element 'key' is missing");
      if (value_0 === get_NULL())
        throw SerializationException_init_$Create$_0("Element 'value' is missing");
      var tmp = (key_0 == null ? true : !(key_0 == null)) ? key_0 : THROW_CCE();
      tmp$ret$0 = this.b15(tmp, (value_0 == null ? true : !(value_0 == null)) ? value_0 : THROW_CCE());
    }
    var result = tmp$ret$0;
    composite.yp(descriptor);
    return result;
  };
  var properties_initialized_Tuples_kt_3vs7ar;
  function _init_properties_Tuples_kt__dz0qyd() {
    if (!properties_initialized_Tuples_kt_3vs7ar) {
      properties_initialized_Tuples_kt_3vs7ar = true;
      NULL = new Object();
    }
  }
  function ULongSerializer() {
    ULongSerializer_instance = this;
    this.p15_1 = InlinePrimitiveDescriptor('kotlin.ULong', serializer_4(Companion_getInstance_1()));
  }
  protoOf(ULongSerializer).pm = function () {
    return this.p15_1;
  };
  protoOf(ULongSerializer).q15 = function (encoder, value) {
    var tmp = encoder.dr(this.p15_1);
    // Inline function 'kotlin.ULong.toLong' call
    var tmp$ret$0 = _ULong___get_data__impl__fggpzb(value);
    tmp.xq(tmp$ret$0);
  };
  protoOf(ULongSerializer).qm = function (encoder, value) {
    return this.q15(encoder, value instanceof ULong ? value.xl_1 : THROW_CCE());
  };
  protoOf(ULongSerializer).r15 = function (decoder) {
    // Inline function 'kotlin.toULong' call
    var this_0 = decoder.up(this.p15_1).op();
    return _ULong___init__impl__c78o9k(this_0);
  };
  protoOf(ULongSerializer).rm = function (decoder) {
    return new ULong(this.r15(decoder));
  };
  var ULongSerializer_instance;
  function ULongSerializer_getInstance() {
    if (ULongSerializer_instance == null)
      new ULongSerializer();
    return ULongSerializer_instance;
  }
  function UIntSerializer() {
    UIntSerializer_instance = this;
    this.s15_1 = InlinePrimitiveDescriptor('kotlin.UInt', serializer_6(IntCompanionObject_instance));
  }
  protoOf(UIntSerializer).pm = function () {
    return this.s15_1;
  };
  protoOf(UIntSerializer).t15 = function (encoder, value) {
    var tmp = encoder.dr(this.s15_1);
    // Inline function 'kotlin.UInt.toInt' call
    var tmp$ret$0 = _UInt___get_data__impl__f0vqqw(value);
    tmp.wq(tmp$ret$0);
  };
  protoOf(UIntSerializer).qm = function (encoder, value) {
    return this.t15(encoder, value instanceof UInt ? value.ml_1 : THROW_CCE());
  };
  protoOf(UIntSerializer).u15 = function (decoder) {
    // Inline function 'kotlin.toUInt' call
    var this_0 = decoder.up(this.s15_1).np();
    return _UInt___init__impl__l7qpdl(this_0);
  };
  protoOf(UIntSerializer).rm = function (decoder) {
    return new UInt(this.u15(decoder));
  };
  var UIntSerializer_instance;
  function UIntSerializer_getInstance() {
    if (UIntSerializer_instance == null)
      new UIntSerializer();
    return UIntSerializer_instance;
  }
  function UShortSerializer() {
    UShortSerializer_instance = this;
    this.v15_1 = InlinePrimitiveDescriptor('kotlin.UShort', serializer_8(ShortCompanionObject_instance));
  }
  protoOf(UShortSerializer).pm = function () {
    return this.v15_1;
  };
  protoOf(UShortSerializer).w15 = function (encoder, value) {
    var tmp = encoder.dr(this.v15_1);
    // Inline function 'kotlin.UShort.toShort' call
    var tmp$ret$0 = _UShort___get_data__impl__g0245(value);
    tmp.vq(tmp$ret$0);
  };
  protoOf(UShortSerializer).qm = function (encoder, value) {
    return this.w15(encoder, value instanceof UShort ? value.im_1 : THROW_CCE());
  };
  protoOf(UShortSerializer).x15 = function (decoder) {
    // Inline function 'kotlin.toUShort' call
    var this_0 = decoder.up(this.v15_1).mp();
    return _UShort___init__impl__jigrne(this_0);
  };
  protoOf(UShortSerializer).rm = function (decoder) {
    return new UShort(this.x15(decoder));
  };
  var UShortSerializer_instance;
  function UShortSerializer_getInstance() {
    if (UShortSerializer_instance == null)
      new UShortSerializer();
    return UShortSerializer_instance;
  }
  function UByteSerializer() {
    UByteSerializer_instance = this;
    this.y15_1 = InlinePrimitiveDescriptor('kotlin.UByte', serializer_10(ByteCompanionObject_instance));
  }
  protoOf(UByteSerializer).pm = function () {
    return this.y15_1;
  };
  protoOf(UByteSerializer).z15 = function (encoder, value) {
    var tmp = encoder.dr(this.y15_1);
    // Inline function 'kotlin.UByte.toByte' call
    var tmp$ret$0 = _UByte___get_data__impl__jof9qr(value);
    tmp.uq(tmp$ret$0);
  };
  protoOf(UByteSerializer).qm = function (encoder, value) {
    return this.z15(encoder, value instanceof UByte ? value.bl_1 : THROW_CCE());
  };
  protoOf(UByteSerializer).a16 = function (decoder) {
    // Inline function 'kotlin.toUByte' call
    var this_0 = decoder.up(this.y15_1).lp();
    return _UByte___init__impl__g9hnc4(this_0);
  };
  protoOf(UByteSerializer).rm = function (decoder) {
    return new UByte(this.a16(decoder));
  };
  var UByteSerializer_instance;
  function UByteSerializer_getInstance() {
    if (UByteSerializer_instance == null)
      new UByteSerializer();
    return UByteSerializer_instance;
  }
  function get_EmptySerializersModuleLegacyJs() {
    _init_properties_SerializersModule_kt__u78ha3();
    return EmptySerializersModule;
  }
  var EmptySerializersModule;
  function SerializersModule() {
  }
  protoOf(SerializersModule).un = function (kClass, typeArgumentsSerializers, $super) {
    typeArgumentsSerializers = typeArgumentsSerializers === VOID ? emptyList() : typeArgumentsSerializers;
    return $super === VOID ? this.tn(kClass, typeArgumentsSerializers) : $super.tn.call(this, kClass, typeArgumentsSerializers);
  };
  function SerialModuleImpl(class2ContextualFactory, polyBase2Serializers, polyBase2DefaultSerializerProvider, polyBase2NamedSerializers, polyBase2DefaultDeserializerProvider) {
    SerializersModule.call(this);
    this.c16_1 = class2ContextualFactory;
    this.d16_1 = polyBase2Serializers;
    this.e16_1 = polyBase2DefaultSerializerProvider;
    this.f16_1 = polyBase2NamedSerializers;
    this.g16_1 = polyBase2DefaultDeserializerProvider;
  }
  protoOf(SerialModuleImpl).wr = function (baseClass, value) {
    if (!baseClass.la(value))
      return null;
    var tmp0_safe_receiver = this.d16_1.d2(baseClass);
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.d2(getKClassFromExpression(value));
    var registered = (!(tmp == null) ? isInterface(tmp, SerializationStrategy) : false) ? tmp : null;
    if (!(registered == null))
      return registered;
    var tmp_0 = this.e16_1.d2(baseClass);
    var tmp1_safe_receiver = (!(tmp_0 == null) ? typeof tmp_0 === 'function' : false) ? tmp_0 : null;
    return tmp1_safe_receiver == null ? null : tmp1_safe_receiver(value);
  };
  protoOf(SerialModuleImpl).vr = function (baseClass, serializedClassName) {
    var tmp0_safe_receiver = this.f16_1.d2(baseClass);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      // Inline function 'kotlin.collections.get' call
      tmp = (isInterface(tmp0_safe_receiver, KtMap) ? tmp0_safe_receiver : THROW_CCE()).d2(serializedClassName);
    }
    var tmp_0 = tmp;
    var registered = (!(tmp_0 == null) ? isInterface(tmp_0, KSerializer) : false) ? tmp_0 : null;
    if (!(registered == null))
      return registered;
    var tmp_1 = this.g16_1.d2(baseClass);
    var tmp1_safe_receiver = (!(tmp_1 == null) ? typeof tmp_1 === 'function' : false) ? tmp_1 : null;
    return tmp1_safe_receiver == null ? null : tmp1_safe_receiver(serializedClassName);
  };
  protoOf(SerialModuleImpl).tn = function (kClass, typeArgumentsSerializers) {
    var tmp0_safe_receiver = this.c16_1.d2(kClass);
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.h16(typeArgumentsSerializers);
    return (tmp == null ? true : isInterface(tmp, KSerializer)) ? tmp : null;
  };
  protoOf(SerialModuleImpl).b16 = function (collector) {
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s = this.c16_1.g2().k();
    while (_iterator__ex2g4s.l()) {
      var element = _iterator__ex2g4s.m();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var kclass = element.z1();
      // Inline function 'kotlin.collections.component2' call
      var serial = element.a2();
      if (serial instanceof Argless) {
        var tmp = isInterface(kclass, KClass) ? kclass : THROW_CCE();
        var tmp_0 = serial.k16_1;
        collector.l16(tmp, isInterface(tmp_0, KSerializer) ? tmp_0 : THROW_CCE());
      } else {
        if (serial instanceof WithTypeArguments) {
          collector.j16(kclass, serial.i16_1);
        }
      }
    }
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s_0 = this.d16_1.g2().k();
    while (_iterator__ex2g4s_0.l()) {
      var element_0 = _iterator__ex2g4s_0.m();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var baseClass = element_0.z1();
      // Inline function 'kotlin.collections.component2' call
      var classMap = element_0.a2();
      // Inline function 'kotlin.collections.forEach' call
      // Inline function 'kotlin.collections.iterator' call
      var _iterator__ex2g4s_1 = classMap.g2().k();
      while (_iterator__ex2g4s_1.l()) {
        var element_1 = _iterator__ex2g4s_1.m();
        // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>.<anonymous>' call
        // Inline function 'kotlin.collections.component1' call
        var actualClass = element_1.z1();
        // Inline function 'kotlin.collections.component2' call
        var serializer = element_1.a2();
        var tmp_1 = isInterface(baseClass, KClass) ? baseClass : THROW_CCE();
        var tmp_2 = isInterface(actualClass, KClass) ? actualClass : THROW_CCE();
        // Inline function 'kotlinx.serialization.internal.cast' call
        var tmp$ret$9 = isInterface(serializer, KSerializer) ? serializer : THROW_CCE();
        collector.m16(tmp_1, tmp_2, tmp$ret$9);
      }
    }
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s_2 = this.e16_1.g2().k();
    while (_iterator__ex2g4s_2.l()) {
      var element_2 = _iterator__ex2g4s_2.m();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var baseClass_0 = element_2.z1();
      // Inline function 'kotlin.collections.component2' call
      var provider = element_2.a2();
      var tmp_3 = isInterface(baseClass_0, KClass) ? baseClass_0 : THROW_CCE();
      collector.n16(tmp_3, typeof provider === 'function' ? provider : THROW_CCE());
    }
    // Inline function 'kotlin.collections.forEach' call
    // Inline function 'kotlin.collections.iterator' call
    var _iterator__ex2g4s_3 = this.g16_1.g2().k();
    while (_iterator__ex2g4s_3.l()) {
      var element_3 = _iterator__ex2g4s_3.m();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      // Inline function 'kotlin.collections.component1' call
      var baseClass_1 = element_3.z1();
      // Inline function 'kotlin.collections.component2' call
      var provider_0 = element_3.a2();
      var tmp_4 = isInterface(baseClass_1, KClass) ? baseClass_1 : THROW_CCE();
      collector.o16(tmp_4, typeof provider_0 === 'function' ? provider_0 : THROW_CCE());
    }
  };
  function Argless() {
  }
  function WithTypeArguments() {
  }
  function ContextualProvider() {
  }
  var properties_initialized_SerializersModule_kt_fjigjn;
  function _init_properties_SerializersModule_kt__u78ha3() {
    if (!properties_initialized_SerializersModule_kt_fjigjn) {
      properties_initialized_SerializersModule_kt_fjigjn = true;
      EmptySerializersModule = new SerialModuleImpl(emptyMap(), emptyMap(), emptyMap(), emptyMap(), emptyMap());
    }
  }
  function EmptySerializersModule_0() {
    return get_EmptySerializersModuleLegacyJs();
  }
  function SerializersModuleCollector$contextual$lambda($serializer) {
    return function (it) {
      return $serializer;
    };
  }
  function SerializersModuleCollector() {
  }
  function SerializableWith(serializer) {
    this.p16_1 = serializer;
  }
  protoOf(SerializableWith).equals = function (other) {
    if (!(other instanceof SerializableWith))
      return false;
    var tmp0_other_with_cast = other instanceof SerializableWith ? other : THROW_CCE();
    if (!this.p16_1.equals(tmp0_other_with_cast.p16_1))
      return false;
    return true;
  };
  protoOf(SerializableWith).hashCode = function () {
    return imul(getStringHashCode('serializer'), 127) ^ this.p16_1.hashCode();
  };
  protoOf(SerializableWith).toString = function () {
    return '@kotlinx.serialization.SerializableWith(' + 'serializer=' + toString(this.p16_1) + ')';
  };
  function createCache(factory) {
    return new createCache$1(factory);
  }
  function createParametrizedCache(factory) {
    return new createParametrizedCache$1(factory);
  }
  function compiledSerializerImpl(_this__u8e3s4) {
    var tmp1_elvis_lhs = constructSerializerForGivenTypeArgs(_this__u8e3s4, []);
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp_0;
      if (_this__u8e3s4 === PrimitiveClasses_getInstance().db()) {
        tmp_0 = NothingSerializer_getInstance();
      } else {
        // Inline function 'kotlin.js.asDynamic' call
        var tmp0_safe_receiver = get_js(_this__u8e3s4).Companion;
        tmp_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.serializer();
      }
      var tmp_1 = tmp_0;
      tmp = (!(tmp_1 == null) ? isInterface(tmp_1, KSerializer) : false) ? tmp_1 : null;
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  function platformSpecificSerializerNotRegistered(_this__u8e3s4) {
    throw SerializationException_init_$Create$_0(notRegisteredMessage(_this__u8e3s4) + 'To get enum serializer on Kotlin/JS, it should be annotated with @Serializable annotation.');
  }
  function isReferenceArray(rootClass) {
    return rootClass.equals(PrimitiveClasses_getInstance().kb());
  }
  function constructSerializerForGivenTypeArgs(_this__u8e3s4, args) {
    var tmp;
    try {
      // Inline function 'kotlin.reflect.findAssociatedObject' call
      var assocObject = findAssociatedObject(_this__u8e3s4, getKClass(SerializableWith));
      var tmp_0;
      if (!(assocObject == null) ? isInterface(assocObject, KSerializer) : false) {
        tmp_0 = (!(assocObject == null) ? isInterface(assocObject, KSerializer) : false) ? assocObject : THROW_CCE();
      } else {
        if (!(assocObject == null) ? isInterface(assocObject, SerializerFactory) : false) {
          var tmp_1 = assocObject.jy(args.slice());
          tmp_0 = isInterface(tmp_1, KSerializer) ? tmp_1 : THROW_CCE();
        } else {
          if (get_isInterface(_this__u8e3s4)) {
            tmp_0 = new PolymorphicSerializer(_this__u8e3s4);
          } else {
            tmp_0 = null;
          }
        }
      }
      tmp = tmp_0;
    } catch ($p) {
      var tmp_2;
      var e = $p;
      tmp_2 = null;
      tmp = tmp_2;
    }
    return tmp;
  }
  function get_isInterface(_this__u8e3s4) {
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_safe_receiver = get_js(_this__u8e3s4).$metadata$;
    return (tmp0_safe_receiver == null ? null : tmp0_safe_receiver.kind) == 'interface';
  }
  function toNativeArrayImpl(_this__u8e3s4, eClass) {
    // Inline function 'kotlin.collections.toTypedArray' call
    return copyToArray(_this__u8e3s4);
  }
  function getChecked(_this__u8e3s4, index) {
    if (!(0 <= index ? index <= (_this__u8e3s4.length - 1 | 0) : false))
      throw IndexOutOfBoundsException_init_$Create$('Index ' + index + ' out of bounds ' + get_indices(_this__u8e3s4).toString());
    return _this__u8e3s4[index];
  }
  function getChecked_0(_this__u8e3s4, index) {
    if (!(0 <= index ? index <= (_this__u8e3s4.length - 1 | 0) : false))
      throw IndexOutOfBoundsException_init_$Create$('Index ' + index + ' out of bounds ' + get_indices_0(_this__u8e3s4).toString());
    return _this__u8e3s4[index];
  }
  function createCache$1($factory) {
    this.q16_1 = $factory;
  }
  protoOf(createCache$1).vn = function (key) {
    return this.q16_1(key);
  };
  function createParametrizedCache$1($factory) {
    this.r16_1 = $factory;
  }
  protoOf(createParametrizedCache$1).wn = function (key, types) {
    // Inline function 'kotlin.runCatching' call
    var tmp;
    try {
      // Inline function 'kotlinx.serialization.internal.<no name provided>.get.<anonymous>' call
      // Inline function 'kotlin.Companion.success' call
      var value = this.r16_1(key, types);
      tmp = _Result___init__impl__xyqfz8(value);
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        // Inline function 'kotlin.Companion.failure' call
        tmp_0 = _Result___init__impl__xyqfz8(createFailure(e));
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  //region block: post-declaration
  protoOf(SerialDescriptorImpl).xn = get_isNullable;
  protoOf(SerialDescriptorImpl).do = get_isInline;
  protoOf(AbstractDecoder).kq = decodeSerializableElement$default;
  protoOf(AbstractDecoder).wp = decodeSerializableValue;
  protoOf(AbstractDecoder).nq = decodeSequentially;
  protoOf(AbstractDecoder).pq = decodeCollectionSize;
  protoOf(AbstractEncoder).sr = encodeNotNullMark;
  protoOf(AbstractEncoder).tr = beginCollection;
  protoOf(AbstractEncoder).pr = encodeSerializableValue;
  protoOf(AbstractEncoder).rr = encodeNullableSerializableValue;
  protoOf(AbstractEncoder).ur = shouldEncodeElementDefault;
  protoOf(ListLikeDescriptor).xn = get_isNullable;
  protoOf(ListLikeDescriptor).do = get_isInline;
  protoOf(ListLikeDescriptor).bo = get_annotations;
  protoOf(MapLikeDescriptor).xn = get_isNullable;
  protoOf(MapLikeDescriptor).do = get_isInline;
  protoOf(MapLikeDescriptor).bo = get_annotations;
  protoOf(PluginGeneratedSerialDescriptor).xn = get_isNullable;
  protoOf(PluginGeneratedSerialDescriptor).do = get_isInline;
  protoOf(InlinePrimitiveDescriptor$1).zx = typeParametersSerializers;
  protoOf(NothingSerialDescriptor).xn = get_isNullable;
  protoOf(NothingSerialDescriptor).do = get_isInline;
  protoOf(NothingSerialDescriptor).bo = get_annotations;
  protoOf(PrimitiveSerialDescriptor_0).xn = get_isNullable;
  protoOf(PrimitiveSerialDescriptor_0).do = get_isInline;
  protoOf(PrimitiveSerialDescriptor_0).bo = get_annotations;
  protoOf(TaggedDecoder).kq = decodeSerializableElement$default;
  protoOf(TaggedDecoder).wp = decodeSerializableValue;
  protoOf(TaggedDecoder).nq = decodeSequentially;
  protoOf(TaggedDecoder).pq = decodeCollectionSize;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = SealedClassSerializer_init_$Create$;
  _.$_$.b = SerializationException_init_$Init$_0;
  _.$_$.c = SerializationException_init_$Create$_0;
  _.$_$.d = UnknownFieldException_init_$Create$;
  _.$_$.e = SEALED_getInstance;
  _.$_$.f = STRING_getInstance;
  _.$_$.g = CONTEXTUAL_getInstance;
  _.$_$.h = ENUM_getInstance;
  _.$_$.i = CLASS_getInstance;
  _.$_$.j = LIST_getInstance;
  _.$_$.k = MAP_getInstance;
  _.$_$.l = BooleanSerializer_getInstance;
  _.$_$.m = DoubleSerializer_getInstance;
  _.$_$.n = FloatSerializer_getInstance;
  _.$_$.o = IntSerializer_getInstance;
  _.$_$.p = LongSerializer_getInstance;
  _.$_$.q = ShortSerializer_getInstance;
  _.$_$.r = StringSerializer_getInstance;
  _.$_$.s = ListSerializer;
  _.$_$.t = MapSerializer;
  _.$_$.u = get_nullable;
  _.$_$.v = serializer_0;
  _.$_$.w = serializer_9;
  _.$_$.x = serializer_7;
  _.$_$.y = serializer_11;
  _.$_$.z = serializer_5;
  _.$_$.a1 = PolymorphicKind;
  _.$_$.b1 = PrimitiveKind;
  _.$_$.c1 = PrimitiveSerialDescriptor;
  _.$_$.d1 = get_annotations;
  _.$_$.e1 = get_isInline;
  _.$_$.f1 = get_isNullable;
  _.$_$.g1 = SerialDescriptor;
  _.$_$.h1 = ENUM;
  _.$_$.i1 = buildSerialDescriptor;
  _.$_$.j1 = getContextualDescriptor;
  _.$_$.k1 = AbstractDecoder;
  _.$_$.l1 = AbstractEncoder;
  _.$_$.m1 = CompositeDecoder;
  _.$_$.n1 = CompositeEncoder;
  _.$_$.o1 = Decoder;
  _.$_$.p1 = Encoder;
  _.$_$.q1 = AbstractPolymorphicSerializer;
  _.$_$.r1 = ArrayListSerializer;
  _.$_$.s1 = ElementMarker;
  _.$_$.t1 = typeParametersSerializers;
  _.$_$.u1 = GeneratedSerializer;
  _.$_$.v1 = InlinePrimitiveDescriptor;
  _.$_$.w1 = LinkedHashMapSerializer;
  _.$_$.x1 = LinkedHashSetSerializer;
  _.$_$.y1 = NamedValueDecoder;
  _.$_$.z1 = PluginGeneratedSerialDescriptor;
  _.$_$.a2 = SerializerFactory;
  _.$_$.b2 = createSimpleEnumSerializer;
  _.$_$.c2 = jsonCachedSerialNames;
  _.$_$.d2 = throwMissingFieldException;
  _.$_$.e2 = EmptySerializersModule_0;
  _.$_$.f2 = contextual;
  _.$_$.g2 = SerializersModuleCollector;
  _.$_$.h2 = DeserializationStrategy;
  _.$_$.i2 = KSerializer;
  _.$_$.j2 = MissingFieldException;
  _.$_$.k2 = SealedClassSerializer;
  _.$_$.l2 = SerializationException;
  _.$_$.m2 = findPolymorphicSerializer_0;
  _.$_$.n2 = findPolymorphicSerializer;
  _.$_$.o2 = serializer;
  //endregion
  return _;
}));

